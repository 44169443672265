import { changeArea, closeMenu, openMenu, hideMenu as hideNavigationMenu } from "../state/slices/NavigationMenuSlice";
import { useAppDispatch, useAppSelector } from "./StateHook";

export const useNavigationMenu = (areaName?: string) => {
    const dispatch = useAppDispatch();
    const { area, open } = useAppSelector(state => state.navigationMenu);

    const displayMenuArea = () => {
        dispatch(changeArea(areaName));
    }

    const hideMenu = () => {        
        dispatch(hideNavigationMenu());
    }

    const expandMenu = (expand: boolean) => {
        if (expand) {
            dispatch(openMenu());
        }
        else {
            dispatch(closeMenu());
        }
    }

    return { area, isExpanded: open, displayMenuArea, expandMenu, hideMenu };
};