import { Box } from '@mui/material';
import Paper from '@mui/material/Paper';
import React, { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useGetAssetsQuery, useGetAssetStatusQuery } from '../../../shared/api/AssetApi';
import { useGetEngineerLocationsQuery } from '../../../shared/api/EnginnerLocationApi';
import { useAppSelector, useAppDispatch } from "../../../shared/hooks/StateHook";
import { updateEngineersLayer, updateOpsAreasLayer, updatePowerInterconnectorsLayer, updateSiteLayer } from '../../../shared/state/slices/MapSettingsSlice';
import { IAssetInformation } from '../../../shared/types/IAssetInformation';
import ElectricityInterconnectors from './ElectricityInterconnectors';
import GoogleMapAssetInformationContextProvider from './GoogleMapAssetInformation';
import GoogleMapAssetMarker from './GoogleMapAssetMarker';
import GoogleMapEngineerMarker from './GoogleMapEngineerLocationMarker';
import LayerMenu from './LayerMenu';
import { ISiteStatus } from '../../../shared/types/ISiteStatus';
import GoogleMapCluster from './GoogleMapCluster';
import { useGetGenerationLatestQuery } from '../../../shared/api/ElectricityInterconnectorApi';
import { IEngineerLocation } from '../../../shared/types/engineer-locations/IEngineerLocation';
import { useColourModeContext } from '../../ColourMode';

const GoogleMap: React.FC<IGoogleMapProps> = () => {
    const [initialised, setInitialised] = useState<boolean>(false);
    const { isDarkMode } = useColourModeContext();

    const [map, setMap] = useState<google.maps.Map>();

    const [assetMarkers, setAssetMarkers] = useState<Map<string, IPortalAssetMarker>>();
    const [engineerMarkers, setEngineerMarkers] = useState<Map<number, IPortalEngineerMarker>>();

    const [assetMarkersArray, setAssetMarkersArray] = useState<IPortalAssetMarker[]>();
    const [engineerMarkersArray, setEngineerMarkersArray] = useState<IPortalEngineerMarker[]>();

    const [allMarkers, setAllMarkers] = useState<google.maps.marker.AdvancedMarkerElement[]>([]);

    const mapRef = useRef<HTMLDivElement | null>(null);
    const paperRef = useRef<HTMLDivElement | null>(null);
    const [mapHeight, setMapHeight] = useState(50);
    const { sites: showSites, engineers: showEngineers, opsAreas, powerInterconnectors: showInterconnectors,  presentationMode } = useAppSelector((state) => state.mapSettings);

    const pollingSeconds: number = 60 * 1000;
    const { data: assets } = useGetAssetsQuery();
    const { data: siteStatuses, isSuccess: isSiteStatusesSuccess } = useGetAssetStatusQuery(undefined, { skip: !assets, pollingInterval: pollingSeconds });
    const { data: engineerLocations, isSuccess: isEngineerLocationsSuccess } = useGetEngineerLocationsQuery(undefined, { pollingInterval: pollingSeconds });
    const { data: interconnectorData, isSuccess: isInterconnectorDataSuccess } = useGetGenerationLatestQuery(undefined, { pollingInterval: pollingSeconds });  

    const dispatch = useAppDispatch();

    useEffect(() => { // Set initialised
        if (map && assetMarkers && engineerMarkers && assetMarkersArray && engineerMarkersArray) {
            setInitialised(true);
        }
    }, [map, assetMarkers, engineerMarkers, assetMarkersArray, engineerMarkersArray])

    const getSiteStatus = useCallback((asset: IAssetInformation) => {
        if (isSiteStatusesSuccess) {
            const statusList = siteStatuses.filter((status: ISiteStatus) => status.siteId === asset.SiteID);
            if (statusList.length === 1) {
                return statusList[0];
            }
        }
        return null;
    }, [isSiteStatusesSuccess, siteStatuses]);

    const mapOptions: google.maps.MapOptions = useMemo(() => { // Map options object
        return {
            mapId: isDarkMode ? "b3121ce625ed13fb" : "a7e7648d5059ff35",
            center: { lat: 52.8621832, lng: -2.4051132 },
            zoom: 7.3,
            streetViewControl: false,
            clickableIcons: false,
            isFractionalZoomEnabled: true,
            mapTypeControlOptions: {
                position: google.maps.ControlPosition.RIGHT_TOP
            },            
        }
    }, []);

    useEffect(() => { // Create map        
        const _map = new window.google.maps.Map(mapRef.current as HTMLDivElement, mapOptions);
        setMap(_map);
        window.addEventListener("resize", resizeMap);

        return () => {
            window.removeEventListener("resize", resizeMap);
        };
    }, [mapOptions]);

    useEffect(() => { // Generate area layers
        const layers: google.maps.KmlLayer[] = [];

        if (opsAreas && map) {
            layers.push(new window.google.maps.KmlLayer({
                map: map,
                preserveViewport: true,
                suppressInfoWindows: true,
                url: "https://ionconnectdevstorage.blob.core.windows.net/kml/ConradArea1.kmz"
            }));

            layers.push(new window.google.maps.KmlLayer({
                map: map,
                preserveViewport: true,
                suppressInfoWindows: true,
                url: "https://ionconnectdevstorage.blob.core.windows.net/kml/ConradArea2.kmz"
            }));

            layers.push(new window.google.maps.KmlLayer({
                map: map,
                preserveViewport: true,
                suppressInfoWindows: true,
                url: "https://ionconnectdevstorage.blob.core.windows.net/kml/ConradArea3.kmz"
            }));

            layers.push(new window.google.maps.KmlLayer({
                map: map,
                preserveViewport: true,
                suppressInfoWindows: true,
                url: "https://ionconnectdevstorage.blob.core.windows.net/kml/ConradArea4.kmz"
            }));

            layers.push(new window.google.maps.KmlLayer({
                map: map,
                preserveViewport: true,
                suppressInfoWindows: true,
                url: "https://ionconnectdevstorage.blob.core.windows.net/kml/ConradArea5.kmz"
            }));
        }

        return () => {
            layers.forEach(layer => {
                layer.setMap(null);
            });
        };
    }, [opsAreas, map])

    useEffect(() => {
        let interval = -1;
        let index = 0;
        if (presentationMode) {
            interval = window.setInterval(() => {
                if (index === 0) {
                    dispatch(updateSiteLayer(true));
                    dispatch(updateEngineersLayer(false));
                    dispatch(updateOpsAreasLayer(false));
                    dispatch(updatePowerInterconnectorsLayer(false));
                }
                if (index === 1) {
                    dispatch(updateSiteLayer(false));
                    dispatch(updateEngineersLayer(true));
                    dispatch(updateOpsAreasLayer(false));
                    dispatch(updatePowerInterconnectorsLayer(false));
                }
                if (index === 2) {
                    dispatch(updateSiteLayer(false));
                    dispatch(updateEngineersLayer(false));
                    dispatch(updateOpsAreasLayer(true));
                    dispatch(updatePowerInterconnectorsLayer(false));
                }
                if (index === 4) {
                    dispatch(updateSiteLayer(false));
                    dispatch(updateEngineersLayer(false));
                    dispatch(updateOpsAreasLayer(false));
                    dispatch(updatePowerInterconnectorsLayer(true));
                }
                index++;
                if (index === 4) index = 0;
            }, 10000);
        } else if (interval !== -1) {
            window.clearInterval(interval);
        }
        return () => window.clearInterval(interval);
    }, [presentationMode, dispatch])

    const resizeMap = () => {
        const mapContainer = paperRef.current as HTMLDivElement;
        if (mapContainer !== null) {
            setMapHeight(window.innerHeight - 190);
        }
    }
    useEffect(() => {
        resizeMap();
    });

    useEffect(() => { // Generate site markers
        if (!assets) {
            return;
        }

        setAssetMarkers((currentMarkers) => {
            const markers = new Map(currentMarkers);
            assets.filter(a => a.Latitude !== null && a.Longitude !== null).forEach((asset) => {
                let assetMarker: IPortalAssetMarker | undefined;

                if (markers.has(asset.SiteID)) { // If we already have a marker for this site, retrieve it
                    assetMarker = markers.get(asset.SiteID);
                }

                if (!assetMarker) { // If we don't have one, create a new one
                    assetMarker = {
                        marker: new google.maps.marker.AdvancedMarkerElement(),
                        asset,
                        status: getSiteStatus(asset)
                    }
                    markers.set(asset.SiteID, assetMarker); // Add to map
                } else {
                    assetMarker.asset = asset; // Otherwise update the asset
                    assetMarker.status = getSiteStatus(asset);
                }
            });

            return markers;
        });
    }, [assets, getSiteStatus]);

    useEffect(() => { // Generate engineer markers
        if (!engineerLocations) {
            return;
        }

        setEngineerMarkers((currentMarkers) => {
            const markers = new Map(currentMarkers);
            engineerLocations.forEach((engineer) => {
                let engineerMarker: IPortalEngineerMarker | undefined;

                if (markers.has(engineer.VehicleId)) { // If we already have a marker for this site, retrieve it
                    engineerMarker = markers.get(engineer.VehicleId);
                }

                if (!engineerMarker) { // If we don't have one, create a new one
                    engineerMarker = {
                        marker: new google.maps.marker.AdvancedMarkerElement(),
                        engineer,
                    }
                    markers.set(engineer.VehicleId, engineerMarker); // Add to map
                } else {
                    engineerMarker.engineer = engineer; // Otherwise update the asset
                }
            });

            return markers;
        });
    }, [engineerLocations]);

    useEffect(() => {
        if (!showSites) {
            setAssetMarkersArray([]);
        } else if (assetMarkers) {
            const assetArray = Array.from(assetMarkers.values());
            setAssetMarkersArray(assetArray);
        }
    }, [assetMarkers, showSites]);

    useEffect(() => {
        if (!showEngineers) {
            setEngineerMarkersArray([]);
        } else if (engineerMarkers) {
            const engineerArray = Array.from(engineerMarkers.values());
            setEngineerMarkersArray(engineerArray);
        }
    }, [engineerMarkers, showEngineers]);

    useEffect(() => {
        const markers = [];
        if (assetMarkersArray) {
            markers.push(...assetMarkersArray.map(am => am.marker));
        }

        if (engineerMarkersArray) {
            markers.push(...engineerMarkersArray.map(em => em.marker));
        }

        setAllMarkers(markers);
    }, [assetMarkersArray, engineerMarkersArray]);

    return (
        <GoogleMapAssetInformationContextProvider>
            <Paper ref={paperRef}>
                <Box sx={{ height: mapHeight }} ref={mapRef} id="map" />
                <LayerMenu />
                {
                    initialised && map && assetMarkersArray && engineerMarkersArray &&
                    <>
                        {assetMarkersArray.map((am) => <GoogleMapAssetMarker key={am.asset.SiteID} asset={am.asset} map={map} status={am.status} marker={am.marker} />)}
                        {engineerMarkersArray.map((em) => <GoogleMapEngineerMarker key={em.engineer.VehicleId} engineer={em.engineer} map={map} marker={em.marker} />)}
                        {interconnectorData && showInterconnectors && <ElectricityInterconnectors map={map} data={interconnectorData} />}
                        {<GoogleMapCluster map={map} markers={allMarkers} />}
                    </>
                }
            </Paper>
        </GoogleMapAssetInformationContextProvider>
    )
}

export interface IGoogleMapProps {

}

export interface IPortalMarker {
    marker: google.maps.marker.AdvancedMarkerElement;
}

interface IPortalAssetMarker extends IPortalMarker {
    asset: IAssetInformation;
    status: ISiteStatus | null;
}

interface IPortalEngineerMarker extends IPortalMarker {
    engineer: IEngineerLocation;
}

export enum MarkerType {
    Asset = "Asset",
    Engineer = "Engineer",
}

export interface IMarkerData {
    type: MarkerType;
    status?: ISiteStatus | null;
    engineer?: IEngineerLocation;
}

export default GoogleMap;