import { Paper, Typography } from "@mui/material";
import React, { useCallback, useEffect } from "react";
import PageWrapper from "../../../components/PageWrapper";

import { Dashboard, Speed } from "@mui/icons-material";
import { useTheme } from '@mui/material/styles';
import LogDataGrid from "../../../components/LogDataGrid";
import { useToastAlertContext } from '../../../components/ToastAlert';
import loggingApi from "../../../shared/api/LoggingApi";
import { ILogEntries } from "../../../shared/types/ILogEntries";
import { IPortalRouteOptions } from "../../../shared/types/shared/routes/routeTypes";
import TableToolbar from "../../../components/shared/table/TableToolbar";

const FrequencyServicesHomePage: React.FC<IFrequencyServicesHomePageProps> = () => {
    const [logEntriesBcp, setLogEntriesBcp] = React.useState<ILogEntries>();

    const [loaded, setLoaded] = React.useState(false);
    const { popToast } = useToastAlertContext();
    const theme = useTheme();

    const appIdBcp = "iON Battery Contracts Processor (PROD)";

    const loadComplete = () => {
        setLoaded(true);
    }

    const load = useCallback(() => {
        const logdataBcp = loggingApi.getLogEntriesForAppId(appIdBcp).then((data) => {
            setLogEntriesBcp(data);
        })

        const promises = [logdataBcp];
        Promise.all(promises).catch((error: Error) => popToast(error.message, "error")).finally(loadComplete);
    }, [popToast]);

    useEffect(() => {
        load();
    }, [load])

    return (
        <PageWrapper title="Frequency Services Dashboard" loaded={loaded}>
            <Paper sx={{ height: "calc(100% - 1px)" }}>
                <TableToolbar title="Battery Contract Processor Log" />
                <LogDataGrid items={logEntriesBcp} height="calc(100% - 70px)"></LogDataGrid>
            </Paper>
        </PageWrapper>
  );
}

export interface IFrequencyServicesHomePageProps {

}

const FrequencyServicesHomePageConfig: IPortalRouteOptions = {
    relativeUrl: "frequency-services",
    page: <FrequencyServicesHomePage />,
    navDisplay: {
        title: "Frequency Services",
        icon: <Speed />,
        nestedTitle: "Dashboard",
        nestedIcon: <Dashboard />,
    }
}

export default FrequencyServicesHomePageConfig;
