import { GenerationType, IAssetInformation } from "../../../shared/types/IAssetInformation";
import { ISiteStatus } from "../../../shared/types/ISiteStatus";
import BatteryIcon from '@mui/icons-material/BatteryChargingFull';
import BoltIcon from '@mui/icons-material/Bolt';
import DieselIcon from '@mui/icons-material/OilBarrel';
import SolarIcon from '@mui/icons-material/SolarPower';
import WindIcon from '@mui/icons-material/WindPower';
import PropaneIcon from '@mui/icons-material/Propane';
import { CSSProperties } from "react";

const GoogleMapAssetIcon: React.FC<IGoogleMapAssetIconProps> = ({ asset, status, height = "100%", width = "100%" }) => {
    const iconStyle: CSSProperties = {
        height: height,
        width: width,
        padding: 2,
        color: status ? (status.textHexColour !== "#ffffff" ? status.textHexColour : "grey") : "black",
    };

    switch (asset?.GenerationType) {
        case GenerationType.Battery:
            return <BatteryIcon style={iconStyle} height={height} width={width} fontSize="large" />;
        case GenerationType.Wind:
            return <WindIcon style={iconStyle} height={height} width={width} fontSize="large" />;
        case GenerationType.Solar:
            return <SolarIcon style={iconStyle} height={height} width={width} fontSize="large" />;
        case GenerationType.Diesel:
            return <DieselIcon style={iconStyle} height={height} width={width} fontSize="large" />
        case GenerationType.Gas:
            return <PropaneIcon style={iconStyle} height={height} width={width} fontSize="large" />
        default:
            return <BoltIcon style={iconStyle} height={height} width={width} fontSize="large" />
    }
}

export interface IGoogleMapAssetIconProps {
    asset?: IAssetInformation;
    status: ISiteStatus | null;
    height?: string | number;
    width?: string | number;
}

export default GoogleMapAssetIcon;
