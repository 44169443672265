import { IEngineTrip } from "../../shared/types/operate/IEngineTrip";
import React, { useEffect } from 'react';
import { GasCommercialOverviewUrl, GasEngineOverviewUrl } from "../../shared/utils/DispathWebUtils"
import { Button, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import { Dictionary } from "@reduxjs/toolkit";
import moment from "moment";

const headCells: HeadCell[] = [
    {
        id: "siteID",
        label: "Site ID"
    },
    {
        id: "engineID",
        label: "Engine ID"
    },
    {
        id: "tripStart",
        label: "Trip Start (Local Time)"
    },
    {
        id: "tripEnd",
        label: "Trip End (Local Time)"
    },
    {
        id: "duration",
        label: "Duration"
    },
]

const EngineTripTable: React.FC<IEngineTripTable> = ({ items, siteIdToName, searchBar }) => {
    const [orderDescending, setOrderDescending] = React.useState<boolean>(false)
    const [orderCategory, setOrderCategory] = React.useState<keyof IEngineTrip>("siteID")
    const [searchContents, setSearchContents] = React.useState<string>("")

    const sortItems = (inputArray: IEngineTrip[], category: keyof IEngineTrip, descending: boolean) => {
        inputArray.sort((a, b) => {
            const aValue = a[category]
            const bValue = b[category]
            if (aValue === null) { return 1 }
            else if (bValue === null) { return -1 }
            else if (aValue < bValue) { return -1 }
            return 1
        })
        if (descending) {
            inputArray.reverse()
        }
        return inputArray
    }

    useEffect(() => {
        setInternalItems(sortItems(items, orderCategory, orderDescending))
    }, [items])

    if (items === undefined) {
        items = []
    }

    const [internalItems, setInternalItems] = React.useState<IEngineTrip[]>(sortItems(items, orderCategory, orderDescending))

    const handleSearchContentsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchContents(event.target.value as string)
    }

    const handleSort = (categoryID: keyof IEngineTrip) => {
        let descending = false
        if (orderCategory === categoryID && !orderDescending) {
            descending = true
        }
        setOrderDescending(descending)
        setOrderCategory(categoryID)
        setInternalItems(sortItems(internalItems, categoryID, descending))
    }

    const filterOutage = (outage: IEngineTrip) => {
        return(
            outage.siteID.toLowerCase().startsWith(searchContents.toLowerCase()) ||
            siteIdToName[outage.siteID]?.toLowerCase().startsWith(searchContents.toLowerCase()) ||
            outage.engineID.toLowerCase().startsWith(searchContents.toLowerCase())
        )
    }

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            {(searchBar ? <TextField
                sx={{"marginBottom": 1}}
                id="search-label"
                label="Search"
                value={searchContents}
                onChange={handleSearchContentsChange}
                name="Search"
                placeholder="Search"
                fullWidth
            /> : <></>)}
            <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader size="small" aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {headCells.map((headCell) => (
                                <TableCell
                                    align="center"
                                    key={headCell.id}
                                >
                                    <IconButton
                                        size="small"
                                        sx={{ "transition": "all .2s", "color": "inherit", "&:hover": { "backgroundColor": "rgba(160, 160, 160, 0.5)" } }}
                                        onClick={() => handleSort(headCell.id)}
                                    >
                                        <Typography variant="subtitle1" fontSize="small">{headCell.label}</Typography>
                                        {headCell.id === orderCategory ? (orderDescending ?  < ArrowDownward fontSize="small" /> : <ArrowUpward fontSize="small" />) : <></>}
                                    </IconButton>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            internalItems
                                .filter((item) => filterOutage(item))
                                .map((item, index) => {
                                    const unfinished = moment(item.tripEnd).year() === 9999
                                    const differenceDay = moment(item.tripEnd).diff(moment(item.tripStart), 'day')
                                    const differenceHour = moment(item.duration).hours()
                                    const differenceMinute = moment(item.duration).minutes()
                                    const differenceSecond = moment(item.duration).seconds()
                                    const differenceMillisecond = moment(item.duration).milliseconds()
                                return (
                                    <TableRow key={"EngineTrip" + index}>
                                        <TableCell align="center"><Button sx={{ width:"100%", color: "white" }} onClick={() => window.open(GasCommercialOverviewUrl(siteIdToName[item.siteID]), '_blank')}>{item.siteID}</Button></TableCell>
                                        <TableCell align="center">{item.engineID.startsWith('G') ? <Button sx={{ width: "100%", color: "white" }} onClick={() => window.open(GasEngineOverviewUrl(siteIdToName[item.siteID], item.engineID), '_blank')}>{item.engineID}</Button> : item.engineID}</TableCell>
                                        <TableCell align="center">{moment(item.tripStart).format("DD-MMM-YY HH:mm:ss")}</TableCell>
                                        <TableCell align="center">{moment(item.tripEnd).format("DD-MMM-YY HH:mm:ss")}</TableCell>
                                        <TableCell align="center">{(unfinished ? "Unfinished" : differenceDay + ":" + differenceHour + ":" + differenceMinute + ":" + differenceSecond + "." + differenceMillisecond)}</TableCell>
                                    </TableRow>
                                )})
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );

}
interface HeadCell {
    id: keyof IEngineTrip,
    label: string
}
export interface IEngineTripTable {
    items: IEngineTrip[],
    siteIdToName: Dictionary<string>
    searchBar?: boolean
}

export default EngineTripTable;