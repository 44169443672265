import { CSSProperties, ReactElement, useCallback, useEffect, useMemo, useState } from "react";
import { Root, createRoot } from "react-dom/client";
import { IAssetInformation } from "../../../shared/types/IAssetInformation";
import { ISiteStatus } from "../../../shared/types/ISiteStatus";
import { useGoogleMapAssetInformationContext } from './GoogleMapAssetInformation';
import GoogleMapAssetIcon from "./GoogleMapAssetIcon";
import { IMarkerData, MarkerType } from "./GoogleMap";

const GoogleMapAssetMarkerElement: React.FC<IGoogleMapAssetMarkerProps> = ({ asset, status }) => {
    const outerHexagon: CSSProperties = {
        backgroundColor: status !== null ? status.backgroundHexColour : "white",
        WebkitClipPath: "polygon(50% 0%, 95% 25%, 95% 75%, 50% 100%, 5% 75%, 5% 25%)",
        clipPath: "polygon(50% 0%, 95% 25%, 95% 75%, 50% 100%, 5% 75%, 5% 25%)",
        width: 40,
        height: 40,
        padding: 3
    };

    const innerHexagon: CSSProperties = {
        backgroundColor: "white",
        WebkitClipPath: "polygon(50% 0%, 95% 25%, 95% 75%, 50% 100%, 5% 75%, 5% 25%)",
        clipPath: "polygon(50% 0%, 95% 25%, 95% 75%, 50% 100%, 5% 75%, 5% 25%)",
        width: "100%",
        height: "100%",
    };

    return (
        <div style={outerHexagon}>
            <div style={innerHexagon}>
                <GoogleMapAssetIcon asset={asset} status={status} />
            </div>
        </div>
    )
}

interface IGoogleMapAssetMarkerProps {
    asset: IAssetInformation;
    status: ISiteStatus | null;
}

const GoogleMapAssetMarker: React.FC<IMarkerProps> = ({ map, asset, status, marker }) => {
    const assetInfo = useGoogleMapAssetInformationContext();
    const [markerContainer, setMarkerContainer] = useState<IMarkerContainer>();

    if (!marker) {
        marker = new google.maps.marker.AdvancedMarkerElement();
    }

    const openInfo = useCallback(() => { // Open asset info callback
        if (status !== null) {
            assetInfo.open(asset, status);
        }
        else {
            alert("No asset information currently available");
        }
    }, [assetInfo, asset, status]);

    const markerOptions: google.maps.marker.AdvancedMarkerElementOptions = useMemo(() => { // Marker options
        return {
            position: { lat: asset.Latitude as number, lng: asset.Longitude as number },
            map: map,
            title: asset.Name,
            content: markerContainer?.domElement,
            zIndex: status !== null ? (status.activePowerExport > 0 ? 99 : 1) : 1,
        }
    }, [map, asset, status, markerContainer]);

    const markerElement: ReactElement = useMemo(() => { // Asset marker element
        return <GoogleMapAssetMarkerElement key={`${asset.SiteID}-element`} asset={asset} status={status} />;
    }, [asset, status]);

    useEffect(() => { // Initialisation
        const domElement = document.createElement("span");
        const reactRoot = createRoot(domElement);
        setMarkerContainer({ reactRoot, domElement });

        return () => { // Teardown
            setTimeout(() => { // Unmount the react root in a timeout
                reactRoot.unmount();
            });

            if (marker) {
                marker.map = null; // Remove the old marker from the map
            }
        }
    }, [marker]);

    useEffect(() => { // Update marker data
        if (marker) {
            const markerData: IMarkerData = { type: MarkerType.Asset, status };
            marker.id = JSON.stringify(markerData);
        }
    }, [marker, status]);

    useEffect(() => { // Update marker options
        if (marker) {
            Object.assign(marker, markerOptions);
        }
    }, [marker, markerOptions]);

    useEffect(() => { // Update asset marker element
        markerContainer?.reactRoot.render(markerElement);
    }, [markerContainer, markerElement]);

    useEffect(() => { // Update event listener
        const listener = marker?.addListener("click", openInfo);

        return () => {
            listener?.remove();
        }
    }, [marker, openInfo]);

    return <></>
};

interface IMarkerProps {
    map: google.maps.Map;
    asset: IAssetInformation;
    status: ISiteStatus | null;
    marker?: google.maps.marker.AdvancedMarkerElement;
}

interface IMarkerContainer {
    domElement: HTMLSpanElement;
    reactRoot: Root;
}

export default GoogleMapAssetMarker;