import { INotificationType } from "../../../shared/types/notification/INotificationType";
import React from 'react';
import { Box, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { ArrowDownward, ArrowUpward, ArrowDropUp, ArrowDropDown } from "@mui/icons-material";

const headCells: HeadCell[] = [
    {
        id: "notificationName",
        label: "Notification"
    },
    {
        id: "maxTimeout",
        label: "Timeout"
    },
    {
        id: "groups",
        label: "Recipients"
    },
]

const NotificationTypeTable: React.FC<INotificationTypeTable> = ({ items, searchBar }) => {
    const [orderDescending, setOrderDescending] = React.useState<boolean>(false)
    const [orderCategory, setOrderCategory] = React.useState<keyof INotificationType>("notificationName")
    const [searchContents, setSearchContents] = React.useState<string>("")

    const sortItems = (inputArray: INotificationType[], category: keyof INotificationType, descending: boolean) => {
        const sortedArray = [...inputArray];
        sortedArray.sort((a, b) => {
            if (a[category] < b[category]) { return -1 }
            return 1
        })
        if (descending) {
            sortedArray.reverse()
        }
        return sortedArray
    }

    if (items === undefined) {
        items = []
    }

    const [internalItems, setInternalItems] = React.useState<INotificationType[]>(sortItems(items, orderCategory, orderDescending))

    const handleSearchContentsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchContents(event.target.value as string)
    }

    const handleSort = (categoryID: keyof INotificationType) => {
        if (categoryID !== "groups") {
            let descending = false
            if (orderCategory === categoryID && !orderDescending) {
                descending = true
            }
            setOrderDescending(descending)
            setOrderCategory(categoryID)
            setInternalItems(sortItems(internalItems, categoryID, descending))
        }
    }

    const filterOutage = (notification: INotificationType) => {
        let inSearch = notification.notificationName.toLowerCase().startsWith(searchContents.toLowerCase())
        notification.groups.forEach((group) => {
            if (group?.toLowerCase().startsWith(searchContents.toLowerCase())) {
                inSearch = true
            }
        })
        return inSearch
    }

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            {(searchBar ? <TextField                
                id="search-label"
                label="Search"
                value={searchContents}
                onChange={handleSearchContentsChange}
                name="Search"
                placeholder="Search"
                fullWidth
            /> : <></>)}
            <TableContainer sx={{ maxHeight: 440, mt: 2 }}>
                <Table stickyHeader size="small" aria-label="sticky table">
                    <TableHead>
                        <TableRow key="header">
                            {headCells.map((headCell) => (
                                <TableCell align="center" key={headCell.id}>
                                    <IconButton size="small" sx={{ "transition": "all .2s", "color": "inherit", "&:hover": { "backgroundColor": "rgba(160, 160, 160, 0.5)" } }} onClick={() => handleSort(headCell.id)}>
                                        <Typography variant="subtitle1" fontSize="small">{headCell.label}</Typography>
                                        {headCell.id === orderCategory ? (orderDescending ? < ArrowDownward fontSize="small" /> : <ArrowUpward fontSize="small" />) : <></>}
                                    </IconButton>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            internalItems
                                .filter((item) => filterOutage(item))
                                .map((item) => {
                                    return (
                                        <TableRow key={item.notificationName}>
                                            <TableCell align="center">{item.notificationName}</TableCell>
                                            <TableCell align="center">{item.maxTimeout}</TableCell>
                                            <TableCell align="center" sx={{ width: "30vw" }}>
                                                <GroupDisplay groups={item.groups} />
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );

}

const GroupDisplay: React.FC<IGroupDisplay> = ({ groups }) => {
    const [expanded, setExpanded] = React.useState<boolean>(false)
    const handleClick = () => {
        setExpanded(!expanded)
    }
    return (
        <Grid container onClick={() => handleClick()} alignItems="center" sx={{"cursor": "pointer"} }>
            <Grid item xs={1}>
                {(expanded ? <ArrowDropUp /> : <ArrowDropDown />)}
            </Grid>
            <Grid item xs={11}>
                <Box sx={{ height: (expanded ? "auto" : "40px") }} overflow="hidden">
                    {(expanded ? [...groups].sort().map((group) => <p key={group}>{group}</p>) : [...groups].sort().toString().replaceAll(',', ", "))}
                </Box>
            </Grid>
        </Grid>
    )
}

interface IGroupDisplay {
    groups: string[]
}
interface HeadCell {
    id: keyof INotificationType,
    label: string
}
export interface INotificationTypeTable {
    items: INotificationType[],
    searchBar?: boolean
}

export default NotificationTypeTable;