import { updateBOAs, addBOA } from "../state/slices/BOADataSlice";
import { IBOAData } from "../types/data/IBOAData";
import { useAppDispatch, useAppSelector } from "./StateHook";

const useBOAData = () => {
    const dispatch = useAppDispatch();
    const { newBoaData } = useAppSelector(state => state.newBoas);

    const setNewBOAs = (newBOAs: IBOAData[]) => {
        dispatch(updateBOAs(newBOAs))
    }
    const addNewBOA = (newBOA: IBOAData) => {
        dispatch(addBOA(newBOA))
    }
    const response = {
        newBoaData,
        setNewBOAs,
        addNewBOA
    }

    return response;
};

export default useBOAData;