import React from 'react';
import { Moment } from "moment";
import { Box, Button, TextField } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { East, West } from '@mui/icons-material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

const DynamicControlBar: React.FC<IDynamicControlBar> = ({ querying, selectedDate, setSelectedDate }) => {

    const clickedLeft = () => {
        const newDate = selectedDate.clone().subtract(1, "day")
        setSelectedDate(newDate)
    }

    const clickedRight = () => {
        const newDate = selectedDate.clone().add(1, "day")
        setSelectedDate(newDate)
    }

    const updateSelectedDate = (Value: Moment | null) => {
        if (!Value) { return; }

        setSelectedDate(Value);
    }

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <Grid
                container
                alignItems="center"
                alignContent="start"
                spacing={2}
                justifyContent="flex-start"
            >
                <Grid xs={4}>
                    <Box sx={{ position: 'relative' }}>
                        <Button
                            variant="contained"
                            disabled={querying}
                            onClick={clickedLeft}
                            size="large"
                            sx={{ height: "55px" }}
                            fullWidth
                        >
                            <West />
                        </Button>
                    </Box>
                </Grid>
            <Grid xs={4}>
                        <Box>
                            <DatePicker
                                label="Date"
                                inputFormat="DD/MM/YYYY"
                                value={selectedDate}
                                onChange={updateSelectedDate}
                                disabled={querying}
                                renderInput={(params) => <TextField {...params}
                                    fullWidth
                                />}
                            />
                        </Box>
                </Grid>
                <Grid xs={4}>
                    <Box sx={{ position: 'relative' }}>
                        <Button
                            variant="contained"
                            disabled={querying}
                            onClick={clickedRight}
                            size="large"
                            sx={{ height: "55px" }}
                            fullWidth
                        >
                            <East />
                        </Button>
                    </Box>
                </Grid>
            </Grid>
            </LocalizationProvider>
    );
}

export interface IDynamicControlBar {
    querying: boolean
    selectedDate: Moment
    setSelectedDate: (selectedDate: Moment) => void
}

export default DynamicControlBar;