import moment from "moment";
import { IDispatchPlan, IEngineStatus, ISiteEngineResponse } from "../types/ISiteEngineResponse";
import { DispatchPlan, EngineHiveDataTile, EngineHiveStatusData, EngineStatusCollection, EngineStatusPlacementCollection, IEngineHivePlacementResult } from "../types/operate/engine-hive";
import { HexGridData, HexGridTile } from "../types/shared/hex-grid";
import { IHiveDimensions } from "./HexGridUtils";

const sortEngineStatusData = (a: EngineHiveStatusData, b: EngineHiveStatusData) => {
    if (a.priority < b.priority) {
        return -1;
    }
    if (a.priority > b.priority) {
        return 1;
    }

    if (a.siteName < b.siteName) {
        return -1;
    }
    if (a.siteName > b.siteName) {
        return 1;
    }

    if (a.name < b.name) {
        return -1;
    }
    if (a.name > b.name) {
        return 1;
    }

    return 0;
}

const sortDispatch = (a: IDispatchPlan, b: IDispatchPlan) => {
    const aTime = moment(a.time);
    const bTime = moment(b.time);

    if (aTime < bTime) {
        return -1;
    }

    if (aTime > bTime) {
        return 1;
    }

    return 0;
}

const resolveDispatchPlan = (status?: ISiteEngineResponse): DispatchPlan => {
    if (!status) { return DispatchPlan.None; }

    const dispatches: IDispatchPlan[] = [];
    let maxDispatch = 0;
    const now = moment();
    status.dispatchPlan.forEach((dispatch) => { // Get all dispatches in the next 4 hours
        const time = moment(dispatch.time).add(-4, "hour");
        if (time > now) { return; }

        dispatches.push(dispatch);
        
        if (dispatch.value > maxDispatch) {
            maxDispatch = dispatch.value;
        }
    });

    dispatches.sort(sortDispatch);
    console.log (dispatches);

    if (maxDispatch === 0) {
        return DispatchPlan.None;
    } else if (dispatches[0].value > 0) {
        return DispatchPlan.Current;
    } else {
        return DispatchPlan.Upcoming;
    }
}

export const generateEngineHiveStatusData = (engineStatus: IEngineStatus, siteName: string, dispatchPlan: DispatchPlan): EngineHiveStatusData => {
    const engineData: EngineHiveStatusData = {
        ...engineStatus,
        key: `${siteName}-${engineStatus.name}`,
        siteName: siteName,
        priority: Number.MAX_SAFE_INTEGER,
        colour: "#000000",
    }

    switch (dispatchPlan) {
        case DispatchPlan.Current:
            if (engineStatus.outageEnd) {
                engineData.priority = 5;
                engineData.colour = EngineHiveColours.Error;
            } else if (engineStatus.setShutdown) {
                engineData.priority = 1;
                engineData.colour = EngineHiveColours.ShutDown;
            } else if (engineStatus.activePower > 0) {
                engineData.priority = 2;
                engineData.colour = EngineHiveColours.Unknown;
            } else if (engineStatus.setWarn) {
                engineData.priority = 3;
                engineData.colour = EngineHiveColours.Warning;
            } else {
                engineData.priority = 4;
                engineData.colour = EngineHiveColours.Ready;
            }
            break;
        case DispatchPlan.Upcoming:
            if (engineStatus.outageEnd) {
                engineData.priority = 5;
                engineData.colour = EngineHiveColours.Error;
            } else if (engineStatus.setShutdown) {
                engineData.priority = 1;
                engineData.colour = EngineHiveColours.ShutDown;
            } else if (engineStatus.inAuto) {
                engineData.priority = 2;
                engineData.colour = EngineHiveColours.Auto;
            } else if (engineStatus.setWarn) {
                engineData.priority = 3;
                engineData.colour = EngineHiveColours.Warning;
            } else {
                engineData.priority = 4;
                engineData.colour = EngineHiveColours.Ready;
            }
            break;
        case DispatchPlan.None:
            if (engineStatus.outageEnd) {
                engineData.priority = 5;
                engineData.colour = EngineHiveColours.Error;
            } else if (engineStatus.setShutdown) {
                engineData.priority = 1;
                engineData.colour = EngineHiveColours.ShutDown;
            } else if (engineStatus.inAuto) {
                engineData.priority = 2;
                engineData.colour = EngineHiveColours.Auto;
            } else if (engineStatus.setWarn) {
                engineData.priority = 3;
                engineData.colour = EngineHiveColours.Warning;
            } else {
                engineData.priority = 4;
                engineData.colour = EngineHiveColours.Ready;
            }
            break;
    }

    return engineData;
}

export const processEngineStatusData = (statuses?: ISiteEngineResponse[]): EngineStatusCollection => {
    const processedData: EngineStatusCollection = {
        [DispatchPlan.Current]: [],
        [DispatchPlan.Upcoming]: [],
        [DispatchPlan.None]: [],
    }

    if (!statuses) { return processedData; }

    statuses.forEach(status => {
        const dispatchPlan = resolveDispatchPlan(status);
        status.engines.forEach(engine => {
            const data = generateEngineHiveStatusData(engine, status.name, dispatchPlan);
            processedData[dispatchPlan].push(data);
        });
    });

    processedData[DispatchPlan.Current].sort(sortEngineStatusData);
    processedData[DispatchPlan.Upcoming].sort(sortEngineStatusData);
    processedData[DispatchPlan.None].sort(sortEngineStatusData);

    return processedData;
}

export const generateEngineHiveTiles = (engineData: EngineStatusCollection, dimensions: IHiveDimensions): EngineStatusPlacementCollection => {
    const result: EngineStatusPlacementCollection = {
        [DispatchPlan.Current]: {},
        [DispatchPlan.Upcoming]: {},
        [DispatchPlan.None]: {}
    }

    result[DispatchPlan.Current] = placeTilesHive(engineData[DispatchPlan.Current], dimensions);
    result[DispatchPlan.Upcoming] = placeTilesHive(engineData[DispatchPlan.Upcoming], dimensions);
    result[DispatchPlan.None] = placeTilesHive(engineData[DispatchPlan.None], dimensions);

    return result;
}

const placeTilesHive = (engineData: EngineHiveStatusData[], dimensions: IHiveDimensions): IEngineHivePlacementResult => {
    const grid = new HexGridData(dimensions.grid.gridWidth, dimensions.grid.gridHeight);
    const dataTiles: EngineHiveDataTile[] = [];

    const currentViableTiles: HexGridTile[] = [];

    const { topLeft } = grid.getAnchorPoints();
    const topLeftTile = grid.getTileAtCoords(topLeft);
    if (!topLeftTile) {
        return { placed: [], failed: engineData };
    }

    currentViableTiles.push(topLeftTile);

    const mapped = new Set<string>();
    const failed: EngineHiveStatusData[] = [];
    engineData.forEach((data) => {
        if (mapped.has(data.key)) {
            console.log("Duplicate engine key found.");
            return;
        }

        const dataTile = tilePlacement(data, () => currentViableTiles, grid);
        if (!dataTile) {
            failed.push(data);
        } else {
            mapped.add(data.key);
            dataTiles.push(dataTile);
        }
    });

    return { placed: dataTiles, failed };
}

const tilePlacement = (data: EngineHiveStatusData, getViableTiles: (data: EngineHiveStatusData) => HexGridTile[], grid: HexGridData) => {
    const currentViableTiles = getViableTiles(data);

    let tileToPlace: HexGridTile | undefined;
    for (var i = currentViableTiles.length; i--;) {
        if (currentViableTiles[i].canPlace()) {
            tileToPlace = currentViableTiles.pop();
            break;
        }

        currentViableTiles.splice(i, 1);
    }

    if (!tileToPlace) {
        return null;
    }

    const neighbourCoords = tileToPlace.getNeighbours();

    for (var i = 0; i < neighbourCoords.length; i++) {
        var tile = grid.getTileAtCoords(neighbourCoords[i]);
        if (tile?.canPlace()) {
            currentViableTiles.unshift(tile);
            break;
        }
    }

    const dataTile = new EngineHiveDataTile(tileToPlace.coords, data);
    tileToPlace.isOccupied = true;
    return dataTile;
}

export const EngineHiveColours = {
    Error: "#962900",
    Warning: "#FFA500",
    ShutDown: "#FF4500",
    Ready: "#32CD32",
    Unknown: "#808080",
    Auto: "#FFD700",
}
