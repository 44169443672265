import { Navigate, Route, RouteProps } from "react-router-dom";
import { PortalUser } from "../auth/authHelperClasses";
import { UserPermissionCollection } from "../auth/userDataTypes";
import { IFlatNavItem, IFlatRoute, IPortalAreaOptions, IPortalPage, IPortalRoute, IPortalRouteNavDisplay, IPortalRouteOptions, PortalPageOrReactElement } from "./routeTypes";
import { ReactElement } from "react";
import SiteLayout from "../../../../components/SiteLayout";
import { isMobile } from "react-device-detect";

const isInstanceOfIPortalPage = (object: any): object is IPortalPage => {
    return 'element' in object;
}

const convertToPortalPage = (page?: PortalPageOrReactElement) => {
    if (!page) { return undefined; }

    return isInstanceOfIPortalPage(page) ? page : { element: page };
}

const buildFullPath = (relativePath: string, parentPath?: string) => {
    return parentPath ? `${parentPath}/${relativePath}` : `/${relativePath}`;
}

export class PortalRoute implements IPortalRoute {
    public readonly relativeUrl: string;
    public readonly navDisplay?: IPortalRouteNavDisplay;
    public readonly page?: IPortalPage;
    public readonly requiredPermissions?: Partial<UserPermissionCollection>;
    public readonly hideOnMobile?: boolean;

    public readonly fullPath: string;

    private readonly _fixedChildRoutes: PortalRoute[];
    private readonly _transientChildRoutes: Map<string, PortalRoute>;

    protected _latestDataGenerated: boolean = false;

    public get childRoutes() {
        const transientRoutes = Array.from(this._transientChildRoutes.values());
        return [...this._fixedChildRoutes, ...transientRoutes];
    }

    constructor(options: IPortalRouteOptions, parentPath?: string) {
        this.relativeUrl = options.relativeUrl;
        this.navDisplay = options.navDisplay;
        this.requiredPermissions = options.requiredPermissions;
        this.hideOnMobile = options.hideOnMobile;
        this.page = convertToPortalPage(options.page);
        this.fullPath = buildFullPath(options.relativeUrl, parentPath);

        this._fixedChildRoutes = [];
        this._transientChildRoutes = new Map<string, PortalRoute>();
    }

    public readonly addChildRoute = (options: IPortalRouteOptions) => {
        this._latestDataGenerated = false;
        const route = new PortalRoute(options, this.fullPath);
        this._fixedChildRoutes.push(route);
        return route;
    }

    public readonly addTransientChildRoute = (key: string, options: IPortalRouteOptions) => {
        this._latestDataGenerated = false;
        const route = new PortalRoute(options, this.fullPath);

        this._transientChildRoutes.set(key, route);

        return route;
    };

    protected readonly getFlatNavItem = (user: PortalUser): IFlatNavItem | undefined => {
        if (this.navDisplay && !this.navDisplay.hideFromNav && user.hasPermissions(this.requiredPermissions) && !(isMobile && this.hideOnMobile)) {
            const navItem: IFlatNavItem = {
                text: this.navDisplay.title,
                icon: this.navDisplay.icon,
                path: this.fullPath,
                disabled: !this.page && this.childRoutes.length <= 0
            };

            return navItem;
        }
    }

    protected readonly getNestedNavItem = (): IFlatNavItem | undefined => {
        if (!this.page || !this.navDisplay) { return; }

        if (!this.navDisplay.hideFromNav && !this.navDisplay.hideFromNested) {
            const navItem: IFlatNavItem = {
                text: this.navDisplay.nestedTitle || this.navDisplay.title,
                icon: this.navDisplay.nestedIcon || this.navDisplay.icon,
                path: this.fullPath,
                disabled: !this.page && this.childRoutes.length <= 0,
            };

            return navItem;
        }
    }

    protected readonly getFlattenedRoutes = (parentKey: string, user: PortalUser): IFlatRoute[] => {
        if (!user.hasPermissions(this.requiredPermissions) || (isMobile && this.hideOnMobile)) { return []; }

        const flattenedRoutes: IFlatRoute[] = [];
        const childRoutes: IFlatRoute[] = [];

        this.childRoutes.forEach((child) => {
            const routes = child.getFlattenedRoutes(this.fullPath, user);
            childRoutes.push(...routes);
        });

        if (!this.page && childRoutes.length <= 0) {
            console.error(`Route '${this.fullPath}' is invalid. Route must have a page or at least one child page.`);
            return [];
        }

        const pageElement = this.page || { element: <Navigate to={childRoutes[0].path} /> };

        const thisNavId = this.childRoutes.length > 0 ? this.fullPath : parentKey; // If it has children, it's a submenu and its nav id should be that of its children
        flattenedRoutes.push({ path: this.fullPath, page: pageElement, navId: thisNavId });
        flattenedRoutes.push(...childRoutes);

        return flattenedRoutes;
    }

    protected readonly getFlattenedNavItems = (user: PortalUser): Map<string, IFlatNavItem[]> => {
        if (!user.hasPermissions(this.requiredPermissions) || (isMobile && this.hideOnMobile)) { return new Map(); }

        const navItems: Map<string, IFlatNavItem[]> = new Map(); // Empty map, with nav items and keys
        const thisLevelItems: IFlatNavItem[] = []; // Array of the nav items at this level

        // This is the landing page of this nav section
        const thisNavItem = this.getNestedNavItem();
        thisNavItem && thisLevelItems.push(thisNavItem);

        // These are the child routes
        this.childRoutes.forEach((child) => {
            const navItem = child.getFlatNavItem(user);
            navItem && thisLevelItems.push(navItem);

            const nested = child.getFlattenedNavItems(user);
            nested.forEach((value, key) => {
                const newValue = navItems.get(key) || [];

                newValue.push(...value);
                navItems.set(key, newValue);
            });
        });

        if (thisLevelItems.length > 0) {
            navItems.set(this.navKey, thisLevelItems);
        }

        return navItems;
    }

    private get navKey() {
        return this.fullPath;
    }
}

export class PortalArea extends PortalRoute {
    public readonly areaTitle: string;
    public readonly areaDescription: string;
    public readonly iconImage: string;
    public readonly hideFromLandingPage?: boolean;
    public readonly allValidPermissions?: Partial<UserPermissionCollection>;

    private _latestUser?: PortalUser;
    private _generatedRoutes: IFlatRoute[] = [];
    private _generatedNavItems: Map<string, IFlatNavItem[]> = new Map();

    constructor(areaOptions: IPortalAreaOptions, routeOptions: IPortalRouteOptions) {
        super(routeOptions);

        this.areaTitle = areaOptions.title;
        this.areaDescription = areaOptions.description;
        this.iconImage = areaOptions.iconImage;
        this.hideFromLandingPage = areaOptions.hideFromLandingPage;
        this.allValidPermissions = areaOptions.allValidPermissions;
    }

    public readonly userHasRoutes = (user: PortalUser): boolean => {
        this.generateData(user);
        return this._generatedRoutes.length > 0;
    }

    public readonly getRouterElements = (user: PortalUser): ReactElement<RouteProps>[] => {
        this.generateData(user);

        return this._generatedRoutes.map(value =>
            <Route
                key={value.path}
                path={value.path}
                element={this.createWrappedElement(value)}
            />
        );
    }

    public readonly generateNavItems = (user: PortalUser): Map<string, IFlatNavItem[]> => {
        this.generateData(user);

        return this._generatedNavItems;
    }

    private readonly generateData = (user: PortalUser) => {
        if (this._latestUser === user && this._latestDataGenerated) { return; }

        if (!user.hasPermissions(this.allValidPermissions, false)) {
            this._generatedRoutes = [];
            this._generatedNavItems = new Map();
        } else {
            this._generatedRoutes = this.getFlattenedRoutes(this.fullPath, user);
            this._generatedNavItems = this.getFlattenedNavItems(user);
        }

        this._latestUser = user;
        this._latestDataGenerated = true;
    }

    private readonly createWrappedElement = (route: IFlatRoute): JSX.Element => {
        const page = route.page;

        return (
            <SiteLayout
                homeRoute={this.fullPath}
                pageHeader={this.areaTitle}
                navMenuId={route.navId}
                disableNav={page.disableNav}
                disableGutters={page.disableGutters}
                forceNavOverlay={page.forceOverlayNav}>
                {page.element}
            </SiteLayout>
        );
    }
}

export class PortalAreaCollection {
    public readonly areas: PortalArea[];

    constructor(areas: PortalArea[]) {
        this.areas = areas;
    }

    public readonly getAllowedRoutes = (user: PortalUser) => {
        const routes: ReactElement[] = [];

        this.areas.forEach((area) => {
            routes.push(...area.getRouterElements(user));
        });

        return routes;
    }

    public readonly getAllNavs = (user: PortalUser) => {
        const navs: Map<string, IFlatNavItem[]> = new Map();
        this.areas.forEach((area) => {
            const routeNavs = area.generateNavItems(user);
            routeNavs.forEach((value, key) => {
                const newValue = navs.get(key) || [];
                newValue.push(...value);
                navs.set(key, newValue);
            });
        });

        return navs;
    }
}
