import { Box, Button, Grid } from "@mui/material";
import PageWrapper from "../../components/PageWrapper";
import React, { useEffect } from "react";
import GasSystemLengthChart from "../../components/analytics/GasSystemLengthChart";
import FuelTypeChart from "../../components/analytics/FuelTypeChart";
import WindForecastChart from "../../components/analytics/WindForecastChart";
import BalancingVolumeChart from "../../components/analytics/BalancingVolumeChart";
import BalancingPricingChart from "../../components/analytics/BalancingPricingChart";
import WithinDayMarketChart from "../../components/analytics/WithinDayMarketChart";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import { IPortalRouteOptions } from "../../shared/types/shared/routes/routeTypes";
import { Analytics } from "@mui/icons-material";

const AnalyticsChartPage: React.FC<IAnalyticsChartPage> = () => {
    const [loaded, setLoaded] = React.useState(false);
    const handle = useFullScreenHandle();
    const chartStyle = (handle.active ? { "height": (100 / 6).toString() + "vh", "width": "100vw", "padding": 1 } : { "height": "70vh", "width": "69vw", "maxHeight": "500px", "padding": 1 })

    const loadComplete = () => {
        setLoaded(true);
    }

    useEffect(() => {
        loadComplete()
    }, [])
    return (
        <PageWrapper title="Media Wall Charts" loaded={loaded}>
            <Button
                variant="contained"
                startIcon={<FullscreenIcon />}
                onClick={handle.enter}
                sx={{ mb: "10px" }}
                fullWidth
            >
                Enter Display Mode
            </Button>

            <FullScreen handle={handle}>
                <Grid container sx={{ backgroundColor: "black" }}
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center">
                    <Grid item xs={12}>
                        <Box sx={chartStyle}>
                            <GasSystemLengthChart displayScreen={true} />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={chartStyle}>
                            <FuelTypeChart displayScreen={true} />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={chartStyle}>
                            <WindForecastChart displayScreen={true} />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={chartStyle}>
                            <BalancingVolumeChart displayScreen={true} />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={chartStyle}>
                            <BalancingPricingChart displayScreen={true} />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={chartStyle}>
                            <WithinDayMarketChart displayScreen={true} />
                        </Box>
                    </Grid>
                </Grid>
            </FullScreen>
        </PageWrapper>
    );
}

export interface IAnalyticsChartPage {

}

const AnalyticsChartPageConfig: IPortalRouteOptions = {
    relativeUrl: "charts",
    page: <AnalyticsChartPage />,
    navDisplay: {
        title: "Charts",
        icon: <Analytics />
    },
}

export default AnalyticsChartPageConfig;