import { useEffect, useState } from "react";
import { IPermissionsConfig } from "../../../shared/api/PermissionsApi";
import { Permissions, PermissionsArea, PermissionsType } from "../../../shared/types/shared/auth/permissionsTypes";
import { userHasAllRequiredPermissions } from "../../../shared/auth/permissions/permissionsHelpers";
import { Checkbox, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";

const PermissionsTable: React.FC<PermissionsTableProps> = ({ title, allPermissions, userPermissions, permissionsUpdated }) => {
    const [editablePermissions, setEditablePermissions] = useState<PermissionsType>();

    useEffect(() => {
        const currentPermissions = userPermissions.permissions[title] || 0;

        setEditablePermissions(currentPermissions);
    }, [userPermissions]);

    const permissionsKeys = Object.keys(allPermissions).filter((key) => key != "None");

    const permissionEnabled = (permission: string): boolean => {
        if (editablePermissions == undefined) { return false; }

        return userHasAllRequiredPermissions(allPermissions[permission], editablePermissions);
    }

    const togglePermission = (permission: string) => {
        if (editablePermissions == undefined) { return; }
        const permissionValue = allPermissions[permission];
        let newValue = editablePermissions;

        if (permissionEnabled(permission)) {
            newValue &= ~permissionValue;
        } else {
            newValue |= permissionValue;
        }

        setEditablePermissions(newValue);
        permissionsUpdated(title, newValue);
    }

    return (
        <Table size="small" aria-label="purchases">
            <TableHead>
                <TableRow>
                    <TableCell width={"50%"}>{title}</TableCell>
                    <TableCell width={"50%"} align="right">Access Enabled</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {permissionsKeys.map((permission) => (
                    <TableRow key={permission}>
                        <TableCell component="th" scope="row" width={"50%"}>
                            {permission}
                        </TableCell>
                        <TableCell align="right" width={"50%"}>
                            <Checkbox
                                sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                                checked={permissionEnabled(permission)}
                                onClick={() => togglePermission(permission)}
                            />
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
}

export interface PermissionsTableProps {
    title: PermissionsArea,
    allPermissions: Permissions,
    userPermissions: IPermissionsConfig,
    permissionsUpdated: (area: PermissionsArea, newValue: PermissionsType) => void;
}

export default PermissionsTable;
