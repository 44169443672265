import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import { useEffect, useState } from "react";
import { createRoot, Root } from 'react-dom/client';
import { IElectricityInterconnector, IGpsLocation } from "../../../shared/types/IElectricityInterconnector";

interface IInterconnector {
    name: string;
    line: google.maps.Polyline;
    startMarker: IMarkerContainer;
    endMarker: IMarkerContainer;
    value: number;
}

interface IMarkerContainer {
    root: Root;
    span: HTMLSpanElement;
    marker: google.maps.marker.AdvancedMarkerElement;
}

const ElectricityInterconnectors: React.FC<IElectricityInterconnectorsProps> = ({ map, data }) => {
    const [interconnectorLines, setInterconnectorLines] = useState<IInterconnector[]>([]);   
    const [timers, setTimers] = useState<number[]>([]);

    const InterconnectorMarkerContent = () => {
        return (<>
            <ElectricBoltIcon fontSize="small" sx={{ marginTop: 1 }} />
        </>);
    }

    const createInterconnectorLines = () => {
        const polylineColour = [
            { name: "INTELEC", colour: "#FF0000" },
            { name: "INTEW", colour: "#FF8000" },
            { name: "INTFR", colour: "#00FF00" },
            { name: "INTIFA2", colour: "#B900FF" },
            { name: "INTIRL", colour: "#FFBC00" },
            { name: "INTNED", colour: "#27B793" },
            { name: "INTNEM", colour: "#9C68AB" },
            { name: "INTNSL", colour: "#B94C37" },
            { name: "INTVKL", colour: "#005DB2" }
        ];

        const lines: IInterconnector[] = [];
        data?.forEach(d => {
            if (d.gpsLocation.length > 0) {
                const gpsData = d.generation > 0 ? [...d.gpsLocation.slice()] : [...d.gpsLocation.slice().reverse()];
                const path = gpsData.map(g => { return { lat: g.latitude, lng: g.longitude } });

                const arrowIcon = {
                    path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,                   
                };

                const lineColour = polylineColour.filter(x => x.name === d.fuelType)[0];

                const line = new google.maps.Polyline({
                    path: path,
                    geodesic: true,
                    strokeColor: lineColour !== null ? lineColour.colour : "#000000",
                    strokeOpacity: 5.0,
                    strokeWeight: 2,
                    icons: [
                        {
                            icon: d.generation === 0 ? null : arrowIcon,
                            repeat: "25%"
                        },
                    ],                    
                });

                const startMarker = createInterconnectorMarker(gpsData[0], d.fuelType, d.generation);
                const endMarker = createInterconnectorMarker(gpsData[gpsData.length - 1], d.fuelType, d.generation);
                const lineData = { name: d.fuelType, line: line, startMarker , endMarker, value: d.generation } as IInterconnector;
                lines.push(lineData);               
            }
        });

        setInterconnectorLines(lines);
    };    

    const createInterconnectorMarker = (gpsLocation: IGpsLocation, name: string, value: number): IMarkerContainer => {
        const container = document.createElement("span");
        const root = createRoot(container);
        root.render(<InterconnectorMarkerContent />);

        const pin = new google.maps.marker.PinElement({
            glyph: container,
            glyphColor: "#ffffff",
            borderColor: "#000000"
        });

        const gigaWatt = Math.abs((value / 1000.00)).toFixed(2);
        let markerTitle = `No activity for ${name}`;
        if (value !== 0) {
            markerTitle = value > 0 ? `We are importing ${gigaWatt}GW of power from ${name}` : `We are exporting ${gigaWatt}GW of power to ${name}`;
        }

        const marker = new google.maps.marker.AdvancedMarkerElement({
            position: { lat: gpsLocation.latitude, lng: gpsLocation.longitude },
            content: pin.element,
            title: markerTitle
        });

        return { marker, root, span: container  };
    };

    useEffect(() => {
        createInterconnectorLines();
    }, [data]);    

    useEffect(() => {
        displayInterconnectors();
        animateInterconnectors();

        return () => {            
            cleanUp();
        };
    }, [interconnectorLines]);

    const cleanUp = () => {
        interconnectorLines.forEach(i => {            
            i.line.setMap(null);
            i.startMarker.marker.map = null;
            i.endMarker.marker.map = null;            
            i.startMarker.span.remove();
            i.endMarker.span.remove();
            setTimeout(() => {
                i.startMarker.root.unmount();
                i.startMarker.root.unmount();
            });
        });
        timers.forEach(t => {
            window.clearTimeout(t);
        });
    };


    const displayInterconnectors = () => {
        interconnectorLines.forEach(i => {                     
            i.line.setMap(map);
            i.startMarker.marker.map = map;            
            i.endMarker.marker.map = map;            
        });        
    };

    const animateInterconnectors = () => {
        interconnectorLines.forEach(i => {            
            animatePolyline(i);
        });
    };

    const animatePolyline = (interconnector: IInterconnector) => {
        let counter = 0;
        const timer = window.setInterval(() => {
            counter = (counter + 1) % 350;

            var arrows = interconnector.line.get("icons");
            arrows[0].offset = (counter / 2) + "%";
            interconnector.line.set("icons", arrows);
        }, 50);   

        setTimers((state: number[]) => {
            let currentTimers = state.slice();
            currentTimers.push(timer);
            return currentTimers;
        });
    }

    return (
        <></>
    )
}

export interface IElectricityInterconnectorsProps {
    map: google.maps.Map;
    data: IElectricityInterconnector[];
}

export default ElectricityInterconnectors;