import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface NavigationMenuState {
    area?: string;
    open: boolean;
    hidden: boolean;
}

const initialState: NavigationMenuState = {
    open: true,
    hidden: false,
};

export const navigationMenuSlice = createSlice({
    name: "navigationMenu",
    initialState,
    reducers: {
        changeArea: (state, action: PayloadAction<string | undefined>) => {
            state.hidden = false;            
            state.area = action.payload;
        },    
        openMenu: (state) => {
            state.hidden = false;
            state.open = true;
        },
        closeMenu: (state) => {
            state.hidden = false;
            state.open = false;
        },
        hideMenu: (state) => {
            state.hidden = true;
        }
    },
});

export const { changeArea, openMenu, closeMenu, hideMenu } = navigationMenuSlice.actions;

export default navigationMenuSlice.reducer;
