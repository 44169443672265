export interface IStackedService {
    contractPeriod: IContractPeriod
    auctionProducts: IAuctionProduct[]
    deqElbows: IDeqElbows[]
    performanceFiles: IPerformanceFiles[]
    periodPerformances: IPeriodPerformances[]

}

export interface IContractPeriod {
    id: number
    cpIndex: string
    unitID: string
    unitName: string
    startTimeUTC: string
    endTimeUTC: string
    efaDateOnly: string
    efa: number
}

export interface IAuctionProduct {
    id: number
    contractPeriod: number
    auctionProduct: number
    serviceType: ServiceTypeEnum
    technologyType: string
    executedQuantity: number
    auctionUnit: string
    unitResultID: string
    deliveryStart: string
    deliveryEnd: string
    clearingPrice: string
    efa_Date: string
    efa: number
}

export interface IDeqElbows {
    id: number
    contractPeriodId: number
    frequencyElbow: number
    powerElbow: number
}

export interface IPerformanceFiles {
    id: number
    contractPeriodId: number
    fileName: string
    fileStartDateTime: string
    processedTime: string
    fileSent: string
    performanceChecked: string
    fileOk: boolean
    missingValues: number
    notes: string
}

export interface IPeriodPerformances {
    id: number
    contractPeriod: number
    periodStart: string
    serviceType: ServiceTypeEnum
    maxError: number
    kfactor: number
    potentialValue: number
    achievedValue: number
    availability: number
}

export interface IPeriodPerformanceTooltipData {
    highData: IPeriodPerformances | null
    lowData: IPeriodPerformances | null
    file: IPerformanceFiles | null
    datetime: string
}
export enum ServiceTypeEnum {
    Low = 1,
    High = 0,
}