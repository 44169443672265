import moment from "moment";
import { getApiAccessToken } from "../auth/helpers";
import { IGasPosition } from "../types/gas-reconciliation/gasposition";
import { IHistoricalSubmission } from "../types/gas-reconciliation/historicalsubmission";
import { ISubmissionDetails } from "../types/gas-reconciliation/submissiondetails";
import ApiRoot from "./ApiRoot";

const rootUrl = process.env.REACT_APP_API_GATEWAY_URL + "gas-rec";
const apiKey = process.env.REACT_APP_API_GATEWAY_KEY;

const getDailyMetered = async (date?: Date): Promise<PositionDataAPIResponse> => {
    const accessToken = await getApiAccessToken();
    const dateString = moment(date);

    return ApiRoot.get<PositionDataAPIResponse>(
        `${rootUrl}/dailymetered`,
        { accessToken, apiKey },
        { date: dateString.format("YYYY-MM-DD") }
    )
        .then((response) => response)
        .catch((error) => { throw error; });
}

const refreshDailyMetered = async (date?: Date): Promise<void> => {
    const accessToken = await getApiAccessToken();
    const dateString = moment(date);

    return ApiRoot.post(
        `${rootUrl}/dailymetered/refresh`,
        null,
        { accessToken, apiKey },
        { date: dateString.format("YYYY-MM-DD") }
    );
}

const getHistoricalSubmissions = async (startDate: Date, endDate: Date): Promise<HistoricalSubmissionAPIResponse> => {
    const accessToken = await getApiAccessToken();
    const startDateString = moment(startDate);
    const endDateString = moment(endDate);

    return ApiRoot.get<HistoricalSubmissionAPIResponse>(
        `${rootUrl}/historicalsubmissions`,
        { accessToken, apiKey },
        {
            startDate: startDateString.format("YYYY-MM-DD"),
            endDate: endDateString.format("YYYY-MM-DD")
        }
    )
        .then((response) => response)
        .catch((error) => { throw error; });
};

const downloadTradeSubmissionFile = async (batchNumber: number, siteType: number): Promise<any> => {
    const accessToken = await getApiAccessToken();
    return ApiRoot.get(
        `${rootUrl}/historicalsubmissions/download/${batchNumber}/${siteType}/`,
        { accessToken, apiKey },
        {}, "blob"
    )
        .then((response) => response)
        .catch((error) => { throw error; });
};

const getNonDailyMetered = async (date?: Date): Promise<PositionDataAPIResponse> => {
    const accessToken = await getApiAccessToken();
    const dateString = moment(date);

    return ApiRoot.get<PositionDataAPIResponse>(
        `${rootUrl}/nondailymetered`,
        { accessToken, apiKey },
        { date: dateString.format("YYYY-MM-DD") }
    )
        .then((response) => response)
        .catch((error) => { throw error; });
}

const refreshNonDailyMetered = async (date?: Date): Promise<void> => {
    const accessToken = await getApiAccessToken();
    const dateString = moment(date);

    return ApiRoot.post(
        `${rootUrl}/nondailymetered/refresh`,
        null,
        { accessToken, apiKey },
        { date: dateString.format("YYYY-MM-DD") }
    );
}

const getSubmissionDetails = async (batchNumber: number, siteType: number): Promise<SubmissionDetailsAPIResponse> => {
    const accessToken = await getApiAccessToken();

    return ApiRoot.get<PositionDataAPIResponse>(
        `${rootUrl}/submissiondetails`,
        { accessToken, apiKey },
        { batchNumber: batchNumber, siteType: siteType }
    )
        .then((response) => response)
        .catch((error) => { throw error; });
}

const gasReconciliationApi = {
    getDailyMetered,
    refreshDailyMetered,
    getHistoricalSubmissions,
    downloadTradeSubmissionFile,
    getNonDailyMetered,
    refreshNonDailyMetered,
    getSubmissionDetails,
}

export interface PositionDataAPIResponse {
    results: IGasPosition[];
    lastRefresh: Date;
    hasCounterpartyCashExposure: boolean;
    totalSPARKVolume: number;
}

export interface HistoricalSubmissionAPIResponse {
    results: IHistoricalSubmission[];
}

export interface SubmissionDetailsAPIResponse {
    results: ISubmissionDetails[];
}

export interface AllocateTradesAPIResponse {
    result: number;
}

export default gasReconciliationApi;
