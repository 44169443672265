import { FilterOptionsState, createFilterOptions } from "@mui/base";
import { Autocomplete, TextField, TextFieldVariants } from "@mui/material";
import { ReactNode, SyntheticEvent, useEffect, useMemo, useState } from "react";

interface StringOptionType {
    inputValue?: string;
    title: string;
}

const filter = createFilterOptions<StringOptionType>();

const StringAutoComplete: React.FC<IAutoCompleteBoxProps> = ({ required, error, helperText, margin, id, label, fullWidth, variant, value = "", onValueChanged, options = [], maxLength }) => {
    const [internalValue, setInternalValue] = useState<string>(value);

    const stringOptions: StringOptionType[] = useMemo(() => options.map((o) => {
        return { title: o, inputValue: o };
    }), [options]);

    const onChange = (event: SyntheticEvent, newValue: any) => {
        let valueToSet = "";
        if (typeof newValue === 'string') {
            valueToSet = newValue;
        } else if (newValue && newValue.inputValue) {
            valueToSet = newValue.inputValue;
        } else {
            valueToSet = newValue;
        }

        setInternalValue(valueToSet);
        if (onValueChanged) {
            onValueChanged(valueToSet);
        }
    }

    const filterOptions = (options: StringOptionType[], params: FilterOptionsState<StringOptionType>) => {
        const filtered = filter(options, params);

        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.some((option) => inputValue === option.title);
        if (inputValue !== '' && !isExisting) {
            filtered.push({
                inputValue,
                title: `Add "${inputValue}"`,
            });
        }

        return filtered;
    }

    const getOptionLabel = (option: string | StringOptionType) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
            return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
            return option.inputValue;
        }
        // Regular option
        return option.title;
    }

    useEffect(() => {
        setInternalValue(value);
    }, [value]);

    return (
        <Autocomplete
            id={id}
            fullWidth={fullWidth}
            value={internalValue}
            onChange={onChange}
            options={stringOptions}
            filterOptions={filterOptions}
            getOptionLabel={getOptionLabel}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            freeSolo
            renderOption={(props, option) => <li {...props}>{option.title}</li>}
            renderInput={(params) => (
                <TextField
                    {...params}
                    required={required}
                    error={error}
                    helperText={helperText}
                    margin={margin}
                    label={label}
                    variant={variant}
                    inputProps={{ ...params.inputProps, maxLength }}
                />
            )}
        />
    );
}

export interface IAutoCompleteBoxProps {
    label?: ReactNode;
    error?: boolean;
    helperText?: string;
    margin?: 'dense' | 'normal' | 'none';
    id?: string;
    fullWidth?: boolean;
    variant?: TextFieldVariants;
    options?: string[];
    value?: string;
    required?: boolean;
    maxLength?: number;
    onValueChanged?: (newValue: string) => void;
}

export default StringAutoComplete;
