import React from "react";
import { Box, TextField } from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers";
import { Moment } from "moment";

const OutageFormTime: React.FC<IOutageFormTime> = ({ label = "", time, setTime, disabled = false }) => {

    const handleTimeChange = (newValue: Moment | null) => {
        if (!newValue) { return; }
        setTime(newValue)
    }
    return (
        <Box>
            <TimePicker
                label={label}
                value={time}
                onChange={handleTimeChange}
                disabled={disabled}
                renderInput={(params) => <TextField {...params}
                    fullWidth
                />}
            />
        </Box>
    );
}
export interface IOutageFormTime {
    label?: string
    time: Moment
    setTime: (time: Moment) => void
    disabled?: boolean
}

export default OutageFormTime;