import React from "react";
import { Box, Grid, Paper } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
const ResultsTable: React.FC<IResultsTableProps> = ({ data, columns, sortBy, hideBottomRow }) => {

    return (
        <Paper sx={{ paddingTop: 0, height: "100%" }}>
            <DataGrid
                getRowId={(row) => row.id}
                rows={data}
                columns={columns}
                initialState={{
                    pagination: {
                        pageSize: 100,
                    },
                    sorting: {
                        sortModel: (sortBy !== undefined ? [{ field: sortBy, sort: 'asc' }] : []),
                    },
                }}
                rowsPerPageOptions={[20, 50, 100]}
                hideFooter={hideBottomRow !== undefined ? hideBottomRow : false}
            />
        </Paper>
    );
}

export interface IResultsTableProps {
    data: any
    columns: { field: string, headerName: string, width: number }[]
    sortBy?: string
    hideBottomRow?: boolean
}

export default ResultsTable;