import React from "react";
import { Box, Button, CircularProgress } from "@mui/material";
import { CalendarToday } from "@mui/icons-material";

const OutageFormSubmitButton: React.FC<IOutageFormSubmitButton> = ({ content, click, fontSize = 16, inserting, disabled = false }) => {
    return (
        <Box
            sx={{ height: "100%" }}>
            <Button
                variant="contained"
                disabled={disabled}
                onClick={click}
                fullWidth
                sx={{ height: "100%", fontSize: fontSize }}
            >
                {inserting ? <CircularProgress
                    size={fontSize}
                /> : <><CalendarToday fontSize="inherit" />&nbsp;{content}</>}
            </Button>
        </Box>
    );
}
export interface IOutageFormSubmitButton {
    content: string
    click: () => void
    fontSize?: string|number
    inserting?: boolean
    disabled?: boolean
}

export default OutageFormSubmitButton;