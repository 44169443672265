import VanIcon from '@mui/icons-material/LocalShipping';
import { CSSProperties } from "react";
import { IEngineerLocation } from "../../../shared/types/engineer-locations/IEngineerLocation";

const GoogleMapEngineerIcon: React.FC<IGoogleMapAssetIconProps> = ({ engineer, height = "100%", width = "100%" }) => {
    const iconStyle: CSSProperties = {
        width: width,
        height: height,
        padding: 2,
        color: engineer ? engineer.Speed > 0 ? "green" : "grey" : "black",
    };

    return <VanIcon style={iconStyle} height={height} width={width} fontSize="large" alignmentBaseline="auto" />;
}

export interface IGoogleMapAssetIconProps {
    engineer?: IEngineerLocation;
    height?: string | number;
    width?: string | number;
}

export default GoogleMapEngineerIcon;
