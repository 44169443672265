import ApiRoot from "./ApiRoot";
import { IMeterReading } from "../types/IMeterReading"
import { getApiAccessToken } from "../auth/helpers";
import { IDataFilterOptions } from "../types/IDataFilterOptions";
import { IPowerPriceReading } from "../types/IPowerPriceReading";
import { IGasPriceReading } from "../types/IGasPriceReading";
import { IBOAData } from "../types/data/IBOAData";

const rootUrl = process.env.REACT_APP_PORTAL_API_URL;
const apiKey = process.env.REACT_APP_API_GATEWAY_KEY;

const getFilterData = async () => {
    const accessToken = await getApiAccessToken();
    return await ApiRoot.get<IDataFilterOptions>(`${rootUrl}DataAccess/GetFilterOptions`, { apiKey, accessToken });
}

const getMeterData = async (startDate: string, endDate: string, siteID: string) => {
    const requestBody = {
        "startDate": startDate,
        "endDate": endDate,
        "siteID": siteID
    }
    const accessToken = await getApiAccessToken();
    return await ApiRoot.post<IMeterReading[]>(`${rootUrl}DataAccess/MeterData`, requestBody, { apiKey, accessToken });
}

const getPowerPriceData = async (startDate: string, endDate: string, market: string, country: string, horizon: string, exchange: string) => {
    const requestBody = {
        "startDate": startDate,
        "endDate": endDate,
        "market": (market === "Any" ? null : market),
        "country": (country === "Any" ? null : country),
        "horizon": (horizon === "Any" ? null : horizon),
        "exchange": (exchange === "Any" ? null : exchange)
    }
    const accessToken = await getApiAccessToken();
    return await ApiRoot.post<IPowerPriceReading[]>(`${rootUrl}DataAccess/PowerPriceData`, requestBody, { apiKey, accessToken });
};

const getGasPriceData = async (startDate: string, endDate: string, strip: string, forwardHorizon: string, country: string, timing: string, year: string, commodity: string) => {
    var yearInt = parseInt(year)
    var forwardHorizonInt = parseInt(forwardHorizon)
    const requestBody = {
        "startDate": startDate,
        "endDate": endDate,
        "strip": (strip === "Any" ? null : strip),
        "forwardHorizon": (isNaN(forwardHorizonInt) ? null : forwardHorizonInt),
        "country": (country === "Any" ? null : country),
        "timing": (timing === "Any" ? null : timing),
        "year": (isNaN(yearInt) ? null : yearInt),
        "commodity": (commodity === "Any" ? null : commodity)
    }
    const accessToken = await getApiAccessToken();
    return await ApiRoot.post<IGasPriceReading[]>(`${rootUrl}DataAccess/GasPriceData`, requestBody, { apiKey, accessToken });
};

const getHistoricBOAData = async (date: string) => {
    const accessToken = await getApiAccessToken();
    return await ApiRoot.get<IBOAData[]>(`${rootUrl}IRIS/GetBOALFsOnDate?date=${date}`, { apiKey, accessToken });
}

const dataRequestApi = {
    getFilterData,
    getMeterData,
    getPowerPriceData,
    getGasPriceData,
    getHistoricBOAData
}

export default dataRequestApi;