import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getApiAccessToken } from "../auth/helpers";
import { IElectricityInterconnector } from "../types/IElectricityInterconnector";

export const electricityInterconnectorApi = createApi({
    reducerPath: 'electricityInterconnectorApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_PORTAL_API_URL}`,        
        prepareHeaders: async (headers: Headers, { getState }) => {
            const accessToken = await getApiAccessToken();
            if (accessToken) {
                headers.set("Authorization", `Bearer ${accessToken}`);
            }
        }
    }),
    endpoints: (builder) => ({           
        getGenerationLatest: builder.query<IElectricityInterconnector[], void>({
            query: () => '/electricityinterconnector/generation/latest',                          
        }),        
    }),
})
export const { useGetGenerationLatestQuery } = electricityInterconnectorApi

