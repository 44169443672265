import { Paper, Typography, TextField, Table, TableCell, TableRow, Button, ListItem, TableBody } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import PageWrapper from "../../../components/PageWrapper";
import React, { useEffect } from "react";
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';

import moment, { Moment } from "moment";
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { IPortalRouteOptions } from "../../../shared/types/shared/routes/routeTypes";
import Propane from "@mui/icons-material/Propane";
import { isMobile } from "react-device-detect";

const GasPricePage: React.FC<IGasPricePageProps> = () => {
    const [loaded, setLoaded] = React.useState(false);

    const [selectedDate, setSelectedDate] = React.useState<string>("")

    const loadComplete = () => {
        setSelectedDate(moment().format("YYYY-MM-DD"));
        setLoaded(true);
    }

    const handleSelectedDateChange = (value: Moment | null) => {
        if (value != null) {
            setSelectedDate(value.format("YYYY-MM-DD"));
        }
    }

    useEffect(() => {
        loadComplete()
    }, [])
    const data = [
        {
            name: 'Day 1',
            withinDay: 4000,
            dayAhead: 2400,
            weekend: 2400,
        },
        {
            name: 'Day 2',
            withinDay: 3000,
            dayAhead: 1398,
            weekend: 2210,
        },
        {
            name: 'Day 3',
            withinDay: 2000,
            dayAhead: 9800,
            weekend: 2290,
        },
        {
            name: 'Day 4',
            withinDay: 2780,
            dayAhead: 3908,
            weekend: 2000,
        },
        {
            name: 'Day 5',
            withinDay: 1890,
            dayAhead: 4800,
            weekend: 2181,
        },
        {
            name: 'Day 6',
            withinDay: 2390,
            dayAhead: 3800,
            weekend: 2500,
        },
        {
            name: 'Day 7',
            withinDay: 3490,
            dayAhead: 4300,
            weekend: 2100,
        },
    ];

    return (
        <PageWrapper title="Gas Price Curve Entry" loaded={loaded}>
            <Grid container spacing={2}>
                <Grid xs={12}>
                    <Paper sx={{ p: 2 }}>
                        <Grid container spacing={2}>
                            <Grid lg={6} xs={12}>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DatePicker
                                        label="EFA Date"
                                        inputFormat="YYYY-MM-DD"
                                        value={selectedDate}
                                        onChange={handleSelectedDateChange}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                                <Table width="100%">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="right">
                                                Within Day
                                            </TableCell>
                                            <TableCell>
                                                <TextField type="number" defaultValue={200}></TextField>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right">
                                                Day Ahead
                                            </TableCell>
                                            <TableCell>
                                                <TextField type="number" defaultValue={200}>200</TextField>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right">
                                                Weekend
                                            </TableCell>
                                            <TableCell>
                                                <TextField type="number" defaultValue={200}>200</TextField>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right">
                                                Working Days Next Week
                                            </TableCell>
                                            <TableCell>
                                                <TextField type="number" defaultValue={200}>200</TextField>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right">
                                                Month Ahead
                                            </TableCell>
                                            <TableCell>
                                                <TextField type="number" defaultValue={200}>200</TextField>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                                <Button fullWidth={true} variant="contained">Update</Button>
                            </Grid>
                            <Grid lg={6} xs={12}>
                                <ResponsiveContainer width="100%" height="100%" minHeight={535}>
                                    <LineChart data={data}>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="name" padding={{ left: 30, right: 30 }} />
                                        <YAxis />
                                        <Tooltip />
                                        <Legend />
                                        <Line type="monotone" dataKey="weekend" stroke="#8884d8" />
                                        <Line type="monotone" dataKey="withinDay" stroke="#82ca9d" />
                                        <Line type="monotone" dataKey="dayAhead" stroke="#82ca9d" />
                                    </LineChart>
                                </ResponsiveContainer>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid xs={isMobile ? 12 : 6}>
                    <Paper sx={{ p: 2 }}>
                        <Typography sx={{ pb: 1 }}>Entries Received:</Typography>
                        <List sx={{
                            width: '100%',
                            position: 'relative',
                            overflow: 'auto',
                            maxHeight: 350,
                            '& ul': { padding: 0 },
                        }} dense={true} disablePadding={true}>
                            <ListItem dense={true}>
                                <ListItemText
                                    primary={"2023-07-02 19:34:02 >> Gas Price Submissions Processed"}
                                    primaryTypographyProps={{ color: "limegreen" }}
                                    secondary={"(User: Rhys.Kirk | IP: 123.123.123.123)"}
                                />
                            </ListItem>
                            <ListItem dense={true}>
                                <ListItemText
                                    primary={"2023-07-02 12:11:43 >> Gas Price Submission Late - Notification Sent"}
                                    primaryTypographyProps={{ color: "orange" }}
                                    secondary={"(User: Gas Price Bot)"}
                                />
                            </ListItem>
                            <ListItem dense={true}>
                                <ListItemText
                                    primary={"2023-07-02 12:11:43 >> Gas Price Submissions Processed"}
                                    primaryTypographyProps={{ color: "limegreen" }}
                                    secondary={"(User: Rhys.Kirk | IP: 123.123.123.123)"}
                                />
                            </ListItem>
                            <ListItem dense={true}>
                                <ListItemText
                                    primary={"2023-07-02 11:34:02 >> Gas Price Submissions Failed"}
                                    primaryTypographyProps={{ color: "red" }}
                                    secondary={"(User: Steve.Moody | IP: 1.2.3.1)"}
                                />
                            </ListItem>
                        </List>
                    </Paper>
                </Grid>
            </Grid>
        </PageWrapper>
    );
}

export interface IGasPricePageProps {

}

const GasPricePageConfig: IPortalRouteOptions = {
    relativeUrl: "gas-price",
    page: <GasPricePage />,
    navDisplay: {
        title: "Gas Price",
        icon: <Propane />
    }
}

export default GasPricePageConfig;
