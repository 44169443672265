import { IPushNotification } from "../../../shared/types/notification/IPushNotification"

export const fullColumns: HeadCell[] = [
    {
        id: "notificationID",
        label: "ID",
        formatType: "Text"
    },
    {
        id: "submissionTime",
        label: "Submission Time",
        formatType: "DateTime"
    },
    {
        id: "priority",
        label: "Priority",
        formatType: "Text"
    },
    {
        id: "type",
        label: "Type",
        formatType: "Text"
    },
    {
        id: "asset",
        label: "Asset",
        formatType: "Text"
    },
    {
        id: "subject",
        label: "Subject",
        formatType: "Text"
    },
    {
        id: "details",
        label: "Details",
        formatType: "RichText"
    },
    {
        id: "iONLink",
        label: "iON Link",
        formatType: "Link"
    },
    {
        id: "trackLink",
        label: "Track Link",
        formatType: "Link"
    },
    {
        id: "recipient",
        label: "Recipient",
        formatType: "Text"
    },
    {
        id: "deleted",
        label: "Deleted",
        formatType: "Boolean"
    },
    {
        id: "seen",
        label: "Read",
        formatType: "Boolean"
    },
]

export const shortenedColumns: HeadCell[] = [
    {
        id: "submissionTime",
        label: "Submission Time",
        formatType: "DateTime"
    },
    {
        id: "asset",
        label: "Asset",
        formatType: "Text"
    },
    {
        id: "priority",
        label: "Priority",
        formatType: "Text"
    },
    {
        id: "type",
        label: "Type",
        formatType: "Text"
    },
    {
        id: "subject",
        label: "Subject",
        formatType: "Text"
    },
    {
        id: "details",
        label: "Details",
        formatType: "RichText"
    }
]

export interface HeadCell {
    id: keyof IPushNotification,
    label: string,
    formatType: "Boolean" | "DateTime" | "Link" | "Text" | "RichText"
}