import ApiRoot from "./ApiRoot";
import { IIglooAssetTrade } from "../../shared/types/IIglooAssetTrade"
import { Dictionary } from "@reduxjs/toolkit";
import { ISchedulerElbowPoint } from "../types/ISchedulerElbowPoint";

// const rootUrl = "https://ion-trade.conradcloud.net/schedulerdata/ProcessorService";
const rootUrl = process.env.REACT_APP_TRADE_PROCESSOR_URL + 'ProcessorService';

const getAssets = async () => {
    return await ApiRoot.get<string[]>(rootUrl + "/Assets");
}

const getBatteryAssets = async () => {
    return await ApiRoot.get<string[]>(rootUrl + "/BatteryAssets");
}

const getRawTrades = async () => {
    return await ApiRoot.get<IIglooAssetTrade[]>(rootUrl + "/RawTrades");
}

const getBMUs = async () => {
    return await ApiRoot.get<Dictionary<string>>(rootUrl + "/BMUs");
}

const getProfilesFromTrades = async () => {
    return await ApiRoot.get<Dictionary<Dictionary<number>>>(rootUrl + "/ProfilesFromTrades");
}

const getProfilesFromAvailability = async () => {
    return await ApiRoot.get<Dictionary<Dictionary<number>>>(rootUrl + "/ProfilesFromAvailability");
}

const getProfilesFromiON = async () => {
    return await ApiRoot.get<Dictionary<Dictionary<number>>>(rootUrl + "/ProfilesFromiON");
}

const getProfilesWithRamps = async () => {
    return await ApiRoot.get<Dictionary<Dictionary<number>>>(rootUrl + "/ProfilesWithRamps");
}

const getElbowPoints = async () => {
    return await ApiRoot.get<Dictionary<ISchedulerElbowPoint[]>>(rootUrl + "/ElbowPoints");
}

const tradeProcessorApi = {
    getAssets,
    getBatteryAssets,
    getRawTrades,
    getBMUs,
    getProfilesFromTrades,
    getProfilesFromAvailability,
    getProfilesFromiON,
    getProfilesWithRamps,
    getElbowPoints
}

export default tradeProcessorApi;