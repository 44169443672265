// Core
import React, { useCallback, useEffect } from 'react';
import { Dictionary } from '@reduxjs/toolkit';
import { useToastAlertContext } from '../../../components/ToastAlert';

// Types
import { IIglooAssetTrade } from '../../../shared/types/IIglooAssetTrade';
import { ISchedulerPosition } from "../../../shared/types/ISchedulerPosition"

// Components
import PageWrapper from '../../../components/PageWrapper';
import TradesTable from '../../../components/trade/TradesTable';
import SchedulerDataChart from '../../../components/optimise/SchedulerDataChart';

// Api & Utils
import moment from "moment"
import tradeProcessorApi from "../../../shared/api/TradeProcessorApi";
import { GasCommercialOverviewUrl } from "../../../shared/utils/DispathWebUtils";

// Material
import { List, ListItemButton, ListItemText, Paper, Typography, Button, Box } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import SchedulerElbowPointsTable from '../../../components/trade/SchedulerElbowPointsTable';
import { ISchedulerElbowPoint } from '../../../shared/types/ISchedulerElbowPoint';

import { Link } from 'react-router-dom';
import { useGetAssetsQuery } from '../../../shared/api/AssetApi';
import { IPortalRouteOptions } from '../../../shared/types/shared/routes/routeTypes';
import { MoveDown } from '@mui/icons-material';
import { isMobile } from 'react-device-detect';

const NonBMUPositionsPage: React.FC<INonBMUPositionsPageProps> = () => {
    const { popToast } = useToastAlertContext();
    const [backgroundRefreshing, setBackgroundRefreshing] = React.useState<boolean>(false);
    const [loaded, setLoaded] = React.useState(false);
    const { data: assets } = useGetAssetsQuery();

    const [dataRefreshed, setDataRefreshed] = React.useState<string>("")
    const [selectedAsset, setSelectedAsset] = React.useState<string>("")
    const [siteIdToName, setSiteIdToName] = React.useState<Dictionary<string>>({})
    const [iglooTrades, setIglooTrades] = React.useState<IIglooAssetTrade[]>([])
    const [selectedIglooTrades, setSelectedIglooTrades] = React.useState<IIglooAssetTrade[]>([])
    const [schedulerBMUs, setSchedulerBMUs] = React.useState<Dictionary<string>>({})
    const [schedulerGasAssets, setGasAssets] = React.useState<string[]>([])

    const [selectedElbowPoints, setSelectedElbowPoints] = React.useState<ISchedulerElbowPoint[]>([])
    const [schedulerElbowPoints, setSchedulerElbowPoints] = React.useState<Dictionary<ISchedulerElbowPoint[]>>({})

    const [schedulerPositions, setSchedulerPositions] = React.useState<ISchedulerPosition[]>([])
    const [schedulerProfileFromTrades, setSchedulerProfileFromTrades] = React.useState<Dictionary<Dictionary<number>>>({})
    const [schedulerProfileFromAvailability, setSchedulerProfileFromAvailability] = React.useState<Dictionary<Dictionary<number>>>({})
    const [schedulerProfileFromiON, setSchedulerProfileFromiON] = React.useState<Dictionary<Dictionary<number>>>({})

    const loadComplete = () => {
        setLoaded(true);
    }

    const fetchPageData = useCallback(async () => {
        const requestMade = moment().format("HH:mm:ss.SSS");

        const getRawTrades = tradeProcessorApi.getRawTrades()
            .then(setIglooTrades)
            .catch(error => { throw new Error("Unable to get Igloo Trades data: " + error); });

        var dict: Dictionary<string> = {};
        assets?.forEach((item) => dict[item.SiteID] = item.Name);
        setSiteIdToName(dict);

        const getBMUs = tradeProcessorApi.getBMUs()
            .then(setSchedulerBMUs)
            .catch(error => { throw new Error("Unable to get BMU List: " + error); });

        const getGasAssets = tradeProcessorApi.getAssets()
            .then(setGasAssets)
            .catch(error => { throw new Error("Unable to get Gas Assets: " + error); });

        const getProfilesFromTrades = tradeProcessorApi.getProfilesFromTrades()
            .then(setSchedulerProfileFromTrades)
            .catch(error => { throw new Error("Unable to get Profile From Trades data: " + error); });

        const getProfilesFromAvailability = tradeProcessorApi.getProfilesFromAvailability()
            .then(setSchedulerProfileFromAvailability)
            .catch(error => { throw new Error("Unable to get Profile From Availability data: " + error); });

        const getProfilesFromiON = tradeProcessorApi.getProfilesFromiON()
            .then(setSchedulerProfileFromiON)
            .catch(error => { throw new Error("Unable to get Profile From iON data: " + error); });

        const getElbowPoints = tradeProcessorApi.getElbowPoints()
            .then(setSchedulerElbowPoints)
            .catch(error => { throw new Error("Unable to get Profile With Elbow Points data: " + error); });

        const promises = [
            getRawTrades,
            getBMUs,
            getGasAssets,
            getProfilesFromTrades,
            getProfilesFromAvailability,
            getProfilesFromiON,
            getElbowPoints,
        ]

        return Promise.all(promises)
            .then(() => { setDataRefreshed(requestMade); })
            .catch((error: Error) => popToast(error.message, "error"))
            .finally(loadComplete);
    }, [popToast, assets]);

    const handleAssetClick = (index: string) => {
        setSelectedAsset(index);
        setSelectedIglooTrades(iglooTrades.filter(item => (item.asset === index)));

        var schedulerPositions: ISchedulerPosition[] = [];
        var data = schedulerProfileFromTrades[index];
        var availData = schedulerProfileFromAvailability[index];
        var iONData = schedulerProfileFromiON[index];

        data && Object.keys(data).forEach((key, keyIndex) => {

            schedulerPositions.push({
                asset: index,
                dispatchTimeUTC: key,
                pnPlanTrade: (data ? (data[key] ? data[key] : 0.0) : 0.0) || 0.0,
                pnPlanAvailability: (availData ? (availData[key] ? availData[key] : 0.0) : 0.0) || 0.0,
                pnPlanIon: (iONData ? (iONData[key] ? iONData[key] : 0.0) : 0.0) || 0.0,
                pnPlanSentinel: 0.0,
                dispatchDate: moment(new Date(key)).format("DD-MMM-YYYY"),
                dispatchTime: moment(new Date(key)).format("HH:mm:ss")
            });

        });

        var elbowPoints: ISchedulerElbowPoint[] = [];
        elbowPoints = schedulerElbowPoints[index] ?? [];
        setSelectedElbowPoints(elbowPoints);

        setSchedulerPositions(schedulerPositions);
    }

    useEffect(() => {
        fetchPageData()

        const intervalId = setInterval(async () => {
            setBackgroundRefreshing(true);
            await fetchPageData(); // Fetch data every 2 minutes
            setBackgroundRefreshing(false);
        }, 120000);

        return () => clearInterval(intervalId);
    }, [fetchPageData])

    return (
        <PageWrapper title="Non-BMU Positions" loaded={loaded} refreshing={backgroundRefreshing}>
            <Typography fontSize={10} textAlign="left" sx={{ width: "100%" }}>Last Updated: {dataRefreshed}</Typography>
            <Grid container spacing={1}>
                <Grid xs={isMobile ? 12 : 3}>
                    <Paper sx={{ p: 2, height: "100%" }}>
                        <Typography flexGrow={1} textAlign="left" sx={{ width: "100%" }}>Select Gas Asset</Typography>
                        <Box>
                            <List sx={{
                                width: '100%',
                                bgcolor: 'background.paper',
                                position: 'relative',
                                overflow: 'auto',
                                height: isMobile ? 200 : 400,
                                mb: 1,
                                '& ul': { padding: 0 },
                            }} dense={true} disablePadding={true}>
                                {schedulerGasAssets.filter(item => (schedulerBMUs[item] === undefined)).map((item) =>
                                    <ListItemButton dense={true} key={item} onClick={() => { handleAssetClick(item) }}>
                                        <ListItemText
                                            primary={item}
                                            secondary={"(" + siteIdToName[item] + ": " + iglooTrades.filter(iglooItem => (iglooItem.asset === item)).length + " trades)"}
                                        />
                                    </ListItemButton>,
                                )}
                            </List>
                            <Button disabled={!selectedAsset} fullWidth={true} variant="contained" sx={{ padding: 1, textAlign: "center", height: 55 }} component={Link} to={GasCommercialOverviewUrl(siteIdToName[selectedAsset])} target="_blank">
                                {!selectedAsset ? "No Asset Selected" : `iON Dispatch for ${selectedAsset} (${siteIdToName[selectedAsset]})`}
                            </Button>
                        </Box>
                    </Paper>
                </Grid>

                <Grid xs={isMobile ? 12 : 9}>
                    <Paper>
                        <Typography flexGrow={1} textAlign="left" sx={{ width: "100%", p: 1 }}>Trades</Typography>
                        <TradesTable height={480} items={selectedIglooTrades} />
                    </Paper>
                </Grid>

                <Grid xs={isMobile ? 12 : 6}>
                    <Paper>
                        <Typography flexGrow={1} textAlign="left" sx={{ width: "100%", p: 1 }}>Elbow Points</Typography>
                        <SchedulerElbowPointsTable height={416} items={selectedElbowPoints} />
                    </Paper>
                </Grid>

                <Grid xs={isMobile ? 12 : 6}>
                    <Paper sx={{ p: 1 }}>
                        <Typography flexGrow={1} textAlign="left" sx={{ width: "100%", pb: 1 }}>Data Chart</Typography>
                        <Box sx={{ p: 2, height: 408, width: "100%", minWidth: 300, overflow: "auto"  }}>
                            <SchedulerDataChart data={schedulerPositions} />
                        </Box>
                    </Paper>
                </Grid>

            </Grid>
        </PageWrapper >
    );
}

export interface INonBMUPositionsPageProps {

}

const NonBMUPositionsPageConfig: IPortalRouteOptions = {
    relativeUrl: "non-bmu-positions",
    page: <NonBMUPositionsPage />,
    navDisplay: {
        title: "Non-BMU Positions",
        icon: <MoveDown />,
    }
}

export default NonBMUPositionsPageConfig;
