import React, { useEffect } from "react";
import PageWrapper from "../../components/PageWrapper";
import { Grid, Paper, Typography } from "@mui/material";
import AvailabilityHeatmapTable from "../../components/operate/AvailabilityHeatmapTable"
import { IAssetAvailability } from "../../shared/types/operate/IAssetAvailability"
import { Dictionary } from "@reduxjs/toolkit";
import availabilityApi from "../../shared/api/AvailabilityApi";
import moment from "moment"
import { useToastAlertContext } from "../../components/ToastAlert";
import { useGetLiveAssetsQuery } from "../../shared/api/AssetApi";
import { IPortalRouteOptions } from "../../shared/types/shared/routes/routeTypes";
import { Dashboard } from "@mui/icons-material";

const OperateHomePage: React.FC<IOperateHomePage> = () => {
    const { popToast } = useToastAlertContext();
    const [loaded, setLoaded] = React.useState(false);

    const [regions, setRegions] = React.useState<string[]>([]);
    const [regionToSite, setRegionToSite] = React.useState<Dictionary<string>>();
    const [capacities, setCapacities] = React.useState<Dictionary<number>>({});
    const [availability, setAvailability] = React.useState<IAssetAvailability[][]>([]);
    const { data: assets } = useGetLiveAssetsQuery();

    const loadComplete = () => {
        setLoaded(true);
    }

    const fetchSiteData = () => {
        if (assets !== undefined) {
            var dictName: Dictionary<string> = {}
            var dictCapacity: Dictionary<number> = {}
            var uniqueTags: Dictionary<string> = {}
            const siteIDs = new Array<string>()
            assets?.forEach((item) => {
                dictName[item.SiteID] = item.Name
                dictCapacity[item.SiteID] = (item.CapabilityKW === null ? 0 : item.CapabilityKW)
                uniqueTags[item.SiteID] = item.ConradRegion;
                siteIDs.push(item.SiteID)
            });
            const uniqueRegions: string[] = [];
            assets?.forEach((item) => {
                if (uniqueRegions.indexOf(item.ConradRegion) === -1) {
                    if (item.ConradRegion) {
                        uniqueRegions.push(item.ConradRegion)
                    }
                }
            });
            setRegionToSite(uniqueTags);
            setCapacities(dictCapacity)
            setRegions(uniqueRegions.sort());
            const getAllAssetAvailability = availabilityApi.getAssetAvailability(moment().startOf('day').format(), moment().endOf('day').format(), siteIDs.sort())
                .then((availabilityResponse) => {
                    setAvailability(availabilityResponse)
                })
                .catch((error) => { throw new Error(`Failed to load asset availability: ${error}`); })

            Promise.all([getAllAssetAvailability])
                .catch((error: Error) => { popToast(error.message, "error"); })
                .finally(loadComplete);
        }
    }

    useEffect(() => {
        fetchSiteData()
    }, [assets])

    const Regions = (): JSX.Element => {
        if (!regions || !availability || !regionToSite || !capacities) { return (<></>); }

        return (
            <>
                {
                    regions.map((regionItem) => {
                        let regionAvailability: IAssetAvailability[][] = [];
                        availability.forEach(availItem => {
                            if (regionToSite && regionToSite[availItem[0].siteID] === regionItem) {
                                regionAvailability.push(availItem);
                            }
                        })
                        return (<AvailabilityHeatmapTable key={regionItem} title={regionItem} items={regionAvailability} siteIdToCapacity={capacities} />);
                    })
                }
            </>
        );
    }

    return (
        <PageWrapper title="Operate" loaded={loaded}>
            <Grid container spacing={2}>
                <Grid item md={12} xs={12}>
                    <Paper sx={{ p: 2 }} elevation={2}>
                        <Typography color="primary" variant="h5">Availability Heatmap for {moment().format("YYYY-MM-DD")}</Typography>
                        <Regions/>
                    </Paper>
                </Grid>
            </Grid>
        </PageWrapper>
    );
}

export interface IOperateHomePage {

}

const OperateHomePageConfig: IPortalRouteOptions = {
    relativeUrl: "operate",
    page: <OperateHomePage />,
    navDisplay: {
        title: "Dashboard",
        icon: <Dashboard />
    }
}

export default OperateHomePageConfig;