import PageWrapper from "../../components/PageWrapper";
import React, { useEffect } from "react";
import BalancingVolumeChart from "../../components/analytics/BalancingVolumeChart";
import { Box } from "@mui/material";
import { IPortalRouteOptions } from "../../shared/types/shared/routes/routeTypes";
import { Analytics } from "@mui/icons-material";

const AnalyticsBalancingVolumesPage: React.FC<IAnalyticsBalancingVolumesPage> = () => {
    const [loaded, setLoaded] = React.useState(false);

    const loadComplete = () => {
        setLoaded(true);

    }

    useEffect(() => {
        loadComplete()
    }, []);

    return (
        <PageWrapper title="Balancing Volume Chart" loaded={loaded}>
            <Box sx={{ height: "75vh", maxHeight: "700px", width: "74vw" }} >
                <BalancingVolumeChart />
            </Box>
        </PageWrapper>
    );
}


export interface IAnalyticsBalancingVolumesPage {

}

const AnalyticsBalancingVolumesPageConfig: IPortalRouteOptions = {
    relativeUrl: "balance-volumes",
    page: <AnalyticsBalancingVolumesPage />,
    navDisplay: {
        title: "Balancing Volumes",
        icon: <Analytics />
    },
}

export default AnalyticsBalancingVolumesPageConfig;