import { Lightbulb } from "@mui/icons-material";
import { Collapse, Paper, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import OpportunitiesGrid from "../../components/commercial/OpportunitiesGrid";
import OpportunityDetails from "../../components/commercial/OpportunityDetails";
import OpportunitySearchForm from "../../components/commercial/OpportunitySearchForm";
import PageWrapper from "../../components/PageWrapper";
import { useFindOpportunitiesQuery } from "../../shared/api/CommercialOpportunityApi";
import { IOpportunity } from "../../shared/types/commercial/opportunity/IOpportunity";
import { IPortalRouteOptions } from "../../shared/types/shared/routes/routeTypes";

const CommercialOpportunitiesPage: React.FC = () => {
    const [loaded, setLoaded] = useState(false);
    const [opportunity, setOpportunity] = useState<IOpportunity | null>(null);

    const loadComplete = useCallback(async () => {
        setLoaded(true);
    }, [])

    useEffect(() => {
        loadComplete()
    }, [loadComplete])

    const [searchCriteria, setSearchCriteria] = useState("");
    const { data: opportunities, isFetching } = useFindOpportunitiesQuery(searchCriteria, { skip: searchCriteria === "" ? true : false });

    const searchOpportunityHandler = (search: string) => {
        setSearchCriteria(search);
    }

    const opportunitySelectHandler = (opportunity: IOpportunity) => {
        setOpportunity(opportunity);
    }

    const opportunityDetailsBackHandler = () => {
        setOpportunity(null);
    }
 
    return (
        <PageWrapper title="Opportunities" loaded={loaded}>
            <Paper sx={{ p: 2, width: "100%" }}>
                {!opportunity &&
                    (
                        <Collapse in={opportunity ?? true}>
                            <Typography variant="body1">Search for an opportunity by tender id, opportunity name, account name</Typography>
                            <OpportunitySearchForm onSearchData={searchOpportunityHandler} searchCriteria={searchCriteria} isSearching={isFetching} />
                            <OpportunitiesGrid data={opportunities} onSelectOpportunity={opportunitySelectHandler} />
                        </Collapse>
                    )
                }
                <Collapse in={opportunity == null ? false : true}>
                    <OpportunityDetails opportunity={opportunity} onBack={opportunityDetailsBackHandler} />
                </Collapse>
            </Paper>
        </PageWrapper>
    );
}

const CommercialOpportunitiesPageConfig: IPortalRouteOptions = {
    relativeUrl: "opportunities",
    page: <CommercialOpportunitiesPage />,
    navDisplay: {
        title: "Opportunities",
        icon: <Lightbulb />
    }
}

export default CommercialOpportunitiesPageConfig;