import { Text } from 'react-hexgrid';
import { StatusHiveSiteData } from '../../shared/types/asset/status-hive';

export const HexBatteryStatus = ({ status, activeColour, inactiveColour, y = 0, x = 0 }: IHexStatusTextProps): JSX.Element => {

    const height = 2.1;
    const width = 2.7;

    const strokeWidth = 0.1; // The total stroke width of the rectangle
    const lineThickness = strokeWidth / 2; // The thickness of each line
    const fontSizeValue = 0.07;

    const widthOffset = width + (strokeWidth * 2);

    // Y Values
    const yPosValue = -(height / 2) - lineThickness + y;
    const yPosValueTopText = yPosValue + (height / 2) - strokeWidth;
    const yPosValueBottomText = yPosValue + height - strokeWidth*2;

    const yPos = `${yPosValue}em`;
    const yPosTopText = `${yPosValueTopText}em`;
    const yPosBottomText = `${yPosValueBottomText}em`;

    // Centre
    const xPosValue = -(width / 2) - lineThickness + x;
    const xPosValueText = 0;

    const xPos = `${xPosValue}em`;
    const xPosText = `${xPosValueText}em`;

    // Left
    const xPosValueLeft = xPosValue - widthOffset;
    const xPosValueLeftText = xPosValueText - widthOffset;

    const xPosLeft = `${xPosValueLeft}em`;
    const xPosLeftText = `${xPosValueLeftText}em`;

    // Right
    const xPosValueRight = xPosValue + widthOffset;
    const xPosValueRightText = xPosValueText + widthOffset;

    const xPosRight = `${xPosValueRight}em`;
    const xPosRightText = `${xPosValueRightText}em`;

    // Formatted
    const fontSize = `${fontSizeValue}em`;
    const rectWidth = `${width}em`;
    const rectHeight = `${height}em`;

    const determineColour = (value?: number) => value && value > 0 ? activeColour : inactiveColour;

    return (
        <>
            <rect x={xPosLeft} y={yPos} fill={'transparent'} fontSize={fontSize} height={rectHeight} width={rectWidth} stroke={activeColour} strokeWidth={`${strokeWidth}em`} />
            <Text x={xPosLeftText} y={yPosTopText} fill={determineColour(status.dch)} fontSize={fontSize} strokeWidth="0" fontWeight={600}>DCH</Text>
            <Text x={xPosLeftText} y={yPosBottomText} fill={determineColour(status.dcl)} fontSize={fontSize} strokeWidth="0" fontWeight={600}>DCL</Text>

            <rect x={xPos} y={yPos} fill={'transparent'} fontSize={fontSize} height={rectHeight} width={rectWidth} stroke={activeColour} strokeWidth={`${strokeWidth}em`} />
            <Text x={xPosText} y={yPosTopText} fill={determineColour(status.dmh)} fontSize={fontSize} strokeWidth="0" fontWeight={600}>DMH</Text>
            <Text x={xPosText} y={yPosBottomText} fill={determineColour(status.dml)} fontSize={fontSize} strokeWidth="0" fontWeight={600}>DML</Text>

            <rect x={xPosRight} y={yPos} fill={'transparent'} fontSize={fontSize} height={rectHeight} width={rectWidth} stroke={activeColour} strokeWidth={`${strokeWidth}em`} />
            <Text x={xPosRightText} y={yPosTopText} fill={determineColour(status.drh)} fontSize={fontSize} strokeWidth="0" fontWeight={600}>DRH</Text>
            <Text x={xPosRightText} y={yPosBottomText} fill={determineColour(status.drl)} fontSize={fontSize} strokeWidth="0" fontWeight={600}>DRL</Text>
        </>
    );
}

export interface IHexStatusTextProps {
    status: StatusHiveSiteData;
    activeColour: string;
    inactiveColour: string;
    y?: number;
    x?: number;
}