import React from "react";
import LoadingSpinner from "../LoadingSpinner";
import { Grid } from "@mui/material";
const LoadingSymbol: React.FC<ILoadingSymbol> = () => {
    return (
        <Grid container direction="column" alignItems="center" spacing={5}>
            <Grid item xs={12}></Grid>
            <Grid item xs={12}>
                <LoadingSpinner />
            </Grid>
        </Grid>
    );

}

export interface ILoadingSymbol {

}

export default LoadingSymbol;