import { Box, Typography } from "@mui/material";
import React, { useCallback, useEffect } from "react";
import analyticsApi from "../../shared/api/AnalyticsApi";
import moment from "moment"
import LoadingSymbol from "../operate/LoadingSymbol";
import { ComposedChart, ErrorBar, Label, Legend, Line, ReferenceLine, ResponsiveContainer, Scatter, Tooltip, XAxis, YAxis } from 'recharts';
import { useColourModeContext } from "../ColourMode";
import { IAnalyticsChartDataItem } from "../../shared/types/analytics/IAnalyticsChartDataItem";
import { NewColorPalette } from "./helpers/NewColorPalette";
import { DateFormatter } from "./helpers/DateFormatter"

const BalancingPricingChart: React.FC<IBalancingPricingChart> = ({ displayScreen }) => {
    const [chartData, setChartData] = React.useState<Array<IAnalyticsChartDataItem>>([])
    const [yAxisMax, setYAxisMax] = React.useState<number>(0)
    const [currentTime, setCurrentTime] = React.useState<number>(0)
    const [beginningOfDay, setBeginningOfDay] = React.useState<number>(0)
    const [domainStart, setDomainStart] = React.useState<number>(0)
    const [domainEnd, setDomainEnd] = React.useState<number>(1)
    const colourMode = useColourModeContext();
    const sipColor = (colourMode.isDarkMode ? "white" : NewColorPalette[4])
    const upperRangeColor = NewColorPalette[1]
    const lowerRangeColor = NewColorPalette[7]

    const loadBalancingData = useCallback(() => {
        const formattedData = new Array<IAnalyticsChartDataItem>();
        let yMax = 0
        analyticsApi.getBalancePriceData()
            .then((response) => {
                const currentMoment = moment().utc().valueOf()
                let currentDate = moment().startOf('day').utc()
                const today = currentDate.valueOf()
                const yesterday = currentDate.add(-1, 'days').valueOf()
                const tomorrow = currentDate.add(2, 'days').valueOf()
                const dateTimes = new Set<number>()
                response.forEach((dataItem) => {
                    const dataMoment = moment(dataItem.dateTimeUTC + "Z").valueOf()
                    if (!dateTimes.has(dataMoment)) {
                        dateTimes.add(dataMoment)
                        const valuesArray = new Array<number>(dataItem.sip || 0, dataItem.maxOffer, dataItem.minOffer, dataItem.maxBid, dataItem.minBid)
                        const max = Math.max.apply(null, valuesArray.map(Math.abs))
                        if (max > yMax) {
                            yMax = max
                        }
                        const minOffer = dataItem.minOffer
                        const maxOffer = dataItem.maxOffer
                        const minBid = dataItem.minBid
                        const maxBid = dataItem.maxBid
                        const newItem: IAnalyticsChartDataItem = {
                            dateTime: dataMoment,
                            sip: dataItem.sip,
                            minOffer: minOffer,
                            maxOffer: maxOffer,
                            minBid: minBid,
                            maxBid: maxBid,
                            offerMidpoint: (maxOffer + minOffer) / 2,
                            bidMidpoint: (maxBid + minBid) / 2,
                            offerRange: (maxOffer - minOffer) / 2,
                            bidRange: (maxBid - minBid) / 2
                        }
                        formattedData.push(newItem)
                    }
                })
                if (yMax > 1000) { yMax = 1000 } // Occasionally get a max offer of 9999 MW that should go off screen
                setYAxisMax(yMax)
                setChartData(formattedData)
                setDomainStart(yesterday)
                setDomainEnd(tomorrow)
                setCurrentTime(currentMoment)
                setBeginningOfDay(today)
            })
    }, [])


    useEffect(() => {
        loadBalancingData()
        const intervalId = setInterval(() => {
            loadBalancingData(); // Fetch data every 2 minutes
        }, 120000);

        return () => clearInterval(intervalId);
    }, [loadBalancingData])

    const CustomTooltip = ({ active, payload }: any) => {
        if (active && payload && payload.length && payload[0].payload) {
            const sip = payload[0].payload.sip
            const minOffer = payload[0].payload.minOffer
            const maxOffer = payload[0].payload.maxOffer
            const minBid = payload[0].payload.minBid
            const maxBid = payload[0].payload.maxBid
            return (
                <Box sx={{
                    "backgroundColor": (colourMode.isDarkMode ? "#262626" : "white"),
                    "borderRadius": 5,
                    "border": (colourMode.isDarkMode ? "1px solid white" : "1px solid #262626"),
                    "color": (colourMode.isDarkMode ? "white" : "black"),
                    px: 1,
                    py: 0.5
                }} >
                    <p>Date (UTC): {DateFormatter(payload[0].payload.dateTime)}</p>
                    {(sip ? <p style={{ color: sipColor }}>SIP: {payload[0].payload.sip} MW</p> : <></>)}
                    {(minOffer || maxOffer ? <p style={{ color: upperRangeColor }}>Offer Range: {minOffer} ~ {maxOffer} MW</p> : <></>)}
                    {(minBid || maxBid ? <p style={{ color: lowerRangeColor }}>Bid Range: {minBid} ~ {maxBid} MW</p> : <></>)}
                </Box>
            );
        }

        return null;
    };
    return (
        <Box
            sx={{ "height": "100%", "width": "100%" }}
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}>
            <Box
                sx={(displayScreen ? { "height": "100%", "width": "100%", "backgroundColor": "#262626", "borderRadius": "10px", padding: 1 } : { "height": "100%", "width": "100%" })}
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}>
                <Typography style={{ fontSize: "20px", color: (colourMode.isDarkMode || displayScreen ? "white" : "black") }}>Balancing Pricing Chart</Typography>
                <ResponsiveContainer height={"87%" }>
                    {(chartData.length > 0 ?
                        <ComposedChart data={chartData} stackOffset="sign">
                            <XAxis padding="gap" name="Time" domain={[domainStart, domainEnd]} dataKey="dateTime" scale="time" type="number" tickFormatter={DateFormatter} hide={displayScreen} allowDataOverflow={true} />
                            <YAxis domain={[0 - yAxisMax, yAxisMax]} allowDataOverflow={true}><Label dx={-20} angle={-90}>MW</Label></YAxis>
                            {(!displayScreen ? <Legend /> : <></>)}
                            <Tooltip content={<CustomTooltip payload={chartData} />} />
                            <ReferenceLine x={beginningOfDay} stroke={"white"} strokeDasharray="5" opacity={0.3} isFront={true} strokeWidth={3} />
                            <ReferenceLine x={currentTime} stroke={NewColorPalette[4]} strokeDasharray="18" opacity={100} isFront={true} strokeWidth={3} />
                            <ReferenceLine y={0} stroke="grey" strokeWidth={3} />
                            <Scatter name="Upper Range" dataKey="offerMidpoint" fill={upperRangeColor} label={false} legendType="plainline" tooltipType="none" shape={<></>} isAnimationActive={false}>
                                <ErrorBar dataKey="offerRange" width={4} strokeWidth={2} stroke={upperRangeColor} direction="y" />
                            </Scatter>
                            <Scatter name="Lower Range" dataKey="bidMidpoint" fill={lowerRangeColor} label={false} legendType="plainline" tooltipType="none" shape={<></>} isAnimationActive={false}>
                                <ErrorBar dataKey="bidRange" width={4} strokeWidth={2} stroke={lowerRangeColor} direction="y" />
                            </Scatter>
                            <Line type="step" dataKey="sip" name="SIP" unit=" MW" stroke={sipColor} strokeWidth={3} legendType="plainline" dot={false} activeDot={false} isAnimationActive={false} />
                        </ComposedChart> :
                        <LoadingSymbol />
                    )}
                </ResponsiveContainer>
            </Box>
        </Box>
    );
}
export interface IBalancingPricingChart {
    displayScreen?: boolean
}

export default BalancingPricingChart;