import { HubConnection } from "@microsoft/signalr";
import moment from "moment";
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from "react";
import useBOAData from "../../shared/hooks/BOADataHook";
import useNotificationMenu from "../../shared/hooks/NotificationMenuHook";
import { IBOAData } from "../../shared/types/data/IBOAData";
import { IPushNotification } from '../../shared/types/notification/IPushNotification';
import { GetNotificationHubConnection } from "../../shared/utils/SignalRHelper";
import { usePortalContext } from "../PortalWrapper";

const LiveNotifications: React.FC<ILiveNotifications> = () => {
    const [connection, setConnection] = useState<null | HubConnection>(null);
    const { enqueueSnackbar } = useSnackbar()
    const { addNotification } = useNotificationMenu()
    const { addNewBOA } = useBOAData()

    const { isAuthenticated } = usePortalContext();
    
    const establishConnection = useCallback(async () => {
        if (connection === null && isAuthenticated) {
            try {
                const connection = await GetNotificationHubConnection();
                setConnection(connection);
            }
            catch (error) {
                console.log(error)
            }
        }
    }, [])

    useEffect(() => {
        establishConnection()
    }, [])

    const sendBrowserNotification = (data: IPushNotification) => {
        const notifBody = "Priority: " + data.priority.toUpperCase() + "\n" + "Type: " + data.type + "\n" + data.details

        const notif = new Notification(data.subject, {
            body: notifBody,
            icon: "../../images/ion-logo.png",
            tag: data.notificationID,
            requireInteraction: data.priority === "High"
        })
    }

    const startConnection = async (delay = 5000) => {
        if (connection != null) {
            connection
                .start()
                .then(() => {
                    // Successful Connection
                    console.log("Connection Started")
                })
                .catch(async (error) => {
                    console.log(error)
                    // Retry with after serving exponential delay
                    await new Promise(() => {
                        setTimeout(() => {
                            startConnection(Math.min(delay * 2, 60000))
                        }, delay)
                    })
                })
        }
        return true
    }

    useEffect(() => {
        if (connection && connection.state === "Disconnected") {
            let connected = startConnection()
            connection.on("NewBOA", ((data: IBOAData) => {
                data.inputTime = moment().utc().format()
                addNewBOA(data)
            }))
            connection.on("ReceiveMessage", ((data: IPushNotification) => {
                addNotification(data)
                // Commenting out browser notifications until V2
                /*
                if (Notification.permission === "granted") {
                    sendBrowserNotification(data)
                }
                else {
                    Notification.requestPermission().then(perm => {
                        if (perm === "granted") {
                            sendBrowserNotification(data)
                        }
                    })
                }
                */
                enqueueSnackbar("", {
                    variant: "customNotification",
                    notification: data
                })
            }))
            }
        }, [connection]);
    return (
        <></>
    )
}
export interface ILiveNotifications {
}
export default LiveNotifications;