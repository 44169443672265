import { getApiAccessToken } from "../auth/helpers";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { UserPermissionCollection } from "../types/shared/auth/userDataTypes";
import { PermissionsArea } from "../types/shared/auth/permissionsTypes";

const transformPermissions = (permissionsList: IPermissionsRequestBody[]): IPermissionsConfig[] => {
    const map = new Map<string, IPermissionsConfig>();

    permissionsList.forEach((perm) => {

        if (!map.has(perm.id)) {
            map.set(perm.id, { id: perm.id, permissions: {} });
        }

        const current = map.get(perm.id);

        if (!current) {
            console.error("Permissions mapping went wrong");
            return;
        }

        const area = perm.portalArea as PermissionsArea;
        current.permissions[area] = perm.permissionsFlag;
    });

    return Array.from(map.values());
}

export const permissionsApi = createApi({
    reducerPath: 'permissionsApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_PORTAL_API_URL}permissions`,
        prepareHeaders: async (headers: Headers, { getState }) => {
            const accessToken = await getApiAccessToken();
            if (accessToken) {
                headers.set("Authorization", `Bearer ${accessToken}`);
            }
        }
    }),
    tagTypes: ['UserPermissions', 'GroupPermissions'],
    refetchOnMountOrArgChange: 5 * 60, // Cache is stale after 5 minutes
    endpoints: (builder) => ({
        getAllPermissions: builder.query<IAllPermissionsConfig, void>({
            query: () => ({
                url: '',
                method: 'GET',
            }),
            transformResponse: (rawResult: IAllPermissionsResponse): IAllPermissionsConfig => {
                const groups: IPermissionsConfig[] = transformPermissions(rawResult.groups);
                const users: IPermissionsConfig[] = transformPermissions(rawResult.users);
                const response: IAllPermissionsConfig = { groups, users };
                return response;
            },
            providesTags: ['UserPermissions', 'GroupPermissions'],
        }),
        getUserPermissions: builder.query<IPermissionsConfig, string>({
            query: (username) => ({
                url: `/user/${username}`,
                method: 'GET',
            }),
            transformResponse: (rawResult: IPermissionsRequestBody[]): IPermissionsConfig => {
                const result = transformPermissions(rawResult);
                return result[0];
            },
            providesTags: ['UserPermissions'],
        }),
        addOrUpdateUserPermissions: builder.mutation<string, IPermissionsRequestBody[]>({
            query: (body) => ({
                url: '/user',
                method: 'POST',
                body,
            }),
            invalidatesTags: ['UserPermissions'],
        }),
        deleteUserPermissions: builder.mutation<string, string>({
            query: (username) => ({
                url: `/user/${username}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['UserPermissions'],
        }),
        getGroupPermissions: builder.query<IPermissionsConfig, string[]>({
            query: (body) => ({
                url: `/groups`,
                method: 'POST',
                body,
            }),
            transformResponse: (rawResult: IPermissionsRequestBody[]): IPermissionsConfig => {
                const result = transformPermissions(rawResult);
                return result[0];
            },
            providesTags: ['GroupPermissions'],
        }),
        addOrUpdateGroupPermissions: builder.mutation<string, IPermissionsRequestBody[]>({
            query: (body) => ({
                url: '/group',
                method: 'POST',
                body,
            }),
            invalidatesTags: ['GroupPermissions'],
        }),
        deleteGroupPermissions: builder.mutation<string, string>({
            query: (groupId) => ({
                url: `/group/${groupId}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['GroupPermissions'],
        }),
    }),
});

export const { 
    useGetAllPermissionsQuery,
    useGetUserPermissionsQuery,
    useAddOrUpdateUserPermissionsMutation,
    useDeleteUserPermissionsMutation,
    useGetGroupPermissionsQuery,
    useAddOrUpdateGroupPermissionsMutation,
    useDeleteGroupPermissionsMutation,
} = permissionsApi;

interface IAllPermissionsResponse {
    groups: IPermissionsRequestBody[];
    users: IPermissionsRequestBody[];
}

export interface IPermissionsRequestBody {
    id: string;
    portalArea: string;
    permissionsFlag: number;
}

export interface IAllPermissionsConfig {
    groups: IPermissionsConfig[];
    users: IPermissionsConfig[];
}

export interface IPermissionsConfig {
    id: string;
    permissions: Partial<UserPermissionCollection>;
}
