import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField } from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import * as yup from "yup";
import { IMetadataAttributeSetting } from "../../../shared/types/system-admin/IMetadataAttributeSetting";

const SiteMetadataAttributeModal: React.FC<ISiteMetadataAttributeModal> = ({ attribute, handleClose, handleSave }) => {
    const [isNewAttribute, setIsNewAttribute] = useState<boolean>(false);

    const validatioSchema = yup.object().shape({
        timeout: yup
            .number()
            .min(15, "Timeout must be 15 seconds minimum")
            .max(604800, "Timeout max value is 604800 seconds e.g. 7 Days")
            .required("Timeout is required")
            .typeError("Must be a number"),
        name: yup
            .string()
            .required("Name is required"),           
        attributeName: yup
            .string()
            .required("Attribute Name is required"),
    });

    const formik = useFormik({
        initialValues: {
            name: attribute?.name,
            attributeName: attribute?.attributeName,
            timeout: attribute?.timeout
        },
        validationSchema: validatioSchema,
        validateOnBlur: true,
        onSubmit: (values) => {
            if (attribute) {                
                const model = {
                    name: values.name,
                    attributeName: values.attributeName,
                    timeout: values.timeout
                } as IMetadataAttributeSetting;
                handleSave(model, isNewAttribute);
            }
            handleClose();
        },  
    });   

    useEffect(() => {
        setIsNewAttribute(attribute?.name == null ? true : false);
    }, [attribute]);

    const modalTitleText = (): string => {
        return isNewAttribute ? "New Attribute" : "Edit Attribute";
    };

    return (
        <Dialog
            fullWidth={true}
            maxWidth="md"
            open={attribute ? true : false}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <form onSubmit={formik.handleSubmit}>
                <DialogTitle id="alert-dialog-title">
                    {modalTitleText()}
                </DialogTitle>
                <DialogContent>
                    <Stack direction="row" padding={3} spacing={2}>
                        {isNewAttribute &&
                            <>
                                <TextField label="Name" variant="outlined" id="name" value={formik.values.name} onChange={formik.handleChange} error={formik.touched.name
                                    && Boolean(formik.errors.name)} helperText={formik.touched.name && formik.errors.name} />
                                <TextField label="Attribute Name" variant="outlined" id="attributeName" value={formik.values.attributeName} onChange={formik.handleChange} error={formik.touched.attributeName
                                    && Boolean(formik.errors.attributeName)} helperText={formik.touched.attributeName && formik.errors.attributeName} />
                            </>
                        }
                        {!isNewAttribute &&
                            <>
                                <TextField label="Name" variant="outlined" id="name" value={formik.values.name} onChange={formik.handleChange} error={formik.touched.name
                                    && Boolean(formik.errors.name)} helperText={formik.touched.name && formik.errors.name} disabled/>
                                <TextField label="Attribute Name" variant="outlined" id="attributeName" value={formik.values.attributeName} onChange={formik.handleChange} error={formik.touched.attributeName
                                    && Boolean(formik.errors.attributeName)} helperText={formik.touched.attributeName && formik.errors.attributeName} disabled />
                            </>
                        }
                        <TextField label="Timeout (seconds)" variant="outlined" id="timeout" value={formik.values.timeout} onChange={formik.handleChange} error={formik.touched.timeout
                            && Boolean(formik.errors.timeout)} helperText={formik.touched.timeout && formik.errors.timeout} />
                    </Stack>
                </DialogContent>
                <DialogActions>                    
                    <Button type="submit" autoFocus variant="contained">
                        Save
                    </Button>
                    <Button onClick={handleClose} variant="contained">
                        Cancel
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
};

export interface ISiteMetadataAttributeModal {
    handleClose: () => void;    
    handleSave: (model: IMetadataAttributeSetting, isNew: boolean) => void;
    attribute: IMetadataAttributeSetting | null;
}

export default SiteMetadataAttributeModal;