import { PortalArea } from "../types/shared/routes/routeHelperClasses";
import ConradAssetLogo from '../../images/Conrad_Asset_Icon.png';
import AssetHomePageConfig from "../../pages/asset/AssetHomePage";
import AssetMapPageConfig from "../../pages/asset/AssetMapPage";
import AssetRegisterPageConfig from "../../pages/asset/AssetRegisterPage";
import AssetDetailsPageConfig from "../../pages/asset/AssetDetailsPage";
import DetailedGasAssetRegisterPageConfig from "../../pages/asset/DetailedGasAssetsPage";
import StatusHivePageConfig from "../../pages/status-hive/StatusHivePage";
import { IPortalAreaOptions } from "../types/shared/routes/routeTypes";
import { AssetPermissions } from "../types/shared/auth/permissionsTypes";

const areaOptions: IPortalAreaOptions = {
    title: "Asset",
    description: "Master data register for all Assets",
    iconImage: ConradAssetLogo,
    allValidPermissions: { Asset: AssetPermissions.AllPermissions }
}

const AssetPortalArea = new PortalArea(areaOptions, AssetHomePageConfig);
AssetPortalArea.addChildRoute(AssetRegisterPageConfig);
AssetPortalArea.addChildRoute(AssetDetailsPageConfig);
AssetPortalArea.addChildRoute(AssetMapPageConfig)
AssetPortalArea.addChildRoute(StatusHivePageConfig);
AssetPortalArea.addChildRoute(DetailedGasAssetRegisterPageConfig);

export default AssetPortalArea;
