import { Box, Paper, Typography, useTheme } from "@mui/material"
import moment from "moment";
import { useEffect, useState } from "react";
import { IPeriodPerformanceTooltipData } from "../../../shared/types/dynamic-contracts/IStackedService";

const SPDataTooltip: React.FC<ISPDataTooltip> = ({ data }) => {
    const theme = useTheme();
    const successColor = theme.palette.conradEnergyFreshGreen.main
    const warningColor = theme.palette.conradEnergyYellow.main
    const errorColor = theme.palette.error.dark

    const [borderColor, setBorderColor] = useState<string>(successColor)

    useEffect(() => {
        if (data !== null) {
            if (data.lowData === null && data.highData === null) {
                const difference = moment.duration(moment(data.datetime).utc().diff(moment().utc()))
                if (difference.asHours() > 2) {
                    setBorderColor(warningColor)
                }
                else {
                    setBorderColor(errorColor)
                }
            }
            else if ((data.lowData && data.lowData.achievedValue === 0) || (data.highData && data.highData.achievedValue === 0)) {
                setBorderColor(errorColor)
            }
            else if ((data.lowData && data.lowData.kfactor !== 1) || (data.highData && data.highData.kfactor !== 1)) {
                setBorderColor(warningColor)
            }
            else {
                setBorderColor(successColor)
            }
        }
    }, [data])

    return (
        <>
            {
                data !== null &&
                <Paper
                    sx={{
                        padding: "10px",
                        border: "3px solid " + borderColor
                    }}
                    >
                        <Typography variant="body2">Processed: {data.file !== null && data.file.processedTime !== null ? moment(data.file.processedTime + "Z").format("DD-MM-YYYY HH:mm") : "File not processed"}</Typography>
                        <Typography variant="h6" color={borderColor}>High:</Typography>
                        <Typography variant="body2" marginLeft="10px" component="div">Available: <Box color={borderColor} display='inline'>{data.highData !== null ? (data.highData.availability*100).toFixed(2) + "%" : "-"}</Box></Typography>
                        <Typography variant="body2" marginLeft="10px" component="div">K Factor: <Box color={borderColor} display='inline'>{data.highData !== null ? (data.highData.kfactor * 100).toFixed(2) + "%" : "-"}</Box></Typography>
                        <Typography variant="body2" marginLeft="10px" component="div">Max Error: <Box color={borderColor} display='inline'>{data.highData !== null ? (data.highData.maxError * 100).toFixed(2) + "%" : "-"}</Box></Typography>
                        <Typography variant="h6" color={borderColor}>Low:</Typography>
                        <Typography variant="body2" marginLeft="10px" component="div">Available: <Box color={borderColor} display='inline'>{data.lowData !== null ? (data.lowData.availability * 100).toFixed(2) + "%" : "-"}</Box></Typography>
                        <Typography variant="body2" marginLeft="10px" component="div">K Factor: <Box color={borderColor} display='inline'>{data.lowData !== null ? (data.lowData.kfactor * 100).toFixed(2) + "%" : "-"}</Box></Typography>
                        <Typography variant="body2" marginLeft="10px" component="div">Max Error: <Box color={borderColor} display='inline'>{data.lowData !== null ? (data.lowData.maxError * 100).toFixed(2) + "%" : "-"}</Box></Typography>
                </Paper>
            }
        </>

    )
}

export interface ISPDataTooltip {
    data: IPeriodPerformanceTooltipData | null
}

export default SPDataTooltip