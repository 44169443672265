import { Box, IconButton, TextField, Toolbar, Tooltip, Typography } from "@mui/material";
import { useState } from "react";

const TableToolbar: React.FC<ITableToolbarProps> = ({ title, searchBar, buttons = [] }) => {
    const [searchTerm, setSearchTerm] = useState<string>("");

    const searchTermUpdated = (value: string) => {
        setSearchTerm(value);
        searchBar?.valueUpdated(value);
    }

    return (
        <Toolbar>
            <Box sx={{ display: "flex", alignItems: "center", flex: '1 1 100%' }} component={"span"}>
                {title && (
                    <Typography variant="h6" component="div" pr={3} >
                        {title}
                    </Typography>
                )}
                {searchBar && (
                    <TextField label={searchBar.label} variant="outlined" sx={{ flexGrow: 1, mt: 1, mb: 1 }} value={searchTerm} onChange={(event) => searchTermUpdated(event.target.value)}  />
                )}
            </Box>
            <Box pl={3}>
                {buttons.map((b) => {
                    return (
                        <Tooltip title={b.tooltipText} key={b.tooltipText}>
                            <IconButton onClick={() => b.action()} disabled={b.disabled}>
                                {b.icon}
                            </IconButton>
                        </Tooltip>
                    );
                })}
            </Box>
        </Toolbar>
    );
}

export interface ITableToolbarProps {
    title?: string;
    searchBar?: ITableToolbarSearch;
    buttons?: ITableToolbarButton[];
}

export interface ITableToolbarButton {
    tooltipText: string;
    icon: JSX.Element;
    action: () => void;
    disabled?: boolean;
}

export interface ITableToolbarSearch {
    label: string;
    valueUpdated: (value: string) => void;
}

export default TableToolbar;
