import React, { useEffect, useState } from 'react';
import moment from "moment";
import { Box, Typography, useTheme } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { IStackedService, ServiceTypeEnum } from '../../../shared/types/dynamic-contracts/IStackedService';
import { Dictionary } from '@reduxjs/toolkit';

const ServicesRollup: React.FC<IServicesRollup> = ({ currentBlocks }) => {
    const theme = useTheme();
    const [totalAchievedValue, setTotalAchievedValue] = useState<number>(0)
    const [totalPossibleValue, setTotalPossibleValue] = useState<number>(0)
    const [rollupMap, setRollupMap] = useState<Dictionary<number>>({})
    const [warningMap, setWarningMap] = useState<Dictionary<boolean>>({})
    const [numberOfSP, setNumberOfSP] = useState<number>(8)
    const warningColor = theme.palette.conradEnergyYellow.main

    useEffect(() => {
        let totalValue = 0
        let totalPossValue = 0
        const newRollupMap: Dictionary<number> = {}
        const newWarningMap: Dictionary<boolean> = {}

        if (currentBlocks.size > 0) {
            const firstBlock = currentBlocks.entries().next().value[1] as IStackedService
            const startTime = moment(firstBlock.contractPeriod.startTimeUTC + "Z")
            const endTime = moment(firstBlock.contractPeriod.endTimeUTC + "Z")
            while (startTime.isBefore(endTime)) {
                const key = startTime.format("YYYY-MM-DDTHH:mm:SSZ")
                newRollupMap[key] = 0
                newWarningMap[key] = false
                startTime.add(30, "minutes")
            }
        }
      
        currentBlocks.forEach((currentBlock, key) => {
            currentBlock.periodPerformances.forEach((periodPerformance) => {
                totalPossValue += periodPerformance.potentialValue
                totalValue += periodPerformance.achievedValue
                const dateTime = moment(periodPerformance.periodStart + "Z")
                const sp = dateTime.format("YYYY-MM-DDTHH:mm:SSZ")
                let existingRollupValue = newRollupMap[sp] || 0
                existingRollupValue += periodPerformance.achievedValue
                newRollupMap[sp] = existingRollupValue
                newWarningMap[sp] = periodPerformance.kfactor !== 1 || newWarningMap[sp]
            })
        })

        setTotalAchievedValue(totalValue)
        setTotalPossibleValue(totalPossValue)
        setRollupMap(newRollupMap)
        setWarningMap(newWarningMap)
        setNumberOfSP(Object.keys(newRollupMap).length)
    }, [currentBlocks])

    return (
        <Box
            sx={{
                paddingLeft: "10px",
                paddingRight: "10px"
            }}>
            <Typography variant="subtitle1" align="center" component="div">
                <Box color={(totalAchievedValue < totalPossibleValue ? warningColor : "default")} display='inline'>&pound;{`${totalAchievedValue.toFixed(2)}`}</Box>{"/"} &pound;{`${totalPossibleValue.toFixed(2)}`}
            </Typography>

                <Grid
                    container
                alignItems="center"
                alignContent="start"
                spacing={0.4}
                justifyContent="flex-start"
                >
                    {Object.keys(rollupMap).sort().map((key, index) =>
                        <Grid xs={12 / numberOfSP} key={key}>
                            <Typography variant="subtitle1" align="center" component="div">
                                <Box sx={{ color: (warningMap[key] ? warningColor : "default") }}>
                                    &pound;{rollupMap[key]?.toFixed(2)}
                                </Box>
                            </Typography>
                        </Grid>
                    )}
                </Grid>
        </Box>
    );
}

export interface IServicesRollup {
    currentBlocks: Map<string, IStackedService>
}

export default ServicesRollup;