import { Paper, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { ReactNode } from "react";

interface Props {
    title?: string;
    children?: ReactNode;
}

const BoxWithTitle = ({ title, children }: Props) => {

    return (
        <Stack spacing={1}>
            {title && <Typography variant="h6">{title}</Typography>}
            {children}
        </Stack>
    );
};

export default BoxWithTitle;
