import { IDetailedGasAsset } from "../../shared/types/asset/IDetailedGasAsset";
import React, { useEffect } from 'react';
import { IconButton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, useTheme } from "@mui/material";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const groupedHeadCells: GroupedHeadCell[] = [
    {
        groupName: "General",
        subCells: [
            {
                id: "siteID",
                label: "Site ID"
            },
            {
                id: "name",
                label: "Name"
            },
            {
                id: "iONName",
                label: "iON Name"
            },
            {
                id: "constructionStage",
                label: "Construction Stage"
            },
            {
                id: "portfolio",
                label: "Portfolio"
            },
            {
                id: "acquisition",
                label: "Acquisition"
            },
            {
                id: "numberOfEngines",
                label: "Number of Engines"
            },
            {
                id: "engineManAndModel",
                label: "Engine Make"
            },
            {
                id: "engineState",
                label: "Engine State"
            },
            {
                id: "isBMU",
                label: "BMU?"
            },
            {
                id: "generationType",
                label: "Gen Type"
            },
            {
                id: "capabilityKW",
                label: "Capacity"
            },
            {
                id: "postcode",
                label: "Postcode"
            },
            {
                id: "conradRegion",
                label: "Area"
            },
            {
                id: "latitude",
                label: "Latitude"
            },
            {
                id: "longitude",
                label: "Longitude"
            },
            {
                id: "address",
                label: "Address"
            }
        ]
    },
    {
        groupName: "Environmental/Planning",
        subCells: [
            {
                id: "dailyRestrictions",
                label: "Daily Restrictions"
            },
            {
                id: "earliestStart",
                label: "Earliest Start"
            },
            {
                id: "latestEnd",
                label: "Latest End"
            },
            {
                id: "yearlyRestrictions",
                label: "Yearly Restrictions"
            }
        ]
    },
    {
        groupName: "Operational and Balancing Parameters",
        subCells: [
            {
                id: "BMID",
                label: "BMU ID"
            },
            {
                id: "MEL",
                label: "MEL"
            },
            {
                id: "HHV",
                label: "HHV"
            },
            {
                id: "minOutputPerc",
                label: "Min Output %"
            },
            {
                id: "minOutputMW",
                label: "Min Output MW"
            },
            {
                id: "NDZ",
                label: "NDZ"
            },
            {
                id: "timeToFullPower",
                label: "Time to Full Power"
            },
            {
                id: "RURE",
                label: "RURE"
            },
            {
                id: "minTimeOn",
                label: "Min Time On"
            },
            {
                id: "maxTimeOn",
                label: "Max Time On"
            },
            {
                id: "rampDown",
                label: "Ramp Down"
            },
            {
                id: "rampDownRate",
                label: "Ramp Down Rate"
            },
            {
                id: "MZT",
                label: "MZT"
            },
            {
                id: "maxStartsPerDay",
                label: "Max Starts Per Day"
            }
        ]
    },
    {
        groupName: "Gas",
        subCells: [
            {
                id: "gasDistributionNetwork",
                label: "Gas Distribution Network"
            },
            {
                id: "gasOfftakePressure",
                label: "Gas Offtake Pressure"
            },
            {
                id: "gasInitialSOQ",
                label: "Initial SOQ"
            },
            {
                id: "gasMeterNumber",
                label: "Gas Meter Number"
            },
            {
                id: "gasMeterOperator",
                label: "Gas Meter Operator"
            }
        ]
    },
    {
        groupName: "Electricity",
        subCells: [
            {
                id: "networkOperator",
                label: "Network Operator"
            },
            {
                id: "connectionVoltage",
                label: "Connection Voltage KV"
            },
            {
                id: "exportMPAN",
                label: "Export MPAN"
            },
            {
                id: "importMPAN",
                label: "Import MPAN"
            },
            {
                id: "meterOperator",
                label: "Meter Operator"
            }
        ]
    }
]

const DetailedGasAssetTable: React.FC<IDetailedGasAssetTableProps> = ({ items, searchBar, updateAsset, deleteAsset, authenticated }) => {
    const [orderDescending, setOrderDescending] = React.useState<boolean>(false)
    const [orderCategory, setOrderCategory] = React.useState<keyof IDetailedGasAsset>("siteID")
    const [searchContents, setSearchContents] = React.useState<string>("")
    const theme = useTheme()

    const sortItems = (inputArray: IDetailedGasAsset[], category: keyof IDetailedGasAsset, descending: boolean) => {
        const arrayToSort = [...inputArray]
        arrayToSort.sort((a, b) => {
            if (a[category] === undefined || a[category] === null) { return 1 }
            else if (b[category] === undefined || b[category] === null) { return -1 }
            else if (a[category]! < b[category]!) { return -1 }
            return 1
        })
        if (descending) {
            arrayToSort.reverse()
        }
        return arrayToSort
    }

    useEffect(() => {
        setInternalItems(sortItems(items, orderCategory, orderDescending))
    }, [items])

    if (items === undefined) {
        items = []
    }

    const [internalItems, setInternalItems] = React.useState<IDetailedGasAsset[]>(sortItems(items, orderCategory, orderDescending))

    const handleSearchContentsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchContents(event.target.value as string)
    }

    const handleSort = (categoryID: keyof IDetailedGasAsset) => {
        let descending = false
        if (orderCategory === categoryID && !orderDescending) {
            descending = true
        }
        setOrderDescending(descending)
        setOrderCategory(categoryID)
        setInternalItems(sortItems(internalItems, categoryID, descending))
    }

    const filterSite = (site: IDetailedGasAsset) => {
        const lowerCaseSearchContents = searchContents.toLowerCase()
        return (
            (site.siteID && site.siteID.toLowerCase().startsWith(lowerCaseSearchContents)) ||
            (site.name && site.name.toLowerCase().startsWith(lowerCaseSearchContents)) ||
            (site.iONName && site.iONName.toLowerCase().startsWith(lowerCaseSearchContents))
        )
    }

    const searchHeight = searchBar ? 56 : 0;

    return (
        <>
            {(searchBar ?
                <TextField
                    id="search-label"
                    label="Search"
                    value={searchContents}
                    onChange={handleSearchContentsChange}
                    name="Search"
                    placeholder="Search"
                    fullWidth
                /> : <></>)}
            <TableContainer sx={{ height: `calc(100% - 74px - ${searchHeight}px)`, marginTop: "10px" }}>
                <Table stickyHeader size="small" aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {groupedHeadCells.map((groupedHeadCell, index) => (
                                <TableCell key={groupedHeadCell.groupName} align="center" colSpan={groupedHeadCell.subCells.length + (index === 0 ? 1 : 0)} style={{ border: "1px solid" }}>
                                    <Typography>{groupedHeadCell.groupName}</Typography>
                                </TableCell>
                            ))}
                        </TableRow>
                        <TableRow>
                            <TableCell align="center" style={{ borderLeft: "1px solid" }}></TableCell>
                            {groupedHeadCells.map((group) => group.subCells.map((headCell, index) => (
                                <TableCell
                                    align="center"
                                    key={headCell.id}
                                    style={{
                                        zIndex: (headCell.id === "siteID" ? "1001" : "default"),
                                        position: "sticky",
                                        left: 0,
                                        borderRight: (headCell.id === "siteID" || index === group.subCells.length - 1 ? "1px solid" : "default"),
                                        borderLeft: (index === 0 && headCell.id !== "siteID" ? "1px solid" : "default")
                                    }}
                                >
                                    <IconButton
                                        size="small"
                                        sx={{ "transition": "all .2s", "color": "inherit", "&:hover": { "backgroundColor": "rgba(160, 160, 160, 0.5)" } }}
                                        onClick={() => handleSort(headCell.id)}
                                    >
                                        <Typography variant="subtitle1" fontSize="small">{headCell.label}</Typography>
                                        {headCell.id === orderCategory ? (orderDescending ? <ArrowDownward fontSize="small" /> : <ArrowUpward fontSize="small" />) : <></>}
                                    </IconButton>
                                </TableCell>
                            )))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            internalItems
                                .filter((item) => filterSite(item))
                                .map((item) => {
                                    return (
                                        <TableRow key={"detailed-gas-asset-table-key-" + item.siteID}>
                                            <TableCell align="center" style={{ backgroundColor: theme.palette.background.paper }}>
                                                <Stack direction="row">
                                                    <IconButton aria-label="edit" onClick={() => updateAsset(item)} disabled={!authenticated}>
                                                        <EditIcon />
                                                    </IconButton>
                                                    <IconButton aria-label="delete" onClick={() => deleteAsset(item)} disabled={!authenticated}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Stack>
                                            </TableCell>
                                            {
                                                groupedHeadCells.map(group => group.subCells.map((headCell) => {
                                                    const key = "cell-key-id-" + item.siteID + "-" + headCell.id
                                                    if (typeof item[headCell.id] === "boolean") {
                                                        return <TableCell key={key} align="center" sx={{ backgroundColor: item[headCell.id] ? "green" : "darkred" }}>{(item[headCell.id] ? "True" : "False")}</TableCell>
                                                    }
                                                    else {
                                                        return <TableCell
                                                                    key={key}
                                                                    style={{
                                                                        position: (headCell.id === "siteID" ? "sticky" : "static"),
                                                                        left: 0,
                                                                        backgroundColor: theme.palette.background.paper,
                                                                        borderRight: (headCell.id === "siteID" ? "1px solid" : "default")
                                                                    }}
                                                                    align="center"
                                                                >{(item[headCell.id] ? item[headCell.id]!.toString() : "")}</TableCell>
                                                    }
                                                }))
                                            }
                                        </TableRow>
                                    )
                                })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );

}
interface HeadCell {
    id: keyof IDetailedGasAsset,
    label: string
}
interface GroupedHeadCell {
    groupName: string,
    subCells: HeadCell[]
}

export interface IDetailedGasAssetTableProps {
    items: IDetailedGasAsset[]
    searchBar?: boolean
    updateAsset: (asset: IDetailedGasAsset) => void
    deleteAsset: (asset: IDetailedGasAsset) => void
    authenticated: boolean
}

export default DetailedGasAssetTable;