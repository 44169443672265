import ApiRoot from "./ApiRoot";
import { getApiAccessToken, getPowerBiAccessToken } from "../auth/helpers";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const sortConfigs = (a: IAnalyticsBIReport, b: IAnalyticsBIReport) => {
    if (a.navSubMenu > b.navSubMenu) {
        return 1;
    } else if (a.navSubMenu < b.navSubMenu) {
        return -1;
    }

    if (a.navIndex < b.navIndex) {
        return -1;
    } else if (a.navIndex > b.navIndex) {
        return 1
    } else {
        return 0;
    }
}

export const powerBiReduxApi = createApi({
    reducerPath: 'powerBiApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_PORTAL_API_URL}`,
        prepareHeaders: async (headers: Headers, { getState }) => {
            const accessToken = await getApiAccessToken();
            if (accessToken) {
                headers.set("Authorization", `Bearer ${accessToken}`);
            }
        }
    }),
    tagTypes: ['PowerBiReport'],
    refetchOnMountOrArgChange: 5 * 60, // Cache is stale after 5 minutes
    endpoints: (builder) => ({
        getConfigs: builder.query<IAnalyticsBIReport[], void>({
            query: () => ({
                url: '/PowerBiReports',
                method: 'GET',
            }),
            transformResponse: (rawResult: IAnalyticsBIReport[]): IAnalyticsBIReport[] => {
                return rawResult.sort(sortConfigs);
            },
            providesTags: ['PowerBiReport'],
        }),
        addOrUpdateConfig: builder.mutation<IAnalyticsBIReport[], IAnalyticsBIReport>({
            query: (body) => ({
                url: '/PowerBiReports',
                method: 'POST',
                body,
            }),
            invalidatesTags: ['PowerBiReport'],
        }),
        deleteConfig: builder.mutation<IAnalyticsBIReport[], number>({
            query: (id) => ({
                url: `/PowerBiReports/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['PowerBiReport'],
        }),
        updateConfigNavIndex: builder.mutation<IAnalyticsBIReport[], { id: number, navIndex: number }>({
            query: ({ id, navIndex }) => ({
                url: `/PowerBiReports/${id}`,
                method: 'POST',
                body: { navIndex },
            }),
            invalidatesTags: ['PowerBiReport'],
        }),
    }),
});

const validateReport = async (groupId: string, reportId: string) => {
    const url = `https://api.powerbi.com/v1.0/myorg/groups/${groupId}/reports/${reportId}`
    const accessToken = await getPowerBiAccessToken();

    return await ApiRoot.get<IValidatedBIReport>(url, { accessToken });
};

const { useGetConfigsQuery, useAddOrUpdateConfigMutation, useDeleteConfigMutation, useUpdateConfigNavIndexMutation } = powerBiReduxApi;
const powerBiApi = {
    useGetConfigsQuery,
    useAddOrUpdateConfigMutation,
    useDeleteConfigMutation,
    useUpdateConfigNavIndexMutation,
    validateReport,
}

export default powerBiApi;

export interface IAnalyticsBIReport {
    id: number;
    navIndex: number;
    navTitle: string;
    navSubMenu: string;
    pageTitle?: string;
    groupId: string;
    reportId: string;
    hidden?: boolean;
}

export interface IValidatedBIReport {
    /**The ID of the report*/
    id: string,
    /**The name of the report*/
    name: string,
    /**The URL to view the report in PowerBI*/
    webUrl: string,
    /**The ID of the group the report belongs to*/
    datasetWorkspaceId: string,
}
