import { useState } from "react";
import { Box, Button, ButtonGroup, Collapse, IconButton, Switch, TableCell, TableRow, Typography } from "@mui/material";
import { DeleteForever, DragIndicator, Edit, KeyboardArrowDown, KeyboardArrowUp, OpenInNew } from "@mui/icons-material";
import { IAnalyticsBIReport } from "../../../../shared/api/PowerBiApi";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from '@dnd-kit/utilities';

const PowerBiReportConfigRow = ({ id, report, disableActions = false, onEditClicked, onDeleteClicked, onHiddenToggled }: IPowerBiReportConfigRowProps) => {
    const [open, setOpen] = useState(false);

    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({ id, disabled: disableActions });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    const reportUrl = `https://app.powerbi.com/groups/${report.groupId}/reports/${report.reportId}`;
    const openInNewTab = (url: string) => {
        window.open(url, '_blank', 'noreferrer');
    };

    return (
        <>
            <TableRow sx={{ '& > td': { borderBottom: 'unset' } }} ref={setNodeRef} style={style} {...attributes}>
                <TableCell sx={{ textAlign: "center", width: "34px", pr: 0 }} {...listeners} className={`grabbable ${disableActions && "disabled"}`}>
                    <DragIndicator />
                </TableCell>
                <TableCell sx={{ textAlign: "center", width: "34px", pr: 0 }}>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => { setOpen(!open) }}
                    >
                        {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                </TableCell>
                <TableCell sx={{ textAlign: "center", width: "60px" }}>
                    <Switch disabled={disableActions} checked={report.hidden} onClick={() => { onHiddenToggled && onHiddenToggled(report, !report.hidden); }} />
                </TableCell>
                {/* <TableCell sx={{ textAlign: "left", width: "200px" }}>{report.navSubMenu}</TableCell> */}
                <TableCell>{report.navTitle}</TableCell>
                <TableCell sx={{ textAlign: "center", width: "34px", pr: 0 }}>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => openInNewTab(reportUrl)}
                    >
                        <OpenInNew />
                    </IconButton>
                </TableCell>
                <TableCell sx={{ textAlign: "center", width: "115px" }}>
                    <ButtonGroup variant="contained" aria-label="outlined primary button group">
                        <Button disabled={disableActions} onClick={() => onEditClicked && onEditClicked(report)}>
                            <Edit />
                        </Button>
                        <Button disabled={disableActions} onClick={() => onDeleteClicked && onDeleteClicked(report)}>
                            <DeleteForever />
                        </Button>
                    </ButtonGroup>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1, mb: 2 }}>
                            <Typography variant="h6" gutterBottom component="div">
                                <strong>{report.pageTitle || report.navTitle}</strong>
                            </Typography>
                            <Typography variant="body2" gutterBottom component="div">
                                Group ID: <strong>{report.groupId}</strong>
                            </Typography>
                            <Typography variant="body2" gutterBottom component="div">
                                Report ID: <strong>{report.reportId}</strong>
                            </Typography>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}

interface IPowerBiReportConfigRowProps {
    id: number,
    report: IAnalyticsBIReport;
    disableActions?: boolean;
    onDeleteClicked?: (report: IAnalyticsBIReport) => void;
    onEditClicked?: (report: IAnalyticsBIReport) => void;
    onHiddenToggled?: (report: IAnalyticsBIReport, newValue: boolean) => void;
}

export default PowerBiReportConfigRow;
