import { Box, Grid, Typography } from "@mui/material";
import React, { useCallback, useEffect } from "react";
import analyticsApi from "../../shared/api/AnalyticsApi";
import moment from "moment"
import LoadingSymbol from "../../components/operate/LoadingSymbol";
import { Area, AreaChart, Label, Legend, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { useColourModeContext } from "../../components/ColourMode";
import { IAnalyticsChartDataItem } from "../../shared/types/analytics/IAnalyticsChartDataItem";
import SquareIcon from '@mui/icons-material/Square';
import { NewColorPalette } from "./helpers/NewColorPalette";
import { DateFormatter } from "./helpers/DateFormatter"

const GasFlowChart: React.FC<IGasFlowChart> = ({ displayScreen }) => {
    const [chartData, setChartData] = React.useState<Array<IAnalyticsChartDataItem>>([])
    const [terminals, setTerminals] = React.useState<Array<string>>([])
    const [currentTime, setCurrentTime] = React.useState<number>(0)
    const [beginningOfDay, setBeginningOfDay] = React.useState<number>(0)
    const [domainStart, setDomainStart] = React.useState<number>(0)
    const [domainEnd, setDomainEnd] = React.useState<number>(1)
    const colourMode = useColourModeContext();


    const loadTerminalFlowData = useCallback(() => {
        analyticsApi.getTerminalSupplyFlow()
            .then((response) => {
                const currentMoment = moment().utc().valueOf()
                let currentDate = moment().startOf('day').utc()
                const today = currentDate.valueOf()
                const yesterday = currentDate.add(-1, 'days').valueOf()
                const tomorrow = currentDate.add(2, 'days').valueOf()
                const chartDataDict = new Map<string, IAnalyticsChartDataItem>()
                const terminalArray = new Array<string>()
                response.forEach((terminalReading) => {
                    const terminal = terminalReading.objectDescription
                    const dateTime = terminalReading.applicableAt
                    const flowRate = terminalReading.flowRate
                    if (terminalArray.indexOf(terminal) == -1) {
                        terminalArray.push(terminal)
                    }
                    if (chartDataDict.has(dateTime)) {
                        let dataValue = (chartDataDict.get(dateTime) as IAnalyticsChartDataItem)
                        dataValue[terminal] = flowRate
                        chartDataDict.set(dateTime, dataValue)
                    }
                    else {
                        let dataValue: IAnalyticsChartDataItem = {
                            dateTime: moment(dateTime).valueOf(),
                            [terminal]: flowRate
                        }
                        chartDataDict.set(dateTime, dataValue)
                    }
                })
                terminalArray.sort()
                const dataArray = new Array<IAnalyticsChartDataItem>()
                chartDataDict.forEach((value, key) => {
                    dataArray.push(value)
                })
                dataArray.sort((a, b) => {
                    if (a.dateTime < b.dateTime) {
                        return -1
                    }
                    else {
                        return 1
                    }
                })
                setTerminals(terminalArray)
                setDomainStart(yesterday)
                setDomainEnd(tomorrow)
                setCurrentTime(currentMoment)
                setBeginningOfDay(today)
                setChartData(dataArray)
            })
    }, [])


    useEffect(() => {
        loadTerminalFlowData()
        const intervalId = setInterval(() => {
            loadTerminalFlowData(); // Fetch data every 2 minutes
        }, 120000);

        return () => clearInterval(intervalId);
    }, [loadTerminalFlowData])

    const CustomTooltip = ({ active, payload }: any) => {
        if (active && payload && payload.length) {
            const data = payload[0].payload
            return (
                <Box sx={{
                    "backgroundColor": (colourMode.isDarkMode ? "#262626" : "white"),
                    "borderRadius": 5,
                    "border": (colourMode.isDarkMode ? "1px solid white" : "1px solid #262626"),
                    px: 1,
                    py: 0.5
                }} >
                    <p style={{ color: (colourMode.isDarkMode ? "white" : "black") }}>Date (UTC): {DateFormatter(data.dateTime)}</p>
                    {payload.map((terminal: any) => {
                        return <p style={{ color: terminal.fill }}>{terminal.name}: {terminal.payload[terminal.dataKey]}{terminal.unit}</p>
                    })}
                </Box>
            );
        }
        return null
    };
    const CustomLegend = ({ active, payload }: any) => {
        return (
            <Box>
                <Grid
                    container
                    spacing={0}
                    alignItems="center"
                    justifyContent="center"
                >
                            {payload.map((entry: any) => {
                                return (
                                    <Grid item xs={3}>
                                        <span style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            flexWrap: 'wrap',
                                        }}>
                                        <SquareIcon htmlColor={entry.payload.fill} />
                                        <span style={{ color: entry.payload.fill }}>{entry.value}</span>

                                        </span>
                                    </Grid>
                                )
                            })}
                </Grid>
            </Box>
        )
    };
    const renderAreas = () => {
        let i = -1;
        const areas = terminals.map((terminal) => {
            i++;
            const color = NewColorPalette[i % NewColorPalette.length]
            return (
                <Area dataKey={terminal} stackId="1" unit=" MSCM"
                    stroke="black" fill={color} fillOpacity={1} opacity={1} isAnimationActive={false}/>
            )
        })
        return areas
    }
    return (
        <Box
            sx={{ "height": "100%", "width": "100%" }}
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}>
            <Box
                sx={(displayScreen ? { "height": "100%", "width": "100%", "backgroundColor": "#262626", "borderRadius": "10px", padding: 1 } : { "height": "100%", "width": "100%" })}
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}>
                <Typography sx={{ fontSize: "20px", color: (colourMode.isDarkMode || displayScreen ? "white" : "black") }}>Terminal Supply Flow</Typography>
                <ResponsiveContainer height={"87%" }>
                    {(chartData.length > 0 ?
                        <AreaChart data={chartData}>
                            <XAxis name="Time" dataKey="dateTime" domain={[domainStart, domainEnd]} scale="time" type="number" tickFormatter={DateFormatter} hide={displayScreen} allowDataOverflow={true} />
                            <YAxis><Label dx={-20} angle={-90}>MSCM</Label></YAxis>
                            {(!displayScreen ? <Legend iconType="square" content={<CustomLegend payload={chartData} />} /> : <></>)}
                            <ReferenceLine x={beginningOfDay} stroke={"white"} strokeDasharray="5" opacity={0.3} isFront={true} strokeWidth={3} />
                            <ReferenceLine x={currentTime} stroke={NewColorPalette[4]} strokeDasharray="18" opacity={100} isFront={true} strokeWidth={3} />
                            <Tooltip filterNull={false} content={<CustomTooltip payload={chartData} />} />
                            {renderAreas()}
                        </AreaChart> :
                        <LoadingSymbol />
                    )}
                </ResponsiveContainer>
            </Box>
        </Box>
    );
}
export interface IGasFlowChart {
    displayScreen?: boolean
}

export default GasFlowChart;