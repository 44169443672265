import { PortalArea } from "../types/shared/routes/routeHelperClasses";
import ConradDispatchLogo from '../../images/Conrad_Dispatch_Icon.png';
import { IPortalAreaOptions } from "../types/shared/routes/routeTypes";
import { DispatchPermissions } from "../types/shared/auth/permissionsTypes";
import DispatchHomePageConfig from "../../pages/dispatch/DispatchHomePage";

const areaOptions: IPortalAreaOptions = {
    title: "Dispatch",
    description: "Dispatch, Generation, Monitoring",
    iconImage: ConradDispatchLogo,
    allValidPermissions: { Dispatch: DispatchPermissions.AllPermissions }
}

const DispatchPortalArea = new PortalArea(areaOptions, DispatchHomePageConfig);

export default DispatchPortalArea;
