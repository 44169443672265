import { graphConfig } from "../auth/config";
import { getLoginRequestToken } from "../auth/helpers";
import { IUserData, IUserDataWithAvatar, IUserGroup, IUserRole } from "../types/shared/auth/userDataTypes";
import ApiRoot, { IImageResponse } from "./ApiRoot";

const getUserData = async (): Promise<IUserDataWithAvatar> => {
    const accessToken = await getLoginRequestToken();

    const promises = [];
    promises.push(ApiRoot.get<IUserData>(graphConfig.graphMeEndpoint, { accessToken }));
    promises.push(ApiRoot.getImage(graphConfig.graphPhotoEndpoint, { accessToken }));

    return Promise.all(promises).then(async ([profile, avatar]) => {
        const response = {
            ...profile as IUserData,
            avatar: avatar as IImageResponse,
        }

        return response;
    }).catch((error) => {
        throw error;
    });
}

const getUserRoles = async (): Promise<IUserRole[]> => {
    const accessToken = await getLoginRequestToken();

    return ApiRoot.get<{ value: IUserRole[]; }>(graphConfig.graphRolesEndpoint, { accessToken })
        .then((response) => response.value)
        .catch((error) => { throw error; });
}

const getAllGroups = async (): Promise<IUserGroup[]> => {
    const accessToken = await getLoginRequestToken();

    return ApiRoot.get<{ value: IUserGroup[]; }>(graphConfig.graphAllGroupsEndpoint, { accessToken })
        .then((response) => response.value)
        .catch((error) => { throw error; });
}

const userDataApi = {
    getUserData,
    getUserRoles,
    getAllGroups,
}

export default userDataApi;