import { CSSObject, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Slide, styled, Theme, Toolbar, Typography } from "@mui/material";
import moment from "moment";
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import packageJson from '../../package.json';
import conradLogoBlack from '../images/Conrad-Energy-Logo-Black.png';
import conradLogoGreen from '../images/Conrad-Energy-Logo-Green.png';
import { useNavigationMenu } from '../shared/hooks/NavigationMenuHook';
import { useAppSelector } from "../shared/hooks/StateHook";
import { IFlatNavItem } from '../shared/types/shared/routes/routeTypes';
import { useColourModeContext } from "./ColourMode";
import { usePortalContext } from './PortalWrapper';
import styles from './SiteNav.module.scss';
import { SwipeableHandlers } from "react-swipeable";

const environment = process.env.REACT_APP_ENVIRONMENT ?? "Development";
const buildDate = process.env.REACT_APP_BUILD_DATE ?? moment().format("DD/MM/YYYY");
const version = packageJson.version;

const drawerWidth = 260;

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const CustomDrawer = styled(Drawer, { shouldForwardProp: (prop: any) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

const NavItems: React.FC<{ open: boolean, forceIn?: boolean, updateOverlayNav?: (value: boolean) => void, }> = ({ open, forceIn, updateOverlayNav }) => {
    const { area } = useNavigationMenu();
    const [previousArea, setPreviousArea] = useState<string>();
    const [transition, setTransition] = useState<"out" | "in">("out");
    const { navItems } = usePortalContext();
    const { isDarkMode } = useColourModeContext();

    const transitionIn = () => {
        setTransition("in")
        setPreviousArea(area);
    }

    if (!previousArea) {
        setTimeout(transitionIn, 100);
    }
    else if (previousArea !== area) {
        setTimeout(() => setTransition("out"), 1);
        setTimeout(transitionIn, 250)
    }

    const getNavigationItems = (): IFlatNavItem[] => {
        if (!previousArea) { return []; }

        return navItems?.get(previousArea) || [];
    }

    const getConradLogo = () => {
        return isDarkMode ? conradLogoGreen : conradLogoBlack;
    };

    return (
        <>
            <Slide direction="right" in={forceIn || transition === "in"}>
                <List>
                    {getNavigationItems().map((navItem) => (
                        <ListItem key={navItem.text}>
                            <ListItemButton
                                disabled={navItem.disabled}
                                component={NavLink}
                                to={navItem.path}
                                onClick={() => updateOverlayNav && updateOverlayNav(false)}
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? 'initial' : 'center',
                                    padding: 0,
                                    paddingLeft: '5px'
                                }}>
                                <ListItemIcon sx={{
                                    minWidth: 0,
                                    mr: open ? 2 : 'auto',
                                    justifyContent: 'center',
                                }}>
                                    {navItem.icon}
                                </ListItemIcon>
                                {open ? <ListItemText primary={navItem.text} /> : <ListItemText>&nbsp;</ListItemText>}
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </Slide>

            <List style={{ marginTop: `auto` }}>
                <ListItem sx={{ pt: 0, pb: 0 }}>
                    <div className={styles.conradLogo}>
                        <a href="https://conradenergy.co.uk/" target="_blank" rel="noreferrer">
                            <img className={open ? '' : styles.small} src={getConradLogo()} alt="Conrad Energy Logo" />
                        </a>
                    </div>
                </ListItem>
                <ListItem>
                    <Typography align="center" width="100%" variant="caption">
                        {open ? <>{environment} - v{version}&nbsp;({buildDate})</> : <></>}
                    </Typography>
                </ListItem>
            </List>
        </>
    );
}

const SiteNav: React.FC<ISiteNavProps> = ({ navExpanded, forceNavOverlay, navOverlayOpen, swipeHandler, updateOverlayNav }: ISiteNavProps) => {
    const { hidden } = useAppSelector((state) => state.navigationMenu);

    return (
        <>
            {!hidden &&
                !forceNavOverlay &&
                <CustomDrawer variant="permanent" open={navExpanded} sx={{ display: { xs: 'none', md: 'block' } }}>
                    <Toolbar />
                    <NavItems open={navExpanded} />
                </CustomDrawer>}
            <Drawer
                sx={{ display: { xs: 'block', md: forceNavOverlay ? 'block' : 'none', "zIndex ": "1201" } }}
                anchor="left"
                open={navOverlayOpen}
                {...swipeHandler}
                onClose={() => updateOverlayNav(false)}
            >
                <NavItems open={navOverlayOpen} forceIn={true} updateOverlayNav={updateOverlayNav} />
            </Drawer>
        </>
    )
}

export interface ISiteNavProps {
    navExpanded: boolean;
    forceNavOverlay?: boolean;
    navOverlayOpen: boolean;
    swipeHandler?: SwipeableHandlers;
    updateOverlayNav: (open: boolean) => void;
}

export default SiteNav;