import { useEffect } from "react";
import moment, { Moment } from "moment";
import { useAppDispatch, useAppSelector } from "../StateHook";
import { getData, getDetails } from "../../state/slices/HistoricalSubmissionsSlice";
import gasReconciliationApi from "../../api/GasReconciliationApi";

const useHistoricalSubmissions = (startDate: Moment, endDate: Moment) => {
    const dispatch = useAppDispatch();
    const { isDataBeingRefreshed } =
        useAppSelector((state) => state.historicalSubmissions);

    const selectSubmission = (batchNumber?: number, siteType?: number) => {
        const run = async () => {
            dispatch(getDetails({ batchNumber: batchNumber, siteType: siteType }));
        };
        run();
    };

    const downloadCSVFile = (batchNumber: number, siteType: number) => {
        const run = async () => {    
            const fileName = `${moment().format("YYYYMMDDHHmmss")}_iglooUpload.csv`
            const response = await gasReconciliationApi.downloadTradeSubmissionFile(batchNumber, siteType);
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);
        };
        run();
    };

    useEffect(() => {
        const run = async () => {
            const start = startDate.startOf("day");
            const end = endDate.startOf("day");
            dispatch(getData({ startDate: start.toDate(), endDate: end.toDate() }));
        };
        run();
    }, [startDate, endDate, dispatch]);

    const response = {
        isDataBeingRefreshed,
        selectSubmission,
        downloadCSVFile
    };
    return response;
};

export default useHistoricalSubmissions;
