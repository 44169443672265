import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { getApiAccessToken } from "../auth/helpers";
import { IAdminNotificationSubscription } from "../types/notification/IAdminNotificationSubscription";
import { INotificationSearchParam } from "../types/notification/INotificationSearchParam";
import { INotificationType } from "../types/notification/INotificationType";
import { IPushNotification } from "../types/notification/IPushNotification";

export const notificationsApi = createApi({
    reducerPath: 'notificationsApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_PORTAL_API_URL}Notification`,
        prepareHeaders: async (headers: Headers, { getState }) => {
            const accessToken = await getApiAccessToken();
            if (accessToken) {
                headers.set("Authorization", `Bearer ${accessToken}`);
            }
        }
    }),
    endpoints: (builder) => ({
        getNotifications: builder.query<IPushNotification[], void>({
            query: () => '/GetNotifications',
        }),    
        getAllNotifications: builder.query<IPushNotification[], void>({
            query: () => '/GetAllNotifications',
        }), 
        getNotificationTypes: builder.query<INotificationType[], void>({
            query: () => '/GetNotificationTypes',            
        }),
        getSelfServeSubscriptions: builder.query<IAdminNotificationSubscription, void>({
            query: () => '/GetAllSelfSubscriptions',
        }),
        getAllRecipients: builder.query<string[], void>({
            query: () => '/GetAllRecipients',
        }),
        getNotificationsSearch: builder.mutation<IPushNotification[], INotificationSearchParam>({
            query: (payload) => ({
                url: '/GetNotificationsBySearch',
                method: 'POST',
                body: payload
            }),
        }),
        markNotificationAsRead: builder.mutation<void, string>({
            query: (payload) => ({
                url: '/MarkNotificationAsRead',
                method: 'POST',
                body: {
                    notificationID: payload
                }
            }),
        }),
        markAllNotificationAsRead: builder.mutation<void, void>({
            query: (payload) => ({
                url: '/MarkAllNotificationsAsRead',
                method: 'POST'                
            }),
        }),
        massMarkNotificationAsRead: builder.mutation<void, string[]>({
            query: (payload) => ({
                url: '/MassMarkNotificationsAsRead',
                method: 'POST',
                body: {
                    notificationIDs: payload
                }
            }),
        }),
        deleteNotification: builder.mutation<void, string>({
            query: (payload) => ({
                url: '/DeleteNotification',
                method: 'POST',
                body: {
                    notificationID: payload
                }
            }),
        }),
        massDeleteNotification: builder.mutation<void, string[]>({
            query: (payload) => ({
                url: '/MassDeleteNotification',
                method: 'POST',
                body: {
                    notificationIDs: payload
                }
            }),
        }),
    })
});

export const {
    useGetNotificationsQuery,
    useGetAllNotificationsQuery,
    useGetNotificationTypesQuery,
    useGetSelfServeSubscriptionsQuery,
    useGetAllRecipientsQuery,
    useGetNotificationsSearchMutation,
    useMarkNotificationAsReadMutation,
    useMarkAllNotificationAsReadMutation,
    useMassMarkNotificationAsReadMutation,
    useDeleteNotificationMutation,
    useMassDeleteNotificationMutation
} = notificationsApi