import SiteMetadataCachingPageConfig from "../../pages/system-admin/site-metadata-caching/SiteMetadataCachingPage";
import SystemAdminPageConfig from "../../pages/system-admin/SystemAdminHomePage";
import SystemAdminPermissionsPageConfig from "../../pages/system-admin/SystemAdminPermissionsPage";
import NotificationAdminPageConfig from "../../pages/user/NotificationAdminPage";
import { SystemAdministrationPermissions } from "../types/shared/auth/permissionsTypes";
import { PortalArea } from "../types/shared/routes/routeHelperClasses";
import { IPortalAreaOptions } from "../types/shared/routes/routeTypes";

const areaOptions: IPortalAreaOptions = {
    title: "System Administration",
    description: "",
    iconImage: "",
    hideFromLandingPage: true,
    allValidPermissions: { SystemAdmin: SystemAdministrationPermissions.AllPermissions }
}

const SystemAdminPortalArea = new PortalArea(areaOptions, SystemAdminPageConfig);
SystemAdminPortalArea.addChildRoute(SystemAdminPermissionsPageConfig);
SystemAdminPortalArea.addChildRoute(SiteMetadataCachingPageConfig);
SystemAdminPortalArea.addChildRoute(NotificationAdminPageConfig);

export default SystemAdminPortalArea;
