import React, { useState } from "react";
import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { isMobile } from "react-device-detect";

const PowerPriceOptions: React.FC<IPowerPriceOptionsProps> = ({ market, setMarket, marketCategory, setMarketCategory, country, setCountry, horizon, setHorizon, exchange, setExchange, hidden, disabled, marketCategories, marketDict, countries, horizons }) => {
    const [markets, setMarkets] = useState<string[]>(marketDict.get(marketCategory) || [])
    const handleMarketChange = (event: SelectChangeEvent) => {
        setMarket(event.target.value as string);
    };
    const handleMarketCategoryChange = (event: SelectChangeEvent) => {
        const newCategory = event.target.value as string
        setMarketCategory(newCategory);
        const newMarkets = marketDict.get(newCategory);
        setMarkets(newMarkets || [])
        setMarket(newMarkets !== undefined ? newMarkets[0] : "")
    }
    const handleCountryChange = (event: SelectChangeEvent) => {
        setCountry(event.target.value as string);
    };
    const handleHorizonChange = (event: SelectChangeEvent) => {
        setHorizon(event.target.value as string);
    };
    const handleExchangeChange = (event: SelectChangeEvent) => {
        setExchange(event.target.value as string);
    };
    const exchanges = [
        "N2EX",
        "EPEX"
    ]
    return (
        <>
            <Grid xs={isMobile ? 6 : 2} hidden={hidden}>
                <Box>
                    <FormControl fullWidth>
                        <InputLabel id="market-category-label">Market Category</InputLabel>
                        <Select
                            labelId="market-category-label"
                            id="market-category-select"
                            value={marketCategory}
                            label="Market Category"
                            onChange={handleMarketCategoryChange}
                            disabled={disabled}
                        >
                            <MenuItem value={"Any"}>{"Any"}</MenuItem>
                            {
                                marketCategories.map((item) =>
                                    <MenuItem key={item} value={item}>{item}</MenuItem>
                                )
                            }
                        </Select>
                    </FormControl>
                </Box>
            </Grid>
            <Grid xs={isMobile ? 6 : 4} hidden={hidden}>
                <Box>
                    <FormControl fullWidth>
                        <InputLabel id="market-label">Market</InputLabel>
                        <Select
                            labelId="market-label"
                            id="market-select"
                            value={market}
                            label="Market"
                            onChange={handleMarketChange}
                            disabled={marketCategory === "Any" || disabled}
                        >
                            {
                                markets.map((item) =>
                                    <MenuItem key={item} value={item}>{item}</MenuItem>
                                )
                            }
                        </Select>
                    </FormControl>
                </Box>
            </Grid>
            <Grid xs={isMobile ? 6 : 2} hidden={hidden}>
                <Box>
                    <FormControl fullWidth>
                        <InputLabel id="country-label">Country</InputLabel>
                        <Select
                            labelId="country-label"
                            id="country-select"
                            value={country}
                            label="Country"
                            onChange={handleCountryChange}
                            disabled={disabled}
                        >
                            <MenuItem value={"Any"}>{"Any"}</MenuItem>
                            {
                                countries.map((item) =>
                                    <MenuItem key={item} value={item}>{item}</MenuItem>
                                )
                            }
                        </Select>
                    </FormControl>
                </Box>
            </Grid>
            <Grid xs={isMobile ? 6 : 2} hidden={hidden}>
                <Box>
                    <FormControl fullWidth>
                        <InputLabel id="horizon-label">Horizon</InputLabel>
                        <Select
                            labelId="horizon-label"
                            id="horizon-select"
                            value={horizon}
                            label="Horizon"
                            onChange={handleHorizonChange}
                            disabled={disabled}
                        >
                            <MenuItem value={"Any"}>{"Any"}</MenuItem>
                            {
                                horizons.map((item) =>
                                    <MenuItem key={item} value={item}>{item}</MenuItem>
                                )
                            }
                        </Select>
                    </FormControl>
                </Box>
            </Grid>
            <Grid xs={isMobile ? 12 : 2} hidden={hidden}>
                <Box>
                    <FormControl fullWidth>
                        <InputLabel id="exchange-label">Exchange</InputLabel>
                        <Select
                            labelId="exchange-label"
                            id="exchange-select"
                            value={exchange}
                            label="Exchange"
                            onChange={handleExchangeChange}
                            disabled={disabled}
                        >
                            <MenuItem value={"Any"}>{"Any"}</MenuItem>
                            {
                                exchanges.map((item) =>
                                    <MenuItem key={item} value={item}>{item}</MenuItem>
                                )
                            }
                        </Select>
                    </FormControl>
                </Box>
            </Grid>
        </>
    );
}

export interface IPowerPriceOptionsProps {
    market: string
    setMarket: (market: string) => void
    marketCategory: string
    setMarketCategory: (marketCategory: string) => void
    country: string
    setCountry: (country: string) => void
    horizon: string
    setHorizon: (horizon: string) => void
    exchange: string
    setExchange: (exchange: string) => void
    hidden: boolean
    disabled: boolean
    marketCategories: string[]
    marketDict: Map<string, string[]>
    countries: string[]
    horizons: string[]
}

export default PowerPriceOptions;