export interface IAssetInformation {
    Name: string;
    SiteID: string;
    BMID: string;
    isBMU: boolean;
    GenerationType: GenerationType;
    CapabilityKW: number;
    Postcode: string;
    ConradRegion: string;
    Latitude?: number;
    Longitude?: number;
    Address?: string;
    LiveOutputs?: boolean;
}

export enum GenerationType {
    Gas = "Gas",
    Diesel = "Diesel",
    Battery = "Battery",
    Solar = "Solar",
    Wind = "Wind"
}

export interface IAllAssetsInformation {
    allSites: IAssetInformation[],    
    LiveSites: IAssetInformation[],    
}

export interface ISiteLists {
    SiteLists: IAllAssetsInformation
}