import { DarkMode, LightMode, Menu, MenuOpen } from '@mui/icons-material';
import { AppBar, Box, Container, IconButton, Link, Stack, SxProps, Theme, Toolbar, Tooltip, Typography } from "@mui/material";
import * as Router from "react-router-dom";
import { INavDrawerState } from '../shared/types/shared/siteNavTypes';
import { AppName } from '../shared/utils/PortalUtils';
import { useColourModeContext } from './ColourMode';
import ionLogoBlack from '../images/ion-plus-black.png';
import ionLogoGreen from '../images/ion-plus-green.png';
import { usePortalContext } from './PortalWrapper';
import styles from './SiteHeader.module.scss';
import UserSettingsMenu from './UserSettingsMenu';
import NotificationBell from './notifications/NotificationBell';

const pageTitleStyle: SxProps<Theme> = {
    fontWeight: 500,
    color: 'inherit',
    textDecoration: 'none',
    marginTop: "13px"
}

const SiteHeader: React.FC<ISiteHeaderProps> = ({ pageHeader, homeRoute, height = 69, navDrawerState, disableNav, forceNavOverlay }: ISiteHeaderProps) => {
    const colourMode = useColourModeContext();
    const { isAuthenticated } = usePortalContext();

    const ionLogo = (): string => {
        return colourMode.isDarkMode ? ionLogoGreen : ionLogoBlack;
    }

    return (
        <AppBar color="default" position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
            <Container maxWidth={false}>
                <Toolbar disableGutters>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2, display: { xs: 'none', md: forceNavOverlay ? 'none' : 'block' } }}
                        onClick={navDrawerState.toggle}
                        disabled={disableNav}
                    >
                        {navDrawerState.open && !disableNav ? <MenuOpen /> : <Menu />}
                    </IconButton>

                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2, display: { xs: 'block', md: forceNavOverlay ? 'block' : 'none'} }}
                        onClick={() => navDrawerState.updateOverlayNav(true)}
                        disabled={disableNav}
                    >
                        <Menu />
                    </IconButton>

                    <Stack direction="row">
                        <Link component={Router.Link} to="/" className={styles.logo}>
                            <img src={ionLogo()} alt={AppName} />
                        </Link>

                        <Typography
                            variant="h6"
                            noWrap
                            component={Router.Link}
                            to={homeRoute}
                            sx={pageTitleStyle}
                        >
                            {pageHeader}
                        </Typography>
                    </Stack>

                    <Box sx={{ flexGrow: 1 }}></Box>
                    {isAuthenticated ?
                        <NotificationBell />
                        : <></>
                    }                   
                    {isAuthenticated ? <UserSettingsMenu /> :
                        <Box sx={{ flexGrow: 0 }}>
                            <Tooltip title={colourMode.isDarkMode ? "Light Mode" : "Dark Mode"}>
                                <IconButton onClick={colourMode.toggleColourMode} sx={{ p: 0 }}>
                                    {colourMode.isDarkMode ? <LightMode /> : <DarkMode />}
                                </IconButton>
                            </Tooltip>
                        </Box>
                    }

                </Toolbar>
            </Container>
        </AppBar>
    )
}

export interface ISiteHeaderProps {
    pageHeader: string;
    height?: number;
    navDrawerState: INavDrawerState;
    disableNav?: boolean;
    homeRoute: string;
    forceNavOverlay?: boolean;
}

export default SiteHeader;