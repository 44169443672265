import { CalendarToday, Refresh } from "@mui/icons-material";
import {
    Box,
    Button,
    Paper,
    Stack,
    TextField, Typography
} from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment, { Moment } from "moment";
import React, { useEffect } from "react";
import { useState } from "react";
import { isMobile } from "react-device-detect";
import BoxWithTitle from "../../../components/BoxWithTitle";
import GasTrades from "../../../components/gas-reconciliation/GasTrades";
import NonDailyMeteredGrid from "../../../components/gas-reconciliation/NonDailyMeteredGrid";
import { useModalWindowContext } from "../../../components/ModalWindow";
import PageWrapper from "../../../components/PageWrapper";
import { Counterparty } from "../../../shared/enums/CounterpartyEnum";
import useNonDailyMetered from "../../../shared/hooks/gas-reconciliation/NonDailyMeteredHook";
import { useAppDispatch } from "../../../shared/hooks/StateHook";
import { updateAllocateCompleted } from "../../../shared/state/slices/NonDailyMeteredSlice";
import { IPortalRouteOptions } from "../../../shared/types/shared/routes/routeTypes";

const GasReconciliationNonDailyMeteredPage = () => {
    const [gasDay, setGasDay] = useState<Moment>(moment().add(1, "day"));
    const { lastRefresh, totalResidual, isDataBeingRefreshed, refresh, allocateTrades, allocateTradesCompletedPerc, loadData } = useNonDailyMetered(gasDay);
    const dispatch = useAppDispatch();
    const [loaded, setLoaded] = useState(false);
    const alertDialog = useModalWindowContext();

    const loadComplete = () => {
        setLoaded(true);
    }

    setTimeout(loadComplete, 500);

    const handleGasDayChange = (value: Moment | null) => {
        if (!isDataBeingRefreshed) {
            if (value != null) {
                setGasDay(value);
            }
        }
    };

    const refreshButtonContent = (): React.ReactNode => {
        return !isDataBeingRefreshed ? "REFRESH" : "Refreshing...";
    };

    const getLastRefresh = (): string => {
        return `Data Last Refreshed: ${lastRefresh == null
            ? "N/A"
            : moment(lastRefresh).format("DD/MM/YYYY HH:mm")
            }`;
    };

    const handleAllocateTrades = async (ppt: number, residualValue: number, counterparty: String) => {
        const alertTitle = `${counterparty} - Trade Submission`;
        await alertDialog?.openModal({ title: alertTitle, content: "Submitting trades to Igloo is currently disabled.  This process will only simulate a submission." });

        if (ppt <= 0) {
            await alertDialog?.openModal({ title: alertTitle, content: `Stop right there, can't allocate ${residualValue} therms at ${ppt} ppt` });
            return;
        }
        if (ppt > 100) {
            const pptResult = await alertDialog?.openModal({ title: alertTitle, content: "Your Gas Price doesn't look correct, is this correct?", yesText: "Yes it's correct", noText: "Cancel" });
            if (pptResult === false) {
                return;
            }
        }
        if (totalResidual === 0) {
            const zeroAllocationResult = await alertDialog?.openModal({ title: alertTitle, content: "Stop right there, You have a Zero allocation volume.  Is this correct?", yesText: "Yes", noText: "No" });
            if (zeroAllocationResult != true) {
                return;
            }
        }

        const allocateTradesResult = await alertDialog?.openModal({ title: alertTitle, content: `Send trades of ${residualValue} residual and ${ppt} PPT to Igloo?`, yesText: "Yes", noText: "No" });
        if (allocateTradesResult == true) {
            const response = await allocateTrades(gasDay, ppt);

            if (response == null) {
                await alertDialog?.openModal({ title: alertTitle, content: "Trade Allocations have been completed." });
                dispatch(updateAllocateCompleted(0));
                loadData();
            }
            else {
                await alertDialog?.openErrorModal({ title: alertTitle, content: `There was an error: ${response}` });
            }
        }
    }

    return (
        <PageWrapper title="Non-Daily Metered" loaded={loaded}>
            <Paper sx={{ p: 2 }}>
                <Stack direction="column" spacing={2}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <Grid
                            container
                            alignItems="center"
                            alignContent="start"
                            spacing={2}
                            justifyContent="flex-start">
                            <Grid>
                                <Box sx={{ maxWidth: 200 }}>
                                    <DatePicker
                                        label="Trading Day"
                                        inputFormat="DD/MM/YYYY"
                                        value={gasDay}
                                        onChange={handleGasDayChange}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </Box>
                            </Grid>
                            <Grid>
                                <Button
                                    variant="contained"
                                    startIcon={<Refresh />}
                                    onClick={refresh}>
                                    {refreshButtonContent()}
                                </Button>
                            </Grid>
                            <Grid>
                                <Typography
                                    variant="caption"
                                    component="p"
                                    position="relative">
                                    {getLastRefresh()}
                                </Typography>
                            </Grid>
                        </Grid>
                    </LocalizationProvider>

                    <Stack>
                        <Grid
                            container
                            alignItems="start"
                            alignContent="start"
                            spacing={2}
                            justifyContent="flex-start">
                            <Grid xs={isMobile ? 12 : 7}>
                                <BoxWithTitle title="Non Daily Metered Sites">
                                    <NonDailyMeteredGrid />
                                </BoxWithTitle>
                            </Grid>
                            <Grid xs={isMobile ? 12 : 5}>
                                <BoxWithTitle title="Trade Allocation">
                                    <GasTrades counterparty={Counterparty.Corona} totalResidual={totalResidual} allocateTrades={handleAllocateTrades} allocationCompleted={allocateTradesCompletedPerc} />
                                </BoxWithTitle>
                            </Grid>
                        </Grid>
                    </Stack>
                </Stack>
            </Paper>
        </PageWrapper>
    );
};

const GasReconciliationNonDailyMeteredPageConfig: IPortalRouteOptions = {
    relativeUrl: "nondailymetered",
    page: <GasReconciliationNonDailyMeteredPage />,
    navDisplay: {
        title: "Non-Daily Metered",
        icon: <CalendarToday />
    }
}

export default GasReconciliationNonDailyMeteredPageConfig;
