import moment from "moment";

const defaultTimestampFormat = "DD/MM/YYYY HH:mm";

export interface ITimestampedGraphData {
    time: string;
    date: string;
}

interface ITimestampedData {
    [key: string]: any;
}

export const GenerateTimestampedGraphData = <DataType extends ITimestampedData>(data: DataType[], timeStampPropertyName: string, momentTimestampFormat = defaultTimestampFormat) => {
    const returnValue: Array<DataType & ITimestampedGraphData> = [];

    for (let i = 0; i < data.length; i++) {
        const thisDate = moment(data[i][timeStampPropertyName], momentTimestampFormat);

        const newValue = ({ ...data[i], time: thisDate.format("HH:mm"), date: "" });
        returnValue.push(newValue);

        if (i === 0) {
            newValue.date = thisDate.format('DD MMMM');
            continue;
        }

        const previous = moment(data[i - 1][timeStampPropertyName], momentTimestampFormat);
        if (previous.day() !== thisDate.day() || previous.month() !== thisDate.month()) {
            newValue.date = thisDate.format('DD MMMM');
        }
    }

    return returnValue;
}

export const GetChartColours = (): string[] => {
    return ['#8dbf36', '#5dc059', '#02bf7b', '#00bc9a', '#00b6b4', '#00afc6', '#00a7d0', '#299dd0', '#00b1d8', '#00c5d5', '#00d6c7', '#2fe5b0', '#7ff294', '#bffb79', '#ffff66', '#ffe950', '#ffff66', '#ffd33c', '#ffbc2d', '#ffa522', '#fd8d1e', '#f8741f', '#f15a24']
};
