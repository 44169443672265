import React, { useEffect, useState } from 'react';
import moment from "moment";
import { Box, Typography, useTheme } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { IPerformanceFiles, IPeriodPerformances, IPeriodPerformanceTooltipData } from '../../../shared/types/dynamic-contracts/IStackedService';
import { BrowserView, isMobile } from 'react-device-detect';

const SiteSettlementPeriod: React.FC<ISiteSettlementPeriod> = ({ highPeriodPerformance, lowPeriodPerformance, performanceFile, utcDatetime, sp, xs, setSelectedSPRollupData }) => {
    const theme = useTheme();
    const successColor = theme.palette.conradEnergyFreshGreen.main
    const warningColor = theme.palette.conradEnergyYellow.main
    const errorColor = theme.palette.error.dark

    const [highData, setHighData] = useState<IPeriodPerformances | null>(highPeriodPerformance)
    const [lowData, setLowData] = useState<IPeriodPerformances | null>(lowPeriodPerformance)
    const [lowPrice, setLowPrice] = useState<number>(0)
    const [highPrice, setHighPrice] = useState<number>(0)
    const [perfFile, setPerfFile] = useState<IPerformanceFiles | null>(performanceFile)
    const [defaultBackgroundColor, setDefaultBackgroundColor] = useState<string>(theme.palette.background.default)
    const [backgroundColor, setBackgroundColor] = useState<string>(defaultBackgroundColor)
    const [borderColor, setBorderColor] = useState<string>(successColor)
    const [warning, setWarning] = useState<boolean>(false)
    const [error, setError] = useState<boolean>(false)

    useEffect(() => {
        setHighData(highPeriodPerformance)
        setLowData(lowPeriodPerformance)
        setHighPrice(highPeriodPerformance === null ? 0 : highPeriodPerformance.achievedValue)
        setLowPrice(lowPeriodPerformance === null ? 0 : lowPeriodPerformance.achievedValue)
        setPerfFile(performanceFile)
        if (lowPeriodPerformance === null && highPeriodPerformance === null) {
            const difference = moment.duration(moment(utcDatetime).diff(moment().utc()))
            if (difference.asHours() > 2) {
                setWarning(true)
            }
            else {
                setError(true)
            }
        }
        else {
            if ((lowPeriodPerformance && lowPeriodPerformance.kfactor !== 1) || (highPeriodPerformance && highPeriodPerformance.kfactor !== 1)) {
                setWarning(true)
            }
            else {
                setWarning(false)
            }
            if ((lowPeriodPerformance && lowPeriodPerformance.achievedValue === 0) || (highPeriodPerformance && highPeriodPerformance.achievedValue === 0)) {
                setError(true)
            }
            else {
                setError(false)
            }
        }
    }, [highPeriodPerformance, lowPeriodPerformance, performanceFile])

    useEffect(() => {
        setDefaultBackgroundColor(theme.palette.background.default)
    }, [theme])

    useEffect(() => {
        if (error) {
            setBackgroundColor(errorColor)
            setBorderColor(theme.palette.common.white)
        }
        else if (warning) {
            setBackgroundColor(warningColor)
            setBorderColor(theme.palette.common.white)
        }
        else {
            setBackgroundColor(defaultBackgroundColor)
            setBorderColor(theme.palette.conradEnergyFreshGreen.light)
        }
    }, [warning, error, defaultBackgroundColor, errorColor, warningColor])

    return (
        <Grid xs={xs}>
            <div
                onMouseEnter={() => {
                    const spTooltipData: IPeriodPerformanceTooltipData = {
                        lowData: lowData,
                        highData: highData,
                        file: perfFile,
                        datetime: utcDatetime
                    }
                    setSelectedSPRollupData(spTooltipData)
                }}
                onMouseLeave={() => {
                    setSelectedSPRollupData(null)
                }}
                onClick={(e) => {
                    if (isMobile) {
                        const spTooltipData: IPeriodPerformanceTooltipData = {
                            lowData: lowData,
                            highData: highData,
                            file: perfFile,
                            datetime: utcDatetime
                        }
                        setSelectedSPRollupData(spTooltipData)
                        e.stopPropagation()
                    }
                }}
            >
                <Box
                    sx={{
                        backgroundColor: backgroundColor,
                        height: isMobile ? "70px" : "100px",
                        border: "1px solid " + borderColor,
                        borderRadius: "5px",
                        padding: "10px 0px"
                    }}
                >
                    <Box
                        height="40%"
                    >
                        <Typography
                            sx={{
                                color: (warning || error ? theme.palette.common.black : theme.palette.conradEnergyGrey.main)
                            }}
                            align="center"
                            fontSize={isMobile ? "small" : "auto" }
                        >
                            SP{sp}
                        </Typography>
                    </Box>
                    <Box
                        height="60%"
                        sx={{
                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                            border: "1px solid grey"
                        }}
                        position="relative"
                    >
                        <Typography
                            sx={{
                                color: (warning || error ? backgroundColor : successColor)
                            }}
                            align="center"
                            fontSize={isMobile? "12px" : "10px" }
                        >
                            {((warning || error) && lowData !== null && lowData.achievedValue !== 0 ? (lowData.kfactor * 100).toFixed(0) + "%" : "")}
                        </Typography>
                        <BrowserView>
                            <Box
                                marginTop="auto"
                                bottom="0"
                                position="absolute"
                                width="100%"
                            >
                                <Typography
                                    sx={{
                                        color: (warning || error ? backgroundColor : successColor)
                                    }}
                                    align="center"
                                    fontSize="13px"
                                >
                                    {lowData == null && highData == null ? "-" : "\u00A3" + (lowPrice + highPrice).toFixed(2)}
                                </Typography>
                            </Box>
                        </BrowserView>
                    </Box>
                </Box>
            </div>
        </Grid>
    );
}

export interface ISiteSettlementPeriod {
    highPeriodPerformance: IPeriodPerformances | null
    lowPeriodPerformance: IPeriodPerformances | null
    performanceFile: IPerformanceFiles | null
    utcDatetime: string
    sp: string
    xs: number
    setSelectedSPRollupData: (rollupData: IPeriodPerformanceTooltipData|null) => void
}

export default SiteSettlementPeriod;