import React from 'react';
import { Moment } from "moment";
import moment from "moment"
import { Box, TextField } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
const DateRangeSelector: React.FC<IDateRangeSelectorProps> = ({ maxRange, startDate, endDate, setStartDate, setEndDate, invalidFunction, disabled, xs = 2 }) => {

    const updateStartDate = (Value: Moment | null) => {
        if (!Value) { return; }

        setStartDate(Value);
        validateDateRange(Value, endDate);
    }

    const updateEndDate = (Value: Moment | null) => {
        if (!Value) { return; }

        setEndDate(Value);
        validateDateRange(startDate, Value);
    }
    function getAbsoluteMonths(momentDate: Moment) {
        var months = Number(momentDate.format("MM"));
        var years = Number(momentDate.format("YYYY"));
        return months + (years * 12);
    }
    const validateDateRange = (startDate: Moment, endDate: Moment) => {
        if (maxRange !== undefined && invalidFunction !== undefined) {
            if (startDate.isAfter(endDate)) {
                invalidFunction("Start Date must be before End Date");
                return;
            }
            

            var startMonths = getAbsoluteMonths(startDate);
            var endMonths = getAbsoluteMonths(endDate);
            var monthsDiff = endMonths - startMonths;
            if (monthsDiff > maxRange || (monthsDiff === maxRange && Number(startDate.format("DD")) <= Number(endDate.format("DD")))) {
                invalidFunction("Date range exceeds maximum of " + maxRange.toString() + " months");
                return;
            }
            invalidFunction("");
        }
    }

    return (
        <>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <Grid xs={xs}>
                <Box>
                    <DatePicker
                        label="Start Date"
                        inputFormat="DD/MM/YYYY"
                        value={startDate}
                        onChange={updateStartDate}
                        disabled={disabled}
                        renderInput={(params) => <TextField {...params}
                        fullWidth
                            />}
                    />
                </Box>
            </Grid>
                <Grid xs={xs}>
                <Box >
                    <DatePicker
                        label="End Date"
                        inputFormat="DD/MM/YYYY"
                        value={endDate}
                        onChange={updateEndDate}
                        disabled={disabled}
                        renderInput={(params) => <TextField {...params}
                        fullWidth
                            />}
                    />
                </Box>
                </Grid>
            </LocalizationProvider>
        </>
    );

}

export interface IDateRangeSelectorProps {
    startDate: Moment
    endDate: Moment
    setStartDate: (startDate: Moment) => void;
    setEndDate: (endDate: Moment) => void;
    invalidFunction?: (valid: string) => void;
    maxRange?: number
    disabled?: boolean
    xs?: number
}

export default DateRangeSelector;