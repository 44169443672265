import { Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material";
import TableToolbar, { ITableToolbarButton, ITableToolbarSearch } from "../../shared/table/TableToolbar";
import { PersonAdd, SearchOff } from "@mui/icons-material";
import { IPermissionsConfig, useAddOrUpdateUserPermissionsMutation, useDeleteUserPermissionsMutation } from "../../../shared/api/PermissionsApi";
import { IAdvancedModalOptions, useModalWindowContext } from "../../ModalWindow";
import PermissionsRow from "./PermissionsRow";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useToastAlertContext } from "../../ToastAlert";
import { generateDummyPermissions } from "../../../shared/types/shared/auth/userDataTypes";
import PermissionsNewUserForm from "./PermissionsNewUserForm";

const UserPermissionsTab: React.FC<IUserPermissionsTabProps> = ({ permissions, inputDisabled, reload }) => {
    const { openModal, updateModal } = useModalWindowContext();
    const { popToast } = useToastAlertContext();

    const [addUser] = useAddOrUpdateUserPermissionsMutation();
    const [deleteUser] = useDeleteUserPermissionsMutation();
    const [savePermissions] = useAddOrUpdateUserPermissionsMutation();

    const [filteredPermissions, setFilteredPermissions] = useState<IPermissionsConfig[]>();
    const [searchTerm, setSearchTerm] = useState<string>("");

    const existingUsers = useMemo(() => {
        return permissions.map((v) => v.id);
    }, [permissions]);

    const searchBar: ITableToolbarSearch = useMemo(() => {
        return {
            label: "Search Users",
            valueUpdated: (searchTerm) => { setSearchTerm(searchTerm); }
        }
    }, []);

    const [formData, setFormData] = useState<string>("");
    const [shouldAdd, setShouldAdd] = useState(false);
    const formUpdated = useCallback((formData: string) => {
        setFormData(formData);
    }, []);

    const validityChanged = useCallback((valid: boolean) => {
        updateModal({ canSubmit: valid });
    }, [updateModal]);

    const openAddUserModal = async () => {
        const modalOptions: IAdvancedModalOptions = {
            title: "Add User",
            fullWidth: true,
            yesText: "Save",
            noText: "Cancel",
            contentComponent: <PermissionsNewUserForm existingUsers={existingUsers} formUpdated={formUpdated} validityChanged={validityChanged} />,
            canSubmit: false,
        }

        const addUser = await openModal(modalOptions);

        if (addUser) {
            setShouldAdd(true);
        }
    }

    useEffect(() => {
        if (shouldAdd) {
            const permissions = generateDummyPermissions(formData);

            addUser(permissions)
                .then(() => {
                    reload();
                })
                .catch(() => {
                    popToast(`Failed to add "${formData}"`, "error");
                })
                .finally(() => {
                    setShouldAdd(false);
                    setFormData("");
                });
        }
    }, [formData, shouldAdd, reload, popToast]);

    useEffect(() => {
        if (!searchTerm) {
            setFilteredPermissions(permissions);
        } else {
            const filtered = permissions.filter((p) => {
                return p.id.toLowerCase().includes(searchTerm.toLowerCase());
            });

            setFilteredPermissions(filtered);
        }
    }, [searchTerm, permissions]);

    const deleteConfig = async (username: string) => {
        await deleteUser(username);
        reload();
    }

    const toolbarButtons: ITableToolbarButton[] = [
        { tooltipText: "Add User", icon: <PersonAdd />, action: openAddUserModal, disabled: inputDisabled }
    ];

    return (
        <>
            <TableToolbar searchBar={searchBar} buttons={toolbarButtons} />
            <TableContainer>
                <Table>
                    <TableBody>
                        {filteredPermissions?.map((value) => (
                            <PermissionsRow key={value.id} permissions={value} saveConfig={savePermissions} deleteConfig={deleteConfig} reload={reload} />
                        ))}
                        {filteredPermissions && filteredPermissions.length === 0 && (
                            <TableRow>
                                <TableCell align="center" sx={{ border: "none" }}>
                                    <SearchOff fontSize="large" />
                                    <br />
                                    <Typography variant="h6">No Users Found</Typography>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}

export default UserPermissionsTab;

export interface IUserPermissionsTabProps {
    permissions: IPermissionsConfig[];
    inputDisabled?: boolean;
    reload: () => void;
}
