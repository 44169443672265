import { ThemeProvider } from '@emotion/react';
import { CloudUpload, Download } from '@mui/icons-material';
import { IconButton, Paper, Stack } from '@mui/material';
import { DataGrid, GridCellParams, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { useAppSelector } from '../../shared/hooks/StateHook';
import { IHistoricalSubmission } from '../../shared/types/gas-reconciliation/historicalsubmission';

interface Props {
    params: GridRenderCellParams;
}

interface ComponentProps {
    selectSubmissionHandler: (batchNumber?: number, siteType?: number) => void;
    downloadCSVFileHandler: (batchNumber: number, siteType: number) => void;
}

const HistoricalSubmissionGrid = ({ selectSubmissionHandler, downloadCSVFileHandler }: ComponentProps) => {
    const { data } = useAppSelector((state) => state.historicalSubmissions);

    const handleRowClick = (cell: GridCellParams) => {
        if (cell.field === "id") return;

        const row = cell.row;
        const dto = row as IHistoricalSubmission;
        selectSubmissionHandler(dto.batchNumber, dto.siteType);
    };

    const handleUploadClick = () => {
        //alertDialogRef?.current?.openDialog("Trade Submission", "Submitting trades to Igloo is currently disabled during testing");
    }

    const columns: GridColDef[] = [
        { field: "submissionDate", headerName: "Submission", minWidth: 150 },
        { field: "tradeDate", headerName: "Trade Date" },
        { field: "gasDay", headerName: "Gas Day" },
        { field: "user", headerName: "User", minWidth: 150 },
        { field: "siteType", headerName: "Type", minWidth: 150, renderCell: (c) => { return c.value === 0 ? "Daily Metered" : "Non Daily Metered"; } },
        { field: "counterparty", headerName: "Counterparty", minWidth: 150 },
        { field: "volume", headerName: "Therms" },
        { field: "price", headerName: "PPT", align: 'center', headerAlign: 'center' },
        { field: "status", headerName: "Status" },
        { field: "id", headerName: "Actions", renderCell: (c) => <ActionsCell params={c} />, disableColumnMenu: true, sortable: false },
    ];

    const ActionsCell = ({ params }: Props) => {
        const { batchNumber, siteType } = params.row;

        return (
            <Stack direction="row">
                <IconButton size="small" title="Download trade csv file" onClick={() => downloadCSVFileHandler(batchNumber, siteType)}>
                    <Download />
                </IconButton>
                <IconButton size="small" title="Upload file to Igloo" onClick={handleUploadClick}>
                    <CloudUpload />
                </IconButton>
            </Stack>
        );
    };

    return (
            <Paper>
                <div style={{ height: 450, width: "100%" }}>
                    {data && (
                        <DataGrid
                            density="compact"
                            rows={data}
                            columns={columns}
                            showCellRightBorder={true}
                            showColumnRightBorder={true}
                            onCellClick={(e) => handleRowClick(e)}

                        />
                    )}
                </div>
            </Paper>
    );
}

export default HistoricalSubmissionGrid;