import { Login } from '@mui/icons-material';
import { Button, Container, Grid, Typography } from '@mui/material';
import { usePortalContext } from '../../components/PortalWrapper';

const SignInPage = () => {
    const { signIn } = usePortalContext();

    return (
        <Container maxWidth={false}>
            <Grid container textAlign="center" spacing={5}>
                <Grid item xs={12}></Grid>
                <Grid item xs={12}>                    
                    <Typography variant="body1">
                        You must be logged in to access the iON Portal.    
                    </Typography>                                        
                </Grid>
                <Grid item xs={12}>
                    <Button variant="contained" startIcon={<Login />} onClick={signIn}>Sign in</Button>
                </Grid>
            </Grid>
        </Container>
    );
}

export default SignInPage;