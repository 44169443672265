import React from "react";
import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";

const OutageFormSelect: React.FC<IOutageFormSelect> = ({ label = "", selected, setSelected, options, disabled = false }) => {

    const handleSelectedChange = (event: SelectChangeEvent) => {
        setSelected(event.target.value as string)
    }

    return (
        <Box>
            <FormControl fullWidth>
                <InputLabel id="type-select-label">{label}</InputLabel>
                <Select
                    labelId="type-select-label"
                    id="type-select-label"
                    value={selected}
                    label={label}
                    disabled={disabled}
                    onChange={handleSelectedChange}
                >
                    {
                        options.map((option) =>
                            <MenuItem key={option} value={option}>{option}</MenuItem>
                        )
                    }
                </Select>
            </FormControl>
        </Box>
    );
}
export interface IOutageFormSelect {
    label?: string
    selected: string
    setSelected: (selected: string) => void
    options: string[]
    disabled?: boolean
}

export default OutageFormSelect;