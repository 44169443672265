import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IGasPosition } from "../../types/gas-reconciliation/gasposition";
import { CalculateResiduals } from "../../utils/GasPositionUtils";
import gasReconciliationApi, { PositionDataAPIResponse } from "../../api/GasReconciliationApi";

interface DailyMeteredState {
    data: IGasPosition[];
    totalResidual: number;
    totalTraded: number;
    totalBurn: number;
    tradedPercentage: number;
    burnPercentage: number;
    lastRefresh: Date | null;
    isDataBeingRefreshed: boolean;
    allocateTradesCompletedPerc: number;
    allocateSPARKTradesCompletedPerc: number;
    hasCounterpartyCashExposure: boolean;
    totalSPARKVolume: number;
}

const initialState: DailyMeteredState = {
    data: [],
    totalResidual: 0,
    totalTraded: 0,
    totalBurn: 0,
    tradedPercentage: 0,
    burnPercentage: 0,
    lastRefresh: null,
    isDataBeingRefreshed: false,
    allocateTradesCompletedPerc: 0,
    allocateSPARKTradesCompletedPerc: 0,
    hasCounterpartyCashExposure: false,
    totalSPARKVolume: 0
};

export const getData = createAsyncThunk(
    "get-dailymetered",
    async (date: Date, thunkApi) => {
        const response = await gasReconciliationApi.getDailyMetered(date);
        return response;
    }
);

export const refreshData = createAsyncThunk(
    "refresh-dailymetered",
    async (date: Date, thunkApi) => {
        await gasReconciliationApi.refreshDailyMetered(date);        
        const response = await gasReconciliationApi.getDailyMetered(date);
        return response;
    }
);

const updateFromState = (state: DailyMeteredState, action: PayloadAction<PositionDataAPIResponse>) => {
    state.lastRefresh = action.payload.lastRefresh;
    state.hasCounterpartyCashExposure = action.payload.hasCounterpartyCashExposure;
    state.totalSPARKVolume = action.payload.totalSPARKVolume;

    const calculatedResult = CalculateResiduals(action.payload.results);

    state.data = calculatedResult.items;
    state.totalResidual = calculatedResult.total;

    state.isDataBeingRefreshed = false;
}

export const dailyMeteredSlice = createSlice({
    name: "dailyMetered",
    initialState,
    reducers: {
        updateOverride: (
            state,
            action: PayloadAction<{ id: number; value: number }>
        ) => {
            const data = state.data.map((d) => {
                if (d.id === action.payload.id) {
                    d.override = action.payload.value;
                }
                return d;
            });

            const calculatedResult = CalculateResiduals(data);

            state.data = calculatedResult.items;
            state.totalResidual = calculatedResult.total;
        },
        updateAllocateCompleted: (
            state, action: PayloadAction<number>) => {
            state.allocateTradesCompletedPerc = action.payload;
        },
        updateAllocateSPARKCompleted: (
            state, action: PayloadAction<number>) => {
            state.allocateSPARKTradesCompletedPerc = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getData.pending, (state) => {
                state.isDataBeingRefreshed = true;
            })
            .addCase(getData.rejected, (state) => {
                state.isDataBeingRefreshed = false;
            })
            .addCase(
                getData.fulfilled,
                (state, action: PayloadAction<PositionDataAPIResponse>) => {
                    updateFromState(state, action);
                    //state.lastRefresh = action.payload.lastRefresh;
                    //state.hasCounterpartyCashExposure = action.payload.hasCounterpartyCashExposure;
                    //state.totalSPARKVolume = action.payload.totalSPARKVolume;

                    //const calculatedResult = CalculateResiduals(action.payload.results);

                    //state.data = calculatedResult.items;
                    //state.totalResidual = calculatedResult.total;                    

                    //state.isDataBeingRefreshed = false;
                }
            )
            .addCase(refreshData.pending, (state) => {
                state.isDataBeingRefreshed = true;
            })
            .addCase(refreshData.rejected, (state) => {
                state.isDataBeingRefreshed = false;
            })
            .addCase(
                refreshData.fulfilled,
                (state, action: PayloadAction<PositionDataAPIResponse>) => {
                    updateFromState(state, action);

                    //state.lastRefresh = action.payload.lastRefresh;
                    //state.hasCounterpartyCashExposure = action.payload.hasCounterpartyCashExposure;
                    //state.totalSPARKVolume = action.payload.totalSPARKVolume;

                    //const calculatedResult = CalculateResiduals(action.payload.results);

                    //state.data = calculatedResult.items;
                    //state.totalResidual = calculatedResult.total;

                    //state.isDataBeingRefreshed = false;
                }
            )
    },
});

export const { updateOverride, updateAllocateCompleted, updateAllocateSPARKCompleted } = dailyMeteredSlice.actions;

export default dailyMeteredSlice.reducer;
