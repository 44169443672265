import React from "react";
import { Route, Routes } from "react-router";
import { usePortalContext } from "./PortalWrapper";

import SiteLayout from "./SiteLayout";
import ErrorPage from "../pages/shared/ErrorPage";
import LandingPage from "../pages/shared/LandingPage";
import LoadingSpinner from "./LoadingSpinner";

const createWrappedElement = (element: JSX.Element, siteArea: string, homeRoute: string, disableNav: boolean = false, disableGutters: boolean = false): JSX.Element => {
    return (
        <SiteLayout homeRoute={homeRoute} pageHeader={siteArea} disableNav={disableNav} disableGutters={disableGutters}>
            {element}
        </SiteLayout>
    );
}

const RouteHandler: React.FC<IRouteHandlerProps> = () => {
    const { routes } = usePortalContext();

    return (
        <Routes>
            <Route key="route-a" path="/" element={createWrappedElement(<LandingPage routesLoaded={!!routes} />, "Portal", "/", true)} />
            {!!routes ? (
                <>
                    {routes}
                    <Route key="route-z" path='*' element={createWrappedElement(<ErrorPage />, "Portal", "/", true)} />
                </>
            ) : (
                <Route key="route-placeholder" path='*' element={createWrappedElement(<LandingPage routesLoaded={false} />, "Portal", "/", true)} />
            )}
        </Routes>
    );
}

export interface IRouteHandlerProps {
}

export default RouteHandler;
