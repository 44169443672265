import React, { useEffect, useState } from "react";
import { Box, Checkbox, FormControlLabel, FormGroup, SxProps, Typography } from "@mui/material";
import { isMobile } from "react-device-detect";
import { Moment } from "moment";
import OutageFormSelect from "./OutageFormSelect";
import OutageFormDate from "./OutageFormDate";
import { OperateType } from "../../../shared/types/operate/OperateType";

const RepeatingOutageSelector: React.FC<IRepeatingOutageSelector> = ({ repeating, setRepeating, interval, setInterval, untilDate, setUntilDate, modifyOrCancel, modifyAll, setModifyAll, loadedRepeatingOutage, type, disabled = false }) => {
    const height = "50px"

    const centerAlignStyling: SxProps = {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
    }

    const intervalOptions = [
        "Day",
        "Weekday",
        "Weekend",
        "Week",
        "Month"
    ]

    const [disabledOptions, setDisableOptions] = useState<boolean>(false)

    useEffect(() => {
        if (disabled) {
            setDisableOptions(true)
        }
        else if (type === "Submit") {
            setDisableOptions(false)
        }
        else {
            setDisableOptions(modifyOrCancel === "Cancel" || !modifyAll)
        }
    }, [disabled, modifyOrCancel, modifyAll])

    const handleRepeatingChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRepeating(event.target.checked);
    };

    const handleModifyAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setModifyAll(event.target.checked);
    };

    return (
        <Box>
            <Box
                display="inline-block"
                width={isMobile ? "100%" : "200px"}
                height={height}
                sx={{ verticalAlign: "top" }}
            >
            {(type === "Submit" || loadedRepeatingOutage) &&
                <FormGroup sx={{ height: "100%" }}>
                    <FormControlLabel
                        sx={{ height: "100%" }}
                        control={
                            <Checkbox
                                checked={repeating}
                                onChange={handleRepeatingChange}
                            />
                        }
                        label="Repeating Outage"
                        disabled={disabled || type !== "Submit"}
                    />
                </FormGroup>
            }
            </Box>
            {repeating && 
                <>
                    <Box
                        display="inline-block"
                        height={height}
                        sx={{ verticalAlign: "top" }}
                    >
                        <Box
                            height={height}
                            sx={centerAlignStyling}
                        >
                            <Typography>Repeat every &nbsp;</Typography>
                        </Box>
                    </Box>
                    <Box
                        display="inline-block"
                        width={isMobile ? "100%" : "200px"}
                        height={height}
                        sx={{ verticalAlign: "top" }}
                    >
                        <OutageFormSelect selected={interval} setSelected={setInterval} options={intervalOptions} disabled={disabledOptions} />
                    </Box>
                    <Box
                        display="inline-block"
                        height={height}
                        sx={{ verticalAlign: "top" }}
                    >
                        <Box
                            height={height}
                            sx={centerAlignStyling}
                        >
                            <Typography>&nbsp; until &nbsp;</Typography>
                        </Box>
                    </Box>
                    <Box
                        display="inline-block"
                        width={isMobile ? "100%" : "200px"}
                        height={height}
                        sx={{
                            verticalAlign: "top"
                        }}
                    >
                        <OutageFormDate date={untilDate} setDate={setUntilDate} disabled={disabledOptions} />
                </Box>
                </>
            }
            {loadedRepeatingOutage &&
                <Box
                    display="inline-block"
                    width={isMobile ? "100%" : "200px"}
                    height={height}
                    sx={{ verticalAlign: "top", ml: 1 }}
                >
                    <FormGroup sx={{ height: "100%" }}>
                        <FormControlLabel
                            sx={{ height: "100%" }}
                            control={
                                <Checkbox
                                    checked={modifyAll}
                                    onChange={handleModifyAllChange}
                                />
                            }
                            label="Modify all instances of this outage"
                            disabled={disabled}
                        />
                    </FormGroup>
                </Box>
            }
            
        </Box>
    );
}

export interface IRepeatingOutageSelector {
    repeating: boolean
    setRepeating: (repeating: boolean) => void
    interval: string
    setInterval: (interval: string) => void
    untilDate: Moment
    setUntilDate: (untilDate: Moment) => void
    modifyOrCancel: string
    modifyAll: boolean
    setModifyAll: (modifyAll: boolean) => void
    loadedRepeatingOutage: boolean
    type: OperateType
    disabled?: boolean
}

export default RepeatingOutageSelector;