import { PortalArea } from "../types/shared/routes/routeHelperClasses";
import UserSettingsPageConfig from "../../pages/user/UserSettingsPage";
import { IPortalAreaOptions } from "../types/shared/routes/routeTypes";
import NotificationSettingsPageConfig from "../../pages/user/NotificationSettingsPage";
import NotificationManagementPageConfig from "../../pages/user/NotificationManagementPage";

const areaOptions: IPortalAreaOptions = {
    title: "User Settings",
    description: "",
    iconImage: "",
    hideFromLandingPage: true,
}

const UserPortalArea = new PortalArea(areaOptions, { relativeUrl: "user" });
UserPortalArea.addChildRoute(UserSettingsPageConfig);
UserPortalArea.addChildRoute(NotificationSettingsPageConfig);
UserPortalArea.addChildRoute(NotificationManagementPageConfig);

export default UserPortalArea;
