const piVisionServer = "https://pi-vision.conradcloud.net/PIVision/#/Displays/";

const engineOverviewGas = "10193/New-Engine-Technical?starttime=t&endtime=t%2B1d";
const commercialOverviewGas = "39/Site-Commercial-Overview?starttime=t&endtime=t%2B1d";
const commercialOverviewBattery = "/10256/Battery-Commercial?starttime=t&endtime=t%2B1d";


const viewOnlyMode = "&mode=kiosk&hidetoolbar";

const separator = "%5C";
const byLiveSitesAssetPath = "&asset=%5C%5CPI-AF%5CiON%5CConrad%5C_By%20Live%20Sites%5C";


export const GasCommercialOverviewUrl = (assetName?: string): string => {
    if (!assetName) { return ""; }

    return piVisionServer + commercialOverviewGas + viewOnlyMode + byLiveSitesAssetPath + assetName
};

export const GasEngineOverviewUrl = (assetName?: string, engineName?: string): string => {
    if (!assetName || !engineName) { return ""; }

    return piVisionServer + engineOverviewGas + viewOnlyMode + byLiveSitesAssetPath + assetName + separator + engineName
};

export const BatteryCommercialOverviewUrl = (assetName?: string): string => {
    if (!assetName) { return ""; }

    return piVisionServer + commercialOverviewBattery + viewOnlyMode + byLiveSitesAssetPath + assetName
};