import { LinearProgress, Paper } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useState } from "react";
import { IGasPosition } from "../../shared/types/gas-reconciliation/gasposition";
import { useAppDispatch, useAppSelector } from "../../shared/hooks/StateHook";
import {
    updateOverride
} from "../../shared/state/slices/DailyMeteredSlice";
import OverrideDialog from "./OverrideDialog";

const columns: GridColDef[] = [
    { field: "site", headerName: "Site", minWidth: 200 },
    { field: "code", headerName: "Code" },
    { field: "traded", headerName: "Traded" },
    { field: "burn", headerName: "Burn" },
    { field: "override", headerName: "Override" },
    { field: "residual", headerName: "Residual" },
];

const DailyMeteredGrid = () => {
    const { data, isDataBeingRefreshed } = useAppSelector((state) => state.dailyMetered);
    const [selectedSite, setSelectedSite] = useState<IGasPosition | null>(
        null
    );
    const dispatch = useAppDispatch();

    const handleRowClick = (dto: IGasPosition) => {
        setSelectedSite(dto);
    };

    const handleDialogClose = () => {
        setSelectedSite(null);
    };

    const handleDialogApplyOverride = (id: number, value: number) => {
        dispatch(updateOverride({ id, value }));
    };

    return (
        <>
            <Paper>
                {isDataBeingRefreshed && <LinearProgress />}
                <div style={{ height: 300, width: "100%" }}>
                    {data && (
                        <DataGrid
                            density="compact"
                            rows={data}
                            columns={columns}
                            showCellRightBorder={true}
                            showColumnRightBorder={true}
                            onRowClick={(e) => handleRowClick(e.row as IGasPosition)}
                        />
                    )}
                </div>
            </Paper>
            {selectedSite && (
                <OverrideDialog
                    site={selectedSite}
                    handleClose={handleDialogClose}
                    handleApply={handleDialogApplyOverride}
                />
            )}
        </>
    );
};

export default DailyMeteredGrid;
