import { StatusHiveSiteData, StatusHiveTileStatus } from "../../shared/types/asset/status-hive";
import { HiveColours } from "../../shared/utils/StatusHiveUtils";
import { HexMultiLineText } from "./HexMultiLineText";
import { Text } from 'react-hexgrid';

export const HexStatusText = ({ status, colour, y }: IHexStatusTextProps): JSX.Element => {
    const fontSize = 0.07;

    if (status.tileStatus === StatusHiveTileStatus.Pending) {
        return (
            <Text y={`${y}em`} fill={HiveColours.Running} fontSize={`${fontSize}em`} strokeWidth="0" fontWeight={600}>
                {status.dispatchTimeHumanReadable}
            </Text>
        );
    }

    return (
        <HexMultiLineText text={status.statusHumanReadable} colour={colour} y={y} fontSize={fontSize} maxChars={19} fontWeight={600} />
    );
}

export interface IHexStatusTextProps {
    status: StatusHiveSiteData;
    colour: string;
    y: number;
}