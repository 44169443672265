import React from "react";
import PageWrapper from "../../components/PageWrapper";
import { Grid } from "@mui/material";
import { IPortalRouteOptions } from "../../shared/types/shared/routes/routeTypes";
import { Dashboard } from "@mui/icons-material";

const AssetHomePage: React.FC<IAssetHomePage> = () => {
  const [loaded, setLoaded] = React.useState(false);

  const loadComplete = () => {
    setLoaded(true);
  }

  setTimeout(loadComplete, 500);

  return (
    <PageWrapper title="Dashboard" loaded={loaded}>
      <Grid container spacing={2}>
      </Grid>
    </PageWrapper>
  );
}

export interface IAssetHomePage {

}

const AssetHomePageConfig: IPortalRouteOptions = {
  relativeUrl: "asset",
  page: <AssetHomePage />,
  navDisplay: {
    title: "Dashboard",
    icon: <Dashboard />,
  }
}

export default AssetHomePageConfig;