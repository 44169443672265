import React, { useEffect, useMemo, useState } from 'react';
import { Box, Button, Checkbox, CircularProgress,  FormControl, FormControlLabel, InputLabel, MenuItem, Select, SelectChangeEvent, Tooltip, useTheme } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import moment, { Moment } from 'moment';
import { IPushNotification } from '../../shared/types/notification/IPushNotification';
import { Search } from '@mui/icons-material';
import DateRangeSelector from '../data/DateRangeSelector';
import { Dictionary } from '@reduxjs/toolkit';
import { useGetAllRecipientsQuery, useGetNotificationsSearchMutation } from "../../shared/api/NotificationsApi";
import { INotificationSearchParam } from '../../shared/types/notification/INotificationSearchParam';
import { isMobile } from 'react-device-detect';

const AdminNotificationOptions: React.FC<IAdminNotificationOptions> = ({ setNotifications, siteIdToName, filterDuplicates = false, truncatedControls = false }) => {
    const [assets, setAssets] = useState<string[]>(['All'])
    const [selectedNotifTypes, setSelectedNotifTypes] = useState<string[]>(['All'])
    const [anyTimeRange, setAnyTimeRange] = useState<boolean>(false)
    const [selectedPriority, setSelectedPriority] = useState<string[]>(['All'])
    const defaultStartTime = useMemo(() => moment.utc().startOf("day").subtract(1, "week"), []);
    const defaultEndTime = useMemo(() => moment.utc().endOf("day").add(1, "second"), []);
    const [startDate, setStartDate] = useState<Moment>(defaultStartTime);
    const [endDate, setEndDate] = useState<Moment>(defaultEndTime);
    const [read, setRead] = useState<string[]>(['Both'])
    const [deleted, setDeleted] = useState<string[]>(['Both'])
    const [error, setError] = useState<string>("")
    const [selectedRecipients, setSelectedRecipients] = useState<string[]>(['All'])
    const [recipients, setRecipients] = useState<string[]>([])
    const [querying, setQuerying] = useState<boolean>(false)

    const [sendQuery] = useGetNotificationsSearchMutation()
    const { data, isSuccess } = useGetAllRecipientsQuery()
    const theme = useTheme();

    useEffect(() => {
        setRecipients(data || [])
    }, [data, isSuccess])

    useEffect(() => {
        if (assets.length === 0) {
            setError("Select at least one asset")
        }
        else if (selectedRecipients.length === 0) {
            setError("Select at least one recipient")
        }
        else if (!anyTimeRange && (startDate.isAfter(endDate))) {
            setError("Start date must be before end date")
        }
        else if (selectedNotifTypes.length === 0) {
            setError("Select at least one notification types")
        }
        else if (selectedPriority.length === 0) {
            setError("Select at least on priority")
        }
        else if (read.length === 0) {
            setError("Select at least one value for read")
        }
        else if (deleted.length === 0) {
            setError("Select at least one value for deleted")
        }
        else {
            setError("")
        }
    }, [assets, selectedRecipients, anyTimeRange, startDate, endDate, selectedNotifTypes, selectedPriority, read, deleted])

    const handleAssetChange = (event: SelectChangeEvent<typeof assets>) => {
        const {
            target: { value },
        } = event;
        const newAssets = typeof value === 'string' ? value.split(',') : value
        setAssets(newAssets)
    };

    const handleNotifTypeChange = (event: SelectChangeEvent<typeof selectedNotifTypes>) => {
        const {
            target: { value },
        } = event;
        const newNotifs = typeof value === 'string' ? value.split(',') : value
        setSelectedNotifTypes(newNotifs)
    };

    const handlePriorityChange = (event: SelectChangeEvent<typeof selectedPriority>) => {
        const {
            target: { value },
        } = event;
        const newPriorities = typeof value === 'string' ? value.split(',') : value
        setSelectedPriority(newPriorities)
    };
    const handleRecipientChange = (event: SelectChangeEvent<typeof selectedRecipients>) => {
        const {
            target: { value },
        } = event;
        const newRecipients = typeof value === 'string' ? value.split(',') : value
        setSelectedRecipients(newRecipients)
    };

    const handleReadChange = (event: SelectChangeEvent<typeof read>) => {
        const {
            target: { value },
        } = event;
        const newReads = typeof value === 'string' ? value.split(',') : value
        setRead(newReads)
    };

    const handleDeletedChange = (event: SelectChangeEvent<typeof deleted>) => {
        const {
            target: { value },
        } = event;
        const newDeleted = typeof value === 'string' ? value.split(',') : value
        setDeleted(newDeleted)
    };

    const clickedSearch = () => {
        setQuerying(true)
        const queryBody: INotificationSearchParam = {
            assets: assets,
            recipients: selectedRecipients,
            anyTime: anyTimeRange,
            startDate: new Date(startDate.utc().format()),
            endDate: new Date(endDate.utc().format()),
            types: selectedNotifTypes,
            priority: selectedPriority,
            read: read,
            deleted: deleted
        }
        sendQuery(queryBody)
            .unwrap()
            .then((response) => {
                if (filterDuplicates) {
                    const existingDateTimes = new Set<string>()
                    const filteredNotifications = new Array<IPushNotification>()
                    response.forEach(notification => {
                        const submissionTime = notification.submissionTime
                        if (!existingDateTimes.has(submissionTime)) {
                            existingDateTimes.add(submissionTime)
                            filteredNotifications.push(notification)
                        }
                    })
                    setNotifications(filteredNotifications)
                }
                else {
                    setNotifications(response);
                }
            })
            .catch((err) => {
                console.error(err)
            })
            .finally(() => {
                setQuerying(false)
            })
    }

    const notifTypes = [
        "Notice",
        "Alarm",
        "Warning"
    ]

    const priorities = [
        "High",
        "Medium",
        "Low",
        "Info"
    ]

    const readValues = [
        "Read",
        "Unread"
    ]

    const deletedValues = [
        "Deleted",
        "Not Deleted"
    ]

    const selectedItemColor = theme.palette.primary.main

    return (
        <Grid
            container
            alignItems="center"
            alignContent="start"
            spacing={2}
            justifyContent="flex-start"
        >
            <DateRangeSelector startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate} maxRange={3} invalidFunction={() => { }} disabled={anyTimeRange} xs={isMobile ? 6 : 2} />
            <Grid xs={isMobile ? truncatedControls ? 6 : 12 : 2}>
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center">
                    <FormControlLabel
                        control={
                            <Checkbox id="anyTimeRange" checked={anyTimeRange} onChange={() => setAnyTimeRange(!anyTimeRange)} sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }} />
                        }
                        label="Any Time?"
                    />
                </Box>
            </Grid>
            <Grid xs={isMobile ? 6 : truncatedControls ? 2 : 3}>
                <Box>
                    <FormControl fullWidth>
                        <InputLabel id="asset-label">Asset</InputLabel>
                        <Select
                            labelId="asset-label"
                            id="multiple-asset"
                            multiple
                            label="Assets"
                            value={assets}
                            onChange={handleAssetChange}
                            renderValue={(selected) => {
                                const maxNumberOfAssets = 5
                                if (selected.indexOf('All') > -1) { return 'All' }
                                const selectedAssets: string[] = []
                                selected.sort().slice(0, maxNumberOfAssets).forEach((asset) => {
                                    selectedAssets.push(siteIdToName[asset] || asset)
                                })
                                if (selected.length > maxNumberOfAssets) {
                                    selectedAssets.push("...")
                                }
                                return selectedAssets.join(', ')
                            }
                            }
                        >
                            <MenuItem key={'All'} value={'All'} sx={{ "&.Mui-selected": { backgroundColor: selectedItemColor } }}>{'All'}</MenuItem>
                            {
                                Object.keys(siteIdToName).sort().map((item, index) =>
                                    <MenuItem key={item} value={item} sx={{ "&.Mui-selected": { backgroundColor: selectedItemColor } }}>{siteIdToName[item]}</MenuItem>
                                )
                            }
                        </Select>
                    </FormControl>
                </Box>
            </Grid>
            <Grid xs={isMobile ? 6 : 3} hidden={truncatedControls}>
                <Box>
                    <FormControl fullWidth>
                        <InputLabel id="recipient-label">Recipients</InputLabel>
                        <Select
                            labelId="recipient-label"
                            id="multiple-recipients"
                            multiple
                            label="Recipients"
                            value={selectedRecipients}
                            onChange={handleRecipientChange}
                            renderValue={(selected) => {
                                if (selected.indexOf('All') > -1) { return 'All' }
                                return selected.sort((a, b) => recipients.indexOf(a) < recipients.indexOf(b) ? -1 : 1).join(', ')
                            }
                            }
                        >
                            <MenuItem key={'All'} value={'All'} sx={{ "&.Mui-selected": { backgroundColor: selectedItemColor } }}>{'All'}</MenuItem>
                            {
                                recipients.map((item, index) =>
                                    <MenuItem key={item} value={item} sx={{ "&.Mui-selected": { backgroundColor: selectedItemColor } }}>{item}</MenuItem>
                                )
                            }
                        </Select>
                    </FormControl>
                </Box>
            </Grid>
            <Grid xs={isMobile ? 6 : truncatedControls ? 2 : 3}>
                <Box>
                    <FormControl fullWidth>
                        <InputLabel id="type-label">Types</InputLabel>
                        <Select
                            labelId="type-label"
                            id="multiple-types"
                            multiple
                            label="Types"
                            value={selectedNotifTypes}
                            onChange={handleNotifTypeChange}
                            renderValue={(selected) => {
                                if (selected.indexOf('All') > -1) { return 'All' }
                                return selected.sort((a, b) => notifTypes.indexOf(a) < notifTypes.indexOf(b) ? -1 : 1).join(', ')
                            }
                            }
                        >
                            <MenuItem key={'All'} value={'All'} sx={{ "&.Mui-selected": { backgroundColor: selectedItemColor } }}>{'All'}</MenuItem>
                            {
                                notifTypes.sort().map((item, index) =>
                                    <MenuItem key={item} value={item} sx={{ "&.Mui-selected": { backgroundColor: selectedItemColor } }}>{item}</MenuItem>
                                )
                            }
                        </Select>
                    </FormControl>
                </Box>
            </Grid>
            <Grid xs={isMobile ? 6 : truncatedControls ? 2 : 3}>
                <Box>
                    <FormControl fullWidth>
                        <InputLabel id="priority-label">Priority</InputLabel>
                        <Select
                            labelId="priority-label"
                            id="multiple-priorities"
                            multiple
                            label="Priority"
                            value={selectedPriority}
                            onChange={handlePriorityChange}
                            renderValue={(selected) => {
                                if (selected.indexOf('All') > -1) { return 'All' }
                                return selected.sort((a, b) => priorities.indexOf(a) < priorities.indexOf(b) ? -1 : 1).join(', ')
                            }
                            }
                        >
                            <MenuItem key={'All'} value={'All'} sx={{ "&.Mui-selected": { backgroundColor: selectedItemColor } }}>{'All'}</MenuItem>
                            {
                                priorities.map((item, index) =>
                                    <MenuItem key={item} value={item} sx={{ "&.Mui-selected": { backgroundColor: selectedItemColor } }}>{item}</MenuItem>
                                )
                            }
                        </Select>
                    </FormControl>
                </Box>
            </Grid>
            <Grid xs={isMobile ? 6 : 2} hidden={truncatedControls}>
                <Box>
                    <FormControl fullWidth>
                        <InputLabel id="read-label">Read</InputLabel>
                        <Select
                            labelId="read-label"
                            id="multiple-reads"
                            multiple
                            label="Read"
                            value={read}
                            onChange={handleReadChange}
                            renderValue={(selected) => {
                                if (selected.indexOf('Both') > -1) { return 'Both' }
                                return selected.sort((a, b) => readValues.indexOf(a) < readValues.indexOf(b) ? -1 : 1).join(', ')
                            }
                            }
                        >
                            <MenuItem key={'Both'} value={'Both'} sx={{ "&.Mui-selected": { backgroundColor: selectedItemColor } }}>{'Both'}</MenuItem>
                            {
                                readValues.map((item, index) =>
                                    <MenuItem key={item} value={item} sx={{ "&.Mui-selected": { backgroundColor: selectedItemColor } }}>{item}</MenuItem>
                                )
                            }
                        </Select>
                    </FormControl>
                </Box>
            </Grid>
            <Grid xs={isMobile ? 6 : 2} hidden={truncatedControls}>
                <Box>
                    <FormControl fullWidth>
                        <InputLabel id="deleted-label">Deleted</InputLabel>
                        <Select
                            labelId="deleted-label"
                            id="multiple-deleted"
                            multiple
                            label="Deleted"
                            value={deleted}
                            onChange={handleDeletedChange}
                            renderValue={(selected) => {
                                if (selected.indexOf('Both') > -1) { return 'Both' }
                                return selected.sort((a, b) => deletedValues.indexOf(a) < deletedValues.indexOf(b) ? -1 : 1).join(', ')
                            }
                            }
                        >
                            <MenuItem key={'Both'} value={'Both'} sx={{ "&.Mui-selected": { backgroundColor: selectedItemColor } }}>{'Both'}</MenuItem>
                            {
                                deletedValues.map((item, index) =>
                                    <MenuItem key={item} value={item} sx={{ "&.Mui-selected": { backgroundColor: selectedItemColor } }}>{item}</MenuItem>
                                )
                            }
                        </Select>
                    </FormControl>
                </Box>
            </Grid>
            {truncatedControls && !isMobile && <Grid xs={10} />}
            <Grid xs={isMobile ? 12 : 2}>
                <Tooltip title={error}>
                    <Box sx={{ position: 'relative' }}>
                        <Button
                            variant="contained"
                            startIcon={<Search />}
                            disabled={querying || !!error}
                            onClick={clickedSearch}
                            size="large"
                            sx={{ height: "55px" }}
                            fullWidth
                        >
                            Search
                        </Button>
                        {querying && (
                            <CircularProgress
                                size={24}
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: '-12px',
                                    marginLeft: '-12px',
                                }}
                            />
                        )}
                    </Box>
                </Tooltip>
            </Grid>
            </Grid>
    );

}
export interface IAdminNotificationOptions {
    setNotifications: (notifications: IPushNotification[]) => void
    siteIdToName: Dictionary<string>
    filterDuplicates?: boolean
    truncatedControls?: boolean
}

export default AdminNotificationOptions;