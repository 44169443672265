import React, { useEffect, useState } from 'react';
import { Box, Stack, Typography, useTheme } from "@mui/material";
import { IAuctionProduct } from '../../../shared/types/dynamic-contracts/IStackedService';
import { isMobile } from 'react-device-detect';

// Helper to extract DCL/DCH/DML/etc
export const extractProductType = (product: IAuctionProduct) => {
    return product.unitResultID.split("#||#")[2]
}

const SiteAuctionProducts: React.FC<ISiteAuctionProducts> = ({ auctionProducts, setSelectedAuctionProduct }) => {
    const theme = useTheme();
    const cColor = theme.palette.conradEnergyCyan.light
    const mColor = theme.palette.conradEnergyMagenta.dark
    const rColor = theme.palette.error.dark

    const [data, setData] = useState<IAuctionProduct[]>(auctionProducts)
    const [backgroundColor, setBackgroundColor] = useState<string>(theme.palette.background.default)

    useEffect(() => {
        setData(auctionProducts)
    }, [auctionProducts])

    useEffect(() => {
        setBackgroundColor(theme.palette.background.default)
    }, [theme])

    return (
        <Stack direction="row" justifyContent="center">
            {data.sort((a, b) => extractProductType(a) < extractProductType(b) ? -1 : 1).map((auctionProduct) => {
                const productType = extractProductType(auctionProduct)
                let textColor = ""
                if (productType === "DCL" || productType === "DCH") { textColor = cColor }
                else if (productType === "DML" || productType === "DMH") { textColor = mColor }
                else if (productType === "DRL" || productType === "DRH") { textColor = rColor }
                return (
                    <div
                        onMouseEnter={() => {
                            setSelectedAuctionProduct(auctionProduct)
                        }}
                        onMouseLeave={() => {
                            setSelectedAuctionProduct(null)
                        }}
                        onClick={(e) => {
                            if (isMobile) {
                                setSelectedAuctionProduct(auctionProduct)
                                e.stopPropagation()
                            }
                        }}
                        key={auctionProduct.unitResultID}
                    >
                        <Box
                            sx={{
                                margin: "0px 5px",
                                padding: "2px 15px",
                                backgroundColor: backgroundColor
                            }}
                        >
                            <Typography
                                sx={{
                                    color: textColor,
                                    fontWeight: "bold"
                                }}
                            >{productType}</Typography>
                        </Box>
                    </div>
                )
            })}
        </Stack>
    );
}

export interface ISiteAuctionProducts {
    auctionProducts: IAuctionProduct[]
    setSelectedAuctionProduct: (selectedAuctionProduct: IAuctionProduct|null) => void
}

export default SiteAuctionProducts;