import React, { PropsWithChildren, useEffect, useState } from 'react';


// Tracks mouse position and renders component next to mouse (or with offset)
const MouseTracker: React.FC<PropsWithChildren<IMouseTracker>> = ({ children, offset = { x: 0, y: 0 } }) => {
    const [mousePosition, setMousePosition] = useState({ x: null, y: null });

    useEffect(() => {
        const updateMousePosition = (ev: any) => {
            setMousePosition({ x: ev.clientX, y: ev.clientY });
        };

        window.addEventListener('mousemove', updateMousePosition);

        return () => {
            window.removeEventListener('mousemove', updateMousePosition);
        };
    }, []);

    return (
        <div style={{
            position: "fixed",
            top: (mousePosition.y || 0) + offset.y,
            left: (mousePosition.x || 0) + offset.x,
            zIndex: 1200
        }}>
            {children}
        </div>
    )
}

export interface IMouseTracker {
    offset?: {
        x: number,
        y: number
    }
}

export default MouseTracker;