import { Balance, Bolt, Home, Info, Layers } from '@mui/icons-material';
import BatteryIcon from '@mui/icons-material/BatteryChargingFull';
import SolarIcon from '@mui/icons-material/SolarPower';
import WindIcon from '@mui/icons-material/WindPower';
import PropaneIcon from '@mui/icons-material/Propane';
import { Chip, Typography, useTheme } from "@mui/material";
import { CSSProperties } from '@mui/material/styles/createMixins';
import React, { ReactElement } from "react";
import { IAssetInformation } from "../../../shared/types/IAssetInformation";
import { ISiteStatus } from '../../../shared/types/ISiteStatus';

const GoogleMapAsset: React.FC<IGoogleMapAssetProps> = ({ asset, status }) => {
    const theme = useTheme();

    const getGenerationTypeIcon = (type: string): ReactElement => {
        const imageHeight = 50;
        if (type === "Battery") {
            return <BatteryIcon style={{ backgroundColor: "rgba(0,0,0, .3)", padding: 2 }} titleAccess="Battery" fontSize="large" alignmentBaseline="auto" />
        }

        if (type === "Solar") {
            return <SolarIcon style={{ backgroundColor: "rgba(0,0,0, .3)", padding: 2 }} titleAccess="Solar" fontSize="large" alignmentBaseline="auto" />
        }

        if (type === "Wind") {
            return <WindIcon style={{ backgroundColor: "rgba(0,0,0, .3)", padding: 2 }} titleAccess="Wind" fontSize="large" alignmentBaseline="auto" />
        }

        return <PropaneIcon style={{ backgroundColor: "rgba(0,0,0, .3)", padding: 2 }}  titleAccess="Gas" fontSize="large" alignmentBaseline="auto" />
    };

    const containerStyle: CSSProperties = {
        margin: 0,
        padding: 0,
    };

    const headerStyle: CSSProperties = {
        backgroundColor: status !== null ? status.backgroundHexColour : theme.palette.common.white,
        color: status !== null ? status.textHexColour : theme.palette.common.black,
        alignItems: "center",
        padding: 5,
        borderRadius: 5
    };

    const informationChip = (label: string | undefined, title: string | undefined, icon: ReactElement) => {    
        return <div style={{ paddingBottom: 5 }}><Chip style={{ color: theme.palette.common.black}} icon={icon} label={label} variant="outlined" title={title}></Chip></div>
    };

    return (
        <div style={containerStyle}>
            <Typography variant="h3" style={headerStyle}>{getGenerationTypeIcon(asset.GenerationType)} {asset.Name}</Typography>
            <div style={{ paddingTop: 5 }}>
                {informationChip(asset.SiteID, asset.SiteID, <Info color="inherit" />)}
                {informationChip(asset.Address, asset.Address, <Home color="inherit" />)}
                {informationChip(asset.ConradRegion, asset.ConradRegion, <Layers color="inherit" />)}
                {informationChip(asset.BMID === null || asset.BMID === "NONBM" ? "N/A" : asset.BMID, "Is this site a BM or Non BM Site", <Balance color="inherit" />)}
                {informationChip(`${asset.CapabilityKW / 1000} MW`, "Site capacity in MW", <Bolt color="inherit" />)}
            </div>
        </div>
    )
}

export interface IGoogleMapAssetProps {
    asset: IAssetInformation;
    status: ISiteStatus | null;
}


export default GoogleMapAsset;