import { Box, Toolbar } from "@mui/material";
import React, { PropsWithChildren, useEffect, useState } from "react";
import { useSwipeable } from "react-swipeable";
import { useNavigationMenu } from "../shared/hooks/NavigationMenuHook";
import useNotificationMenu from "../shared/hooks/NotificationMenuHook";
import { INavDrawerState } from "../shared/types/shared/siteNavTypes";
import NotificationSubscriber from "./notifications/NotificationSubscriber";
import { usePortalContext } from "./PortalWrapper";
import SiteHeader from "./SiteHeader";
import SiteNav from "./SiteNav";

const SiteLayout: React.FC<PropsWithChildren<ISiteLayoutProps>> = ({ children, pageHeader, homeRoute, navMenuId, disableNav, disableGutters, forceNavOverlay }: PropsWithChildren<ISiteLayoutProps>) => {
    const { isAuthenticated } = usePortalContext();
    const { isExpanded, expandMenu } = useNavigationMenu();
    const { toggleDrawer: toggleNotifDrawer } = useNotificationMenu();
    const [overlayNavOpen, setOverlayNavOpen] = useState(false);

    const { displayMenuArea } = useNavigationMenu(navMenuId);

    const [mainWidth, setMainWidth] = useState<string>("");

    useEffect(() => {
        if (navMenuId && !disableNav) {
            displayMenuArea();
        }
    });

    useEffect(() => {
        let width = isExpanded ? 260 : 65;
        const newValue = `calc(100vw - ${width}px)`;
        setMainWidth(newValue);
    }, [isExpanded])

    const toggleNav = () => {
        expandMenu(!isExpanded);
    }

    const updateOverlayNav = (open: boolean) => {
        setOverlayNavOpen(open);
    }

    const navDrawerState: INavDrawerState = { open: isExpanded, toggle: toggleNav, updateOverlayNav }
    const shouldDisableNav = !isAuthenticated || disableNav;

    const swipeHandler = useSwipeable({
        onSwiped: (eventData) => {
            if (eventData.event.cancelable) {
                if (eventData.dir === "Right") {
                    expandMenu(true)
                    updateOverlayNav(true);
                }
                else if (eventData.dir === "Left") {
                    if (isExpanded) {
                        expandMenu(false)
                        updateOverlayNav(false);
                    }
                    else {
                        toggleNotifDrawer(true)
                    }
                }
            }
        },
        touchEventOptions: { passive: false }
    });

    return (
        <Box sx={{ display: 'flex' }} {...swipeHandler}>
            <NotificationSubscriber authenticated={isAuthenticated}>
                <SiteHeader
                    pageHeader={pageHeader}
                    homeRoute={homeRoute}
                    navDrawerState={navDrawerState}
                    disableNav={shouldDisableNav}
                    forceNavOverlay={forceNavOverlay}
                />

                {!shouldDisableNav &&
                    <SiteNav
                        navExpanded={isExpanded}
                        forceNavOverlay={forceNavOverlay}
                        navOverlayOpen={overlayNavOpen}
                        swipeHandler={swipeHandler}
                        updateOverlayNav={updateOverlayNav}
                    />}

                <Box component="main" sx={{ flexGrow: 1, pt: disableGutters ? 0 : 2, pb: disableGutters ? 0 : 2, height: "100vh", width: mainWidth }}>
                    <Toolbar />
                    {children}
                </Box>
            </NotificationSubscriber>
        </Box>
    );
}

export interface ISiteLayoutProps {
    pageHeader: string;
    homeRoute: string;
    navMenuId?: string;
    disableNav?: boolean;
    disableGutters?: boolean;
    forceNavOverlay?: boolean;
}

export default SiteLayout;
