import { Box, CircularProgress, IconButton, TextField, Tooltip } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { DatePicker } from "@mui/x-date-pickers";
import moment, { Moment } from "moment";
import { isMobile } from "react-device-detect";
import { useEffect, useRef } from "react";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material"

const DynamicContractsDaySelection: React.FC<IDynamicContractsDaySelectionProps> = ({ value, onChange, querying, queryError, onClickRefresh }) => {
    const isFirstRender = useRef(true);

    const clickLeft = () => {
        onChange(moment(value).subtract(1, "days"))
    }

    const clickRight = () => {
        onChange(moment(value).add(1, "days"))
    }

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }
        if (!querying) {
            onClickRefresh()
        }
    }, [value])

    return (
        <Grid container
            alignItems="center"
            alignContent="start"
            spacing={1}
            justifyContent="flex-start">
            <Grid xs={isMobile ? 9 : 2}>
                <DatePicker
                    label="Query Date"
                    inputFormat="DD/MM/YYYY"
                    value={value}
                    onChange={onChange}
                    disabled={querying}
                    renderInput={(params) => <TextField {...params} fullWidth/>}
                />
            </Grid>
            <Grid xs={isMobile ? 3 : 1}>
                <Tooltip title={queryError}>
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center">
                        {querying ?
                            <CircularProgress
                                size={24}
                            />
                            :
                            <>
                                <IconButton
                                    size="large"
                                    color="primary"
                                    disabled={querying || !!queryError}
                                    onClick={clickLeft}>
                                    <ArrowBackIos fontSize="inherit" />
                                </IconButton>
                                <IconButton
                                    size="large"
                                    color="primary"
                                    disabled={querying || !!queryError}
                                    onClick={clickRight}>
                                    <ArrowForwardIos fontSize="inherit" />
                                </IconButton>
                            </>
                        }
                    </Box>
                </Tooltip>
            </Grid>
        </Grid>
    );
}

export interface IDynamicContractsDaySelectionProps {
    value: Moment;
    onChange: (Value: Moment | null) => void;
    querying: boolean;
    queryError?: string;
    onClickRefresh: () => void;
}

export default DynamicContractsDaySelection;
