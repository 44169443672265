import PageWrapper from "../../../components/PageWrapper";
import React, { useMemo } from "react";
import { AdminPanelSettings } from "@mui/icons-material";
import PowerBiReportConfigTable from "../../../components/analytics/admin/power-bi-config/PowerBiReportConfigTable";
import { Paper } from "@mui/material";
import powerBiApi from "../../../shared/api/PowerBiApi";
import { IPortalRouteOptions } from "../../../shared/types/shared/routes/routeTypes";
import { AnalyticsPermissions } from "../../../shared/types/shared/auth/permissionsTypes";

const AnalyticsAdminPage: React.FC<IAnalyticsAdminPageProps> = () => {
    const { data, isLoading, isSuccess, isFetching } = powerBiApi.useGetConfigsQuery();

    const reports = useMemo(() => {
        return data || [];
    }, [data]);

    const loaded = useMemo(() => {
        return !isLoading && isSuccess;
    }, [isLoading, isSuccess]);

    return (
        <PageWrapper title="Analytics Admin" loaded={loaded} contentOverflow="hidden" refreshing={isFetching}>
            <Paper sx={{ height: "calc(100% - 1px)" }}>
                <PowerBiReportConfigTable data={reports} isFetching={isFetching} />
            </Paper>
        </PageWrapper>
    );
}

export interface IAnalyticsAdminPageProps {

}

const AnalyticsAdminPageConfig: IPortalRouteOptions = {
    relativeUrl: "admin",
    page: <AnalyticsAdminPage />,
    navDisplay: { title: "Admin", icon: <AdminPanelSettings /> },
    requiredPermissions: { Analytics: AnalyticsPermissions.Admin }    
}

export default AnalyticsAdminPageConfig;
