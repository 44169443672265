import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Stack, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import * as yup from "yup";
import { IDetailedGasAsset } from "../../shared/types/asset/IDetailedGasAsset";

const DetailedAssetModalForm: React.FC<IDetailedAssetModalForm> = ({ attribute, handleClose, handleSave }) => {
    const [isNewAttribute, setIsNewAttribute] = useState<boolean>(false);

    const number = yup
        .number().nullable()
        .typeError("Must be a number")
    const positiveNumber = yup
        .number().nullable()
        .min(0, "Cannot be negative")
        .typeError("Must be a number")
    const positiveInteger = yup
        .number().integer().nullable()
        .min(0, "Cannot be negative")
        .typeError("Must be a number")
    const percentage = yup
        .number().nullable()
        .min(0, "Cannot be negative")
        .max(100, "Cannot exceed 100%")
        .typeError("Must be a number")

    const validationSchema = yup.object().shape({
        siteID: yup
            .string()
            .required("Site ID is required"),      
        generationType: yup
            .string()
            .required("Generation Type is required"),
        numberOfEngines: positiveInteger,
        capabilityKW: positiveNumber,
        latitude: number,
        longitude: number,
        MEL: positiveNumber,
        HHV: positiveNumber,
        minOutputPerc: percentage,
        minOutputMW: positiveNumber,
        NDZ: positiveNumber,
        timeToFullPower: positiveNumber,
        RURE: positiveNumber,
        rampDown: positiveNumber,
        rampDownRate: positiveNumber,
        MZT: positiveNumber,
        gasOfftakePressure: positiveNumber,
        connectionVoltage: positiveNumber,
        exportMPAN: positiveNumber,
        importMPAN: positiveNumber,
        positiveNumber: positiveNumber,
    });

    const formik = useFormik({
        initialValues: {
            name: attribute?.name,
            iONName: attribute?.iONName,
            siteID: attribute?.siteID,
            constructionStage: attribute?.constructionStage,
            portfolio: attribute?.portfolio,
            acquisition: attribute?.acquisition,
            numberOfEngines: attribute?.numberOfEngines,
            engineManAndModel: attribute?.engineManAndModel,
            engineState: attribute?.engineState,
            isBMU: attribute?.isBMU,
            generationType: (attribute?.generationType === undefined ? "Gas" : attribute?.generationType),
            capabilityKW: attribute?.capabilityKW,
            postcode: attribute?.postcode,
            conradRegion: attribute?.conradRegion,
            latitude: attribute?.latitude,
            longitude: attribute?.longitude,
            address: attribute?.address,

            dailyRestrictions: attribute?.dailyRestrictions,
            earliestStart: attribute?.earliestStart,
            latestEnd: attribute?.latestEnd,
            yearlyRestrictions: attribute?.yearlyRestrictions,

            BMID: attribute?.BMID,
            MEL: attribute?.MEL,
            HHV: attribute?.HHV,
            minOutputPerc: attribute?.minOutputPerc,
            minOutputMW: attribute?.minOutputMW,
            NDZ: attribute?.NDZ,
            timeToFullPower: attribute?.timeToFullPower,
            RURE: attribute?.RURE,
            minTimeOn: attribute?.minTimeOn,
            maxTimeOn: attribute?.maxTimeOn,
            rampDown: attribute?.rampDown,
            rampDownRate: attribute?.rampDownRate,
            MZT: attribute?.MZT,
            maxStartsPerDay: attribute?.maxStartsPerDay,
    
            gasDistributionNetwork: attribute?.gasDistributionNetwork,
            gasOfftakePressure: attribute?.gasOfftakePressure,
            gasInitialSOQ: attribute?.gasInitialSOQ,
            gasMeterNumber: attribute?.gasMeterNumber,
            gasMeterOperator: attribute?.gasMeterOperator,
    
            networkOperator: attribute?.networkOperator,
            connectionVoltage: attribute?.connectionVoltage,
            exportMPAN: attribute?.exportMPAN,
            importMPAN: attribute?.importMPAN,
            meterOperator: attribute?.meterOperator,
        },
        validationSchema: validationSchema,
        validateOnBlur: true,
        onSubmit: (values) => {
            if (attribute) {
                const model = {
                    name: values.name,
                    iONName: values.iONName,
                    siteID: values.siteID,
                    constructionStage: values.constructionStage,
                    portfolio: values.portfolio,
                    acquisition: values.acquisition,
                    numberOfEngines: values.numberOfEngines,
                    engineManAndModel: values.engineManAndModel,
                    engineState: values.engineState,
                    isBMU: (values.isBMU === undefined ? false : values.isBMU),
                    generationType: values.generationType,
                    capabilityKW: values.capabilityKW,
                    postcode: values.postcode,
                    conradRegion: values.conradRegion,
                    latitude: values.latitude,
                    longitude: values.longitude,
                    address: values.address,

                    dailyRestrictions: values.dailyRestrictions,
                    earliestStart: values.earliestStart,
                    latestEnd: values.latestEnd,
                    yearlyRestrictions: values.yearlyRestrictions,

                    BMID: values.BMID,
                    MEL: values.MEL,
                    HHV: values.HHV,
                    minOutputPerc: values.minOutputPerc,
                    minOutputMW: values.minOutputMW,
                    NDZ: values.NDZ,
                    timeToFullPower: values.timeToFullPower,
                    RURE: values.RURE,
                    minTimeOn: values.minTimeOn,
                    maxTimeOn: values.maxTimeOn,
                    rampDown: values.rampDown,
                    rampDownRate: values.rampDownRate,
                    MZT: values.MZT,
                    maxStartsPerDay: values.maxStartsPerDay,

                    gasDistributionNetwork: values.gasDistributionNetwork,
                    gasOfftakePressure: values.gasOfftakePressure,
                    gasInitialSOQ: values.gasInitialSOQ,
                    gasMeterNumber: values.gasMeterNumber,
                    gasMeterOperator: values.gasMeterOperator,

                    networkOperator: values.networkOperator,
                    connectionVoltage: values.connectionVoltage,
                    exportMPAN: values.exportMPAN,
                    importMPAN: values.importMPAN,
                    meterOperator: values.meterOperator
                } as IDetailedGasAsset;
                handleSave(model, isNewAttribute);
            }
            handleClose();
        },
    });

    useEffect(() => {
        setIsNewAttribute(attribute?.siteID == null ? true : false);
    }, [attribute]);

    const modalTitleText = (): string => {
        return isNewAttribute ? "New Gas Asset" : "Edit Existing Asset";
    };

    return (
        <Dialog
            fullWidth={true}
            maxWidth="md"
            open={attribute ? true : false}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <form onSubmit={formik.handleSubmit}>
                <DialogTitle id="alert-dialog-title">
                    {modalTitleText()}
                </DialogTitle>
                <DialogContent>
                    <Stack direction="column" height={"65vh"} padding={3} spacing={2} sx={{ overflowY: "auto" }}>
                        <Typography variant="h6">General:</Typography>
                        <TextField label="Site ID" variant="outlined" id="siteID" value={formik.values.siteID || ""} onChange={formik.handleChange} error={formik.touched.siteID
                            && Boolean(formik.errors.siteID)} helperText={formik.touched.siteID && formik.errors.siteID} disabled={!isNewAttribute} />
                        <TextField label="Name" variant="outlined" id="name" value={formik.values.name || ""} onChange={formik.handleChange} error={formik.touched.name
                            && Boolean(formik.errors.name)} helperText={formik.touched.name && formik.errors.name} />
                        <TextField label="iON Name" variant="outlined" id="iONName" value={formik.values.iONName || ""} onChange={formik.handleChange} error={formik.touched.iONName
                            && Boolean(formik.errors.iONName)} helperText={formik.touched.iONName && formik.errors.iONName} />
                        <TextField label="Construction Stage" variant="outlined" id="constructionStage" value={formik.values.constructionStage || ""} onChange={formik.handleChange} error={formik.touched.constructionStage
                            && Boolean(formik.errors.constructionStage)} helperText={formik.touched.constructionStage && formik.errors.constructionStage} />
                        <TextField label="Portfolio" variant="outlined" id="portfolio" value={formik.values.portfolio || ""} onChange={formik.handleChange} error={formik.touched.portfolio
                            && Boolean(formik.errors.portfolio)} helperText={formik.touched.portfolio && formik.errors.portfolio} />
                        <TextField label="Acquisition" variant="outlined" id="acquisition" value={formik.values.acquisition || ""} onChange={formik.handleChange} error={formik.touched.acquisition
                            && Boolean(formik.errors.acquisition)} helperText={formik.touched.acquisition && formik.errors.acquisition} />
                        <TextField label="Number Of Engines" variant="outlined" id="numberOfEngines" value={formik.values.numberOfEngines || ""} onChange={formik.handleChange} error={formik.touched.numberOfEngines
                            && Boolean(formik.errors.numberOfEngines)} helperText={formik.touched.numberOfEngines && formik.errors.numberOfEngines} />
                        <TextField label="Engine Man/Model" variant="outlined" id="engineManAndModel" value={formik.values.engineManAndModel || ""} onChange={formik.handleChange} error={formik.touched.engineManAndModel
                            && Boolean(formik.errors.engineManAndModel)} helperText={formik.touched.engineManAndModel && formik.errors.engineManAndModel} />
                        <TextField label="Engine State" variant="outlined" id="engineState" value={formik.values.engineState || ""} onChange={formik.handleChange} error={formik.touched.engineState
                            && Boolean(formik.errors.engineState)} helperText={formik.touched.engineState && formik.errors.engineState} />
                        <FormControlLabel control={<Checkbox id="isBMU" checked={formik.values.isBMU} onChange={() => formik.setFieldValue("isBMU", !formik.values.isBMU)} />} label="BMU?" />
                        <TextField label="Generation Type" variant="outlined" id="generationType" value={formik.values.generationType || ""} onChange={formik.handleChange} error={formik.touched.generationType
                            && Boolean(formik.errors.generationType)} helperText={formik.touched.generationType && formik.errors.generationType} disabled={true}/>
                        <TextField label="Capacity (KW)" variant="outlined" id="capabilityKW" value={formik.values.capabilityKW || ""} onChange={formik.handleChange} error={formik.touched.capabilityKW
                            && Boolean(formik.errors.capabilityKW)} helperText={formik.touched.capabilityKW && formik.errors.capabilityKW} />
                        <TextField label="Postcode" variant="outlined" id="postcode" value={formik.values.postcode || ""} onChange={formik.handleChange} error={formik.touched.postcode
                            && Boolean(formik.errors.postcode)} helperText={formik.touched.postcode && formik.errors.postcode} />
                        <TextField label="Area" variant="outlined" id="conradRegion" value={formik.values.conradRegion || ""} onChange={formik.handleChange} error={formik.touched.conradRegion
                            && Boolean(formik.errors.conradRegion)} helperText={formik.touched.conradRegion && formik.errors.conradRegion} />
                        <TextField label="Latitude" variant="outlined" id="latitude" value={formik.values.latitude || ""} onChange={formik.handleChange} error={formik.touched.latitude
                            && Boolean(formik.errors.latitude)} helperText={formik.touched.latitude && formik.errors.latitude} />
                        <TextField label="Longitude" variant="outlined" id="longitude" value={formik.values.longitude || ""} onChange={formik.handleChange} error={formik.touched.longitude
                            && Boolean(formik.errors.longitude)} helperText={formik.touched.longitude && formik.errors.longitude} />
                        <TextField label="Address" variant="outlined" id="address" value={formik.values.address || ""} onChange={formik.handleChange} error={formik.touched.address
                            && Boolean(formik.errors.address)} helperText={formik.touched.address && formik.errors.address} />

                        <Typography variant="h6">Environment/Planning Parameters:</Typography>
                        <TextField label="Daily Restrictions" variant="outlined" id="dailyRestrictions" value={formik.values.dailyRestrictions || ""} onChange={formik.handleChange} error={formik.touched.dailyRestrictions
                            && Boolean(formik.errors.dailyRestrictions)} helperText={formik.touched.dailyRestrictions && formik.errors.dailyRestrictions} />
                        <TextField label="Earliest Start" variant="outlined" id="earliestStart" value={formik.values.earliestStart || ""} onChange={formik.handleChange} error={formik.touched.earliestStart
                            && Boolean(formik.errors.earliestStart)} helperText={formik.touched.earliestStart && formik.errors.earliestStart} />
                        <TextField label="Latest End" variant="outlined" id="latestEnd" value={formik.values.latestEnd || ""} onChange={formik.handleChange} error={formik.touched.latestEnd
                            && Boolean(formik.errors.latestEnd)} helperText={formik.touched.latestEnd && formik.errors.latestEnd} />
                        <TextField label="Yearly Restrictions" variant="outlined" id="yearlyRestrictions" value={formik.values.yearlyRestrictions || ""} onChange={formik.handleChange} error={formik.touched.yearlyRestrictions
                            && Boolean(formik.errors.yearlyRestrictions)} helperText={formik.touched.yearlyRestrictions && formik.errors.yearlyRestrictions} />

                        <Typography variant="h6">Operational and Balancing:</Typography>
                        <TextField label="BMID" variant="outlined" id="BMID" value={formik.values.BMID || ""} onChange={formik.handleChange} error={formik.touched.BMID
                            && Boolean(formik.errors.BMID)} helperText={formik.touched.BMID && formik.errors.BMID} />
                        <TextField label="MEL" variant="outlined" id="MEL" value={formik.values.MEL || ""} onChange={formik.handleChange} error={formik.touched.MEL
                            && Boolean(formik.errors.MEL)} helperText={formik.touched.MEL && formik.errors.MEL} />
                        <TextField label="HHV" variant="outlined" id="HHV" value={formik.values.HHV || ""} onChange={formik.handleChange} error={formik.touched.HHV
                            && Boolean(formik.errors.HHV)} helperText={formik.touched.HHV && formik.errors.HHV} />
                        <TextField label="Min Output (%)" variant="outlined" id="minOutputPerc" value={formik.values.minOutputPerc || ""} onChange={formik.handleChange} error={formik.touched.minOutputPerc
                            && Boolean(formik.errors.minOutputPerc)} helperText={formik.touched.minOutputPerc && formik.errors.minOutputPerc} />
                        <TextField label="Min Output (MW)" variant="outlined" id="minOutputMW" value={formik.values.minOutputMW || ""} onChange={formik.handleChange} error={formik.touched.minOutputMW
                            && Boolean(formik.errors.minOutputMW)} helperText={formik.touched.minOutputMW && formik.errors.minOutputMW} />
                        <TextField label="NDZ" variant="outlined" id="NDZ" value={formik.values.NDZ || ""} onChange={formik.handleChange} error={formik.touched.NDZ
                            && Boolean(formik.errors.NDZ)} helperText={formik.touched.NDZ && formik.errors.NDZ} />
                        <TextField label="Time to Full Power" variant="outlined" id="timeToFullPower" value={formik.values.timeToFullPower || ""} onChange={formik.handleChange} error={formik.touched.timeToFullPower
                            && Boolean(formik.errors.timeToFullPower)} helperText={formik.touched.timeToFullPower && formik.errors.timeToFullPower} />
                        <TextField label="RURE" variant="outlined" id="RURE" value={formik.values.RURE || ""} onChange={formik.handleChange} error={formik.touched.RURE
                            && Boolean(formik.errors.RURE)} helperText={formik.touched.RURE && formik.errors.RURE} />
                        <TextField label="Min Time On" variant="outlined" id="minTimeOn" value={formik.values.minTimeOn || ""} onChange={formik.handleChange} error={formik.touched.minTimeOn
                            && Boolean(formik.errors.minTimeOn)} helperText={formik.touched.minTimeOn && formik.errors.minTimeOn} />
                        <TextField label="Max Time On" variant="outlined" id="maxTimeOn" value={formik.values.maxTimeOn || ""} onChange={formik.handleChange} error={formik.touched.maxTimeOn
                            && Boolean(formik.errors.maxTimeOn)} helperText={formik.touched.maxTimeOn && formik.errors.maxTimeOn} />
                        <TextField label="Ramp Down" variant="outlined" id="rampDown" value={formik.values.rampDown || ""} onChange={formik.handleChange} error={formik.touched.rampDown
                            && Boolean(formik.errors.rampDown)} helperText={formik.touched.rampDown && formik.errors.rampDown} />
                        <TextField label="Ramp Down Rate" variant="outlined" id="rampDownRate" value={formik.values.rampDownRate || ""} onChange={formik.handleChange} error={formik.touched.rampDownRate
                            && Boolean(formik.errors.rampDownRate)} helperText={formik.touched.rampDownRate && formik.errors.rampDownRate} />
                        <TextField label="MZT" variant="outlined" id="MZT" value={formik.values.MZT || ""} onChange={formik.handleChange} error={formik.touched.MZT
                            && Boolean(formik.errors.MZT)} helperText={formik.touched.MZT && formik.errors.MZT} />
                        <TextField label="Max Start Per Day" variant="outlined" id="maxStartsPerDay" value={formik.values.maxStartsPerDay || ""} onChange={formik.handleChange} error={formik.touched.maxStartsPerDay
                            && Boolean(formik.errors.maxStartsPerDay)} helperText={formik.touched.maxStartsPerDay && formik.errors.maxStartsPerDay} />

                        <Typography variant="h6">Gas:</Typography>
                        <TextField label="Gas Distribution Network" variant="outlined" id="gasDistributionNetwork" value={formik.values.gasDistributionNetwork || ""} onChange={formik.handleChange} error={formik.touched.gasDistributionNetwork
                            && Boolean(formik.errors.gasDistributionNetwork)} helperText={formik.touched.gasDistributionNetwork && formik.errors.gasDistributionNetwork} />
                        <TextField label="Gas Offtake Pressure" variant="outlined" id="gasOfftakePressure" value={formik.values.gasOfftakePressure || ""} onChange={formik.handleChange} error={formik.touched.gasOfftakePressure
                            && Boolean(formik.errors.gasOfftakePressure)} helperText={formik.touched.gasOfftakePressure && formik.errors.gasOfftakePressure} />
                        <TextField label="Gas Initial SOQ" variant="outlined" id="gasInitialSOQ" value={formik.values.gasInitialSOQ || ""} onChange={formik.handleChange} error={formik.touched.gasInitialSOQ
                            && Boolean(formik.errors.gasInitialSOQ)} helperText={formik.touched.gasInitialSOQ && formik.errors.gasInitialSOQ} />
                        <TextField label="Gas Meter Number" variant="outlined" id="gasMeterNumber" value={formik.values.gasMeterNumber || ""} onChange={formik.handleChange} error={formik.touched.gasMeterNumber
                            && Boolean(formik.errors.gasMeterNumber)} helperText={formik.touched.gasMeterNumber && formik.errors.gasMeterNumber} />
                        <TextField label="Gas Meter Operator" variant="outlined" id="gasMeterOperator" value={formik.values.gasMeterOperator || ""} onChange={formik.handleChange} error={formik.touched.gasMeterOperator
                            && Boolean(formik.errors.gasMeterOperator)} helperText={formik.touched.gasMeterOperator && formik.errors.gasMeterOperator} />

                        <Typography variant="h6">Electricity:</Typography>
                        <TextField label="Network Operator" variant="outlined" id="networkOperator" value={formik.values.networkOperator || ""} onChange={formik.handleChange} error={formik.touched.networkOperator
                            && Boolean(formik.errors.networkOperator)} helperText={formik.touched.networkOperator && formik.errors.networkOperator} />
                        <TextField label="Connection Voltage" variant="outlined" id="connectionVoltage" value={formik.values.connectionVoltage || ""} onChange={formik.handleChange} error={formik.touched.connectionVoltage
                            && Boolean(formik.errors.connectionVoltage)} helperText={formik.touched.connectionVoltage && formik.errors.connectionVoltage} />
                        <TextField label="Export MPAN" variant="outlined" id="exportMPAN" value={formik.values.exportMPAN || ""} onChange={formik.handleChange} error={formik.touched.exportMPAN
                            && Boolean(formik.errors.exportMPAN)} helperText={formik.touched.exportMPAN && formik.errors.exportMPAN} />
                        <TextField label="Import MPAN" variant="outlined" id="importMPAN" value={formik.values.importMPAN || ""} onChange={formik.handleChange} error={formik.touched.importMPAN
                            && Boolean(formik.errors.importMPAN)} helperText={formik.touched.importMPAN && formik.errors.importMPAN} />
                        <TextField label="Meter Operator" variant="outlined" id="meterOperator" value={formik.values.meterOperator || ""} onChange={formik.handleChange} error={formik.touched.meterOperator
                            && Boolean(formik.errors.meterOperator)} helperText={formik.touched.meterOperator && formik.errors.meterOperator} />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button type="submit" autoFocus variant="contained">
                        Save
                    </Button>
                    <Button onClick={handleClose} variant="contained">
                        Cancel
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
};

export interface IDetailedAssetModalForm {
    handleClose: () => void;
    handleSave: (model: IDetailedGasAsset, isNew: boolean) => void;
    attribute: IDetailedGasAsset | null;
}

export default DetailedAssetModalForm;