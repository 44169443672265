import PageWrapper from "../../components/PageWrapper";
import React, { useEffect } from "react";
import FuelTypeChart from "../../components/analytics/FuelTypeChart";
import { Box } from "@mui/material";
import { IPortalRouteOptions } from "../../shared/types/shared/routes/routeTypes";
import { Analytics } from "@mui/icons-material";

const AnalyticsFuelTypePage: React.FC<IAnalyticsFuelTypePage> = () => {
    const [loaded, setLoaded] = React.useState(false);

    const loadComplete = () => {
        setLoaded(true);

    }


    useEffect(() => {
        loadComplete()
    }, [])
    return (
        <PageWrapper title="Fuel Type Generation Chart" loaded={loaded}>
            <Box sx={{ height: "75vh", maxHeight: "700px", width: "74vw" }} >
                <FuelTypeChart />
            </Box>
        </PageWrapper>
    );
}


export interface IAnalyticsFuelTypePage {

}

const AnalyticsFuelTypePageConfig: IPortalRouteOptions = {
    relativeUrl: "fuel",
    page: <AnalyticsFuelTypePage />,
    navDisplay: {
        title: "Fuel",
        icon: <Analytics />
    },
}

export default AnalyticsFuelTypePageConfig;