import React from "react";
import { Box, Paper, Typography } from "@mui/material";
import { Dictionary } from "@reduxjs/toolkit";
import EngineOutageTable from "../EngineOutageTable";
import { IEngineOutage } from "../../../shared/types/operate/IEngineOutage";

const OverlappingOutagesWarning: React.FC<IOverlappingOutagesWarning> = ({ overlappingOutages, siteIdToName }) => {
    return (
        <Box>
            <Paper sx={{ p: 2, mb: 2 }} elevation={2}>
                <Typography variant="h5" color="error">Warning</Typography>
                <Typography variant="body1" color="error" style={{ wordWrap: "break-word" }}>
                    This outage operation overlaps with the following outages.
                    <br></br>
                    Are you sure?
                </Typography>
            </Paper>
            <EngineOutageTable items={overlappingOutages} siteIdToName={siteIdToName}></EngineOutageTable>
        </Box>
    );
}
export interface IOverlappingOutagesWarning {
    overlappingOutages: IEngineOutage[]
    siteIdToName: Dictionary<string>
}

export default OverlappingOutagesWarning;