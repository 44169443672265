import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import React from 'react';
import { ISchedulerElbowPoint } from "../../shared/types/ISchedulerElbowPoint";
import moment from "moment";

const SchedulerElbowPointsTable: React.FC<ISchedulerElbowPointsTableProps> = ({ items, height = 500 }) => {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(100);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <>
            <TableContainer sx={{ height: height-52 }}>
                <Table stickyHeader size="small" aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">Time From</TableCell>
                            <TableCell align="center">Level From</TableCell>
                            <TableCell align="center">Time To</TableCell>
                            <TableCell align="center">Level To</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            items
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((item, index) => {
                                return (
                                    <TableRow key={index}>
                                        <TableCell align="center">{moment(item.timeFrom).utc().format("DD-MMM-YYYY HH:mm:ss")}</TableCell>
                                        <TableCell align="center" sx={{ backgroundColor: item.levelFrom > 0 ? "limegreen" : "" }}>{item.levelFrom}</TableCell>
                                        <TableCell align="center">{moment(item.timeTo).utc().format("DD-MMM-YYYY HH:mm:ss")}</TableCell>   
                                        <TableCell align="center" sx={{ backgroundColor: item.levelTo > 0 ? "limegreen" : "" }}>{item.levelTo}</TableCell>     
                                    </TableRow>
                                )})
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={items.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    );
}

export interface ISchedulerElbowPointsTableProps {
    items: ISchedulerElbowPoint[];
    height?: number;
}

export default SchedulerElbowPointsTable;