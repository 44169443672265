import { Cancel } from "@mui/icons-material";
import { Divider, FormControlLabel, FormGroup, IconButton, Stack, Switch, Typography, useTheme } from "@mui/material";
import { CSSProperties, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../shared/hooks/StateHook";
import {
    updateEngineersLayer, updateOpsAreasLayer, updateSiteLayer, updatePresentationMode, updatePowerInterconnectorsLayer
} from "../../../shared/state/slices/MapSettingsSlice";

const LayerMenu: React.FC<ILayerMenuProps> = () => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const { sites, engineers, opsAreas, presentationMode, powerInterconnectors } = useAppSelector((state) => state.mapSettings);
    const dispatch = useAppDispatch();
    const theme = useTheme();

    const menuContainer: CSSProperties = {
        position: "absolute",
        top: "50%",
        backgroundColor: theme.palette.conradEnergyGrey.dark,
        opacity: "0.7",
        borderTopRightRadius: "5%",
        borderBottomRightRadius: "5%"
    }

    const menuStyle: CSSProperties = {
        width: 40,
        height: 220,
        padding: 7,
        cursor: "pointer"
    };

    const menuTextStyle: CSSProperties = {
        writingMode: "vertical-lr",
        textOrientation: "upright",
        textAlign: "center",
        height: "100%",
    };

    const menuTextOpenStyle: CSSProperties = {
        textAlign: "center",
    };

    const menuOpenStyle: CSSProperties = {
        width: 250,
        height: 250,
        padding: 10,
        transition: "ease-out",
        transitionDuration: "200ms",
    };

    const closeButtonStyle: CSSProperties = {
        position: "absolute",
        top: "0",
        right: "0",       
    };

    const handleOpen = () => {
        if (!isOpen) {
            setIsOpen(true);
        }
    }

    const handleClose = () => {
        setIsOpen(false);
    }

    const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>, type: switchType) => {
        switch (type) {
            case "sites":
                return dispatch(updateSiteLayer(event.target.checked));
            case "engineers":
                return dispatch(updateEngineersLayer(event.target.checked));
            case "opsAreas":
                return dispatch(updateOpsAreasLayer(event.target.checked));
            case "interconnectors":
                return dispatch(updatePowerInterconnectorsLayer(event.target.checked));
            case "presentationMode":
                return dispatch(updatePresentationMode(event.target.checked));
        }
    };

    return (
        <div style={menuContainer}>
            <div style={isOpen ? menuOpenStyle : menuStyle} onClick={handleOpen}>
                <Typography variant="subtitle2" style={isOpen ? menuTextOpenStyle : menuTextStyle}>Menu</Typography>
                {isOpen && <IconButton style={closeButtonStyle} title="Close" onClick={handleClose}>
                    <Cancel />
                </IconButton>}
                {isOpen && <Stack>
                    <FormGroup>
                        <FormControlLabel control={<Switch color="success" checked={sites} onChange={(e) => handleSwitchChange(e, "sites")} />} label="Sites" />
                        <FormControlLabel control={<Switch color="success" checked={engineers} onChange={(e) => handleSwitchChange(e, "engineers")} />} label="Engineers" />
                        <FormControlLabel control={<Switch color="success" checked={opsAreas} onChange={(e) => handleSwitchChange(e, "opsAreas")} />} label="Ops Areas" />
                        <FormControlLabel control={<Switch color="success" checked={powerInterconnectors} onChange={(e) => handleSwitchChange(e, "interconnectors")} />} label="Power Interconnectors" />
                        <Divider />
                        <FormControlLabel control={<Switch color="primary" checked={presentationMode} onChange={(e) => handleSwitchChange(e, "presentationMode")} />} label="Presentation Mode" />
                    </FormGroup>
                </Stack>}
            </div>
        </div>
    )
};


interface ILayerMenuProps {

}

type switchType = "sites" | "engineers" | "opsAreas" | "interconnectors" | "presentationMode";

export default LayerMenu;