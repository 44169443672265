import { Box, Paper, Stack, TextField } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment, { Moment } from "moment";
import { useEffect, useState } from "react";
import BoxWithTitle from "../../../components/BoxWithTitle";
import HistoricalSubmissionGrid from "../../../components/gas-reconciliation/HistoricalHubmissionsGrid";
import SubmissionDetailsGrid from "../../../components/gas-reconciliation/SubmissionDetailsGrid";
import { IPortalRouteOptions } from "../../../shared/types/shared/routes/routeTypes";
import { ReceiptLong } from "@mui/icons-material";
import PageWrapper from "../../../components/PageWrapper";
import { useAppSelector } from "../../../shared/hooks/StateHook";
import useHistoricalSubmissions from "../../../shared/hooks/gas-reconciliation/HistoricalSubmissionsHook";
import { isMobile } from "react-device-detect";

const GasReconciliationHistoricalSubmissionsPage = () => {
    const [startDate, setStartDate] = useState<Moment>(moment());
    const [endDate, setEndDate] = useState<Moment>(moment());
    const { selectedDetails } = useAppSelector((state) => state.historicalSubmissions);
    const { isDataBeingRefreshed, selectSubmission, downloadCSVFile } = useHistoricalSubmissions(startDate, endDate);
    const [loaded, setLoaded] = useState(false);

    const loadComplete = () => {
        setLoaded(true);
    }

    setTimeout(loadComplete, 500);

    const handleStartDateChange = (value: Moment | null) => {
        if (!isDataBeingRefreshed) {
            if (value != null) {
                setStartDate(value);
            }
        }
    };

    const handleEndDateChange = (value: Moment | null) => {
        if (!isDataBeingRefreshed) {
            if (value != null) {
                setEndDate(value);
            }
        }
    };

    return (
        <PageWrapper title="Historical Submissions" loaded={loaded}>
            <Paper sx={{ p: 2 }}>
                <Stack direction="column" spacing={3}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <Grid
                            container
                            alignItems="center"
                            alignContent="start"
                            spacing={2}
                            justifyContent="flex-start">
                            <Grid xs={isMobile ? 6 : 2}>
                                <Box>
                                    <DatePicker
                                        label="Start Date"
                                        inputFormat="DD/MM/YYYY"
                                        value={startDate}
                                        onChange={handleStartDateChange}
                                        disabled={isDataBeingRefreshed}
                                        renderInput={(params) => <TextField {...params} fullWidth/>}
                                    />
                                </Box>
                            </Grid>
                            <Grid xs={isMobile ? 6 : 2}>
                                <Box width="100%">
                                    <DatePicker
                                        label="End Date"
                                        inputFormat="DD/MM/YYYY"
                                        value={endDate}
                                        onChange={handleEndDateChange}
                                        disabled={isDataBeingRefreshed}
                                        renderInput={(params) => <TextField {...params} fullWidth/>}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </LocalizationProvider>

                    <BoxWithTitle title="Previous Trade Submissions">
                        <HistoricalSubmissionGrid selectSubmissionHandler={selectSubmission} downloadCSVFileHandler={downloadCSVFile} />
                    </BoxWithTitle>

                    {selectedDetails &&
                        <BoxWithTitle title="Asset Data Upon Submission">
                            <SubmissionDetailsGrid />
                        </BoxWithTitle>
                    }
                </Stack>
            </Paper>
        </PageWrapper>
    )
}

const GasReconciliationHistoricalSubmissionsPageConfig: IPortalRouteOptions = {
    relativeUrl: "historicalsubmissions",
    page: <GasReconciliationHistoricalSubmissionsPage />,
    navDisplay: {
        title: "Historical Submissions",
        icon: <ReceiptLong />
    }
}

export default GasReconciliationHistoricalSubmissionsPageConfig;
