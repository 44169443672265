import { Sync } from "@mui/icons-material";
import { Grid } from "@mui/material";
import React, { useCallback, useEffect } from "react";
import PageWrapper from "../../../components/PageWrapper";
import SiteMetadataAttributesGrid from "../../../components/system-admin/site-metadata-caching/SiteMetadataAttributesGrid";
import { useGetAttributeSettingsQuery } from "../../../shared/api/MetadataApi";
import { SystemAdministrationPermissions } from "../../../shared/types/shared/auth/permissionsTypes";
import { IPortalRouteOptions } from "../../../shared/types/shared/routes/routeTypes";

const SiteMetadataCachingPage: React.FC<ISiteMetadataCachingPage> = () => {
    const { data } = useGetAttributeSettingsQuery();
    const [loaded, setLoaded] = React.useState(false);
   
    const loadComplete = useCallback(async () => {
        setLoaded(true);
    }, [])

    useEffect(() => {
        loadComplete()
    }, [loadComplete])

    return (
        <PageWrapper title="Site Metadata Caching" loaded={loaded}>
            <SiteMetadataAttributesGrid settings={data ?? []} />
        </PageWrapper>
    );
}

export interface ISiteMetadataCachingPage {

}

const SiteMetadataCachingPageConfig: IPortalRouteOptions = {
    relativeUrl: "site-metadata-caching",
    page: <SiteMetadataCachingPage />,
    navDisplay: {
        title: "Site Metadata Caching",
        icon: <Sync />,
    },
}


export default SiteMetadataCachingPageConfig;