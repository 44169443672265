import { useCallback, useEffect, useMemo, useState } from 'react';
import { useGetAssetStatusQuery, useGetEngineStatusQuery } from '../../shared/api/AssetApi';
import { IPortalRouteOptions } from "../../shared/types/shared/routes/routeTypes";
import { Hive } from '@mui/icons-material';
import { useToastAlertContext } from '../../components/ToastAlert';
import { Grid } from '@mui/material';
import HexContainer from '../../components/shared/hex-grid/HexContainer';
import { generateEngineHiveTiles, processEngineStatusData } from '../../shared/utils/EngineHiveUtils';
import { IHexGridConfig, IHiveDimensions, getCurrentDimension } from '../../shared/utils/HexGridUtils';
import { DispatchPlan, EngineStatusCollection, EngineStatusPlacementCollection } from '../../shared/types/operate/engine-hive';

const EngineStatusPage: React.FC = () => {
  const { popToast } = useToastAlertContext();

  useEffect(() => { // Disable scroll on load
    document.body.style.overflow = 'hidden';

    // Reenable scroll on unload
    return () => {
      document.body.style.overflow = 'unset';
    }
  }, []);

  const getGridConfig = useCallback((thisWindow: Window): IHexGridConfig => {
    return {
      heightOffset: -64,
      panelWidth: thisWindow.innerWidth / 3,
      numberOfRowsWidescreen: 17,
      numberOfRowsUltraWide: 15,
    }
  }, []);

  // Configure API calls
  const pollingInterval = useMemo(() => 60 * 1000, []);
  const { data: rawEngineData, isSuccess: enginesSucceeded, isLoading: enginesLoading } = useGetEngineStatusQuery(undefined, { pollingInterval });
  const [loaded, setLoaded] = useState(false);

  // State
  // const [statusList, setStatusList] = useState<StatusHiveSiteData[]>([]);
  const [engineStatuses, setEngineStatuses] = useState<EngineStatusCollection>();
  const [dimensions, setDimensions] = useState<IHiveDimensions>(getCurrentDimension(getGridConfig(window))); // Dimensions
  const [dataTiles, setDataTiles] = useState<EngineStatusPlacementCollection>(); // The tiles to place in the grid
  const [firstPass, setFirstPass] = useState(false);

  useEffect(() => { // When window resizes, update all dimensions values
    const updateDimension = () => {
      const newDimensions = getCurrentDimension(getGridConfig(window));
      setDimensions(newDimensions);
    }

    window.addEventListener('resize', updateDimension);

    return (() => {
      window.removeEventListener('resize', updateDimension);
    });
  }, [getGridConfig]);

  const updateDataTiles = (newDimensions: IHiveDimensions, engineStatuses?: EngineStatusCollection) => {
    if (!engineStatuses) { return; }

    const result = generateEngineHiveTiles(engineStatuses, newDimensions);
    setDataTiles(result);

    const failed = [
      ...result[DispatchPlan.Current].failed || [],
      ...result[DispatchPlan.Upcoming].failed || [],
      ...result[DispatchPlan.None].failed || [],
    ]

    if (failed.length > 0) {
      popToast(`Unable to place ${failed.length} tiles`, "warning", 2500);
    }

    setFirstPass(true);
  };

  useEffect(() => { // Track when loading is complete
    if (!enginesLoading && enginesSucceeded) {
      setLoaded(true);
    }
  }, [enginesLoading, enginesSucceeded]);

  useEffect(() => { // When raw engine data changes
    const processedData = processEngineStatusData(rawEngineData);
    setEngineStatuses(processedData);
  }, [rawEngineData]);

  useEffect(() => { // If data or dimensions change, update tiles
    updateDataTiles(dimensions, engineStatuses);
  }, [engineStatuses, dimensions]);

  if (!firstPass) { // Gives the grid an opportunity to have calculated at least once before displaying anything
    return (<></>)
  }

  return (
    <Grid container spacing={0}>
      <Grid item xs={4}>
        <HexContainer dimensions={dimensions}>
          {loaded && dataTiles && dataTiles[DispatchPlan.Current].placed?.map((tile) => tile.graphic())}
        </HexContainer>
      </Grid>
      <Grid item xs={4}>
        <HexContainer dimensions={dimensions}>
          {loaded && dataTiles && dataTiles[DispatchPlan.Upcoming].placed?.map((tile) => tile.graphic())}
        </HexContainer>
      </Grid>
      <Grid item xs={4}>
        <HexContainer dimensions={dimensions}>
          {loaded && dataTiles && dataTiles[DispatchPlan.None].placed?.map((tile) => tile.graphic())}
        </HexContainer>
      </Grid>
    </Grid>
  );
}

const EngineStatusPageConfig: IPortalRouteOptions = {
    relativeUrl: "engine-status",
    navDisplay: {
        title: "Engine Status Hive",
        icon: <Hive />
    },
    page: {
        element: <EngineStatusPage />,
        disableGutters: true,
        forceOverlayNav: true,
    },
    hideOnMobile: true
}

export default EngineStatusPageConfig;
