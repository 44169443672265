import React from "react";
import { Badge, IconButton } from "@mui/material";
import { Notifications } from '@mui/icons-material';
import useNotificationMenu from "../../shared/hooks/NotificationMenuHook";

const NotificationBell: React.FC<INotificationBell> = () => {
    const { toggleDrawer, unseenNotifications } = useNotificationMenu()

    const openDrawer = () => {
        toggleDrawer(true)
    }

    return (
        <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={openDrawer}
        >
            <Badge badgeContent={unseenNotifications} color={"error" }>
                <Notifications />
            </Badge>
        </IconButton>
  );
}
export interface INotificationBell {
}
export default NotificationBell;