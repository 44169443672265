import { IAnalyticsSrmc } from "../../shared/types/IAnalyticsSrmc"
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import React from 'react';
import moment from "moment";
import { useTheme } from "@mui/material";

const SrmcTable: React.FC<ISrmcTableProps> = ({ items, height = 400 }) => {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(100);
    const theme = useTheme();

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    if (items === undefined) {
        items = []
    };

    return (
        <Paper sx={{ overflow: 'hidden', height, width: "100%" }} elevation={0}>
            <TableContainer sx={{ width: "100%", height: `calc(${height} - 50px)` }}>
                <Table stickyHeader size="small" aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">Site Id</TableCell>
                            <TableCell align="center">Settlement Date</TableCell>
                            <TableCell align="center">Settlement Period</TableCell>
                            <TableCell align="center">SRMC</TableCell>
                            <TableCell align="center">DLM</TableCell>
                            <TableCell align="center">TLM</TableCell>
                            <TableCell align="center">GDUOS Rate</TableCell>
                            <TableCell align="center">BSUOS Rate</TableCell>
                            <TableCell align="center">Hurdle</TableCell>
                            <TableCell align="center">VOM</TableCell>
                            <TableCell align="center">Efficiency</TableCell>
                            <TableCell align="center">Variable Gas</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            items
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((item) => {
                                    const settlementDate = moment(item.settlementDate).format("YYYY-MM-DD");
                                    return (
                                        <TableRow key={`${item.siteId}-${settlementDate}-${item.settlementPeriod}`}>
                                            <TableCell align="center">{item.siteId}</TableCell>
                                            <TableCell align="center">{settlementDate}</TableCell>
                                            <TableCell align="center">{item.settlementPeriod}</TableCell>
                                            <TableCell align="center" sx={{ backgroundColor: theme.palette.success.dark }}>{item.srmc.toFixed(2)}</TableCell>
                                            <TableCell align="center">{item.dlm}</TableCell>
                                            <TableCell align="center">{item.tlm}</TableCell>
                                            <TableCell align="center">{item.gduosRate}</TableCell>
                                            <TableCell align="center">{item.bsuosRate}</TableCell>
                                            <TableCell align="center">{item.hurdle}</TableCell>
                                            <TableCell align="center">{item.vom}</TableCell>
                                            <TableCell align="center">{item.efficiency}</TableCell>
                                            <TableCell align="center">{item.variableGas.toFixed(2)}</TableCell>
                                        </TableRow>
                                    )
                                })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={items.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </ Paper>
    );
}

export interface ISrmcTableProps {
    items?: IAnalyticsSrmc[];
    height?: number | string;
}

export default SrmcTable;