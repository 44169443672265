import React, { useEffect, useState } from "react";
import { Box, Button, Drawer, List, ListItem, Stack, Typography } from "@mui/material";
import CustomNotificationBody from "./CustomNotificationBody"
import useNotificationMenu from "../../shared/hooks/NotificationMenuHook";
import { IPushNotification } from "../../shared/types/notification/IPushNotification";
import { useSwipeable } from "react-swipeable";


const NotificationHistory: React.FC<INotificationHistory> = () => {
    const { expanded, notifications, toggleDrawer, markAllNotificationsAsRead } = useNotificationMenu()
    const swipeHandler = useSwipeable({
        onSwiped: (eventData) => {
            if (eventData.event.cancelable) {
                if (eventData.dir === "Right") {
                    closeDrawer()
                }
            }
        }
    });

    const closeDrawer = () => {
        toggleDrawer(false)
    }
    const [displayedNotifications, setDisplayedNotifications] = useState<IPushNotification[]>([])
    const [notificationsDisplayed, setNotificationsDisplayed] = useState<number>(50)
    const [displayedAllNotifications, setDisplayedAllNotifications] = useState<boolean>(false)

    const loadMoreClick = () => {
        setNotificationsDisplayed(notificationsDisplayed + 50)
    }

    useEffect(() => {
        setDisplayedNotifications(notifications.filter((notification) => !notification.deleted).slice(0, notificationsDisplayed))
    }, [notifications, notificationsDisplayed])

    useEffect(() => {
        setDisplayedAllNotifications(notifications.filter((notification) => !notification.deleted).length <= displayedNotifications.length)
    }, [notifications, displayedNotifications])

    return (
            <Drawer
                anchor={"right"}
                open={expanded}
                onClose={closeDrawer}
                sx={{ "zIndex ": "1201" }}
                {...swipeHandler }
            >
                <Box
                    sx={{ width: 300 }}
                    role="presentation"
                    style={{ }}
                >
                    {(displayedNotifications.length === 0 ?
                        <Stack
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            sx={{ width: 1, height: "100vh" }}
                        >
                            <Typography>No Notifications</Typography>
                        </Stack>
                        : 
                        <List>
                            <ListItem key={"MarkAllAsRead"}>
                                <Button sx={{ width: "100%" }} variant="contained" onClick={markAllNotificationsAsRead}>Mark All As Read</Button>
                            </ListItem>
                            {
                                displayedNotifications.map((notification, index) => 
                                    <ListItem key={notification.notificationID} hidden={notification.deleted}>
                                        <CustomNotificationBody notification={notification} />
                                    </ListItem>
                                )
                            }

                            {!displayedAllNotifications && 
                                <ListItem key={"LoadMoreNotifications"}>
                                    <Button sx={{ width: "100%" }} variant="contained" onClick={loadMoreClick}>Load More</Button>
                                </ListItem>
                            }
                        </List>
                    )}
                </Box>
            </Drawer>
  );
}
export interface INotificationHistory {
}
export default NotificationHistory;