import { HexGrid, Layout, Pattern } from "react-hexgrid";
import hexHelper from '../../../images/hive-images/hex-helper.png';
import honeycomb from '../../../images/hive-images/honeycomb.svg';
import hatching from '../../../images/hive-images/diagonal-hatching.svg';
import repeatingHex from '../../../images/hive-images/repeating-hex.svg';
import { PropsWithChildren } from "react";
import { IHiveDimensions } from "../../../shared/utils/HexGridUtils";

const HexContainer: React.FC<PropsWithChildren<IHexContainerProps>> = ({ dimensions, children }) => {

    const { width: screenWidth, height: screenHeight, viewBoxWidth, viewBoxHeight } = dimensions.window;
    const { tileRadius, tileSize, spacing, gridOriginX, gridOriginY } = dimensions.grid;

    return (
        <HexGrid width={screenWidth} height={screenHeight} viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`} fontSize={tileSize} letterSpacing={"0.01ex"}>
            <Layout size={{ x: tileRadius, y: tileRadius }} flat={false} spacing={1 + spacing} origin={{ x: gridOriginX, y: gridOriginY }}>
                <>
                    {children}
                    <Pattern size={{ x: tileRadius, y: tileRadius }} id="alignment" link={hexHelper} />
                    <Pattern size={{ x: tileRadius, y: tileRadius }} id="honeycomb" link={honeycomb} />
                    <Pattern size={{ x: tileRadius, y: tileRadius }} id="hatching" link={hatching} />
                    <Pattern size={{ x: tileRadius, y: tileRadius }} id="hex-background" link={repeatingHex} />
                </>
            </Layout>
        </HexGrid >
    );
}

export interface IHexContainerProps {
    dimensions: IHiveDimensions;
}

export default HexContainer;
