import { Button, Card, Divider, Grid, Stack, Tab, Typography } from "@mui/material";
import React, { useCallback, useEffect } from "react";
import PageWrapper from "../../../components/PageWrapper";

import tradeProcessorApi from "../../../shared/api/TradeProcessorApi";

import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { Dictionary } from "@reduxjs/toolkit";

import { useToastAlertContext } from '../../../components/ToastAlert';
import { useGetAssetsQuery } from "../../../shared/api/AssetApi";
import { BatteryCommercialOverviewUrl, GasCommercialOverviewUrl } from "../../../shared/utils/DispathWebUtils";
import { IPortalRouteOptions } from "../../../shared/types/shared/routes/routeTypes";
import { House } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { BrowserView, MobileView } from "react-device-detect";
import { TabContext, TabList, TabPanel } from "@mui/lab";

const AssetsPage: React.FC<ISchedulerPageProps> = () => {
    const { popToast } = useToastAlertContext();
    const navigate = useNavigate();
    const [loaded, setLoaded] = React.useState(false);
    const { data: assets } = useGetAssetsQuery();

    const [siteIdToName, setSiteIdToName] = React.useState<Dictionary<string>>({})
    const [schedulerBMUs, setSchedulerBMUs] = React.useState<Dictionary<string>>({})
    const [schedulerGasAssets, setGasAssets] = React.useState<string[]>([])
    const [schedulerBatteryAssets, setBatteryAssets] = React.useState<string[]>([])
    const [selectedOption, setSelectedOption] = React.useState<string>("GasBMU")

    const loadComplete = () => {
        setLoaded(true);
    }

    const getSiteNameFromId = (id: string): string => {
        return siteIdToName[id] || "";
    }

    const fetchAssets = useCallback(() => {
        var dict: Dictionary<string> = {}
        assets?.forEach((item) => dict[item.SiteID] = item.Name);
        setSiteIdToName(dict);

        const getBatteryAssets =
            tradeProcessorApi.getBatteryAssets()
                .then(setBatteryAssets)
                .catch(error => { throw new Error("Unable to get Battery Assets: " + error); });

        const getBMUS =
            tradeProcessorApi.getBMUs()
                .then(setSchedulerBMUs)
                .catch(error => { throw new Error("Unable to get BMU List: " + error); });

        const getAssets =
            tradeProcessorApi.getAssets()
                .then(setGasAssets)
                .catch(error => { throw new Error("Unable to get Gas Assets: " + error); })

        const promises = [getBatteryAssets, getBMUS, getAssets];
        Promise.all(promises).catch((error: Error) => popToast(error.message, "error")).finally(loadComplete);
    }, [popToast, assets]);

    useEffect(() => {
        fetchAssets()
    }, [fetchAssets]);

    const handleMobilePanelChange = (event: any, newValue: string) => {
        setSelectedOption(newValue);
    };

    const GasAssetsBMU = () => {
        return (
            <List sx={{
                overflow: 'auto',
                height: '100%',
                '& ul': { padding: 0 },
            }} dense={true}>
                {schedulerGasAssets.filter(item => (schedulerBMUs[item] !== undefined)).map((item, index) => {
                    const url = GasCommercialOverviewUrl(siteIdToName[item]);
                    return (<ListItemButton key={index} disabled={!url} component="a" href={url} target="_blank">
                        <ListItemText
                            primary={item}
                            secondary={getSiteNameFromId(item) + " (BMU: " + schedulerBMUs[item] + ")"}
                        />
                    </ListItemButton>)
                })}
            </List>
        )
    }

    const GasAssetsNonBMU = () => {
        return (
            <List sx={{
                overflow: 'auto',
                height: '100%',
                '& ul': { padding: 0 },
            }} dense={true}>
                {schedulerGasAssets.filter(item => (schedulerBMUs[item] === undefined)).map((item, index) => {
                    const url = GasCommercialOverviewUrl(siteIdToName[item]);
                    return (<ListItemButton key={index} disabled={!url} component="a" href={url} target="_blank">
                        <ListItemText
                            primary={item}
                            secondary={getSiteNameFromId(item)}
                        />
                    </ListItemButton>)
                })}
            </List>
        )
    }

    const BatteryAssets = () => {
        return (
            <List sx={{
                overflow: 'auto',
                height: '100%',
                '& ul': { padding: 0 },
            }} dense={true}>
                {schedulerBatteryAssets.map((item, index) => {
                    const url = BatteryCommercialOverviewUrl(siteIdToName[item]);
                    return (<ListItemButton key={index} disabled={!url} component="a" href={url} target="_blank">
                        <ListItemText
                            primary={item}
                            secondary={getSiteNameFromId(item)}
                        />
                    </ListItemButton>)
                })}
            </List>
        )
    }

    return (
        <PageWrapper title="Scheduler Assets" loaded={loaded}>
            <BrowserView>
                <Grid container spacing={1}>
                    <Grid item md={3} sm={4} xs={6}>
                        <Card sx={{ width: "100%", height: 375, display: "inline-block" }}>
                            <Stack height="100%">
                                <Typography textAlign="center" sx={{ width: "100%", pt: 2, pb: 2 }}>Gas Assets - BMU</Typography>
                                <Divider />
                                <GasAssetsBMU />
                            </Stack>
                        </Card>
                    </Grid>
                    <Grid item md={3} sm={4} xs={6}>
                        <Card sx={{ width: "100%", height: 375, display: "inline-block" }}>
                            <Stack height="100%">
                                <Typography textAlign="center" sx={{ width: "100%", pt: 2, pb: 2 }}>Gas Assets - Non-BMU</Typography>
                                <Divider />
                                <GasAssetsNonBMU />
                            </Stack>
                        </Card>
                    </Grid>
                    <Grid item md={3} sm={4} xs={6}>
                        <Card sx={{ width: "100%", height: 375, display: "inline-block" }}>
                            <Stack height="100%">
                                <Typography textAlign="center" sx={{ width: "100%", pt: 2, pb: 2 }}>Battery Assets</Typography>
                                <Divider />
                                <BatteryAssets />
                            </Stack>
                        </Card>
                    </Grid>
                </Grid>
            </BrowserView>
            <MobileView>
                <Card sx={{ width: "100%", height: 425, display: "inline-block" }}>
                    <TabContext value={selectedOption} >
                        <TabList onChange={handleMobilePanelChange} aria-label="Mobile Tabs Option" variant="fullWidth">
                            <Tab label="Gas Assets - BMU" value="GasBMU" />
                            <Tab label="Gas Assets - Non-BMU" value="GasNonBMU" />
                            <Tab label="Battery Assets" value="Battery" />
                        </TabList>
                        <TabPanel value="GasBMU" sx={{ padding: "10px 0px 0px 0px" }}>
                            <GasAssetsBMU />
                        </TabPanel>
                        <TabPanel value="GasNonBMU" sx={{ padding: "10px 0px 0px 0px" }}>
                            <GasAssetsNonBMU />
                        </TabPanel>
                        <TabPanel value="Battery" sx={{ padding: "10px 0px 0px 0px" }}>
                            <BatteryAssets />
                        </TabPanel>
                    </TabContext>
                </Card>
            </MobileView>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Button onClick={() => { navigate('/asset/register'); }}>Open Asset Register</Button>
                </Grid>
            </Grid>
        </PageWrapper>
    );
}

export interface ISchedulerPageProps {

}

const AssetsPageConfig: IPortalRouteOptions = {
    relativeUrl: "assets",
    page: <AssetsPage />,
    navDisplay: {
        title: "Assets",
        icon: <House />,
    }
}

export default AssetsPageConfig;
