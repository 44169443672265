export const PermissionsArea = { 
    Optimise: "Optimise", 
    Operate: "Operate",
    Analytics: "Analytics",
    Dispatch: "Dispatch",
    Asset: "Asset",
    Data: "Data",
    SystemAdministration: "SystemAdmin",
    Commercial: "Commercial",
} as const;

export type PermissionsArea = typeof PermissionsArea[keyof typeof PermissionsArea];

export const OptimisePermissions = {
    /**User has no permissions in this area*/
    None: 0,

    // Permissions Here
    Read: 1 << 0,

    /**User has all permissions in this area*/
    AllPermissions: ~(1 << 31),
} as const;

export const OperatePermissions = {
    /**User has no permissions in this area*/
    None: 0,

    // Permissions Here
    Read: 1 << 0,
    Admin: 1 << 1,

    /**User has all permissions in this area*/
    AllPermissions: ~(1 << 31),
} as const;

export const AnalyticsPermissions = {
    /**User has no permissions in this area*/
    None: 0,

    // Permissions Here
    Read: 1 << 0,
    Admin: 1 << 1,

    /**User has all permissions in this area*/
    AllPermissions: ~(1 << 31),
} as const;

export const DispatchPermissions = {
    /**User has no permissions in this area*/
    None: 0,

    // Permissions Here
    Read: 1 << 0,

    /**User has all permissions in this area*/
    AllPermissions: ~(1 << 31),
} as const;

export const AssetPermissions = {
    /**User has no permissions in this area*/
    None: 0,

    // Permissions Here
    Read: 1 << 0,
    Admin: 1 << 1,

    /**User has all permissions in this area*/
    AllPermissions: ~(1 << 31),
} as const;

export const DataPermissions = {
    /**User has no permissions in this area*/
    None: 0,

    // Permissions Here
    Read: 1 << 0,

    /**User has all permissions in this area*/
    AllPermissions: ~(1 << 31),
} as const;

export const SystemAdministrationPermissions = {
    /**User has no permissions in this area*/
    None: 0,

    // Permissions Here
    Read: 1 << 0,
    Admin: 1 << 1,

    /**User has all permissions in this area*/
    AllPermissions: ~(1 << 31),
} as const;

export const CommercialPermissions = {
    /**User has no permissions in this area*/
    None: 0,

    // Permissions Here
    Read: 1 << 0,    

    /**User has all permissions in this area*/
    AllPermissions: ~(1 << 31),
} as const;

export type Permissions = { [id: string]: number };
export type PermissionsCollection = Record<PermissionsArea, Permissions>;

export const AllPermissions: PermissionsCollection = {
    Optimise: OptimisePermissions,
    Operate: OperatePermissions,
    Analytics: AnalyticsPermissions,
    Dispatch: DispatchPermissions,
    Asset: AssetPermissions,
    Data: DataPermissions,
    SystemAdmin: SystemAdministrationPermissions,
    Commercial: CommercialPermissions,
} as const;

export type PermissionsType = number;

export const TestPermissions = {
    None: 0,
    Read: 1 << 0,
    Write: 1 << 1,
    AllPermissions: ~(1 << 31),
}
