import { common } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";
import { conradEnergyCyanColour, conradEnergyDeepBlueColour, conradEnergyFreshGreenColour, conradEnergyGreyColour, conradEnergyLightGreyColour, conradEnergyMagentaColour, conradEnergyYellowColour } from "./themeBrand";

export const ConradThemeLight = createTheme({
    palette: {
        mode: "light",        
        background: {
            default: "#ffffff",
            paper: "#eceff1",            
        },
        primary: {
            main: "#3942FF",
            dark: "#272eb2",
            light: "#6067ff",
        },
        secondary: {
            main: "#00E5FC",
            light: "#33eafc",
            dark: "#00a0b0",
        },
        success: {
            main: conradEnergyFreshGreenColour.main,
            contrastText: conradEnergyFreshGreenColour.contrastText
        },
        warning: {
            main: conradEnergyMagentaColour.main,
            contrastText: conradEnergyMagentaColour.contrastText
        },
        conradEnergyGrey: conradEnergyGreyColour,
        conradEnergyLightGrey: conradEnergyLightGreyColour,
        conradEnergyFreshGreen: conradEnergyFreshGreenColour,
        conradEnergyCyan: conradEnergyCyanColour,
        conradEnergyDeepBlue: conradEnergyDeepBlueColour,
        conradEnergyMagenta: conradEnergyMagentaColour,
        conradEnergyYellow: conradEnergyYellowColour,
    },
    typography: {
        fontFamily: "Manrope, san-serif",        
        allVariants: {
            color: common.black
        },
        button: {
            color: common.white,
        }
    },    
    components: {
        MuiTypography: {
            styleOverrides: {
                root: (_) => ({
                    fontFamily: "Manrope, sans-serif",
                    color: _.theme.palette.common.black,
                }),
            }
        },
        MuiButton: {
            styleOverrides: {
                root: (_) => ({
                    "*": {
                        color: common.white,
                    },
                    color: _.theme.palette.conradEnergyDeepBlue.contrastText,
                    backgroundColor: _.theme.palette.conradEnergyDeepBlue.main,                    
                    ":hover": {
                        backgroundColor: _.theme.palette.conradEnergyDeepBlue.dark,
                    }
                }),
            }
        },
        MuiCircularProgress: {
            styleOverrides: {
                // svg: ({ theme }) => ({
                //     color: theme.palette.text.primary
                // })
            }
        },
        MuiAppBar: {
            styleOverrides: {
                colorDefault: (_) => ({
                    backgroundColor: _.theme.palette.conradEnergyGrey.main
                })
            }
        },
        MuiDrawer: {
            styleOverrides: {
                paper: (_) => ({
                    backgroundColor: _.theme.palette.conradEnergyGrey.light,
                    borderColor: _.theme.palette.conradEnergyGrey.dark
                }),
            }
        },        
        MuiListItemButton: {
            styleOverrides: {
                root: (_) => ({  
                    ":hover": {
                        backgroundColor: _.theme.palette.conradEnergyGrey.dark
                    }
                }),
            }
        },
        //MuiCard: {
        //    styleOverrides: {
        //        root: (_) => ({
        //            backgroundColor: "#E8ECEE",
        //            ":hover": {
        //                "backgroundColor": "#B1C0C8"
        //            }
        //        })
        //    }
        //},
        //MuiPaper: {
        //    styleOverrides: {
        //        root: (_) => ({
        //            backgroundColor: conradEnergyGreyColour[50]
        //        })
        //    }
        //},
        MuiTable: {
            styleOverrides: {                    
                root: (_) => ({                    
                    ".MuiTableCell-head": {
                        backgroundColor: conradEnergyGreyColour.main,
                        color: _.theme.palette.conradEnergyGrey.contrastText,
                    },
                    ".MuiTableRow-root": {
                        backgroundColor: "white",
                        ":hover": {
                            backgroundColor: conradEnergyCyanColour[50]
                        }
                    },
                    
                }),
            }
        },
        //MuiTableRow: {
        //    styleOverrides: {
        //        root: (_) => ({
        //            "& .MuiTableCell-body": {
        //                backgroundColor: conradEnergyGreyColour.main,
        //                color: _.theme.palette.conradEnergyGrey.contrastText,
        //            },
        //        }),
        //    }
        //},
        MuiDataGrid: {
            styleOverrides: {
                root: (_) => ({
                    backgroundColor: conradEnergyGreyColour.main,
                    color: _.theme.palette.conradEnergyGrey.contrastText
                }),
                main: {
                    backgroundColor: "white",
                },
                columnHeaders: (_) => ({
                    //backgroundImage: `linear-gradient(${conradEnergyGreyColour[200]} 45%, ${conradEnergyGreyColour[100]});`,
                    backgroundColor: conradEnergyGreyColour.main,
                    color: _.theme.palette.conradEnergyGrey.contrastText,
                }),
                row: ({ theme }) => ({
                    //":nth-of-type(odd)": {
                    //    backgroundColor: conradEnergyLightGreyColour["100"],
                    //},
                    //":nth-of-type(even)": {
                    //    backgroundColor: conradEnergyLightGreyColour["100"],
                    //},
                    ":hover": {
                        backgroundColor: conradEnergyCyanColour[50],
                    },
                    "&.Mui-selected": {
                        backgroundColor: conradEnergyCyanColour[50],
                        color: "white",
                        "&:hover": {
                            backgroundColor: conradEnergyCyanColour[50]
                        },
                        ".MuiSvgIcon-root": {
                            color: "black",
                        },
                    }
                }),
            },
        },
        MuiGrid: {
            styleOverrides: {

                root: (_) => ({                   
                    "> *": {
                        color: common.black
                    },
                    "& .MuiTableHead-root": {
                        backgroundColor: conradEnergyGreyColour.main,
                    },   
                }),
            }
        },
        MuiGrid2: {
            styleOverrides: {
                root: (_) => ({
                    // margin: 0,
                    "> *": {
                        color: common.black
                    },            
                    ".MuiTableHead-root": {
                        backgroundColor: conradEnergyGreyColour.main,
                        color: _.theme.palette.conradEnergyGrey.contrastText,
                    },
                }),
            }
        },
        MuiTabs: {
            styleOverrides: {
                root: (_) => ({
                    backgroundColor: _.theme.palette.conradEnergyDeepBlue.main,
                    ".MuiTab-root": {
                        color: "white",
                        ":hover": {
                            backgroundColor: _.theme.palette.conradEnergyDeepBlue.light,
                        },
                        "&.Mui-selected": {
                            color: "white",
                            backgroundColor: _.theme.palette.conradEnergyDeepBlue.dark,
                            "svg": {
                                color: "white"
                            }
                        },
                    },
                                        
                })
            }
        },                
    },
});