import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { IGasPosition } from "../../shared/types/gas-reconciliation/gasposition";

const overrideSchema = yup.object().shape({
    override: yup
        .number()
        .integer()
        .required("Override is required")
        .typeError("Must be a number"),
});

interface Props {
    site: IGasPosition | null;
    handleClose: () => void;
    handleApply: (id: number, value: number) => void;
}

const OverrideDialog = ({ site, handleClose, handleApply }: Props) => {
    const formik = useFormik({
        initialValues: {
            override: site?.override,
        },
        validationSchema: overrideSchema,
        onSubmit: (values) => {
            if (site) {
                handleApply(site.id, Number(values.override));
            }
            handleClose();
        },
    });

    return (
        <Dialog
            open={site ? true : false}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <form onSubmit={formik.handleSubmit}>
                <DialogTitle id="alert-dialog-title">
                    Edit Override for {site?.site}
                </DialogTitle>
                <DialogContent>
                    {/* <DialogContentText id="alert-dialog-description">
          
        </DialogContentText> */}

                    <TextField
                        autoFocus
                        margin="dense"
                        id="override"
                        name="override"
                        label="Override"
                        value={formik.values.override}
                        fullWidth
                        onChange={formik.handleChange}
                        error={formik.touched.override && Boolean(formik.errors.override)}
                        helperText={formik.touched.override && formik.errors.override}
                        variant="outlined"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="contained">
                        Cancel
                    </Button>
                    <Button type="submit" autoFocus variant="contained">
                        Apply Override
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default OverrideDialog;
