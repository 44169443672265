import { PortalArea } from "../types/shared/routes/routeHelperClasses";
import ConradOptimiseLogo from '../../images/Conrad_Optimise_Icon.png';
import { IPortalAreaOptions } from "../types/shared/routes/routeTypes";
import { OptimisePermissions } from "../types/shared/auth/permissionsTypes";
import OptimiseHomePageConfig from "../../pages/optimise/OptimiseHomePage";
import FrequencyServicesHomePageConfig from "../../pages/optimise/frequency-services/FrequencyServicesHomePage";
import DynamicContractsPageConfig from "../../pages/optimise/frequency-services/dynamic-contracts/DynamicContractsPage";
import DynamicContractsChartPageConfig from "../../pages/optimise/frequency-services/DynamicContractsChartPage";
import DynamicServicesPageConfig from "../../pages/optimise/frequency-services/DynamicServicesPage";
import GasPricePageConfig from "../../pages/optimise/gas-price/GasPricePage";
import GasReconciliationHomePageConfig from "../../pages/optimise/gas-reconciliation/GasReconciliationHomePage";
import SchedulerPageConfig from "../../pages/optimise/scheduler/SchedulerPage";
import StrikePricePageConfig from "../../pages/optimise/strike-price/StrikePricePage";
import GasReconciliationDailyMeteredPageConfig from "../../pages/optimise/gas-reconciliation/GasReconciliationDailyMeteredPage";
import GasReconciliationNonDailyMeteredPageConfig from "../../pages/optimise/gas-reconciliation/GasReconciliationNonDailyMeteredPage";
import GasReconciliationHistoricalSubmissionsPageConfig from "../../pages/optimise/gas-reconciliation/GasReconciliationHistoricalSubmissionsPage";
import AssetsPageConfig from "../../pages/optimise/scheduler/AssetsPage";
import TradedVolumePageConfig from "../../pages/optimise/scheduler/TradedVolumePage";
import BMUPositionsPageConfig from "../../pages/optimise/scheduler/BMUPositionsPage";
import NonBMUPositionsPageConfig from "../../pages/optimise/scheduler/NonBMUPositionsPage";
import BatteryContractsPageConfig from "../../pages/optimise/frequency-services/BatteryContractsPage";

const areaOptions: IPortalAreaOptions = {
    title: "Optimise",
    description: "Scheduling, Traded Volume, EDL/EDT",
    iconImage: ConradOptimiseLogo,
    allValidPermissions: { Optimise: OptimisePermissions.AllPermissions }
}

// Root
const OptimisePortalArea = new PortalArea(areaOptions, OptimiseHomePageConfig);
const FrequencyServicesSubmenu = OptimisePortalArea.addChildRoute(FrequencyServicesHomePageConfig);
OptimisePortalArea.addChildRoute(GasPricePageConfig);
const GasReconciliationSubmenu = OptimisePortalArea.addChildRoute(GasReconciliationHomePageConfig);
const SchedulerSubmenu = OptimisePortalArea.addChildRoute(SchedulerPageConfig);
OptimisePortalArea.addChildRoute(StrikePricePageConfig);

// Frequency Services
FrequencyServicesSubmenu.addChildRoute(DynamicContractsPageConfig);
FrequencyServicesSubmenu.addChildRoute(DynamicContractsChartPageConfig);
FrequencyServicesSubmenu.addChildRoute(BatteryContractsPageConfig);
FrequencyServicesSubmenu.addChildRoute(DynamicServicesPageConfig);

// Gas Reconciliation
GasReconciliationSubmenu.addChildRoute(GasReconciliationDailyMeteredPageConfig);
GasReconciliationSubmenu.addChildRoute(GasReconciliationNonDailyMeteredPageConfig);
GasReconciliationSubmenu.addChildRoute(GasReconciliationHistoricalSubmissionsPageConfig);

// Scheduler
SchedulerSubmenu.addChildRoute(AssetsPageConfig);
SchedulerSubmenu.addChildRoute(TradedVolumePageConfig);
SchedulerSubmenu.addChildRoute(BMUPositionsPageConfig);
SchedulerSubmenu.addChildRoute(NonBMUPositionsPageConfig);

export default OptimisePortalArea;
