import { Moment } from "moment";
import { EfaBlock, IContractInformation, ServiceType } from "./IDynamicContract";
import { IPerfmonFile } from "./IPerfmonFile";
import moment from "moment";
import { ganttDateDisplayFormat, ganttDateKeyFormat } from "../../utils/GanttChartUtils";

export const buildGanttViewDataFromContracts = (contracts: IContractInformation[]): GanttViewData => {
    const viewData = new GanttViewData();
    const data = viewData.data;

    contracts.forEach((contract) => {
        const timestamp = moment(contract.deliveryDate);
        const humanReadableDate = timestamp.format(ganttDateDisplayFormat);
        const weekNumber = timestamp.week();

        if (!viewData.datesCovered.includes(humanReadableDate)) { // Add unique dates to dates covered
            viewData.datesCovered.push(humanReadableDate);
        }

        if (!viewData.weeksCovered.includes(weekNumber)) {
            viewData.weeksCovered.push(weekNumber);
        }

        if (!data[contract.siteName]) { // Create new site data if none exists for this site
            data[contract.siteName] = new GanttSiteData(contract.siteName);
        }

        const serviceData = data[contract.siteName].serviceData;
        if (!serviceData[contract.service]) { // Create new service data if none exists for this site -> service
            serviceData[contract.service] = new GanttServiceData(contract.service);
        }

        const timeStampedData = serviceData[contract.service]?.timestampedData;
        if (!timeStampedData) { throw new Error("Chart data processing failed."); } // This shouldn't be possible

        const dateKey = timestamp.format(ganttDateKeyFormat);
        if (!timeStampedData[dateKey]) { // Create new timestamped block data if none exists for this site -> service -> timestamp
            timeStampedData[dateKey] = new GanttTimestampedData(timestamp);
        }

        // Set block data contained in this contract
        const blockData = timeStampedData[dateKey].blockData[contract.efaBlock];
        blockData.setDataFromContract(contract);
    });

    viewData.datesCovered.sort((a, b) => a > b ? 1 : -1);
    return viewData;
}

export class GanttViewData {
    constructor() {
        this.data = {};
        this.datesCovered = [];
        this.weeksCovered = [];
    }

    /**
    * Dictionary containing contract data for all sites, keyed by SiteName.
    */
    public readonly data: Record<string, GanttSiteData>;
    public readonly datesCovered: string[];
    public readonly weeksCovered: number[];
}

export class GanttSiteData {
    constructor(siteName: string) {
        this.siteName = siteName;
        this.serviceData = {};
    }

    public readonly siteName: string;
    /**
    * Dictionary containing contract data for this site, keyed by ServiceType.
    */
    public readonly serviceData: Partial<Record<ServiceType, GanttServiceData>>;
}

export class GanttServiceData {
    constructor(service: ServiceType) {
        this.service = service;
        this.timestampedData = {};
    }

    public readonly service: ServiceType;
    /**
    * Dictionary containing contract data for this service, keyed by ISO Date String.
    */
    public readonly timestampedData: Record<string, GanttTimestampedData>;
}

export class GanttTimestampedData {
    constructor(timestamp: Moment) {
        this.deliveryDate = timestamp;
        this.blockData = {
            1: new GanttEfaBlockData(1),
            2: new GanttEfaBlockData(2),
            3: new GanttEfaBlockData(3),
            4: new GanttEfaBlockData(4),
            5: new GanttEfaBlockData(5),
            6: new GanttEfaBlockData(6),
        };
    }

    public getAllBlockSummary(): IGanttBlockData {
        const values = Object.values(this.blockData);
        const summary = { performance: 0, value: 0, volume: 0, hasData: true, }
        let totalValues = 0;

        values.forEach(value => {
            summary.performance += value.performance || 0;
            summary.value += value.value || 0;
            summary.volume += value.volume || 0;
            totalValues++;
        });

        summary.performance /= totalValues; // Performance is an average for the day
        summary.volume /= totalValues; // Volume is an average for the day

        return summary;
    }

    public readonly deliveryDate: Moment;
    /**
    * Dictionary containing contract data for this date, keyed by EFA Block.
    */
    public readonly blockData: Record<EfaBlock, GanttEfaBlockData>;
}

export interface IGanttBlockData {
    hasData: boolean;
    performance?: number;
    value?: number;
    volume?: number;
    deliveryEnd?: Date;
    deliveryStart?: Date;
}

export class GanttEfaBlockData implements IGanttBlockData {
    constructor(efaBlock: EfaBlock) {
        this.efaBlock = efaBlock;
        this._files = [];
        this._hasData = false;
    }

    setDataFromContract(contract: IContractInformation) {
        this._performance = contract.performance;
        this._value = contract.value;
        this._volume = contract.volume;
        this._deliveryStart = contract.deliveryStart;
        this._deliveryEnd = contract.deliveryEnd

        contract.files.forEach((file) => {
            this._files.push(file);
        });

        this._hasData = true;
    }

    public readonly efaBlock: EfaBlock;

    private _performance?: number;
    private _value?: number;
    private _volume?: number;
    private _deliveryEnd?: Date;
    private _deliveryStart?: Date;
    private _files: IPerfmonFile[];
    private _hasData: boolean;

    // Getters
    get performance() {
        return this._performance;
    }

    get value() {
        return this._value;
    }

    get volume() {
        return this._volume;
    }

    get deliveryEnd() {
        return this._deliveryEnd;
    }

    get deliveryStart() {
        return this._deliveryStart;
    }

    get files() {
        return this._files;
    }

    get hasData() {
        return this._hasData;
    }
}


