import { Button, LinearProgress, Stack, SxProps, TextField, Typography } from "@mui/material";
import { Counterparty } from "../../shared/enums/CounterpartyEnum";
import { useFormik } from "formik";
import * as yup from "yup";
import { CloudUpload } from "@mui/icons-material";

interface Props {
    totalResidual: number;
    allocateTrades: (ppt: number, residualValue: number, counterparty: Counterparty) => void;
    allocationCompleted: number;
    counterparty: Counterparty;
    disabled?: boolean;
}

const validatioSchema = yup.object().shape({
    ppt: yup
        .number()
        .min(1, "PPT must be greater than zero")
        .max(250, "PPT max value is 250")
        .required("PPT is required")
        .typeError("Must be a number"),
});

const GasTrades = ({ totalResidual, allocateTrades, allocationCompleted, counterparty, disabled = false }: Props) => {
    const formik = useFormik({
        initialValues: {
            ppt: 0
        },
        validationSchema: validatioSchema,
        onSubmit: (values) => { }
    });

    let isFocused: boolean = false;

    const getDisabledStyleSX = (): SxProps => {
        return disabled == false ? {} : { opacity: 0.3 };
    }

    const getResidualColour = () => {
        if (totalResidual == 0) return;

        isFocused = true;
        return totalResidual < 0 ? "error" : "primary";
    }



    return (
        <>
            {disabled && <Stack direction="row" padding={2} sx={getDisabledStyleSX()}>
                <Typography variant="body2">There is currently No Cash Exposure and allocating trades for {counterparty} are disabled.</Typography>
            </Stack>}
            <Stack direction="row" padding={3} spacing={2} sx={getDisabledStyleSX()}>
                <TextField
                    id="Residual"
                    label="Residual"
                    value={!disabled ? totalResidual : "N/A"}
                    variant="outlined"
                    disabled={disabled}
                    InputProps={{
                        readOnly: true,
                    }}
                    color={getResidualColour()}
                    focused={isFocused}
                />
                <form onBlur={formik.handleSubmit}>
                    <TextField type="text" disabled={disabled} id="ppt" label="PPT" value={!disabled ? formik.values.ppt : "N/A"} onChange={formik.handleChange}
                        variant="outlined" error={formik.touched.ppt && Boolean(formik.errors.ppt)} helperText={formik.touched.ppt && formik.errors.ppt} />
                </form>
                <Button disabled={disabled || !formik.isValid} sx={{ minWidth: "105px" }} variant="contained" startIcon={<CloudUpload />} onClick={() => allocateTrades(formik.values.ppt, totalResidual, counterparty)}>
                    Allocate Trades
                </Button>
            </Stack>

            {allocationCompleted != 0 &&
                <Stack direction="row" padding={3}>
                    <LinearProgress color="primary" variant="determinate" value={allocationCompleted} valueBuffer={100} sx={{ width: "100%", padding: 1, borderRadius: 5 }} />
                    <Typography variant="body2" sx={{ marginLeft: '10px' }} color="text.secondary">{`${Math.round(allocationCompleted)}%`}</Typography>
                </Stack>
            }

        </>
    );
}

export default GasTrades;
