import React, { useEffect, useMemo, useState } from "react";
import { Box, Button, Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, SelectChangeEvent, TextField, Tooltip } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { IPushNotification } from "../../shared/types/notification/IPushNotification";
import { Dictionary } from "@reduxjs/toolkit";
import moment, { Moment } from "moment";
import DateRangeSelector from "../data/DateRangeSelector";
import { Delete, MarkChatRead } from "@mui/icons-material";

const NotificationManagementOptions: React.FC<INotificationManagementOptions> = ({ allNotifications, setFilteredNotifications, siteIdToName, markAsReadFunction, massDeleteFunction, reapplyFilter }) => {
    const [assets, setAssets] = useState<string[]>(['All'])
    const [selectedNotifTypes, setSelectedNotifTypes] = useState<string[]>(['All'])
    const [anyTimeRange, setAnyTimeRange] = useState<boolean>(true)
    const [selectedPriority, setSelectedPriority] = useState<string[]>(['All'])
    const defaultStartTime = useMemo(() => moment.utc().startOf("week"), []);
    const defaultEndTime = useMemo(() => moment.utc().endOf("week").add(1, "second"), []);
    const [startDate, setStartDate] = useState<Moment>(defaultStartTime);
    const [endDate, setEndDate] = useState<Moment>(defaultEndTime);
    const [read, setRead] = useState<string[]>(['Both'])
    const [error, setError] = useState<string>("")

    const handleAssetChange = (event: SelectChangeEvent<typeof assets>) => {
        const {
            target: { value },
        } = event;
        const newAssets = typeof value === 'string' ? value.split(',') : value
        if (newAssets.indexOf('All') > -1) {
            setAssets(['All', ...assets.sort()])
        }
        else {
            setAssets(newAssets)
        }
    };

    const handleNotifTypeChange = (event: SelectChangeEvent<typeof selectedNotifTypes>) => {
        const {
            target: { value },
        } = event;
        const newNotifs = typeof value === 'string' ? value.split(',') : value
        if (newNotifs.indexOf('All') > -1) {
            setSelectedNotifTypes(['All', ...selectedNotifTypes.sort()])
        }
        else {
            setSelectedNotifTypes(newNotifs)
        }
    };

    const handlePriorityChange = (event: SelectChangeEvent<typeof selectedPriority>) => {
        const {
            target: { value },
        } = event;
        const newPriorities = typeof value === 'string' ? value.split(',') : value
        if (newPriorities.indexOf('All') > -1) {
            setSelectedPriority(['All', ...selectedPriority])
        }
        else {
            setSelectedPriority(newPriorities)
        }
    };

    const handleReadChange = (event: SelectChangeEvent<typeof read>) => {
        const {
            target: { value },
        } = event;
        const newReads = typeof value === 'string' ? value.split(',') : value
        if (newReads.indexOf('Both') > -1) {
            setRead(['Both', ...read])
        }
        else {
            setRead(newReads)
        }
    };

    const notifTypes = [
        "Notice",
        "Alarm",
        "Warning"
    ]

    const priorities = [
        "High",
        "Medium",
        "Low",
        "Info"
    ]

    const readValues = [
        "Read",
        "Unread"
    ]

    useEffect(() => {
        let newlyFilteredNotifs = new Array<IPushNotification>()
        allNotifications.forEach((notif) => {
            const submissionTime = moment(notif.submissionTime)
            if (
                (assets.indexOf('All') > -1 || assets.indexOf(notif.asset) > -1) &&
                (selectedPriority.indexOf('All') > -1 || selectedPriority.indexOf(notif.priority) > -1) &&
                (selectedNotifTypes.indexOf('All') > -1 || (notif.type && selectedNotifTypes.indexOf(notif.type) > -1)) &&
                (read.indexOf('Both') > -1 || read.indexOf(notif.seen ? "Read" : "Unread") > -1) &&
                (anyTimeRange || (submissionTime.isAfter(startDate) && submissionTime.isBefore(endDate)))
            ) {
                newlyFilteredNotifs.push(notif)
            }
        })
        setFilteredNotifications(newlyFilteredNotifs)
        if (newlyFilteredNotifs.length === 0) {
            setError("There must be at least one notification to perform operation")
        }
        else {
            setError("")
        }
    }, [assets, selectedPriority, selectedNotifTypes, anyTimeRange, read, startDate, endDate, allNotifications, setFilteredNotifications, reapplyFilter])

    return (
        <>
            <Grid
                container
                alignItems="center"
                alignContent="start"
                spacing={2}
                justifyContent="flex-start">
                <Grid xs={3.5}>
                    <Box>
                        <FormControl fullWidth>
                            <InputLabel id="asset-label">Asset</InputLabel>
                            <Select
                                labelId="asset-label"
                                id="multiple-asset"
                                multiple
                                label="Assets"
                                value={assets}
                                onChange={handleAssetChange}
                                renderValue={(selected) => {
                                    const maxNumberOfAssets = 5
                                    if (selected.indexOf('All') > -1) { return 'All' }
                                    const selectedAssets: string[] = []
                                    selected.sort().slice(0, maxNumberOfAssets).forEach((asset) => {
                                        selectedAssets.push(siteIdToName[asset] || asset)
                                    })
                                    if (selected.length > maxNumberOfAssets) {
                                        selectedAssets.push("...")
                                    }
                                    return selectedAssets.join(', ')
                                }
                                }
                            >
                                <MenuItem key={'All'} value={'All'}>{'All'}</MenuItem>
                                {
                                    Object.keys(siteIdToName).sort().map((item, index) =>
                                        <MenuItem key={item} value={item} disabled={assets.indexOf('All') > -1}>{siteIdToName[item]}</MenuItem>
                                    )
                                }
                            </Select>
                        </FormControl>
                    </Box>
                </Grid>
                <Grid xs={3.5}>
                    <Box>
                        <FormControl fullWidth>
                            <InputLabel id="type-label">Types</InputLabel>
                            <Select
                                labelId="type-label"
                                id="multiple-types"
                                multiple
                                label="Types"
                                value={selectedNotifTypes}
                                onChange={handleNotifTypeChange}
                                renderValue={(selected) => {
                                    if (selected.indexOf('All') > -1) { return 'All' }
                                    return selected.sort((a, b) => notifTypes.indexOf(a) < notifTypes.indexOf(b) ? -1 : 1).join(', ')
                                }
                                }
                            >
                                <MenuItem key={'All'} value={'All'}>{'All'}</MenuItem>
                                {
                                    notifTypes.sort().map((item, index) =>
                                        <MenuItem key={item} value={item} disabled={selectedNotifTypes.indexOf('All') > -1}>{item}</MenuItem>
                                    )
                                }
                            </Select>
                        </FormControl>
                    </Box>
                </Grid>

                <Grid xs={1}>
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center">
                        <FormControlLabel
                            control={
                                <Checkbox id="anyTimeRange" checked={anyTimeRange} onChange={() => setAnyTimeRange(!anyTimeRange)} sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }} />
                            }
                            label="Any Time?"
                        />
                    </Box>
                </Grid>
                <DateRangeSelector startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate} maxRange={3} invalidFunction={() => { }} disabled={anyTimeRange} />
                <Grid xs={3.5}>
                    <Box>
                        <FormControl fullWidth>
                            <InputLabel id="priority-label">Priority</InputLabel>
                            <Select
                                labelId="priority-label"
                                id="multiple-priorities"
                                multiple
                                label="Priority"
                                value={selectedPriority}
                                onChange={handlePriorityChange}
                                renderValue={(selected) => {
                                    if (selected.indexOf('All') > -1) { return 'All' }
                                    return selected.sort((a, b) => priorities.indexOf(a) < priorities.indexOf(b) ? -1 : 1).join(', ')
                                }
                                }
                            >
                                <MenuItem key={'All'} value={'All'}>{'All'}</MenuItem>
                                {
                                    priorities.map((item, index) =>
                                        <MenuItem key={item} value={item} disabled={selectedNotifTypes.indexOf('All') > -1}>{item}</MenuItem>
                                    )
                                }
                            </Select>
                        </FormControl>
                    </Box>
                </Grid>
                <Grid xs={3.5}>
                    <Box>
                        <FormControl fullWidth>
                            <InputLabel id="read-label">Read</InputLabel>
                            <Select
                                labelId="read-label"
                                id="multiple-reads"
                                multiple
                                label="Read"
                                value={read}
                                onChange={handleReadChange}
                                renderValue={(selected) => {
                                    if (selected.indexOf('Both') > -1) { return 'Both' }
                                    return selected.sort((a, b) => readValues.indexOf(a) < readValues.indexOf(b) ? -1 : 1).join(', ')
                                }
                                }
                            >
                                <MenuItem key={'Both'} value={'Both'}>{'Both'}</MenuItem>
                                {
                                    readValues.map((item, index) =>
                                        <MenuItem key={item} value={item} disabled={read.indexOf('Both') > -1}>{item}</MenuItem>
                                    )
                                }
                            </Select>
                        </FormControl>
                    </Box>
                </Grid>
                <Grid xs={2.5}>
                    <Tooltip title={error}>
                        <Box sx={{ position: 'relative' }}>
                            <Button
                                variant="contained"
                                startIcon={<MarkChatRead />}
                                disabled={!!error}
                                onClick={markAsReadFunction}
                                size="large"
                                sx={{ height: "55px" }}
                                fullWidth
                            >
                                Mark As Read
                            </Button>
                        </Box>
                    </Tooltip>
                </Grid>
                <Grid xs={2.5}>
                    <Tooltip title={error}>
                        <Box sx={{ position: 'relative' }}>
                            <Button
                                variant="contained"
                                startIcon={<Delete />}
                                disabled={!!error}
                                onClick={massDeleteFunction}
                                size="large"
                                sx={{ height: "55px" }}
                                fullWidth
                            >
                                Delete
                            </Button>
                        </Box>
                    </Tooltip>
                </Grid>
            </Grid>
        </>
    );
}

export interface INotificationManagementOptions {
    allNotifications: IPushNotification[]
    setFilteredNotifications: (filteredNotifications: IPushNotification[]) => void
    siteIdToName: Dictionary<string>
    markAsReadFunction: () => void
    massDeleteFunction: () => void
    reapplyFilter: boolean
}

export default NotificationManagementOptions;