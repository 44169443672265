import { Box, Button, Card, CardActionArea, CardContent, CardMedia, Container, Typography } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import React from "react";
import { isMobile } from "react-device-detect";
import * as Router from 'react-router-dom';
import PageWrapper from "../../components/PageWrapper";
import { usePortalContext } from "../../components/PortalWrapper";
import { PortalArea } from "../../shared/types/shared/routes/routeHelperClasses";

const NoAreasAccessible = () => {
    return (
        <Grid xs={12}>
            <Typography>
                Sorry, you do not have the necessary permissions to access the iON Portal.
            </Typography>
        </Grid>
    )
}

interface IAreaCardProps {
    area: PortalArea;
}

const AreaCard = ({ area }: IAreaCardProps) => {
    const navigate = Router.useNavigate();

    return (
        <Grid lg={2} md={3} sm={4} xs={isMobile ? 12 : 6}>
            <Card sx={{ width: "100%", height: "100%", display: "inline-block" }}>
                <CardActionArea sx={{ height: "100%", }} onClick={() => navigate(area.fullPath)}>
                    <Grid container>
                        <Grid xs={isMobile? 6 : 12}>
                            <CardMedia
                                sx={{ objectFit: "contain" }}
                                component="img"
                                height="150"
                                image={area.iconImage}
                            />
                        </Grid>
                        <Grid xs={isMobile ? 6 : 12}>
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                    {area.areaTitle}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    {area.areaDescription}
                                </Typography>
                            </CardContent>
                        </Grid>
                    </Grid>
                </CardActionArea>
            </Card>
        </Grid>
    );
}

const LandingPage: React.FC<ILandingPageProps> = ({ routesLoaded }) => {
    const { isAuthenticated, currentUser, portalAreas } = usePortalContext();

    const areasToDisplay = portalAreas && currentUser ? portalAreas.areas.filter((area) => area.userHasRoutes(currentUser) && !area.hideFromLandingPage) : [];    

    return (
        <PageWrapper title={routesLoaded ? `Welcome, ${currentUser?.firstName || "User"}`: ""} loaded={isAuthenticated && routesLoaded}>
            <Grid container spacing={2}>
                {(
                    areasToDisplay.length > 0 ?
                        areasToDisplay.map((area) => <AreaCard key={area.areaTitle} area={area} />) :
                        <NoAreasAccessible />
                )}               
            </Grid>            
        </PageWrapper>
    );
}

export interface ILandingPageProps {
    routesLoaded: boolean;
}

export default LandingPage;