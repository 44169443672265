import { IPortalRouteOptions } from "../../shared/types/shared/routes/routeTypes";
import { MenuBook } from "@mui/icons-material";
import PageWrapper from "../../components/PageWrapper";
import { Paper } from "@mui/material";
import { useEffect, useState } from "react";
import { IPushNotification } from "../../shared/types/notification/IPushNotification";
import { Dictionary } from "@reduxjs/toolkit";
import { useGetLiveAssetsQuery } from "../../shared/api/AssetApi";
import AdminNotificationOptions from "../../components/notifications/AdminNotificationOptions";
import NotificationsTable from "../../components/notifications/tables/NotificationsTable";

const NotificationsArchivePage: React.FC<INotificationsArchivePage> = () => {
    const [queriedNotifications, setQueriedNotifications] = useState<IPushNotification[]>([])
    const [siteIdToName, setSiteIdToName] = useState<Dictionary<string>>({})
    const { data: assets, isSuccess: loaded } = useGetLiveAssetsQuery();

    const fetchPageData = () => {
        if (assets !== undefined) {
            var dictName: Dictionary<string> = {}
            assets?.forEach((item) => {
                dictName[item.SiteID] = item.Name
            });
            setSiteIdToName(dictName)
        }
    }

    useEffect(() => {
        fetchPageData()
    }, [assets])

    return (
        <PageWrapper title="Notifications Archive" loaded={loaded}>
            <Paper sx={{ p: 2 }}>
                <AdminNotificationOptions setNotifications={setQueriedNotifications} siteIdToName={siteIdToName} filterDuplicates={true} truncatedControls={true} />
                <NotificationsTable items={queriedNotifications} siteIdToName={siteIdToName} searchBar={true} tablePagination={true} truncatedColumns={true} />
            </Paper>
        </PageWrapper>
    );
}
export interface INotificationsArchivePage {

}

const NotificationsArchivePageConfig: IPortalRouteOptions = {
    relativeUrl: "notifications-archive",
    page: <NotificationsArchivePage />,
    navDisplay: {
        title: "Notifications Archive",
        icon: <MenuBook />
    }
}

export default NotificationsArchivePageConfig;