import React, { useEffect, useMemo, useState } from "react";
import { Box, Button, CircularProgress, FormControl, InputLabel, ListSubheader, MenuItem, OutlinedInput, Select, SelectChangeEvent, Tooltip } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import DateRangeSelector from "../../components/data/DateRangeSelector";
import CSVButton from "../../components/data/CSVButton";
import { Moment } from "moment";
import moment from "moment"
import availabilityApi from "../../shared/api/AvailabilityApi";
import { CalendarToday } from "@mui/icons-material";
import { Dictionary } from "@reduxjs/toolkit";
import { IEngine } from "../../shared/types/operate/IEngine";
import { reasonsDictionary } from "../operate/helpers/ReasonsDictionary";
import { isMobile } from "react-device-detect";

const OutageDataRequestBar: React.FC<IOutageDataRequestProps> = ({ readings, setReadings, siteIdToName, siteIDToGenerators }) => {
    const defaultStartTime = useMemo(() => moment.utc().subtract(1, "day").startOf("day"), []);
    const defaultEndTime = useMemo(() => moment.utc().endOf("day"), []);
    const [queryError, setQueryError] = useState<string>("");
    const [assets, setAssets] = useState<string[]>([]);
    const [generatorList, setGeneratorList] = useState<Array<string>>(new Array<string>())
    const [generators, setGenerators] = useState<string[]>([])
    const [reasonList, setReasonList] = useState<Array<string>>(new Array<string>())
    const [reasons, setReasons] = useState<string[]>([])
    const [categories, setCategories] = useState<string[]>(["General Servicing"])
    const [startDate, setStartDate] = useState<Moment>(defaultStartTime);
    const [endDate, setEndDate] = useState<Moment>(defaultEndTime);
    const [querying, setQuerying] = useState<boolean>(false);
    const [restriction, setRestriction] = useState<string[]>(["Outage", "Restriction"]);

    useEffect(() => {
        const newGeneratorList = new Array<string>();
        assets.forEach((asset) => {
            const newGenerators = getGeneratorsFromAssetID(asset)
            newGenerators.forEach((newGenerator) => {
                if (newGeneratorList.indexOf(newGenerator) < 0) {
                    newGeneratorList.push(newGenerator)
                }
            })
        })

        setGeneratorList(newGeneratorList)
        if (generators.indexOf('All') > -1) {
            setGenerators(['All', ...newGeneratorList])
        }
        else {
            const newGenerators = new Array<string>()
            generators.forEach((generator) => {
                if (newGeneratorList.indexOf(generator) >= 0) {
                    newGenerators.push(generator)
                }
            })
            setGenerators(newGenerators)
        }

    }, [assets])

    useEffect(() => {
        const newReasonsList = new Array<string>()
        categories.forEach((category) => {
            if (category !== 'All') {
                const newReasons = reasonsDictionary[category]
                newReasons.forEach((newReason) => {
                    newReasonsList.push(newReason)
                })
            }
        })
        setReasonList(newReasonsList)
        const newReasons = new Array<string>()
        reasons.forEach((reason) => {
            if (newReasonsList.indexOf(reason) >= 0) {
                newReasons.push(reason)
            }
        })
        setReasons(newReasons)
    }, [categories])

    useEffect(() => {
        if (assets.length === 0) {
            setQueryError("Please select at least one asset")
        }
        else if (generators.length === 0) {
            setQueryError("Please select at least one engine")
        }
        else if (reasons.length === 0) {
            setQueryError("Please select at least one reason")
        }
        else if (restriction.length === 0) {
            setQueryError("Please select at least one outage variety")
        }
        else if (startDate.valueOf() > endDate.valueOf()) {
            setQueryError("Start Date is before End Date")
        }
        else {
            setQueryError("")
        }
    }, [assets, generators, reasons, restriction, startDate, endDate])
    
    function getGeneratorsFromAssetID(assetID: string) {
        var generators = new Array<string>("Whole Site");
        var siteGenerators = siteIDToGenerators.get(assetID)
        if (siteGenerators !== undefined) {
            siteGenerators.forEach((generator) => {
                generators.push(generator.engine);
            })
        }
        return generators;
    }

    const clickQueryRequestButton = () => {
        setQuerying(true);

        var getOutageData = availabilityApi.getOutageByRequest(moment(startDate).utc().format(), moment(endDate).utc().format(), assets, "0", generators, reasons, restriction)
            .then(readings => {
                setReadings(readings);
            });

        const promises = [
            getOutageData
        ]

        return Promise.all(promises).then().finally(() => setQuerying(false));
    }

    const handleAssetChange = (event: SelectChangeEvent<typeof assets>) => {
        const {
            target: { value },
        } = event;
        const newAssets = typeof value === 'string' ? value.split(',') : value
        if (newAssets.indexOf('All') > -1) {
            setAssets(['All', ...Object.keys(siteIdToName).sort()])
        }
        else {
            setAssets(newAssets)
        }
    };
    const handleGeneratorChange = (event: SelectChangeEvent<typeof generators>) => {
        const {
            target: { value },
        } = event;
        const newGenerators = typeof value === 'string' ? value.split(',') : value
        if (newGenerators.indexOf('All') > -1) {
            setGenerators(['All', ...generatorList])
        }
        else {
            setGenerators(newGenerators)
        }
    }
    const handleCategoriesChange = (event: SelectChangeEvent<typeof categories>) => {
        const {
            target: { value },
        } = event;
        const selectedCategories = typeof value === 'string' ? value.split(',') : value
        if (selectedCategories.indexOf('All') > -1) {
            setCategories(['All', ...Object.keys(reasonsDictionary)])
        }
        else {
            const newCategories = new Array<string>()
            Object.keys(reasonsDictionary).forEach((category) => {
                if (selectedCategories.indexOf(category) > -1) {
                    newCategories.push(category)
                }
            })
            setCategories(newCategories)
        }
    };
    const handleReasonsChange = (event: SelectChangeEvent<typeof reasons>) => {
        const {
            target: { value },
        } = event;
        const newReasons = typeof value === 'string' ? value.split(',') : value
        if (newReasons.indexOf('All') > -1) {
            setReasons(['All', ...reasonList])
        }
        else {
            setReasons(newReasons)
        }
    };
    const handleRestrictionChange = (event: SelectChangeEvent<typeof restriction>) => {
        const {
            target: { value },
        } = event;
        setRestriction(typeof value === 'string' ? value.split(',') : value)
    }
    const columnsCsv = [
        { key: "remitID", label: "Remit ID" },
        { key: "siteID", label: "Site ID" },
        { key: "engineID", label: "Engine ID" },
        { key: "outageStart", label: "Outage Start" },
        { key: "outageEnd", label: "Outage End" },
        { key: "mwOutage", label: "Outage MW" },
        { key: "comments", label: "Reason" },
        { key: "live", label: "Live" },
        { key: "restriction", label: "Restriction" },
        { key: "created", label: "Created" },
    ];

    return (
        <Grid
            container
            alignItems="center"
            alignContent="start"
            spacing={isMobile ? 1 : 2}
            justifyContent="flex-start">
            <Grid xs={isMobile ? 6 : 4}>
                <Box>
                    <FormControl fullWidth>
                        <InputLabel id="asset-label">Asset</InputLabel>
                        <Select
                            labelId="asset-label"
                            id="multiple-asset"
                            multiple
                            label="Assets"
                            value={assets}
                            onChange={handleAssetChange}
                            renderValue={(selected) => {
                                const maxNumberOfAssets = 5
                                if (selected.indexOf('All') > -1) { return 'All' }
                                const selectedAssets: string[] = []
                                selected.sort().slice(0, maxNumberOfAssets).forEach((asset) => {
                                    selectedAssets.push(siteIdToName[asset] || asset)
                                })
                                if (selected.length > maxNumberOfAssets) {
                                    selectedAssets.push("...")
                                }
                                return selectedAssets.join(', ')
                            }
                            }
                        >
                            <MenuItem key={'All'} value={'All'}>{'All'}</MenuItem>
                            {
                                Object.keys(siteIdToName).sort().map((item, index) =>
                                    <MenuItem key={item} value={item} disabled={assets.indexOf('All') > -1}>{siteIdToName[item]}</MenuItem>
                                )
                            }
                        </Select>
                    </FormControl>
                </Box>
            </Grid>
            <Grid xs={isMobile ? 6 : 4}>
                <Box>
                    <FormControl fullWidth>
                        <InputLabel id="generator-label">Generator</InputLabel>
                        <Select
                            labelId="generator-label"
                            id="multiple-generator"
                            multiple
                            value={generators}
                            input={<OutlinedInput label="Generator" />}
                            onChange={handleGeneratorChange}
                            renderValue={(selected) => {
                                const maxNumberOfGenerators = 5
                                if (selected.indexOf('All') > -1) { return 'All' }
                                const selectedGenerators: string[] = []
                                selected.sort((a, b) => {
                                    if (a === "Whole Site") {
                                        return -1
                                    }
                                    else if (b === "Whole Site") {
                                        return 1
                                    }
                                    else {
                                        return a < b ? -1 : 1 
                                    }
                                }).slice(0, maxNumberOfGenerators).forEach((generator) => {
                                    selectedGenerators.push(generator)
                                })
                                if (selected.length > maxNumberOfGenerators) {
                                    selectedGenerators.push("...")
                                }
                                return selectedGenerators.join(', ')
                            }
                            }
                        >
                            <MenuItem key={'All'} value={'All'}>{'All'}</MenuItem>
                            {
                                generatorList.map((generator) => 
                                    <MenuItem key={generator} value={generator} disabled={generators.indexOf('All') > -1}>{generator}</MenuItem>
                                )
                            }
                        </Select>
                    </FormControl>
                </Box>
            </Grid>
            <DateRangeSelector startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate} maxRange={3} invalidFunction={setQueryError} disabled={querying} xs={isMobile ? 6 : 2} />
            <Grid xs={isMobile ? 6 : 2}>
                <Box>
                    <FormControl fullWidth>
                        <InputLabel id="categories-label">Categories</InputLabel>
                        <Select
                            labelId="categories-label"
                            id="multiple-categories"
                            multiple
                            value={categories}
                            input={<OutlinedInput label="Categories" />}
                            onChange={handleCategoriesChange}
                            renderValue={(selected) => {
                                const maxCategories = 1
                                if (selected.indexOf('All') > -1) { return 'All' }
                                const selectedCategories: string[] = []
                                Object.keys(reasonsDictionary).forEach((category, index) => {
                                    if (selected.indexOf(category) > -1 && selectedCategories.length < maxCategories) {
                                        selectedCategories.push(category)
                                    }
                                })
                                if (selected.length > maxCategories) {
                                    selectedCategories.push("...")
                                }
                                return selectedCategories.join(', ')
                            }
                            }
                        >
                            <MenuItem key={'All'} value={'All'}>{'All'}</MenuItem>
                            {
                                
                                Object.keys(reasonsDictionary).map((item, index) =>
                                    <MenuItem key={item} value={item} disabled={categories.indexOf('All') > -1}>{item}</MenuItem>
                                )
                            }
                        </Select>
                    </FormControl>
                </Box>
            </Grid>
            <Grid xs={isMobile ? 6 :2}>
                <Box>
                    <FormControl fullWidth>
                        <InputLabel id="reasons-label">Reasons</InputLabel>
                        <Select
                            labelId="reasons-label"
                            id="multiple-reasons"
                            multiple
                            value={reasons}
                            input={<OutlinedInput label="Reasons" />}
                            onChange={handleReasonsChange}
                            renderValue={(selected) => {
                                const maxReasons = 1
                                if (selected.indexOf('All') > -1) { return 'All' }
                                const selectedReasons: string[] = []
                                reasonList.forEach((category, index) => {
                                    if (selected.indexOf(category) > -1 && selectedReasons.length < maxReasons) {
                                        selectedReasons.push(category)
                                    }
                                })
                                if (selected.length > maxReasons) {
                                    selectedReasons.push("...")
                                }
                                return selectedReasons.join(', ')
                            }
                            }
                        >
                            <MenuItem key={'All'} value={'All'}>{'All'}</MenuItem>
                            {
                                categories.map((category) => {
                                    if (category !== 'All') {
                                        const reasons = reasonsDictionary[category]
                                        return [
                                            <ListSubheader>{category}</ListSubheader>,
                                            reasons.map((reason) =>
                                                <MenuItem key={reason} value={reason} disabled={reasons.indexOf('All') > -1}>{reason}</MenuItem>
                                            )
                                        ]
                                    }
                                })
                            }
                        </Select>
                    </FormControl>
                </Box>
            </Grid>
            <Grid xs={isMobile ? 12 : 2}>
                <Box>
                    <FormControl fullWidth>
                        <InputLabel id="restriction-label">Outage/Restriction</InputLabel>
                        <Select
                            labelId="restriction-label"
                            id="select-restriction"
                            label="Outage/Restriction"
                            multiple
                            value={restriction}
                            onChange={handleRestrictionChange}
                            renderValue={(selected) => {
                                if (selected.length == 1) {
                                    return selected[0]
                                }
                                else {
                                    return "Both"
                                }
                            }
                            }
                        >
                            <MenuItem key={"Outage"} value={"Outage"}>Outage</MenuItem>
                            <MenuItem key={"Restriction"} value={"Restriction"}>Restriction</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
            </Grid>
            <Grid xs={isMobile ? 6 : 3}>
                <Tooltip title={queryError}>
                    <Box sx={{ position: 'relative' }}>
                        <Button
                            variant="contained"
                            startIcon={<CalendarToday />}
                            disabled={querying || !!queryError}
                            onClick={clickQueryRequestButton}
                            size="large"
                            sx={{ height: "55px" }}
                            fullWidth
                        >
                            Gather Data
                        </Button>
                        {querying && (
                            <CircularProgress
                                size={24}
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: '-12px',
                                    marginLeft: '-12px',
                                }}
                            />
                        )}
                    </Box>
                </Tooltip>
            </Grid>
            <CSVButton data={readings} disabled={querying || !!queryError} columns={columnsCsv} filename={'OutageData_' + moment(startDate).format("YYYYMMDD") + '_to_' + moment(endDate).format("YYYYMMDD") + '.csv'} xs={isMobile ? 6 : 3} />
        </Grid>
    );
}

export interface IOutageDataRequestProps {
    readings: any
    setReadings: (readings: any) => void;
    siteIdToName: Dictionary<string>;
    siteIDToGenerators: Map<string, Array<IEngine>>
}

export default OutageDataRequestBar;