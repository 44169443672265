import { SvgIcon } from "@mui/material";

export const MarkerIcon: React.FC<IMarkerIconProps> = ({ height, width }) => {
    return (
        <SvgIcon fontSize="inherit" height={height} width={width}>
            <svg viewBox="0 0 24 24">
                <path
                    stroke="none"
                    transform="rotate(-90, 12, 12)"
                    fill="currentColor"
                    fillRule="nonzero"
                    fillOpacity={1}
                    d="M 11.75 0.0195312 C 11.375 0.0703125 10.996094 0.28125 10.742188 0.578125 C 10.671875 0.664062 9.933594 1.914062 8.335938 4.65625 C 1.671875 16.085938 0.230469 18.5625 0.164062 18.6875 C 0.0507812 18.914062 0.0117188 19.082031 0.0117188 19.386719 C 0.0117188 19.597656 0.0195312 19.671875 0.0507812 19.792969 C 0.222656 20.402344 0.707031 20.847656 1.328125 20.960938 C 1.46875 20.988281 2.480469 20.992188 12 20.992188 C 21.519531 20.992188 22.53125 20.988281 22.671875 20.960938 C 23.292969 20.847656 23.777344 20.402344 23.949219 19.792969 C 23.980469 19.671875 23.988281 19.597656 23.988281 19.386719 C 23.988281 19.082031 23.949219 18.914062 23.835938 18.6875 C 23.796875 18.613281 22.417969 16.242188 20.773438 13.421875 C 19.128906 10.601562 16.785156 6.585938 15.570312 4.496094 C 14.039062 1.867188 13.328125 0.664062 13.257812 0.578125 C 13.046875 0.332031 12.738281 0.136719 12.421875 0.0546875 C 12.246094 0.0078125 11.941406 -0.00390625 11.75 0.0195312 Z M 11.75 0.0195312 " />
            </svg>
        </SvgIcon>
    )
}

export interface IMarkerIconProps {
    height?: string | number;
    width?: string | number;
}