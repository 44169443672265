import { Icon } from "@mui/material";
import { FileStatus, IPerfmonFile } from "../types/dynamic-contracts/IPerfmonFile";
import { IGanttBlockData } from "../types/dynamic-contracts/gantt-chart-data";
import moment, { Moment } from "moment";
import { Cancel, CheckCircle, RemoveCircle } from "@mui/icons-material";

export const ganttDateDisplayFormat = "DD-MMM-YYYY";
export const ganttDateKeyFormat = "YYYY-MM-DD";

export const getAllDatesInWeeks = (weeks: number[]) => {
    const dates: Moment[] = [];

    weeks.forEach((week) => {
        const thisWeek = [];

        const date = moment.utc().day("Monday").startOf("day").week(week);
        for (var day = 1; day <= 7; day++) {
            thisWeek.push(date.clone());
            date.add(1, 'day');
        }

        dates.push(...thisWeek);
    });

    return dates;
}

export const getGanttCellBackgroundColour = (block: IGanttBlockData) => {
    return "transparent";

    // if (!block.hasData) { return "transparent"; }
    //if (!block.performance) { return "yellow"; }

    //if (block.performance < 1) {
    //    return "transparent";
    //}
    //if (block.performance === 1) {
    //    return "transparent";
    //}

    //if (block.performance === 0 && moment(block.deliveryEnd).isBefore(moment())) { return "red"; }
    // return "transparent";
}

export const getGanttCellTextColour = (block: IGanttBlockData) => {
    if (!block.hasData) { return "transparent"; }
    //if (!block.performance) { return "black"; }

    if (block.performance && block.performance < 1) {
        return "red";
    }
    if (block.performance && block.performance === 1) {
        return "limegreen";
    }

    if (block.performance === 1) { return "limegreen"; }
    if (block.performance === 0 && moment(block.deliveryEnd).isBefore(moment())) { return "red"; }
    return "orange";
}

export const getGanttCellPercentageText = (block: IGanttBlockData) => {
    if (!block.hasData) { return ""; }

    const perf = block.performance || 0.0;

    if (perf < 1) {
        return `${(perf * 100).toFixed(2)}%`;
    }
    if (perf === 1) {
        return `${(perf * 100).toFixed(2)}%`;
    }

    return "Unknown";
}

export const getGanttCellValueText = (block: IGanttBlockData) => {
    if (!block.hasData) { return ""; }

    const curr = "\u00A3";
    const expectedValue = (block.value || 0.0).toFixed(2);
    if (block.performance === 1) {
        return `${curr}${expectedValue}`;
    }

    const performanceValue = ((block.value || 0.0) * (block.performance || 0.0)).toFixed(2);

    return `${curr}${performanceValue}/${curr}${expectedValue}`;
}

export const getGanttFileDisplayData = (file?: IPerfmonFile) => {
    if (!file) { return { colour: "transparent", icon: <Icon /> } }
    if (!file.fileProcessed) { return { colour: "orange", icon: <RemoveCircle /> } }
    if (file.fileOK && file.highAvailability >= .999 && file.lowAvailability >= 0.999 ) {
        const colour = file.maxError < 0.07 ? "#004225" : "red";
        return { colour, icon: <CheckCircle /> }
    }
    

    return { colour: "red", icon: <Cancel /> }
}

export const getGanttFileTooltip = (file?: IPerfmonFile): string => {
    if (!file) { return "No data"; }

    return `File: ${file.expectedFileName}
        Status: ${FileStatus[file.status]}
        Processed: ${file.processedTime ? moment(file.processedTime).format("YYYY-MM-DD HH:mm:ss") : "N/A"}
        K-Factor: ${(file.kfactor * 100).toFixed(2)}%
        Max Error: ${(file.maxError * 100).toFixed(2)}%
        Missing Values: ${(file.missingValues).toFixed(0)}
        Low Availability : ${(file.lowAvailability * 100).toFixed(3)}%
        High Availability: ${(file.highAvailability * 100).toFixed(3)}%`;
}