import ConradOperateLogo from '../../images/Conrad_Operate_Icon.png';
import CommercialHomePageConfig from "../../pages/commercial/CommercialHomePage";
import CommercialLogsPageConfig from '../../pages/commercial/CommercialLogsPage';
import CommercialOpportunitiesPageConfig from '../../pages/commercial/CommercialOpportunitiesPage';
import CommercialPricingPageConfig from '../../pages/commercial/CommercialPricingPage';
import { CommercialPermissions } from "../types/shared/auth/permissionsTypes";
import { PortalArea } from "../types/shared/routes/routeHelperClasses";
import { IPortalAreaOptions } from "../types/shared/routes/routeTypes";

const areaOptions: IPortalAreaOptions = {
    title: "Commercial",
    description: "Commercial, PPA, Pricing Information",
    iconImage: ConradOperateLogo,
    allValidPermissions: { Commercial: CommercialPermissions.AllPermissions }
}

const CommercialPortalArea = new PortalArea(areaOptions, CommercialHomePageConfig);
CommercialPortalArea.addChildRoute(CommercialOpportunitiesPageConfig);
CommercialPortalArea.addChildRoute(CommercialPricingPageConfig);
CommercialPortalArea.addChildRoute(CommercialLogsPageConfig);


export default CommercialPortalArea;
