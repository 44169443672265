import { Dashboard } from "@mui/icons-material";
import { Grid } from "@mui/material";
import React, { useCallback, useEffect } from "react";
import PageWrapper from "../../components/PageWrapper";
import { IPortalRouteOptions } from "../../shared/types/shared/routes/routeTypes";

const CommercialHomePage: React.FC<ICommercialHomePage> = () => {
  const [loaded, setLoaded] = React.useState(false);

    const loadComplete = useCallback(async () => {
        setLoaded(true);
    }, [])

    useEffect(() => {
        loadComplete()
    }, [loadComplete])

  return (
    <PageWrapper title="Dashboard" loaded={loaded}>
      <Grid container spacing={2}>
      </Grid>
    </PageWrapper>
  );
}

export interface ICommercialHomePage {

}

const CommercialHomePageConfig: IPortalRouteOptions = {
    relativeUrl: "commercial",
    page: <CommercialHomePage />,
    navDisplay: {
        title: "Dashboard",
        icon: <Dashboard />
    }
}

export default CommercialHomePageConfig;