
import React, { useEffect, useState } from "react";
import PageWrapper from "../../components/PageWrapper";
import { Paper, Typography } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import availabilityApi from "../../shared/api/AvailabilityApi";
import { IEngineOutage } from "../../shared/types/operate/IEngineOutage";
import EngineOutageTable from "../../components/operate/EngineOutageTable";
import moment from "moment"
import { Dictionary } from "@reduxjs/toolkit";
import { useGetLiveAssetsQuery } from "../../shared/api/AssetApi";
import LoadingSymbol from "../../components/operate/LoadingSymbol";
import { IPortalRouteOptions } from "../../shared/types/shared/routes/routeTypes";
import { EditCalendar } from "@mui/icons-material";
import AssetEngineSelector from "../../components/operate/outage-forms/AssetEngineSelector";
import { IEngine } from "../../shared/types/IEngine";
import { isMobile } from "react-device-detect";

const EngineOutagesPage: React.FC<IEngineOutagesPage> = () => {
    const [engineOutagesLive, setEngineOutagesLive] = useState<IEngineOutage[]>([])
    const [filteredOutages, setFilteredOutages] = useState<IEngineOutage[]>([])
    const [siteIdToName, setSiteIdToName] = useState<Dictionary<string>>({})
    const [liveOutagesLoaded, setLiveOutagesLoaded] = useState<boolean>(true)
    const [asset, setAsset] = React.useState<string>("Any")
    const [generator, setGenerator] = useState<string>("")
    const [siteIDToGenerators, setSiteIDToGenerators] = useState<Map<string, Array<IEngine>>>(new Map<string, Array<IEngine>>())
    const [siteIDToType, setSiteIDToType] = useState<Dictionary<string>>({})
    const { data: assets } = useGetLiveAssetsQuery();

    const fetchSiteData = () => {
        if (assets !== undefined) {
            var dictName: Dictionary<string> = {}
            var dictType: Dictionary<string> = {}
            assets?.forEach((item) => {
                dictName[item.SiteID] = item.Name
                const genType = item.GenerationType
                const formattedGenType = genType.charAt(0).toUpperCase() + genType.slice(1).toLowerCase()
                dictType[item.SiteID] = formattedGenType
            });
            setSiteIdToName(dictName);
            setSiteIDToType(dictType);
        }
        availabilityApi.getEngines()
            .then((engines) => {
                let dict = new Map<string, Array<IEngine>>();
                engines.forEach((engine) => {
                    var generators = dict.get(engine.siteID);
                    if (generators !== undefined) {
                        generators.push(engine);
                        dict.set(engine.siteID, generators);
                    }
                    else {
                        dict.set(engine.siteID, new Array<IEngine>(engine));
                    }
                })
                setSiteIDToGenerators(dict);
            })
    }

    useEffect(() => {
        fetchSiteData()
    }, [assets])

    const filterOutage = (outage: IEngineOutage) => {
        return (
            asset === "" ||
            asset === "Any" ||
            (asset === outage.siteID && generator === "Any") ||
            (asset === outage.siteID && generator === outage.engineID)
        )
    }

    useEffect(() => {
        const filtered = engineOutagesLive.filter((outage) => filterOutage(outage))
        setFilteredOutages(filtered)
    }, [engineOutagesLive, asset, generator])

    const fetchPageData = () => {
        setLiveOutagesLoaded(false)
        availabilityApi.getUnfinishedOutages()
            .then((liveOutageData) => {
                setEngineOutagesLive(liveOutageData);
            })
            .finally(() => {
                setLiveOutagesLoaded(true)
            })
    }

    useEffect(() => {
        fetchPageData()

        const intervalId = setInterval(() => {
            fetchPageData(); // Fetch data every 2 minutes
        }, 120000);

        return () => clearInterval(intervalId);
    }, [])

    return (
        <PageWrapper title="Engine Outages" loaded={true}>
            <Grid container spacing={2}>
                <Grid xs={12}>
                    <Paper sx={{ p:1 }}>
                        <Typography variant="h5" sx={{ mb: 2 }}>Filter by Site/Engine</Typography>
                        <AssetEngineSelector asset={asset} setAsset={setAsset} generator={generator} setGenerator={setGenerator} siteIdToName={siteIdToName} siteIDToGenerators={siteIDToGenerators} siteIDToType={siteIDToType} anyAsset={true} anyGenerator={true} />
                    </Paper>
                </Grid>
                <Grid xs={12}>
                    <Paper sx={{ p: 1 }}>
                        {(liveOutagesLoaded || engineOutagesLive.length > 0 ? <EngineOutageTable items={filteredOutages} siteIdToName={siteIdToName} searchBar={true} maxHeight={isMobile ? 440 : 550}></EngineOutageTable> : <LoadingSymbol />)}
                    </Paper>
                </Grid>
            </Grid>
        </PageWrapper>
    );
}

export interface IEngineOutagesPage {

}

const EngineOutagesPageConfig: IPortalRouteOptions = {
    relativeUrl: "existing-outages",
    page: <EngineOutagesPage />,
    navDisplay: {
        title: "Existing Outages",
        icon: <EditCalendar />
    }
}

export default EngineOutagesPageConfig;
