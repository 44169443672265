import { ArrowBack, Home } from "@mui/icons-material";
import { Button, Container, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function ErrorPage() {
  const message = "Sorry, an unexpected error has occurred. You may not have permission to view this page."
  const navigate = useNavigate();

  return (
    <Container maxWidth={false}>
      <Grid container textAlign="center" spacing={5}>
        <Grid item xs={12}></Grid>
        <Grid item xs={12}>
          <Typography variant="h2" mb={2}>Oops!</Typography>
          <Typography variant="body1" mb={2}>{message}</Typography>
          <Button variant="contained" onClick={() => navigate(-1)} sx={{ mr: 1 }} startIcon={<ArrowBack/>}>Go Back</Button>
          <Button variant="contained" onClick={() => navigate("/")} startIcon={<Home/>}>Return Home</Button>
        </Grid>
      </Grid>
    </Container>
  );
}