import { IGasPosition } from "../types/gas-reconciliation/gasposition";

export interface CalculatedResidualsProps {
    items: IGasPosition[];
    total: number;
}

export const CalculateResiduals = (data: IGasPosition[]): CalculatedResidualsProps => {
    const total = data.reduce((sum, current) => {
        return (
            sum +
            (current.siteType === 0
                ? current.override === 0
                    ? current.burn - current.traded
                    : current.override - current.traded
                : current.volume + current.override)
        );
    }, 0);

    const items = data.map(x => {
        if (x.siteType === 0) {
            x.residual = x.override === 0 ? x.burn - x.traded : x.override - x.traded;
        }
        else {
            x.residual = x.volume + x.override;
        }
        return x;
    });

    return { items: items, total: total };
};
