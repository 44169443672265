import React, { useEffect, useState } from "react";
import { IPermissionsConfig, IPermissionsRequestBody, useAddOrUpdateUserPermissionsMutation } from "../../../shared/api/PermissionsApi";
import { Box, Button, ButtonGroup, Collapse, TableCell, TableRow } from "@mui/material";
import { Cancel, DeleteForever, Edit, Save } from "@mui/icons-material";
import { AllPermissions, PermissionsArea, PermissionsType } from "../../../shared/types/shared/auth/permissionsTypes";
import { IAdvancedModalOptions, useModalWindowContext } from "../../ModalWindow";
import { useToastAlertContext } from "../../ToastAlert";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { SerializedError } from "@reduxjs/toolkit";
import PermissionsTable from "./PermissionsTable";

const PermissionsRow: React.FC<IPermissionsRowProps> = ({ permissions, inputDisabled, saveConfig, deleteConfig, reload }) => {
    const { openModal } = useModalWindowContext();
    const { popToast } = useToastAlertContext();
    const [editing, setEditing] = useState(false);
    const [stagingPermissions, setStagingPermissions] = useState<IPermissionsConfig>();

    useEffect(() => {
        const clone = JSON.parse(JSON.stringify(permissions));
        setStagingPermissions(clone);
    }, [permissions]);

    const permissionsUpdated = (area: PermissionsArea, permissions: PermissionsType) => {
        setStagingPermissions((prevState) => {
            if (prevState) {
                prevState.permissions[area] = permissions;
            }

            return prevState;
        });
    }

    const saveChanges = () => {
        if (!stagingPermissions) {
            console.error("Oops")
            return;
        }

        const body = Object.entries(stagingPermissions.permissions)
            .map<IPermissionsRequestBody>((val) => {
                return {
                    id: stagingPermissions.id,
                    portalArea: val[0],
                    permissionsFlag: val[1],
                }
            });

        saveConfig(body)
            .then(() => {
                setEditing(false);
                reload();
            })
            .catch(() => {
                popToast(`Failed to save permissions for ${stagingPermissions.id}`);
            });

        setEditing(false);
    }

    const confirmDelete = async (id: string) => {
        const modalConfig: IAdvancedModalOptions = {
            title: `Delete permissions?`,
            content: `This will permanently delete all permissions assigned to ${id}`,
            yesText: "Yes",
            noText: "No",
        }

        const confirm = await openModal(modalConfig);

        if (confirm) {
            await deleteConfig(id);
        }
    }

    return (
        <React.Fragment>
            <TableRow sx={{ '& > td': { borderBottom: 'unset' } }}>
                <TableCell>
                    {permissions.id}
                </TableCell>
                <TableCell align="right">
                    {
                        editing ?
                            (
                                <ButtonGroup variant="contained" disabled={inputDisabled}>
                                    <Button onClick={() => setEditing(false)}>
                                        <Cancel />
                                    </Button>
                                    <Button onClick={() => saveChanges()}>
                                        <Save />
                                    </Button>
                                </ButtonGroup>
                            )
                            :
                            (
                                <ButtonGroup variant="contained" disabled={inputDisabled}>
                                    <Button onClick={() => setEditing(!editing)}>
                                        <Edit />
                                    </Button>
                                    <Button onClick={() => confirmDelete(permissions.id)}>
                                        <DeleteForever />
                                    </Button>
                                </ButtonGroup>
                            )
                    }
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={2}>
                    <Collapse in={editing} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            {Object.entries(AllPermissions).map((areaPerms) => {
                                return (
                                    <PermissionsTable
                                        key={areaPerms[0]}
                                        title={areaPerms[0] as PermissionsArea}
                                        allPermissions={areaPerms[1]} userPermissions={permissions}
                                        permissionsUpdated={permissionsUpdated}
                                    />
                                );
                            })}
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export interface IPermissionsRowProps {
    permissions: IPermissionsConfig;
    inputDisabled?: boolean;
    deleteConfig: (id: string) => Promise<void>;
    saveConfig: (body: IPermissionsRequestBody[]) => Promise<{ data: string; } | { error: FetchBaseQueryError | SerializedError; }>;
    reload: () => void;
}

export default PermissionsRow;
