import { TextField, styled } from "@mui/material";
import { DatePicker, PickersDay, PickersDayProps } from "@mui/x-date-pickers";
import { Moment } from "moment";

interface ICustomPickerDayProps extends PickersDayProps<Moment> {
    dayIsBetween: boolean;
    isFirstDay: boolean;
    isLastDay: boolean;
}

const CustomPickersDay = styled(PickersDay, {
    shouldForwardProp: (prop) =>
        prop !== 'dayIsBetween' && prop !== 'isFirstDay' && prop !== 'isLastDay',
})<ICustomPickerDayProps>(({ theme, dayIsBetween, isFirstDay, isLastDay }) => ({
    ...(dayIsBetween && {
        borderRadius: 0,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        '&:hover, &:focus': {
            backgroundColor: theme.palette.primary.dark,
        },
        boxShadow: `-2px 0 0 ${theme.palette.primary.main}, 2px 0 0 ${theme.palette.primary.main}`,
    }),
    ...(isFirstDay && {
        borderTopLeftRadius: '50%',
        borderBottomLeftRadius: '50%',
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        boxShadow: `2px 0 0 ${theme.palette.primary.main}`,
    }),
    ...(isLastDay && {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        borderTopRightRadius: '50%',
        borderBottomRightRadius: '50%',
        boxShadow: `-2px 0 0 ${theme.palette.primary.main}`,
    }),
})) as React.ComponentType<ICustomPickerDayProps>;

const WeekSelection: React.FC<IDynamicContractsDaySelectionProps> = ({ label, value, onChange, disabled }) => {
    const getWeekBounds = (value: Moment) => {
        const start = value.clone().startOf('week');
        const end = value.clone().endOf('week');

        return { start, end };
    }
    
    const renderWeekPickerDay = (
        date: Moment,
        selectedDates: Array<Moment | null>,
        pickersDayProps: PickersDayProps<Moment>,
    ) => {
        if (!value) {
            return <PickersDay {...pickersDayProps} />;
        }

        const { start, end } = getWeekBounds(value);

        const dayIsBetween = date.isBetween(start, end);
        const isFirstDay = date.isSame(start, 'day');
        const isLastDay = date.isSame(end, 'day');

        return (
            <CustomPickersDay
                {...pickersDayProps}
                dayIsBetween={dayIsBetween}
                isFirstDay={isFirstDay}
                isLastDay={isLastDay}
            />
        );
    };

    return (
        <DatePicker
            label={label}
            inputFormat="DD/MM/YYYY"
            value={value}
            onChange={onChange}
            disabled={disabled}
            renderInput={(params) => <TextField {...params} fullWidth/>}
            renderDay={renderWeekPickerDay}
        />
    );
}

export interface IDynamicContractsDaySelectionProps {
    label: string;
    value: Moment;
    onChange: (Value: Moment | null) => void;
    disabled?: boolean;
}

export default WeekSelection;
