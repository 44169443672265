import React, { useEffect } from "react";
import PageWrapper from "../../components/PageWrapper";
import imageOptimise from "../../images/iON_Optimise.png";
import { IPortalRouteOptions } from "../../shared/types/shared/routes/routeTypes";
import { Dashboard } from "@mui/icons-material";

const OptimiseHomePage: React.FC<IOptimiseHomePage> = () => {
  const [loaded, setLoaded] = React.useState(false);

  const loadComplete = () => {
    setLoaded(true);
  }

  setTimeout(loadComplete, 500);

  return (
    <PageWrapper title="Optimise" loaded={loaded}>
              <img alt="Optimise" src={imageOptimise} width="100%" />
    </PageWrapper>
  );
}

export interface IOptimiseHomePage {

}

const OptimiseHomePageConfig: IPortalRouteOptions = {
  relativeUrl: "optimise",
  page: <OptimiseHomePage />,
  navDisplay: {
      title: "Dashboard",
      icon: <Dashboard/>
  }
}

export default OptimiseHomePageConfig;
