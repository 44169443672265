import { Hexagon } from "react-hexgrid";
import { AssetType, ISiteStatus, SiteCommsStatus, SiteRunningStatus } from "../types/ISiteStatus";
import { CubicCoords, HexGridData, HexGridTile, HiveGenerationMode } from "../types/shared/hex-grid";
import { BatteryChargingFull, Bolt, GasMeter, Propane, WbSunny, WindPower } from "@mui/icons-material";
import { IStatusHivePlacementResult, StatusHiveDataTile, StatusHiveSiteData, StatusHiveTileStatus } from "../types/asset/status-hive";

const isIdle = (status: ISiteStatus): boolean => {
    return status.siteRunningStatus === SiteRunningStatus.NoPNNoRunAuto;
}

const getTileStatus = (status: ISiteStatus): StatusHiveTileStatus => {
    if (!isIdle(status)) { return StatusHiveTileStatus.Active; }

    const hourLimit = 12;
    if (status.minutesToDispatch / 60 < hourLimit) {
        return StatusHiveTileStatus.Pending;
    }

    return StatusHiveTileStatus.Idle;
}

const getAssetTypeIcon = (type?: AssetType) => {
    switch (type) {
        case AssetType.Battery:
            return BatteryChargingFull;
        case AssetType.Gas:
            return Propane;
        case AssetType.Diesel:
            return GasMeter;
        case AssetType.Solar:
            return WbSunny;
        case AssetType.Wind:
            return WindPower;
        default:
            return Bolt;
    }
}

const getCommsStatusIconData = (status?: SiteCommsStatus) => {
    switch (status) {
        case SiteCommsStatus.Healthy:
            return { colour: HiveColours.Running, tooltip: SiteCommsStatus[status] }
        case SiteCommsStatus.Warning:
            return { colour: HiveColours.Warning, tooltip: SiteCommsStatus[status] }
        case SiteCommsStatus.Error:
            return { colour: HiveColours.Error, tooltip: SiteCommsStatus[status] }
        case SiteCommsStatus.InMaintenance:
            return { colour: HiveColours.Manual, tooltip: "In Maintenance" }
        case SiteCommsStatus.OutOfService:
            return { colour: HiveColours.Idle, tooltip: "Out of Service" }
        default:
            return { colour: HiveColours.Idle, tooltip: "Unknown" }
    }
}

const getDispatchText = (minutesToDispatch: number): string => {
    if (minutesToDispatch === 0) {
        return `Dispatching...`;
    }

    let minutes = minutesToDispatch;
    let hours = 0;
    while (minutes >= 60) {
        minutes -= 60;
        hours += 1;
    }

    const hoursText = hours > 0 ? `${hours}hr ` : '';
    const minutesText = minutes > 0 || hours === 0 ? `${minutes}mins` : '';

    return `T - ${hoursText}${minutesText}`;
}

export const generateStatusHiveSiteData = (status: ISiteStatus): StatusHiveSiteData => {
    const siteData: StatusHiveSiteData = {
        ...status,
        priority: Number.MAX_SAFE_INTEGER,
        colour: HiveColours.Warning,
        statusHumanReadable: "Unknown",
        tileStatus: getTileStatus(status),
        assetIcon: getAssetTypeIcon(status.assetType),
        commsIconData: getCommsStatusIconData(status.commsHealthStatus),
        dispatchTimeHumanReadable: getDispatchText(status.minutesToDispatch),
    }

    switch (status.siteRunningStatus) {
        case SiteRunningStatus.GeneratingToPNAuto:
            siteData.priority = 1;
            siteData.colour = HiveColours.Running;
            siteData.statusHumanReadable = "Running";
            break;
        case SiteRunningStatus.ManualDispatch:
        case SiteRunningStatus.GeneratingToPNManual:
        case SiteRunningStatus.UnScheduledRunManual:
        case SiteRunningStatus.FailedPNRunManual:
        case SiteRunningStatus.NoPNNoRunManual:
            siteData.priority = 2;
            siteData.colour = HiveColours.Manual;
            siteData.statusHumanReadable = "Manual";
            break;
        case SiteRunningStatus.GeneratingToBidAuto:
            siteData.priority = 2;
            siteData.colour = HiveColours.RunningBid;
            siteData.statusHumanReadable = "Run (Bid)";
            break;
        case SiteRunningStatus.GeneratingToOfferAuto:
            siteData.priority = 2;
            siteData.colour = HiveColours.RunningOffer;
            siteData.statusHumanReadable = "Run (Offer)";
            break;
        case SiteRunningStatus.DNODispatch:
            siteData.priority = 3;
            siteData.colour = HiveColours.DNO;
            siteData.statusHumanReadable = "DNO";
            break;
        case SiteRunningStatus.UnScheduledRunAuto:
            siteData.priority = 3;
            siteData.colour = HiveColours.Unscheduled;
            siteData.statusHumanReadable = "Uncontracted";
            break;
        case SiteRunningStatus.FailedPNRunAuto:
            siteData.priority = 3;
            siteData.colour = HiveColours.Error;
            siteData.statusHumanReadable = "Unavailable";
            break;
        case SiteRunningStatus.NoPNNoRunAuto:
            if (siteData.tileStatus === StatusHiveTileStatus.Idle) {
                siteData.priority = 5;
            } else {
                siteData.priority = 4; // Tiles displaying dispatch times are higher priority
            }

            siteData.colour = HiveColours.Idle;
            siteData.statusHumanReadable = "Idle";
            break;
    }

    return siteData;
}

export const createBackgroundTile = (tile: HexGridTile): JSX.Element => {
    return (
        <Hexagon
            q={tile.coords.q}
            r={tile.coords.r}
            s={tile.coords.s}
            fill={tile.isOffscreen ? "hatching" : "none"}
            stroke="black"
            strokeOpacity={0.25}
            strokeWidth={"0.04em"}
            key={tile.coords.value}
            id={tile.coords.value}
        />
    );
}

const sortSiteStatusData = (a: StatusHiveSiteData, b: StatusHiveSiteData) => {
    if (a.priority < b.priority) {
        return -1;
    }
    if (a.priority > b.priority) {
        return 1;
    }

    if (a.siteId < b.siteId) {
        return -1;
    }
    if (a.siteId > b.siteId) {
        return 1;
    }

    return 0;
}

export const processData = (statuses: ISiteStatus[]): StatusHiveSiteData[] => {
    const data: StatusHiveSiteData[] = statuses.map((status) => {
        return { ...status, ...generateStatusHiveSiteData(status) }
    });

    return data.sort(sortSiteStatusData);
}

export const generateDataTiles = (siteData: StatusHiveSiteData[], grid: HexGridData, generationMode: HiveGenerationMode, reserveCentreTile?: boolean): IStatusHivePlacementResult => {
    switch (generationMode) {
        case HiveGenerationMode.Clusters:
            return placeTilesClusters(siteData, grid, reserveCentreTile);
        case HiveGenerationMode.Hive:
        default:
            return placeTilesHive(siteData, grid, reserveCentreTile);
    }
}

const tilePlacement = (data: StatusHiveSiteData, getViableTiles: (data: StatusHiveSiteData) => HexGridTile[], grid: HexGridData) => {
    const currentViableTiles = getViableTiles(data);
    let tileToPlace: HexGridTile | undefined;
    for (var i = currentViableTiles.length; i--;) {
        if (currentViableTiles[i].canPlace()) {
            tileToPlace = currentViableTiles.pop();
            break;
        }

        currentViableTiles.splice(i, 1);
    }

    if (!tileToPlace) {
        return null;
    }

    const neighbourCoords = tileToPlace.getNeighbours();
    currentViableTiles.unshift(...grid.getTilesAtCoords(neighbourCoords));

    const dataTile = new StatusHiveDataTile(tileToPlace.coords, data);
    tileToPlace.isOccupied = true;
    return dataTile;
}

const failedTilePlacement = (failed: StatusHiveSiteData[], mapped: Set<string>, grid: HexGridData) => {
    if (failed.length <= 0) { return [] };

    const placed: StatusHiveDataTile[] = [];

    const unoccupiedTiles = grid.getUnoccupiedTiles();
    for (var i = failed.length; i--;) {
        if (unoccupiedTiles.length <= 0) { break; }

        const data = failed[i];

        if (mapped.has(data.siteId)) {
            console.warn("Duplicate site ID found.");
            continue;
        }

        const tileToPlace = unoccupiedTiles.pop();
        if (!tileToPlace) {
            continue;
        }

        const dataTile = new StatusHiveDataTile(tileToPlace.coords, data);
        placed.push(dataTile);
        tileToPlace.isOccupied = true;
        mapped.add(data.siteId);

        failed.pop();
    };

    return placed;
}

const placeTilesHive = (siteData: StatusHiveSiteData[], grid: HexGridData, reserveCentreTile?: boolean): IStatusHivePlacementResult => {
    const dataTiles: StatusHiveDataTile[] = [];

    const currentViableTiles: HexGridTile[] = [];

    const { centre } = grid.getAnchorPoints();
    const centreTile = grid.getTileAtCoords(centre);
    if (!centreTile) {
        return { placed: [], failed: siteData };
    }

    if (!reserveCentreTile) {
        currentViableTiles.push(centreTile);
    } else {
        centreTile.isOccupied = true;
        const neighbourCoords = centreTile.getNeighbours();
        currentViableTiles.unshift(...grid.getTilesAtCoords(neighbourCoords));
    }

    const mapped = new Set<string>();
    const failed: StatusHiveSiteData[] = [];
    siteData.forEach((data) => {
        if (mapped.has(data.siteId)) {
            console.log("Duplicate site ID found.");
            return;
        }

        const dataTile = tilePlacement(data, () => currentViableTiles, grid);
        if (!dataTile) {
            failed.push(data);
        } else {
            mapped.add(data.siteId);
            dataTiles.push(dataTile);
        }
    });

    const bruteForced = failedTilePlacement(failed, mapped, grid);
    dataTiles.push(...bruteForced);

    return { placed: dataTiles, failed };
}

const placeTilesClusters = (siteData: StatusHiveSiteData[], grid: HexGridData, reserveCentreTile?: boolean): IStatusHivePlacementResult => {
    const dataTiles: StatusHiveDataTile[] = [];

    const viableClusterC: HexGridTile[] = [];
    const viableClusterTL: HexGridTile[] = [];
    const viableClusterTR: HexGridTile[] = [];
    const viableClusterBL: HexGridTile[] = [];
    const viableClusterBR: HexGridTile[] = [];

    const { centre, topLeft, topRight, bottomLeft, bottomRight } = grid.getAnchorPoints();
    const centreTile = grid.getTileAtCoords(centre);

    if (centreTile) {
        if (!reserveCentreTile) {
            viableClusterC.push(centreTile);
        } else {
            centreTile.isOccupied = true;
            const neighbourCoords = centreTile.getNeighbours();
            viableClusterC.unshift(...grid.getTilesAtCoords(neighbourCoords));
        }
    }

    const topLeftTile = grid.getTileAtCoords(topLeft);
    topLeftTile && viableClusterTL.push(topLeftTile);

    const topRightTile = grid.getTileAtCoords(topRight);
    topRightTile && viableClusterTR.push(topRightTile);

    const bottomLeftTile = grid.getTileAtCoords(bottomLeft);
    bottomLeftTile && viableClusterBL.push(bottomLeftTile);

    const bottomRightTile = grid.getTileAtCoords(bottomRight);
    bottomRightTile && viableClusterBR.push(bottomRightTile);

    if (centreTile && reserveCentreTile) {
        centreTile.isOccupied = true;
    }

    const getCluster = (data: StatusHiveSiteData): HexGridTile[] => {
        switch (data.siteRunningStatus) {
            case SiteRunningStatus.GeneratingToBidAuto:
            case SiteRunningStatus.GeneratingToOfferAuto:
                return viableClusterTR;
            case SiteRunningStatus.DNODispatch:
                return viableClusterTL;
            case SiteRunningStatus.UnScheduledRunAuto:
                return viableClusterBL;
            case SiteRunningStatus.FailedPNRunAuto:
                return viableClusterBR;
            default:
                return viableClusterC;
        }
    }

    const mapped = new Set<string>();
    const failed: StatusHiveSiteData[] = [];
    siteData.forEach((data) => {
        if (mapped.has(data.siteId)) {
            console.log("Duplicate site ID found.");
            return;
        }

        const dataTile = tilePlacement(data, getCluster, grid);
        if (!dataTile) {
            failed.push(data);
        } else {
            mapped.add(data.siteId);
            dataTiles.push(dataTile);
        }
    });

    const bruteForced = failedTilePlacement(failed, mapped, grid);
    dataTiles.push(...bruteForced);

    return { placed: dataTiles, failed };
}

export const HiveColours = {
    Running: "#32CD32",
    Manual: "#00BFFF",
    DNO: "#9932CC",
    RunningBid: "#4169E1",
    RunningOffer: "#F08080",
    Unscheduled: "#FFD700",
    Idle: "#808080",
    Error: "#FF4500",
    Warning: "#FFA500",
    ION: "#F15B24",
}

export const GetUKLayout = (centreCoords: CubicCoords): CubicCoords[] => {
    const returnVal = [centreCoords];

    returnVal.push(new CubicCoords(centreCoords.q, centreCoords.r - 1, centreCoords.s + 1));
    returnVal.push(new CubicCoords(centreCoords.q, centreCoords.r + 1, centreCoords.s - 1));
    returnVal.push(new CubicCoords(centreCoords.q - 1, centreCoords.r + 1, centreCoords.s));

    return returnVal;
}
