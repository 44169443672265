import { PortalArea } from "../types/shared/routes/routeHelperClasses";
import ConradAnalyticsLogo from '../../images/Conrad_Analytics_Icon.png';
import { Analytics } from "@mui/icons-material";
import AnalyticsAdminPageConfig from "../../pages/analytics/admin/AnalyticsAdminPage";
import AnalyticsHomePageConfig from "../../pages/analytics/AnalyticsHomePage";
import AnalyticsGasSystemLengthPageConfig from "../../pages/analytics/AnalyticsGasSystemLengthPage";
import AnalyticsGasFlowPageConfig from "../../pages/analytics/AnalyticsGasFlowPage";
import AnalyticsChartPageConfig from "../../pages/analytics/AnalyticsChartsPage";
import AnalyticsFuelTypePageConfig from "../../pages/analytics/AnalyticsFuelTypePage";
import AnalyticsWindPageConfig from "../../pages/analytics/AnalyticsWindPage";
import AnalyticsBalancingVolumesPageConfig from "../../pages/analytics/AnalyticsBalancingVolumesPage";
import AnalyticsBalancingPricingPageConfig from "../../pages/analytics/AnalyticsBalancingPricingPage";
import AnalyticsWithinDayMarketPageConfig from "../../pages/analytics/AnalyticsWithinDayMarketPage";
import { IPortalAreaOptions } from "../types/shared/routes/routeTypes";
import { AnalyticsPermissions } from "../types/shared/auth/permissionsTypes";

const areaOptions: IPortalAreaOptions = {
    title: "Analytics",
    description: "Market Analytics, Operational Analytics",
    iconImage: ConradAnalyticsLogo,
    allValidPermissions: { Analytics: AnalyticsPermissions.AllPermissions }
}

const AnalyticsPortalArea = new PortalArea(areaOptions, AnalyticsHomePageConfig);
AnalyticsPortalArea.addChildRoute(AnalyticsAdminPageConfig);

const gasSubMenu = AnalyticsPortalArea.addChildRoute({ relativeUrl: "gas", navDisplay: { title: "Gas", icon: <Analytics />, } });
gasSubMenu.addChildRoute(AnalyticsGasSystemLengthPageConfig);
gasSubMenu.addChildRoute(AnalyticsGasFlowPageConfig);

const mediaWallChartsSubMenu = AnalyticsPortalArea.addChildRoute({ relativeUrl: "media", navDisplay: { title: "Media Wall", icon: <Analytics /> } });
mediaWallChartsSubMenu.addChildRoute(AnalyticsChartPageConfig);
mediaWallChartsSubMenu.addChildRoute(AnalyticsFuelTypePageConfig);
mediaWallChartsSubMenu.addChildRoute(AnalyticsWindPageConfig);
mediaWallChartsSubMenu.addChildRoute(AnalyticsBalancingVolumesPageConfig);
mediaWallChartsSubMenu.addChildRoute(AnalyticsBalancingPricingPageConfig);
mediaWallChartsSubMenu.addChildRoute(AnalyticsWithinDayMarketPageConfig);

export default AnalyticsPortalArea;
