import { TableChart } from "@mui/icons-material";
import DateRangeIcon from '@mui/icons-material/DateRange';
import TodayIcon from '@mui/icons-material/Today';
import { Box, Paper, Tab, Tabs } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment, { Moment } from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import DynamicContractsDaySelection from "../../../components/dynamic-contracts/DynamicContractsDaySelection";
import DynamicContractsGanttDayView from "../../../components/dynamic-contracts/DynamicContractsGanttDayView";
import DynamicContractsGanttWeekView from "../../../components/dynamic-contracts/DynamicContractsGanttWeekView";
import DynamicContractsWeekSelection from "../../../components/dynamic-contracts/DynamicContractsWeekSelection";
import PageWrapper from "../../../components/PageWrapper";
import TabPanel from "../../../components/TabPanel";
import { useToastAlertContext } from "../../../components/ToastAlert";
import contractListApi from "../../../shared/api/PerfmonStatus";
import { IContractInformation } from "../../../shared/types/dynamic-contracts/IDynamicContract";
import { IPortalRouteOptions } from "../../../shared/types/shared/routes/routeTypes";

enum GanttChartTab {
    "Day" = 0,
    "Week" = 1,
}

const DynamicContractsChartPage: React.FC = () => {
    const { popToast } = useToastAlertContext();

    const defaultDayStartDate = useMemo(() => moment.utc().startOf("day"), []);
    const defaultWeekStartDate = useMemo(() => moment.utc().startOf("day").startOf("week"), []);

    const [selectedTab, setSelectedTab] = useState<GanttChartTab>(GanttChartTab.Day);
    const [dayStartDate, setDayStartDate] = useState<Moment>(defaultDayStartDate);
    const [dayContracts, setDayContracts] = useState<IContractInformation[]>([]);
    const [dayLoaded, setDayLoaded] = useState(false);
    const [dayQuerying, setDayQuerying] = useState<boolean>(true);
    const [weekStartDate, setWeekStartDate] = useState<Moment>(defaultWeekStartDate);
    const [weekContracts, setWeekContracts] = useState<IContractInformation[]>([]);
    const [weekLoaded, setWeekLoaded] = useState(false);
    const [weekQuerying, setWeekQuerying] = useState<boolean>(true);

    const dayLoadComplete = () => {
        setDayLoaded(true);
        setDayQuerying(false);
    }

    const weekLoadComplete = () => {
        setWeekLoaded(true);
        setWeekQuerying(false);
    }

    const onClickWeekDay = (dateToShow: Moment) => {
        setDayStartDate(dateToShow);

        const contractList = weekContracts.filter((contract) => {
            const deliveryDate = moment(contract.deliveryDate);
            return dateToShow.isSame(deliveryDate, "date");
        });

        setDayContracts(contractList);
        setSelectedTab(GanttChartTab.Day);
    }

    const updateDayStartDate = (Value: Moment | null) => {
        if (!Value) { return; }

        setDayStartDate(Value);
    }

    const updateWeekStartDate = (Value: Moment | null) => {
        if (!Value) { return; }

        setWeekStartDate(Value);
    }

    const updateTab = (event: React.SyntheticEvent, newValue: number) => {
        setSelectedTab(newValue);
    };

    const dayQueryRequest = useCallback((startDate: Moment) => {
        const reqEndDate = moment(startDate).endOf("day");
        const reqStartDate = moment(startDate);

        contractListApi.getContractList(reqStartDate.subtract(1, "hour").toISOString(), reqEndDate.toISOString())
            .then((contracts) => {
                contracts.sort((a, b) => {
                    return a.siteName > b.siteName ? 1 : -1;
                })
                setDayContracts(contracts);
            })
            .catch((error) => { popToast(`Failed to load file list: ${error}`, "error"); })
            .finally(dayLoadComplete);
    }, [popToast]);

    const clickDayQueryRequestButton = () => {
        setDayQuerying(true);
        dayQueryRequest(dayStartDate);
    }

    const weekQueryRequest = useCallback((startDate: Moment) => {
        const reqEndDate = moment(startDate).endOf("week");
        const reqStartDate = moment(startDate);

        contractListApi.getContractList(reqStartDate.subtract(1, "hour").toISOString(), reqEndDate.toISOString())
            .then((contracts) => {
                contracts.sort((a, b) => {
                    return a.siteName > b.siteName ? 1 : -1;
                })
                setWeekContracts(contracts);
            })
            .catch((error) => { popToast(`Failed to load file list: ${error}`, "error"); })
            .finally(weekLoadComplete);
    }, [popToast]);

    const clickWeekQueryRequestButton = () => {
        setWeekQuerying(true);
        weekQueryRequest(weekStartDate);
    }

    useEffect(() => {
        dayQueryRequest(defaultDayStartDate);
        weekQueryRequest(defaultWeekStartDate);
    }, [dayQueryRequest, defaultDayStartDate, weekQueryRequest, defaultWeekStartDate]);

    return (
        <PageWrapper title="Dynamic Contracts" loaded={dayLoaded && weekLoaded}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <Paper sx={{ p: 2 }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={selectedTab} onChange={updateTab} variant="fullWidth">
                            <Tab icon={<TodayIcon />} label="Day View" />
                            <Tab icon={<DateRangeIcon />} label="Week View" />
                        </Tabs>
                    </Box>
                    <TabPanel value={selectedTab} index={GanttChartTab.Day} padding={0} paddingTop={2}>
                        <Grid container spacing={2}>
                            <Grid xs={12}>
                                <DynamicContractsDaySelection value={dayStartDate} onChange={updateDayStartDate} querying={dayQuerying} onClickRefresh={clickDayQueryRequestButton} />
                            </Grid>
                            <Grid xs={12}>
                                <Box sx={{ width: "100%", overflowX: "auto" }}>
                                    <DynamicContractsGanttDayView contracts={dayContracts} />
                                </Box>
                            </Grid>
                        </Grid>
                    </TabPanel>
                    <TabPanel value={selectedTab} index={GanttChartTab.Week} padding={0} paddingTop={2}>
                        <Grid container spacing={2}>
                            <Grid xs={12}>
                                <DynamicContractsWeekSelection value={weekStartDate} onChange={updateWeekStartDate} querying={weekQuerying} onClickRefresh={clickWeekQueryRequestButton} />
                            </Grid>
                            <Grid xs={12}>
                                <Box sx={{ width: "100%", overflowX: "auto" }}>
                                    <DynamicContractsGanttWeekView contracts={weekContracts} onClickWeekDay={onClickWeekDay} />
                                </Box>
                            </Grid>
                        </Grid>
                    </TabPanel>
                </Paper>
            </LocalizationProvider>
        </PageWrapper>
    );
}

const DynamicContractsChartPageConfig: IPortalRouteOptions = {
    relativeUrl: "dynamic-contracts-performance",
    page: <DynamicContractsChartPage />,
    navDisplay: {
        title: "DC Performance",
        icon: <TableChart />
    }
}

export default DynamicContractsChartPageConfig;
