import { Wrapper } from "@googlemaps/react-wrapper";
import { Grid } from "@mui/material";
import React from "react";
import GoogleMap from "../../components/asset/map/GoogleMap";
import PageWrapper from "../../components/PageWrapper";
import { IPortalRouteOptions } from "../../shared/types/shared/routes/routeTypes";
import { LocationOn } from "@mui/icons-material";

const AssetMapPage: React.FC<IAssetMapPage> = () => {
    const [loaded, setLoaded] = React.useState(false);

    const loadComplete = () => {
        setLoaded(true);
    }

    setTimeout(loadComplete, 500);

    return (
        <PageWrapper title="Map of Assets" loaded={loaded}>
            <Grid container spacing={2}>
                <Grid item md={12}>
                    <Wrapper apiKey={`${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`} libraries={["marker"]}>
                        <GoogleMap />
                    </Wrapper>
                </Grid>
            </Grid>
        </PageWrapper>
    );
}

export interface IAssetMapPage {

}

const AssetMapPageConfig: IPortalRouteOptions = {
    relativeUrl: "map",
    page: <AssetMapPage />,
    navDisplay: {
        title: "Map",
        icon: <LocationOn />
    },
    hideOnMobile: true
}

export default AssetMapPageConfig;


