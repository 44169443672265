import { Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import AssetsDataGrid from "../../components/asset/AssetsDataGrid";
import PageWrapper from "../../components/PageWrapper";
import { useGetAssetsQuery } from "../../shared/api/AssetApi";
import { IPortalRouteOptions } from "../../shared/types/shared/routes/routeTypes";
import { Business } from "@mui/icons-material";
import { useNavigate } from "react-router";

const AssetRegisterPage: React.FC<IAssetRegisterPage> = () => {
    const [loaded, setLoaded] = useState(false);
    const { data: assets, isSuccess, isLoading } = useGetAssetsQuery();
    const navigate = useNavigate();

    useEffect(() => {
        if (!isLoading && isSuccess) {
            setLoaded(true);
        }
    }, [isLoading, isSuccess]);

    const handleOnSelect = (assetid: string) => {
        if (assetid === null || assetid === undefined || assetid === "") return;
        navigate(`/asset/details?assetid=${assetid}`);
    }

    return (
        <PageWrapper title="Asset Register" loaded={loaded}>
            <Paper sx={{ height: "calc(100% - 1px)" }}>
                <AssetsDataGrid items={assets} onSelect={handleOnSelect} />
            </Paper>
        </PageWrapper>
    );
}

export interface IAssetRegisterPage {

}

const AssetRegisterPageConfig: IPortalRouteOptions = {
    relativeUrl: "register",
    page: <AssetRegisterPage />,
    navDisplay: {
        title: "Register",
        icon: <Business />
    }
}

export default AssetRegisterPageConfig;