import React from 'react';
import moment from "moment"
import { Box, Button } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { CSVLink } from 'react-csv';
import { FileDownload } from "@mui/icons-material";
const CSVButton: React.FC<ICSVButtonProps> = ({columns, data, disabled, filename, xs = 3}) => {

    return (
        <Grid xs={xs} sx={{height:"100%"} }>
            <Box sx={{ position: 'relative', height: "100%" }}>
                <CSVLink data={data} headers={columns !== undefined ? columns : undefined} filename={filename !== undefined ? filename : "CSV-Data-Request-" + moment().toISOString() + ".csv"}>
                    <Button
                        variant="contained"
                        startIcon={<FileDownload />}
                        disabled={disabled || data.length === 0}
                        size="large"
                        sx={{ height: "55px" }}
                        fullWidth
                    >
                        Download CSV
                    </Button>
                </CSVLink>
            </Box>
        </Grid>
    );

}

export interface ICSVButtonProps {
    columns?: {key: string, label: string}[]
    data: any
    disabled?: boolean
    filename?: string
    xs?: number
}

export default CSVButton;