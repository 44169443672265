import { Breadcrumbs, CircularProgress, Container, Grid, Grow, Link, Stack, Typography } from "@mui/material";
import React, { PropsWithChildren } from "react";
import * as Router from "react-router-dom";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import LoadingSpinner from "./LoadingSpinner";
import { Property } from "csstype";

const PageWrapper: React.FC<PropsWithChildren<IPageWrapperProps>> = ({ title, loaded, refreshing, showTitle = true, contentOverflow = "unset", children, displayBreadcrumbs = true }: PropsWithChildren<IPageWrapperProps>) => {
    const breadcrumbs = useBreadcrumbs();

    const breadcrumbLinks = () => breadcrumbs.map((value, index) => {
        if (index === 0) { return null; }
        if (index >= breadcrumbs.length - 1) {
            return <Typography key={value.key} color="text.primary"></Typography>;
        }

        return (
            <Link underline="hover" component={Router.Link} key={value.key} color="inherit" to={value.match.pathname}>
                {value.breadcrumb}
            </Link>
        );
    })

    const BreadcrumbElement = () => {
        if (breadcrumbs.length < 3 || !displayBreadcrumbs) { return <></>; }

        return (
            <Grid item xs={12}>
                <Breadcrumbs aria-label="breadcrumb">
                    {breadcrumbLinks()}
                </Breadcrumbs>
            </Grid>
        );
    }

    return (
        <Stack spacing={2} sx={{ height: "calc(100% - 48px)" }}>
            <Container maxWidth={false}>
                <Grid container spacing={1}>
                    <BreadcrumbElement />
                    <Grid item xs={12}>
                        {showTitle && <Typography sx={{ display: "inline-block" }} variant="h5">{title}</Typography>}
                        {refreshing && <CircularProgress color="primary" size={24} sx={{ float: "right" }} />}
                    </Grid>
                </Grid>
            </Container>
            {
                loaded ?
                    <Grow in={loaded} style={{ transformOrigin: '0 0 0' }}>
                        <Container maxWidth={false} sx={{ flex: 1, overflow: contentOverflow, pb: 2 }}>
                            {children}
                        </Container>
                    </Grow>
                    :
                    <Container maxWidth={false}>
                        <Grid container direction="column" alignItems="center" spacing={5}>
                            <Grid item xs={12}></Grid>
                            <Grid item xs={12}>
                                <LoadingSpinner />
                            </Grid>
                        </Grid>
                    </Container>
            }
        </Stack>
    );
};

export interface IPageWrapperProps {
    title: string;
    loaded: boolean;
    refreshing?: boolean;
    showTitle?: boolean;
    contentOverflow?: Property.Overflow;
    displayBreadcrumbs?: boolean;
}

export default PageWrapper;
