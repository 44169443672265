import React, { useCallback, useEffect } from "react";
import PageWrapper from "../../components/PageWrapper";
import { Paper, Typography } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { IPortalRouteOptions } from "../../shared/types/shared/routes/routeTypes";
import { Dashboard } from "@mui/icons-material";

const SystemAdminHomePage: React.FC<ISystemAdminHomePage> = () => {
    const [loaded, setLoaded] = React.useState(false);

    const loadComplete = useCallback(async () => {
        setLoaded(true);
    },[])

    useEffect(() => {
        loadComplete()
    }, [loadComplete])

  return (
    <PageWrapper title="System Administration" loaded={loaded}>
          <Grid container spacing={2}>
              <Grid xs={12}>
                  <Paper sx={{ p: 2 }}>
                      <Typography>System administration options are available from the left navigation menu, if you have the required permissions.</Typography>
                  </Paper>
              </Grid>
      </Grid>
    </PageWrapper>
  );
}

export interface ISystemAdminHomePage {

}

const SystemAdminPageConfig: IPortalRouteOptions = {
    relativeUrl: "system-admin",
    page: <SystemAdminHomePage />,
    navDisplay: {
        title: "Dashboard",
        icon: <Dashboard />,
    },
}


export default SystemAdminPageConfig;