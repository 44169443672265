import { useEffect, useMemo, useState } from 'react';
import { Hexagon } from 'react-hexgrid';
import ionLogoWhite from '../../images/ion-plus-white.png';
import ionLogoBlack from '../../images/ion-plus-black.png';
import { HexGridData, HiveGenerationMode } from '../../shared/types/shared/hex-grid';
import { generateDataTiles, GetUKLayout, processData } from '../../shared/utils/StatusHiveUtils';
import { useGetAssetStatusQuery } from '../../shared/api/AssetApi';
import { IPortalRouteOptions } from "../../shared/types/shared/routes/routeTypes";
import { Hive } from '@mui/icons-material';
import { useToastAlertContext } from '../../components/ToastAlert';
import HexContainer from '../../components/shared/hex-grid/HexContainer';
import { useColourModeContext } from '../../components/ColourMode';
import { common } from '@mui/material/colors';
import { IHexGridConfig, IHiveDimensions, getCurrentDimension } from '../../shared/utils/HexGridUtils';
import { StatusHiveDataTile, StatusHiveSiteData } from '../../shared/types/asset/status-hive';


const StatusHivePage: React.FC = () => {
  const { popToast } = useToastAlertContext();
  const { isDarkMode } = useColourModeContext();

  useEffect(() => { // Disable scroll on load
    document.body.style.overflow = 'hidden';

    // Reenable scroll on unload
    return () => {
      document.body.style.overflow = 'unset';
    }
  }, []);

  // Configure API calls
  const statusPollingInterval = useMemo(() => 60 * 1000, []);
  const { data: statusList, isSuccess: statusesSucceeded, isLoading: statusesLoading } = useGetAssetStatusQuery(undefined, { pollingInterval: statusPollingInterval });

  const [data, setData] = useState<StatusHiveSiteData[]>([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => { // Track when loading is complete
    if (!statusesLoading && statusesSucceeded) {
      setLoaded(true);
    }
  }, [statusesLoading, statusesSucceeded]);

  const gridConfig = useMemo((): IHexGridConfig => {
    return {
      heightOffset: -64,
    }
  }, []);

  const [dimensions, setDimensions] = useState<IHiveDimensions>(getCurrentDimension(gridConfig)); // Dimensions
  const [grid, setGrid] = useState<HexGridData>(new HexGridData(0, 0)); // All Grid Hexagons
  const [dataTiles, setDataTiles] = useState<StatusHiveDataTile[]>([]); // The tiles to place in the grid
  const [firstPass, setFirstPass] = useState(false);

  const updateDataTiles = (newStatusData: StatusHiveSiteData[], newDimensions: IHiveDimensions) => {
    const newGrid = new HexGridData(newDimensions.grid.gridWidth, newDimensions.grid.gridHeight);
    setGrid(newGrid);

    const result = generateDataTiles(newStatusData, newGrid, HiveGenerationMode.Clusters, true);
    setDataTiles(result.placed);

    if (result.failed.length > 0) {
      popToast(`Unable to place ${result.failed.length} tiles`, "warning", 2500);
    }

    setFirstPass(true);
  };

  useEffect(() => { // When window resizes, update all dimensions values
    const updateDimension = () => {
      const newDimensions = getCurrentDimension(gridConfig);
      setDimensions(newDimensions);
    }

    window.addEventListener('resize', updateDimension);

    return (() => {
      window.removeEventListener('resize', updateDimension);
    });
  }, [gridConfig]);

  useEffect(() => { // If raw data changes, update data
    const newData = processData(statusList || []);
    setData(newData);
  }, [statusList]);

  useEffect(() => { // If data or dimensions change, update tiles
    updateDataTiles(data, dimensions);
  }, [data, dimensions]);


  const { tileRadius } = dimensions.grid;
  const { centre } = grid.getAnchorPoints();

  if (!firstPass) { // Gives the grid an opportunity to have calculated at least once before displaying anything
    return (<></>)
  }

  return (
    <HexContainer dimensions={dimensions}>
      {
        loaded ?
          dataTiles.map((tile) => tile.graphic())
          :
          GetUKLayout(centre).map((coords, index) => (
            <Hexagon key={`load-${index}`} q={coords.q} r={coords.r} s={coords.s} fill="none" stroke={isDarkMode ? common.white : common.black} strokeWidth={"0.01em"} />
          ))
      }

      <Hexagon q={centre.q} r={centre.r} s={centre.s} fill="none" stroke={isDarkMode ? common.white : common.black} strokeWidth={"0.01em"}>
        <image className="pulsing" x={-(tileRadius / 2.5)} y={-(tileRadius / 2)} href={isDarkMode ? ionLogoWhite : ionLogoBlack} height={tileRadius} width={tileRadius}></image>
      </Hexagon>
    </HexContainer>
  );
}

const StatusHivePageConfig: IPortalRouteOptions = {
    relativeUrl: "status-hive",
        navDisplay: {
        title: "Status Hive",
        icon: <Hive />
    },
    page: {
        element: <StatusHivePage />,
        disableGutters: true,
        forceOverlayNav: true,
    },
    hideOnMobile: true
}

export default StatusHivePageConfig;
