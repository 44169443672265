export interface IPerfmonFile {
    dateTime: string;
    processedTime?: Date;
    ContractedLevel: number;
    expectedFileName: string;
    fileProcessed: boolean;
    fileOK: boolean;
    missingValues: number;
    status: FileStatus;
    displayStatus: number;
    late: boolean;
    maxError: number;
    kfactor: number;
    lowAvailability: number;
    highAvailability: number;
}

export interface IContractPerfmonFiles {
    ContractFiles: IPerfmonFile[]
}

export enum FileStatus {
    Future = 0,
    Waiting = 1,
    Sent = 2,
    "SENT fixed" = 3,
    "Not SENT" = 4,
    Late = 5,
}