import { useDeleteNotificationMutation, useMarkAllNotificationAsReadMutation, useMassMarkNotificationAsReadMutation, useMarkNotificationAsReadMutation, useMassDeleteNotificationMutation } from "../api/NotificationsApi";
import { prependNotification, removeNotification, setAllNotificationsAsRead, setNotificationAsRead, updateExpanded, updateNotifications } from "../state/slices/NotificationsSlice";
import { IPushNotification } from "../types/notification/IPushNotification";
import { useAppDispatch, useAppSelector } from "./StateHook";

const useNotificationMenu = () => {
    const dispatch = useAppDispatch()
    const [markAsRead] = useMarkNotificationAsReadMutation()
    const [markAllAsRead] = useMarkAllNotificationAsReadMutation()
    const [massMarkAsRead] = useMassMarkNotificationAsReadMutation()
    const [massDeleteNotifications] = useMassDeleteNotificationMutation()
    const [deleteNotif] = useDeleteNotificationMutation();
    const { expanded, notifications, unseenNotifications } = useAppSelector(state => state.notifications);

    const toggleDrawer = (open: boolean) => {
        dispatch(updateExpanded(open))
    }

    const setNotifications = (newNotifications: IPushNotification[]) => {
        dispatch(updateNotifications(newNotifications))
    }
    const addNotification = (newNotification: IPushNotification) => {
        dispatch(prependNotification(newNotification))
    }
    const markNotificationAsRead = (notificationID: string) => {
        notifications.forEach((notification) => {
            if (notification.notificationID === notificationID && !notification.seen) {
                markAsRead(notificationID).catch((e) => console.error(e))
                dispatch(setNotificationAsRead(notificationID))
            }
        })
    }
    const massMarkNotificationAsRead = (notificationIDs: string[]) => {
        notifications.forEach((notification) => {
            notificationIDs.forEach((notifToUpdate) => {
                if (notification.notificationID === notifToUpdate && !notification.seen) {
                    dispatch(setNotificationAsRead(notifToUpdate))
                }
            })
        })
        massMarkAsRead(notificationIDs)
    }
    const markAllNotificationsAsRead = () => {
        markAllAsRead().catch((e) => console.error(e))
        dispatch(setAllNotificationsAsRead(""))
    }
    const deleteNotification = (notificationID: string) => {
        deleteNotif(notificationID).catch((e) => console.error(e))
        dispatch(removeNotification(notificationID))
    }
    const massDelete = (notificationIDs: string[]) => {
        notifications.forEach((notification) => {
            notificationIDs.forEach((notifToUpdate) => {
                if (notification.notificationID === notifToUpdate && !notification.seen) {
                    dispatch(removeNotification(notifToUpdate))
                }
            })
        })
        massDeleteNotifications(notificationIDs)
    }
    const response = {
        expanded,
        toggleDrawer,
        notifications,
        setNotifications,
        addNotification,
        markNotificationAsRead,
        markAllNotificationsAsRead,
        massMarkNotificationAsRead,
        unseenNotifications,
        deleteNotification,
        massDelete
    }

    return response;
};

export default useNotificationMenu;