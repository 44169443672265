import { Box, CircularProgress, IconButton, TextField, Tooltip } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { DatePicker } from "@mui/x-date-pickers";
import moment, { Moment } from "moment";
import WeekSelection from "../WeekSelection";
import { isMobile } from "react-device-detect";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material"
import { useEffect, useRef } from "react";

const DynamicContractsWeekSelection: React.FC<IDynamicContractsWeekSelectionProps> = ({ value, onChange, querying, queryError, onClickRefresh }) => {

    const isFirstRender = useRef(true);
    const updateWeekStartDate = (Value: Moment | null) => {
        if (!Value) { return; }

        onChange(Value.startOf("week"));
    }

    const updateWeek = (event: any) => {
        let weekNumber = parseInt(event.target.value);
        if (!weekNumber) { return; }

        const date = moment(value).week(weekNumber);
        onChange(date);
    }

    const clickLeft = () => {
        updateWeekStartDate(moment(value).subtract(1, "week"))
    }

    const clickRight = () => {
        updateWeekStartDate(moment(value).add(1, "week"))
    }

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }
        if (!querying) {
            onClickRefresh()
        }
    }, [value])
    
    return (
        <Grid container
            alignItems="center"
            alignContent="start"
            spacing={1}
            justifyContent="flex-start">
            <Grid xs={isMobile ? 12 : 2}>
                <WeekSelection label="Week Starting" value={value} onChange={updateWeekStartDate} disabled={querying} />
            </Grid>
            <Grid xs={isMobile ? 4.5 : 2}>
                <TextField label="Week" type="number" value={value.week()} onChange={updateWeek} disabled={querying} fullWidth />
            </Grid>
            <Grid xs={isMobile ? 4.5 : 2}>
                <DatePicker
                    views={['year']}
                    openTo="year"
                    label="Year"
                    inputFormat="YYYY"
                    value={value}
                    onChange={updateWeekStartDate}
                    renderInput={(params) => <TextField {...params} fullWidth/>}
                    disabled={querying}
                />
            </Grid>
            <Grid xs={isMobile ? 3 : 1}>
                <Tooltip title={queryError}>
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center">
                        {querying ?
                            <CircularProgress
                                size={24}
                            />
                            :
                            <>
                                <IconButton
                                    size="large"
                                    color="primary"
                                    disabled={querying || !!queryError}
                                    onClick={clickLeft}>
                                    <ArrowBackIos fontSize="inherit" />
                                </IconButton>
                                <IconButton
                                    size="large"
                                    color="primary"
                                    disabled={querying || !!queryError}
                                    onClick={clickRight}>
                                    <ArrowForwardIos fontSize="inherit" />
                                </IconButton>
                            </>
                        }
                    </Box>
                </Tooltip>
            </Grid>
        </Grid>
    );
}

export interface IDynamicContractsWeekSelectionProps {
    value: Moment;
    onChange: (Value: Moment | null) => void;
    querying: boolean;
    queryError?: string;
    onClickRefresh: () => void;
}

export default DynamicContractsWeekSelection;
