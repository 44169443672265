import { DarkMode, LightMode, Logout, Settings, AdminPanelSettings } from "@mui/icons-material";
import { Avatar, Divider, IconButton, ListItemIcon, Menu, MenuItem, Stack, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SystemAdministrationPermissions } from "../shared/types/shared/auth/permissionsTypes";
import { UserPermissionCollection } from "../shared/types/shared/auth/userDataTypes";
import { useColourModeContext } from "./ColourMode";
import { usePortalContext } from "./PortalWrapper";

const UserSettingsMenu: React.FC<IUserSettingsMenuProps> = () => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const colourMode = useColourModeContext();
    const navigate = useNavigate();
    const { currentUser, signOut } = usePortalContext();
    const [authenticatedSystemAdmin, setAuthenticatedSystemAdmin] = useState<boolean>(false);

    useEffect(() => {
        const perm: Partial<UserPermissionCollection> = {
            SystemAdmin: SystemAdministrationPermissions.Admin
        }
        setAuthenticatedSystemAdmin(currentUser?.hasPermissions(perm) || false)
    }, [currentUser])

    const handlePortalSettings = () => {
        navigate("/user/settings");
    }

    const handleSystemAdmin = () => {
        navigate("/system-admin");
    }

    const handleNotificationSettings = () => {
        navigate("/user/notifications/settings");
    }

    const handleNotificationAdmin = () => {
        navigate("/user/notifications/admin");
    }

    const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const changeTheme = () => {
        handleClose();
        colourMode.toggleColourMode();
    }

    const avatar: JSX.Element = (
        <Avatar alt={currentUser?.displayName} src={currentUser?.avatar.url} variant="rounded" />
    );

    return (
        <Stack direction="row" sx={{ flexGrow: 0 }}>
            <Tooltip title="User Settings">
                <IconButton onClick={handleOpen} sx={{ p: 0 }}>
                    {avatar}
                </IconButton>
            </Tooltip>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={Boolean(anchorEl)}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                            fontSize: '1rem',
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem onClick={handleClose} href="https://myaccount.microsoft.com/" target="_blank" rel="noreferrer" component="a">
                    {avatar} {currentUser?.displayName}
                </MenuItem>
                <MenuItem onClick={handlePortalSettings}>
                    <ListItemIcon>
                        <Settings fontSize="small" />
                    </ListItemIcon>
                    User Settings
                </MenuItem>
                {DisplayModeMenuItem(colourMode.isDarkMode, changeTheme)}
                <Divider />
                {authenticatedSystemAdmin && 
                <MenuItem onClick={handleSystemAdmin}>
                    <ListItemIcon>
                        <AdminPanelSettings fontSize="small" />
                    </ListItemIcon>
                    System Admin
                </MenuItem>}
                <MenuItem onClick={signOut}>
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </Menu>
        </Stack>
    );
}

const DisplayModeMenuItem = (isDarkMode: boolean, handleClose: () => void): JSX.Element => {
    let icon: JSX.Element = <></>;
    let text: string = "";

    if (isDarkMode) {
        icon = <LightMode fontSize="small" />;
        text = "Light Mode";
    } else {
        icon = <DarkMode fontSize="small" />;
        text = "Dark Mode";
    }

    return (
        <MenuItem onClick={handleClose}>
            <ListItemIcon>
                {icon}
            </ListItemIcon>
            {text}
        </MenuItem>
    );
}

export interface IUserSettingsMenuProps {
}

export default UserSettingsMenu;