import React, { useEffect, useMemo, useState } from 'react';
import { PropsWithChildren, createContext, useContext } from 'react';
import { ConradThemeDark } from '../shared/theme/themeDark';
import { ConradThemeLight } from '../shared/theme/themeLight';
import { ThemeProvider } from '@emotion/react';
import { useLocalStorage } from '../shared/hooks/useLocalStorage';
import { Theme } from '@mui/material';

type ColourMode = "light" | "dark";

interface IContextProps {
    toggleColourMode: () => void;
    mode: ColourMode;
    isDarkMode: boolean;
    theme: Theme;
}

const defaultProps: IContextProps = {
    toggleColourMode: () => { },
    mode: "dark",
    isDarkMode: true,
    theme: ConradThemeLight,
}

const ColourModeContext = createContext<IContextProps>(defaultProps);

const resolveIsDarkMode = (mode: ColourMode) => {
    return mode !== 'light';
}

const resolveTheme = (mode: ColourMode) => {
    return mode === 'dark' ? ConradThemeDark : ConradThemeLight;
}

const ColourModeContextProvider: React.FC<PropsWithChildren<any>> = ({ children }: PropsWithChildren<{}>) => {
    const [storedMode, setStoredMode] = useLocalStorage("colourMode", "dark");
    const [isDarkMode, setIsDarkMode] = useState<boolean>(resolveIsDarkMode(storedMode));
    const [theme, setTheme] = useState<Theme>(resolveTheme(storedMode));
    const [mode, setMode] = useState<ColourMode>(storedMode);

    const toggleColourMode = () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
    }

    useEffect(() => {
        setStoredMode(mode);
        setIsDarkMode(resolveIsDarkMode(mode));
        setTheme(resolveTheme(mode));
    }, [mode]);

    return (
        <ColourModeContext.Provider value={{ toggleColourMode, mode, isDarkMode, theme }}>
            <ThemeProvider theme={theme}>
                {children}
            </ThemeProvider>
        </ColourModeContext.Provider>
    )
}

export const useColourModeContext = () => useContext(ColourModeContext);
export default ColourModeContextProvider;
