import { Save, Settings } from "@mui/icons-material";
import { Button, Card, CardContent, CardHeader, Grid, Slider, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import PageWrapper from "../../components/PageWrapper";
import { useToastAlertContext } from "../../components/ToastAlert";
import { useGetSettingsQuery, useUpdateSettingsMutation } from "../../shared/api/UserSettingsApi";
import { usePortalContext } from "../../components/PortalWrapper";
import { IPortalRouteOptions } from "../../shared/types/shared/routes/routeTypes";

const UserSettingsPage: React.FC<IUserSettingsPageProps> = () => {
    const { currentUser } = usePortalContext();
    const [ username, setUsername] = useState<string|null>(null);
    const { popToast } = useToastAlertContext();
    const [updateSettings] = useUpdateSettingsMutation();
        const { data, isLoading: loadingSettings } = useGetSettingsQuery(username, { skip: !username});
    const [assetMapRefreshInterval, setAssetMapRefreshInterval] = useState<number>(50);

    useEffect(() => {
        if (data) {
            setAssetMapRefreshInterval(Number(data.assetMapRefreshInterval));
        }
    }, [data]);

    useEffect(() => {
        if (currentUser) {
            setUsername(currentUser.userName);
        }
    }, [currentUser]);

    const marks = [
        {
            value: 1,
            label: '1 min',
        },
        {
            value: 20,
            label: '2 mins',
        },
        {
            value: 50,
            label: '5 mins',
        },
        {
            value: 100,
            label: '10 mins',
        },
    ];

    const valueText = (value: number): string => {
        return `${value} min{s}`;
    }

    const valueLabelFormat = (value: number): string => {
        var index = marks.findIndex((mark) => mark.value === value);
        return marks[index].label;
    }

    const handleAssetMapRefreshIntervalChange = (event: Event, newValue: number | number[]) => {
        setAssetMapRefreshInterval(Number(newValue));
    };

    const handleSaveSettings = async () => {
        const updateData = { username: username as string, data: { assetMapRefreshInterval: assetMapRefreshInterval } };
        const response = await updateSettings(updateData);
        if ('error' in response) {
            popToast("User Settings did not save", "error");
        }
        else {
            popToast("User Settings have saved successfully", "success");
        }
    };

    return (
        <PageWrapper title="Settings" loaded={!loadingSettings}>
            <Grid container spacing={2}>                
                <Grid item md={6} xs={12}>
                    <Card sx={{ width: "100%", height: "100%", display: "inline-block" }}>
                        <CardHeader title="Assets"></CardHeader>
                        <CardContent>
                            <Grid container spacing={1} alignItems="center">
                                <Grid item md={4}>
                                    <Typography variant="body2" color="text.secondary">
                                        Map Refresh Interval
                                    </Typography>
                                </Grid>
                                <Grid item md={7}>
                                    <Slider
                                        aria-label="Asset Map Refresh Interval"
                                        value={assetMapRefreshInterval}
                                        onChange={handleAssetMapRefreshIntervalChange}
                                        valueLabelFormat={valueLabelFormat}
                                        getAriaValueText={valueText}
                                        step={null}
                                        valueLabelDisplay="auto"
                                        marks={marks}
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item md={12}>
                    <Button
                        variant="contained"
                        startIcon={<Save />}
                        onClick={handleSaveSettings}>
                        Save
                    </Button>
                </Grid>
            </Grid>
        </PageWrapper>
    );
}

export interface IUserSettingsPageProps {

}

const UserSettingsPageConfig: IPortalRouteOptions = {
    relativeUrl: "settings",
    page: <UserSettingsPage />,
    navDisplay: {
        title: "General",
        icon: <Settings/>,
    }
}

export default UserSettingsPageConfig;