import { IPublicClientApplication } from '@azure/msal-browser';
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate } from '@azure/msal-react';
import { CssBaseline } from '@mui/material';
import moment from 'moment';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import RouteHandler from './components/RouteHandler';
import SiteLayout from './components/SiteLayout';
import SignInPage from './pages/shared/SignInPage';
import PortalContextProvider from './components/PortalWrapper';
import { AppName } from './shared/utils/PortalUtils';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from './components/ApplicationInsightsService';
import { useNavigate } from 'react-router-dom';
import { CustomNavigationClient } from './shared/utils/CustomNavigationClient';

moment.updateLocale('en', {
    week: {
        dow: 1, // Monday is the first day of the week.
    }
});

function App({ pca }: IAppProps) {
    const navigate = useNavigate();
    const navigationClient = new CustomNavigationClient(navigate);
    pca.setNavigationClient(navigationClient);


    return (
        <HelmetProvider>
            <AppInsightsContext.Provider value={reactPlugin}>
                <MsalProvider instance={pca}>
                    <PortalContextProvider>
                        <CssBaseline />
                        <AuthenticatedTemplate>
                            <Helmet>
                                <title>{AppName}</title>
                                <meta name="viewport" content="initial-scale=1, width=device-width" />
                            </Helmet>
                            <RouteHandler />
                        </AuthenticatedTemplate>
                        <UnauthenticatedTemplate>
                            <Helmet>
                                <title>Not Logged In</title>
                                <meta name="viewport" content="initial-scale=1, width=device-width" />
                            </Helmet>
                            <SiteLayout homeRoute="/" pageHeader="Portal" disableNav={true}>
                                <SignInPage />
                            </SiteLayout>
                        </UnauthenticatedTemplate>
                    </PortalContextProvider>
                </MsalProvider>
            </AppInsightsContext.Provider>
        </HelmetProvider>
    );
}

export interface IAppProps {
    pca: IPublicClientApplication;
}

export default App;
