import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from "@reduxjs/toolkit/query/react";
import { IEngineerLocation } from "../types/engineer-locations/IEngineerLocation";
import { IQuartixAuth } from "../types/engineer-locations/IQuartixAuth";

const baseQuery = fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_GATEWAY_URL}engineer-locations`,    
    prepareHeaders: (headers, api) => {        
        headers.set("Api-Key", `${process.env.REACT_APP_API_GATEWAY_KEY}`);        

        const accessToken = sessionStorage.getItem("vehicleLocations");

        // If we have a token set in state, let's assume that we should be passing it.
        if (accessToken) {
            headers.set("AccessToken", `${accessToken}`);
        }

        return headers;
    },         
});

const baseQueryWithAuth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}> = async (args, api, extraOptions) => {   
    let result = await baseQuery(args, api, extraOptions);

    if (result.error && result.error.status === 401) {
        const formData = new URLSearchParams({
            "CustomerID": "Conrad Energy",
            "UserName": `${process.env.REACT_APP_QUARTIX_USERNAME}`,
            "Password": `${process.env.REACT_APP_QUARTIX_PASSWORD}`,
            "Application": "Conrad Energy.app"
        });
        
        const authQuery = await baseQuery({
            url: "/auth",
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            },
            body: formData,            
        }, api, extraOptions);

        if (authQuery.data) {
            const response = authQuery.data as IQuartixAuth;
            sessionStorage.setItem("vehicleLocations", response.Data.AccessToken);

            // retry the initial query
            result = await baseQuery(args, api, extraOptions);
        } else {
            console.log("NO AUTH");
        }
    }
    return result;
};

export const engineerLocationApi = createApi({
    reducerPath: 'engineerLocationApi',
    baseQuery: baseQueryWithAuth,
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({       
        getEngineerLocations: builder.query<IEngineerLocation[], void>({ 
            query: () => ({
                url: '/current',                
                method: 'GET'                
            }),
            transformResponse: (rawResult: any): IEngineerLocation[] => {
                return rawResult.Data;
            },              
        }),        
    }),
})
export const { useGetEngineerLocationsQuery } = engineerLocationApi

