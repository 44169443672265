import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ICommercialOpportunitySetting } from "../types/commercial/opportunity/ICommercialOpportunitySetting";
import { IOpportunity } from "../types/commercial/opportunity/IOpportunity";
import { IOpportunitySubmission } from "../types/commercial/opportunity/IOpportunitySubmission";

export const commercialOpportunityApi = createApi({
    reducerPath: 'commercialOpportunityApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_GATEWAY_URL}commercial-opportunity/`,
        prepareHeaders: async (headers: Headers, { getState }) => {
            headers.set("Api-Key", `${process.env.REACT_APP_API_GATEWAY_KEY}`);
        }
    }),
    tagTypes: ["FindOpportunities", "PricingSchedule"],    
    endpoints: (builder) => ({        
        findOpportunities: builder.query<IOpportunity[], string>({
            query: (searchCriteria) => `/salesforceopportunitydetailssearch/${searchCriteria}`,
            providesTags: ["FindOpportunities"],                  
            keepUnusedDataFor: 120
        }),    
        getOpportunitySubmission: builder.query<IOpportunitySubmission[], string>({
            query: (opportunityId) => `/seaglasssubmission/${opportunityId}`,    
            keepUnusedDataFor: 60
        }),
        getPricingSchedule: builder.query<ICommercialOpportunitySetting, void>({
            query: () => `/commercialopportunitysettings/Pricing/NextScheduledRun`, 
            providesTags: ["PricingSchedule"]
        }),
        savePricingSchedule: builder.mutation<void, string>({
            query: (payload) => ({
                url: '/SalesforceQuoteSchedulerUpdate',
                method: 'POST',
                body: { NextSchedule: payload }
            }),
            invalidatesTags: ["PricingSchedule"]
        }),       
    }),
})
export const { useFindOpportunitiesQuery, useGetOpportunitySubmissionQuery, useGetPricingScheduleQuery, useSavePricingScheduleMutation } = commercialOpportunityApi

