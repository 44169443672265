import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CalculateResiduals } from "../../utils/GasPositionUtils";
import { IGasPosition } from "../../types/gas-reconciliation/gasposition";
import gasReconciliationApi, { PositionDataAPIResponse } from "../../api/GasReconciliationApi";

interface NonDailyMeteredState {
    data: IGasPosition[];
    totalResidual: number;
    lastRefresh: Date | null;
    isDataBeingRefreshed: boolean;
    allocateTradesCompletedPerc: number;
}

const initialState: NonDailyMeteredState = {
    data: [],
    totalResidual: 0,
    lastRefresh: null,
    isDataBeingRefreshed: false,
    allocateTradesCompletedPerc: 0,
};

export const getData = createAsyncThunk(
    "get-nondailymetered",
    async (date: Date, thunkApi) => {
        const response = await gasReconciliationApi.getNonDailyMetered(date);
        return response;
    }
);

export const refreshData = createAsyncThunk(
    "refresh-nondailymetered",
    async (date: Date, thunkApi) => {
        await gasReconciliationApi.refreshNonDailyMetered(date);
        const response = await gasReconciliationApi.getNonDailyMetered(date);
        return response;
    }
);

export const nonDailyMeteredSlice = createSlice({
    name: "nonDailyMetered",
    initialState,
    reducers: {
        updateOverride: (
            state,
            action: PayloadAction<{ id: number; value: number }>
        ) => {
            const data = state.data.map((d) => {
                if (d.id === action.payload.id) {
                    d.override = action.payload.value;
                }
                return d;
            });

            const calculatedResult = CalculateResiduals(data);

            state.data = calculatedResult.items;
            state.totalResidual = calculatedResult.total;

        },
        updateAllocateCompleted: (
            state, action: PayloadAction<number>) => {
            state.allocateTradesCompletedPerc = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getData.pending, (state) => {
                state.isDataBeingRefreshed = true;
            })
            .addCase(getData.rejected, (state) => {
                state.isDataBeingRefreshed = false;
            })
            .addCase(
                getData.fulfilled,
                (state, action: PayloadAction<PositionDataAPIResponse>) => {
                    state.lastRefresh = action.payload.lastRefresh;

                    const calculatedResult = CalculateResiduals(action.payload.results);

                    state.data = calculatedResult.items;
                    state.totalResidual = calculatedResult.total;

                    state.isDataBeingRefreshed = false;
                }
            )
            .addCase(refreshData.pending, (state) => {
                state.isDataBeingRefreshed = true;
            })
            .addCase(refreshData.rejected, (state) => {
                state.isDataBeingRefreshed = false;
            })
            .addCase(
                refreshData.fulfilled,
                (state, action: PayloadAction<PositionDataAPIResponse>) => {
                    state.lastRefresh = action.payload.lastRefresh;

                    const calculatedResult = CalculateResiduals(action.payload.results);

                    state.data = calculatedResult.items;
                    state.totalResidual = calculatedResult.total;

                    state.isDataBeingRefreshed = false;
                }
            );
    },
});

export const { updateOverride, updateAllocateCompleted } = nonDailyMeteredSlice.actions;

export default nonDailyMeteredSlice.reducer;
