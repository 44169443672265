import { useEffect } from "react";
import { Moment } from "moment";
import { useAppDispatch, useAppSelector } from "../StateHook";
import { getData, refreshData, updateAllocateCompleted } from "../../state/slices/NonDailyMeteredSlice";
import { getApiAccessToken } from "../../auth/helpers";
import { Counterparty } from "../../enums/CounterpartyEnum";

const useNonDailyMetered = (date: Moment) => {
    const dispatch = useAppDispatch();
    const { data, lastRefresh, totalResidual, isDataBeingRefreshed, allocateTradesCompletedPerc } =
        useAppSelector((state) => state.nonDailyMetered);

    const allocateTrades = async (gasDay: Moment, pricePerTherm: number): Promise<string | null> => {
        let responseMessage = null;
        const run = async () => {
            try {
                await submitTrades(gasDay, pricePerTherm);
            }
            catch (ex: any) {
                responseMessage = ex;
            }
        };
        await run();
        return responseMessage;
    };

    const submitTrades = async (gasDay: Moment, pricePerTherm: number): Promise<void> => {
        const accessToken = await getApiAccessToken();

        const postData = {
            pricePerTherm: pricePerTherm,
            totalVolume: totalResidual,
            tradeItems: data,
            counterpartyName: Counterparty.Corona,
            gasDay: gasDay.format()
        };

        return new Promise((success) => {
            fetch(`${process.env.REACT_APP_BASE_API_URL}gas-rec/nondailymetered/allocate`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${accessToken}`, "Api-Key": `${process.env.REACT_APP_BASE_API_KEY}` },
                body: JSON.stringify(postData)
            })
                .then(async response => {
                    const reader = response.body?.getReader();
                    if (!reader) {
                        throw new Error('Failed to read response');
                    }
                    const decoder = new TextDecoder();

                    while (true) {
                        const { done, value } = await reader.read();
                        if (done) break;
                        if (!value) continue;
                        const rawValue = decoder.decode(value);
                        const completePerc = parseFloat(rawValue.replace(/[^\d.-]/g, ''));

                        dispatch(updateAllocateCompleted(completePerc));
                    }
                    reader.releaseLock();
                    success();
                });
        });
    }

    const refresh = () => {
        const run = async () => {
            const dateOnly = date.startOf("day");
            dispatch(refreshData(dateOnly.toDate()));
        };
        run();
    };

    const loadData = () => {
        const run = async () => {
            const dateOnly = date.startOf("day");
            dispatch(getData(dateOnly.toDate()));
            dispatch(updateAllocateCompleted(0));
        };
        run();
    };

    useEffect(() => {
        loadData();
    }, [date, dispatch]);

    const response = {
        data,
        lastRefresh,
        totalResidual,
        isDataBeingRefreshed,
        refresh,
        allocateTrades,
        allocateTradesCompletedPerc,
        loadData
    };
    return response;
};

export default useNonDailyMetered;
