import { HttpTransportType, HubConnectionBuilder, IRetryPolicy, RetryContext } from "@microsoft/signalr";
import { getApiAccessToken } from "../auth/helpers";

const hubUrl = process.env.REACT_APP_PORTAL_API_URL?.replace("api/", "") + "hubs/notifications"

const retryDelaysInSeconds = [1, 2, 10, 30, 60]
const CustomRetryPolicy: IRetryPolicy = {
    nextRetryDelayInMilliseconds: (retryContext: RetryContext) => {
        const index = Math.min(retryDelaysInSeconds.length - 1, retryContext.previousRetryCount)
        return retryDelaysInSeconds[index] * 1000
    }
}

export const GetNotificationHubConnection = async () => {
    const accessToken = getApiAccessToken();
    const connect = new HubConnectionBuilder()
        .withUrl(hubUrl, {
            skipNegotiation: true,
            transport: HttpTransportType.WebSockets,
            accessTokenFactory: () => accessToken,
            withCredentials: true
        })
        .withAutomaticReconnect(CustomRetryPolicy)
        .build();
    return connect
};