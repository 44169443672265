import ApiRoot from "./ApiRoot";
import { ILogEntries } from "../types/ILogEntries"
import { getApiAccessToken } from "../auth/helpers";

const rootUrl = process.env.REACT_APP_API_GATEWAY_URL + "logging/"
const token = process.env.REACT_APP_SEQ_API_KEY
const apiKey = process.env.REACT_APP_BASE_API_KEY;

const sortMessageTokens = async (logs: ILogEntries) => {
    logs.Events.forEach(item => {
        item.MessageTemplateTokens.forEach(msgItem => {
            if (msgItem.PropertyName) {
                msgItem.PropertyName = item.Properties.find(propItem => propItem.Name === msgItem.PropertyName)?.Value.toString();
            }
        });
    });

    return logs;
}

const getLogEntries = async () => {
    const accessToken = await getApiAccessToken();
    var items = await ApiRoot.get<ILogEntries>(rootUrl + "api/events/signal?apiKey=" + token, { accessToken, apiKey });
    items = await sortMessageTokens(items);

    return items;
}

const getLogEntriesForAppId = async (appId: string) => {
    const accessToken = await getApiAccessToken();

    var items = await ApiRoot.get<ILogEntries>(rootUrl + "api/events/signal?apiKey=" + token + "&count=100&filter=AppId=='" + appId + "'", { accessToken, apiKey });
    items = await sortMessageTokens(items);

    return items;
}

const loggingApi = {
    getLogEntries, getLogEntriesForAppId
}

export default loggingApi;