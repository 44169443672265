import { List } from "@mui/icons-material";
import { Paper } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import LogDataGrid from "../../components/LogDataGrid";
import PageWrapper from "../../components/PageWrapper";
import loggingApi from "../../shared/api/LoggingApi";
import { ILogEntries } from "../../shared/types/ILogEntries";
import { IPortalRouteOptions } from "../../shared/types/shared/routes/routeTypes";

const CommercialLogsPage: React.FC = () => {
    const [loaded, setLoaded] = useState(false);
    const [logs, setLogs] = useState<ILogEntries>();

    const logAppId = process.env.REACT_APP_COMMERCIAL_OPPORTUNITY_LOGS_APPID ?? "Commercial Opportunity (DEV)";

    const loadComplete = useCallback(async () => {
        setLoaded(true);
    }, [])

    useEffect(() => {
        loadComplete()
    }, [loadComplete])

    useEffect(() => {
        const loadLogs = async () => {
            const logData = await loggingApi.getLogEntriesForAppId(logAppId);
            setLogs(logData);
        }

        loadLogs();
    }, []);
   
 
    return (
        <PageWrapper title="Logs" loaded={loaded}>
            <Paper sx={{ p: 2, width: "100%" }}>
                <LogDataGrid items={logs}></LogDataGrid>
            </Paper>
        </PageWrapper>
    );
}

const CommercialLogsPageConfig: IPortalRouteOptions = {
    relativeUrl: "logs",
    page: <CommercialLogsPage />,
    navDisplay: {
        title: "Logs",
        icon: <List />
    }
}

export default CommercialLogsPageConfig;