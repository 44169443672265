import React, { useEffect, useState } from 'react';
import { ComposedChart, Customized, Rectangle, ResponsiveContainer, Scatter, XAxis, YAxis } from 'recharts';
import { IEngineTrip } from '../../shared/types/operate/IEngineTrip';
import moment, { Moment } from 'moment';
import { DateFormatter } from "../analytics/helpers/DateFormatter";
import { Box, Paper, Typography, useTheme } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { Dictionary } from '@reduxjs/toolkit';
import MouseTracker from '../shared/MouseTracker';
import { isMobile } from 'react-device-detect';

const EngineTripPopup: React.FC<IEngineTripPopup> = ({ selectedTrip, siteIDToName }) => {
    const theme = useTheme();
    return (
        <Paper hidden={selectedTrip === null} sx={{ border: "3px solid", borderColor: theme.palette.conradEnergyYellow.main, borderRadius: 2, paddingLeft: 2, paddingRight: 2, height: "fit-content" }}>
            <Grid xs={12}>
                <Typography align="center" variant="body1">{siteIDToName[selectedTrip?.siteID || ""] + " | " + selectedTrip?.engineID}</Typography>
            </Grid>
            <Grid xs={12}>
                <Typography variant="body1" display="inline" sx={{ fontWeight: 'bold' }}>{DateFormatter(moment(selectedTrip?.tripStart).valueOf())} (Local Time)</Typography>
                <Typography variant="body1" display="inline"> to </Typography>
                <Typography variant="body1" display="inline" sx={{ fontWeight: 'bold' }}>{(moment(selectedTrip?.tripEnd).year() === 9999 ? "Unfinished" : DateFormatter(moment(selectedTrip?.tripEnd).valueOf()) + " (Local Time)")}</Typography>
            </Grid>
        </Paper>
    )
}
interface IEngineTripPopup {
    selectedTrip: IEngineTrip | null
    siteIDToName: Dictionary<string>
}

const EngineTripGanttChart: React.FC<IEngineTripGanttChart> = ({ trips, siteIDToName, startDate, endDate }) => {
    const [tripStartPoints, setTripStartPoints] = useState<IGanttChartDataItem[]>([])
    const [tripEndPoints, setTripEndPoints] = useState<IGanttChartDataItem[]>([])
    const defaultStart = moment().utc().startOf("day").valueOf()
    const defaultEnd = moment().utc().endOf("day").valueOf()
    const [chartStart, setChartStart] = useState<number>(defaultStart)
    const [chartEnd, setChartEnd] = useState<number>(defaultEnd)
    const [selectedTrip, setSelectedTrip] = useState<IEngineTrip | null>(null)
    const [internalTripData, setInternalTripData] = useState<IEngineTrip[]>([])
    const [categories, setCategories] = useState<string[]>([])
    const theme = useTheme();

    const formatData = () => {

        setChartStart(startDate.valueOf())
        setChartEnd(endDate.valueOf())
        if (internalTripData.length === 0) {
            setTripStartPoints([])
            setTripEndPoints([])
        }
        else {
            internalTripData.sort((a, b) => {
                const aCategory = a.siteID + "-" + a.engineID
                const bCategory = b.siteID + "-" + b.engineID
                return 0- aCategory.localeCompare(bCategory)
            })
            const newStartTripData = new Array<IGanttChartDataItem>();
            const newEndTripData = new Array<IGanttChartDataItem>();
            const newCategories = new Array<string>();
            internalTripData.forEach((trip) => {
                let tripStartValue = moment(trip.tripStart).valueOf()
                if (tripStartValue < startDate.valueOf()) {
                    tripStartValue = startDate.valueOf()
                }
                let tripEndValue = moment(trip.tripEnd).valueOf()
                if (tripEndValue > endDate.valueOf()) {
                    tripEndValue = endDate.valueOf()
                }
                const tripCategory = trip.siteID + "-" + trip.engineID
                if (newCategories.indexOf(tripCategory) < 0) {
                    newCategories.push(tripCategory)
                }
                const newTripStart: IGanttChartDataItem = {
                    dateTime: tripStartValue,
                    row: tripCategory,
                    id: trip.webID
                }
                const newTripEnd: IGanttChartDataItem = {
                    dateTime: tripEndValue,
                    row: tripCategory,
                    id: trip.webID
                }
                newStartTripData.push(newTripStart)
                newEndTripData.push(newTripEnd)
            })
            setTripStartPoints(newStartTripData)
            setTripEndPoints(newEndTripData)
            setCategories(newCategories)
        }
    }

    useEffect(() => {
        formatData()
    }, [internalTripData])

    useEffect(() => {
        setInternalTripData(trips)
    }, [trips])

    
    const CustomErrorBars = ({ formattedGraphicalItems }: any) => {
        const firstSeries = formattedGraphicalItems[0]
        const secondSeries = formattedGraphicalItems[1]
        let maxRank = 0
        firstSeries.props.points.forEach((point: any) => {
            if (point.startRank > maxRank) { maxRank = point.startRank }
        })
        return firstSeries.props.points.map((firstSeriesPoint: any, index: number) => {
            const webID = firstSeriesPoint.payload.id
            let currentTrip: IEngineTrip | null = null
            internalTripData.forEach((trip) => {
                if (trip.webID === webID.toString()) {
                    currentTrip = trip
                }
            })
            let secondSeriesPoint = firstSeriesPoint;
            secondSeries.props.points.forEach((point: any) => {
                if (point.payload.id === webID) {
                    secondSeriesPoint = point
                }
            })
            const startMidpoint = (firstSeriesPoint.x + firstSeriesPoint.width / 2.0)
            const endMidpoint = (secondSeriesPoint.x + secondSeriesPoint.width / 2.0)
            const width = endMidpoint - startMidpoint
            const height = 20
            return (
                    <Rectangle
                        key={"start" + index}
                        width={width}
                        height={height}
                        x={startMidpoint}
                        y={firstSeriesPoint.cy - (height / 2)}
                        radius={5}
                        fill={theme.palette.conradEnergyYellow.main}
                        stroke={theme.palette.text.primary}
                        strokeWidth="1"
                        onMouseEnter={() => {
                            if (!isMobile) {
                                setSelectedTrip(currentTrip)
                            }
                        }}
                        onMouseLeave={() => {
                            setSelectedTrip(null)
                        }}
                        onClick={(e) => {
                            if (isMobile) {
                                setSelectedTrip(currentTrip)
                                e.stopPropagation()
                            }
                        }}
                    >
                    </Rectangle>
                )
        })
    };
    const heightOfTooltip = 50
    const heightOfChart = (): number => {
        return categories.length * 30 + 50
    }

    return (
        <Box
            width="100%"
            height={(heightOfChart() + heightOfTooltip).toString() + "px"}
            onTouchStart={() => setSelectedTrip(null)}
        >
            <Box width="95%" height={heightOfChart().toString() + "px"} onMouseLeave={() => setSelectedTrip(null)}>
                <ResponsiveContainer width="100%" height="100%">
                    <ComposedChart margin={{ left: 50, bottom: 0, top: 0, right: 30 }}>
                        <XAxis dataKey="dateTime" scale="time" type="number" domain={[chartStart, chartEnd]} allowDataOverflow={true} tickFormatter={DateFormatter} ticks={[chartStart, chartEnd]} />
                        <YAxis type="category" dataKey="row" allowDuplicatedCategory={false} padding={{ bottom: 20, top: 20 }} />
                        <Scatter name="start" dataKey="row" data={tripStartPoints} label={false} legendType="none" tooltipType="none" shape={<></>} isAnimationActive={false} />
                        <Scatter name="end" dataKey="row" data={tripEndPoints} label={false} legendType="none" tooltipType="none" shape={<></>} isAnimationActive={false} />
                        <Customized component={CustomErrorBars} />
                    </ComposedChart>
                </ResponsiveContainer>
            </Box>
            <MouseTracker offset={isMobile ? { x: 0, y: 0 } : { x: 30, y: 30 }}>
                <EngineTripPopup selectedTrip={selectedTrip} siteIDToName={siteIDToName} />
            </MouseTracker>
        </Box>
    );

}
export interface IEngineTripGanttChart {
    trips: IEngineTrip[],
    siteIDToName: Dictionary<string>,
    startDate: Moment,
    endDate: Moment
}

interface IGanttChartDataItem {
    dateTime: number,
    row: string,
    id: string
}
export default EngineTripGanttChart;