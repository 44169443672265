import { Button, Typography } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { Dictionary } from "@reduxjs/toolkit";
import chroma from "chroma-js";
import moment from "moment";
import React from 'react';
import { IAssetAvailability } from "../../shared/types/operate/IAssetAvailability";
import { useNavigate } from "react-router-dom";

const AvailabilityHeatmapTable: React.FC<IAvailabilityHeatmapTableProps> = ({ items, siteIdToCapacity, title }) => {
    const chromaScale = chroma.scale(["black", "limegreen"]).domain([0, 1]);
    const navigate = useNavigate();

    const redirectToAsset = (siteName: string) => {
        navigate(`/operate/availability?assetid=${siteName}`);
    }

    return (
        <>
            <Typography variant="h6">{title + ":"}</Typography>
            {(items.length > 0 ?
                <TableContainer>
                    <Table stickyHeader size="small" aria-label="sticky table">
                        <TableBody>
                            {
                                items.map(siteItems => {
                                    const siteName = siteItems[0].siteID;
                                    return (
                                        <TableRow sx={{ height: 5 }} key={siteName}>
                                            <TableCell sx={{ fontSize: 10 }} width="5" align="center"><Button sx={{ fontSize: 10 }} onClick={() => redirectToAsset(siteName)}>{siteName}</Button></TableCell>
                                            {siteItems.filter(siteItem => moment(siteItem.settlementDate, "YYYY-MM-DD hh:mm:ss").format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")).map(siteItem => {
                                                var perc = 0.0;
                                                perc = (siteItem.export === 0.0 ? 0.0 :
                                                    siteIdToCapacity[siteItem.siteID] === 0.0 ? 0.0 : (siteItem.export / ((siteIdToCapacity[siteItem.siteID] ?? 1.0) / 1000.0))
                                                );
                                                return (
                                                    <TableCell
                                                        key={siteItem.id + "-" + siteItem.settlementPeriod}
                                                        size="small"
                                                        align="center"
                                                        sx={{ backgroundColor: chromaScale(perc).hex() ?? "", padding: "0px" }}>
                                                    </TableCell>)
                                            })}
                                        </TableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                :
                <Typography>No Data Available</Typography>
            )
            }
        </>
    );
}

export interface IAvailabilityHeatmapTableProps {
    items: IAssetAvailability[][],
    siteIdToCapacity: Dictionary<number>,
    title: string
}

export default AvailabilityHeatmapTable;