import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IPushNotification } from "../../types/notification/IPushNotification"

interface NotificationsState {
    expanded: boolean
    notifications: IPushNotification[]
    unseenNotifications: number
}

const initialState: NotificationsState = {
    expanded: false,
    notifications: [],
    unseenNotifications: 0
};

export const notificationsSlice = createSlice({
    name: "notifications",
    initialState,
    reducers: {
        updateExpanded: (state, action: PayloadAction<boolean>) => {
            state.expanded = action.payload;
        },
        updateNotifications: (state, action: PayloadAction<IPushNotification[]>) => {
            state.notifications = action.payload;
            let unseenCounter = 0
            action.payload.forEach((notification) => {
                if (!notification.seen) { unseenCounter++ }
            })
            state.unseenNotifications = unseenCounter
        },
        prependNotification: (state, action: PayloadAction<IPushNotification>) => {
            state.notifications = [action.payload, ...state.notifications];
            if (!action.payload.seen) { state.unseenNotifications++ }
        },
        setNotificationAsRead: (state, action: PayloadAction<string>) => {
            state.notifications.forEach((notification) => {
                if (notification.notificationID === action.payload) {
                    notification.seen = true
                }
            })
            state.unseenNotifications--
        },
        setAllNotificationsAsRead: (state, action: PayloadAction<string>) => {
            state.notifications.forEach((notification) => notification.seen = true)
            state.unseenNotifications = 0
        },
        removeNotification: (state, action: PayloadAction<string>) => {
            state.notifications.forEach((notification) => {
                if (notification.notificationID === action.payload) {
                    notification.deleted = true
                    if (!notification.seen) {
                        state.unseenNotifications--
                    }
                }
            })
        },
    },
});

export const { updateExpanded, updateNotifications, prependNotification, setNotificationAsRead, setAllNotificationsAsRead, removeNotification } = notificationsSlice.actions;

export default notificationsSlice.reducer;
