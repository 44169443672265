import { IAssetInformation } from "../../shared/types/IAssetInformation"
import React from 'react';
import { DataGrid } from "@mui/x-data-grid";

const AssetsDataGrid: React.FC<IAssetsDataGridProps> = ({ items, onSelect }) => {
    const columns = [
        { field: 'Name', headerName: 'Asset', width: 170 },
        { field: 'SiteID', headerName: 'Site Id', width: 170 },
        { field: 'BMID', headerName: 'BMU Id', width: 170 },
        { field: 'GenerationType', headerName: 'Generation Type', width: 170 },
        { field: 'CapabilityKW', headerName: 'Capability KW', width: 170 },
        { field: 'Postcode', headerName: 'Post Code', width: 170 },
        { field: 'ConradRegion', headerName: 'Conrad Region', width: 170 },
    ];

    return (
        <DataGrid
            getRowId={(row) => row.SiteID}
            rows={items as IAssetInformation[]}
            columns={columns}
            initialState={{
                pagination: {
                    pageSize: 100,
                },
            }}
            onSelectionModelChange={(newRowSelectionModel) => {
                var id = newRowSelectionModel[0].toString();
                onSelect(id);
            }}
            rowsPerPageOptions={[20, 50, 100]}
        />
    );

}

export interface IAssetsDataGridProps {
    items: IAssetInformation[] | undefined;
    onSelect: (id: string) => void;
}

export default AssetsDataGrid;