import ApiRoot from "./ApiRoot";
import { IContractInformation } from "../types/dynamic-contracts/IDynamicContract";
import { getApiAccessToken } from "../auth/helpers";
import { IStackedService } from "../types/dynamic-contracts/IStackedService";

const rootUrl = process.env.REACT_APP_PERF_MON_API_URL;

const getContractList = async (startDate: string, endDate: string) => {
    const accessToken = await getApiAccessToken();
    
    const params = { StartTime: startDate, EndTime: endDate };
    return await ApiRoot.get<IContractInformation[]>(`${rootUrl}FileStatus`, { accessToken }, params);
}

const getStackedServices = async (startDate: string, endDate: string) => {
    const accessToken = await getApiAccessToken();

    const params = { startDate: startDate, endDate: endDate };
    return await ApiRoot.get<IStackedService[]>(`${rootUrl}StackedServices/GetDynamicServicesStatus`, { accessToken }, params);
}

const contractListApi = {
    getContractList,
    getStackedServices
}

export default contractListApi;