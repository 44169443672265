import { CloudUpload } from "@mui/icons-material";
import { Button, Stack } from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid, GridColDef, GridRenderCellParams, GridValueGetterParams } from "@mui/x-data-grid";
import React from "react";
import { IOpportunity } from "../../shared/types/commercial/opportunity/IOpportunity";

interface Props {
    params: GridRenderCellParams;
}

const OpportunitiesGrid: React.FC<IOpportunitiesGridProps> = ({ data, onSelectOpportunity }) => {
    const columns: GridColDef[] = [
        { field: "Id", headerName: "Tender Id", minWidth: 200 },
        { field: "Name", headerName: "Name", minWidth: 150, flex: 1 },
        { field: "Number_of_Sites__c", headerName: "Number of Sites", minWidth: 150 },
        { field: "Deal_Structure__c", headerName: "Deal Structure", minWidth: 150 },
        { field: "Date_Priced__c", headerName: "Date Priced", minWidth: 200 },
        { field: "StageName", headerName: "Stage Name", minWidth: 200 },
        { field: "AccountName", headerName: "Account", minWidth: 150, valueGetter: (params: GridValueGetterParams) => `${params.row.Account.Name}`, flex: 1 },
    ];

    const ActionsCell = ({ params }: Props) => {
        const { id } = params.row;

        return (
            <Stack direction="row">
                <Button size="small" variant="contained" title="View Seaglass submission Json" startIcon={<CloudUpload />}>Json</Button>
            </Stack>
        );
    };

    return (
        <>
            {data && (
                <Box paddingTop={2} height={500} width="100%">
                    <DataGrid
                        density="compact"
                        getRowId={(row) => row.Id}
                        rows={data}
                        columns={columns}
                        showCellRightBorder={true}
                        showColumnRightBorder={true}
                        pageSize={25}
                        onRowClick={(e) => onSelectOpportunity(e.row as IOpportunity)}
                    />
                </Box >
            )}            
        </>
    );
};

export default OpportunitiesGrid;

export interface IOpportunitiesGridProps {
    data?: IOpportunity[];
    onSelectOpportunity: (opportunity: IOpportunity) => void;
}
