import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getApiAccessToken } from "../auth/helpers";
import { IUserSettings } from "../types/IUserSettings";

export const userSettingsApi = createApi({
    reducerPath: 'userSettingsApi',
    baseQuery: fetchBaseQuery({   
        baseUrl: `${process.env.REACT_APP_PORTAL_API_URL}`,
        prepareHeaders: async (headers: Headers, { getState }) => {
            const accessToken = await getApiAccessToken();
            if (accessToken) {
                headers.set("Authorization", `Bearer ${accessToken}`);
            }
        }        
    }),
    tagTypes: ['UserSettings'],
    endpoints: (builder) => ({
        getSettings: builder.query<IUserSettings, string|null>({            
            query: (username) => `/user/settings/${username}`,       
            providesTags: ["UserSettings"],
            onQueryStarted: (data) => {
                
            }
        }),
        updateSettings: builder.mutation<string, { username: string, data: IUserSettings }>({
            query: (payload) => ({
                url: `/user/settings/${payload.username}`,
                method: 'POST',
                body: payload.data             
            }),
            invalidatesTags: ["UserSettings"],            
            onQueryStarted: (data) => {
                
            }
        }),
    }),
})
export const { useGetSettingsQuery, useUpdateSettingsMutation } = userSettingsApi

