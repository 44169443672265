import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { createContext, PropsWithChildren, useCallback, useContext, useRef, useState } from 'react';
import { IAssetInformation } from '../../../shared/types/IAssetInformation';
import { ISiteStatus } from '../../../shared/types/ISiteStatus';
import GoogleMapAsset from './GoogleMapAsset';

interface IGoogleMapAssetInformationContextProps {
    open: (asset: IAssetInformation, status: ISiteStatus | null) => Promise<boolean>;
}

const GoogleMapAssetInformationContext = createContext<IGoogleMapAssetInformationContextProps>({ open: async (asset: IAssetInformation, status: ISiteStatus | null) => true});

const GoogleMapAssetInformationContextProvider: React.FC<PropsWithChildren<any>> = ({ children }: PropsWithChildren<{}>) => {
    const resolver = useRef<any>();
    const [isOpen, setIsOpen] = useState(false);
    const [currentAsset, setCurrentAsset] = useState<IAssetInformation>({} as IAssetInformation);
    const [currentStatus, setCurrentStatus] = useState<ISiteStatus | null>(null);

    const handleOpen = useCallback((asset: IAssetInformation, status: ISiteStatus | null): Promise<boolean> => {
        setCurrentAsset(asset);
        setCurrentStatus(status);
        setIsOpen(true);

        return new Promise((resolve: any) => {
            resolver.current = resolve;
        });
    }, []);

    const handleOk = () => {
        resolver.current && resolver.current(true);
        setIsOpen(false);
    }

    const handleClose = () => {
        resolver.current && resolver.current(false);
        setIsOpen(false);
    }

    return (
        <GoogleMapAssetInformationContext.Provider value={{ open: handleOpen }}>
            {children}
            <Dialog
                open={isOpen}
                onClose={handleClose}
                aria-labelledby="modal-window-title"
                aria-describedby="modal-window-description"
            >
                <DialogContent style={{ backgroundColor: "white" }}>


                    <GoogleMapAsset asset={currentAsset} status={currentStatus} />


                </DialogContent>
                <DialogActions style={{ backgroundColor: "white" }}>
                    <Button variant="contained" onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </GoogleMapAssetInformationContext.Provider>
    )
}

export const useGoogleMapAssetInformationContext = () => useContext(GoogleMapAssetInformationContext);
export default GoogleMapAssetInformationContextProvider;