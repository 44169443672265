import Paper from '@mui/material/Paper';
import React from 'react';
import moment from "moment"
import { DataGrid, GridRenderCellParams } from "@mui/x-data-grid";
import { ILogEntries, MessageTemplateToken } from "../shared/types/ILogEntries"
import { Box, Typography } from '@mui/material';
import { Info, Warning } from '@mui/icons-material';

const LogDataGrid: React.FC<ILogDataGridProps> = ({ items, height = '600px' }) => {


    const columns = [
        {
            field: 'Timestamp', headerName: 'Timestamp', minWidth: 150, maxWidth: 180, flex: 1,
            renderCell: (params: GridRenderCellParams<string>) => (
                <Typography fontSize={12}>{moment(params.value).format("YYYY-MM-DD HH:mm:ss")}</Typography>
            ),
        },
        {
            field: 'Level', headerName: '', minWidth: 40, maxWidth: 50, flex: 1,
            renderCell: (params: GridRenderCellParams<string>) => (
                <Typography fontSize={12}>{params.value === "Information" ? <Info color="info"></Info> : <Warning color="error"></Warning>}</Typography>
            ),
        },
        {
            field: 'MessageTemplateTokens', headerName: 'Message', minWidth: 500, flex: 2,

            renderCell: (params: GridRenderCellParams<MessageTemplateToken[]>) => (
                <Typography fontSize={12}>{MakeString(params.value ?? [])}</Typography>
            ),
        },
        {
            field: 'Exception', headerName: 'Exception', minWidth: 300, flex:1,
        },
    ];

    const MakeString = (messageItems: MessageTemplateToken[]) => {

        var stringBuilder: string[] = [];

        messageItems.forEach(messageItem => {
            stringBuilder.push(messageItem.Text ? messageItem.Text ?? "" : messageItem.PropertyName ?? "");
        })

        return stringBuilder.join("");
    }

    return (
        <Box sx={{ height: height, width: "100%" }}>
            <DataGrid
                sx={{ overflowX: "auto" }}
                getRowId={(row) => row.Id}
                rows={items?.Events ?? []}
                columns={columns}
                initialState={{
                    pagination: {
                        pageSize: 100,
                    },
                }}
                rowsPerPageOptions={[20, 50, 100]}
            />
        </Box>
    );

}

export interface ILogDataGridProps {
    items?: ILogEntries
    height?: number|string
}

export default LogDataGrid;