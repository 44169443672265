import { SvgIconComponent } from "@mui/icons-material";
import { Tooltip } from "@mui/material";

export const HexIcon: React.FC<IHexIconProps> = ({ Icon, fontSize = 0.1, colour, y = 0, x = 0, tooltip }) => {
    const size = `${fontSize}em`;
    return (
        <svg x={`${-(fontSize / 2) + x}em`} y={`${y}em`}>
            <Tooltip title={tooltip} placement={'top'} >
                <Icon height={size} width={size} fontSize="inherit" sx={{ color: colour, stroke: colour }} />
            </Tooltip>
        </svg>
    )
}

export interface IHexIconProps {
    Icon: SvgIconComponent;
    fontSize?: number;
    colour?: string;
    y?: number;
    x?: number;
    tooltip?: string;
}