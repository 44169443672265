import PageWrapper from "../../components/PageWrapper";
import React, { useEffect } from "react";
import WindForecastChart from "../../components/analytics/WindForecastChart";
import { Box } from "@mui/material";
import { IPortalRouteOptions } from "../../shared/types/shared/routes/routeTypes";
import { Analytics } from "@mui/icons-material";

const AnalyticsWindPage: React.FC<IAnalyticsWindPage> = () => {
    const [loaded, setLoaded] = React.useState(false);

    const loadComplete = () => {
        setLoaded(true);

    }


    useEffect(() => {
        loadComplete()
    }, [])
    return (
        <PageWrapper title="Wind Forecast Chart" loaded={loaded}>
            <Box sx={{ height: "75vh", maxHeight: "700px", width: "74vw" }} >
                <WindForecastChart />
            </Box>
        </PageWrapper>
    );
}


export interface IAnalyticsWindPage {

}

const AnalyticsWindPageConfig: IPortalRouteOptions = {
    relativeUrl: "wind",
    page: <AnalyticsWindPage />,
    navDisplay: {
        title: "Wind",
        icon: <Analytics />
    },
}


export default AnalyticsWindPageConfig;