import { CSSProperties, ReactElement, useEffect, useMemo, useState } from "react";
import { Root, createRoot } from "react-dom/client";
import { IEngineerLocation } from "../../../shared/types/engineer-locations/IEngineerLocation";
import { useGoogleMapAssetInformationContext } from './GoogleMapAssetInformation';
import GoogleMapEngineerIcon from './GoogleMapEngineerIcon';
import { IMarkerData, MarkerType } from "./GoogleMap";

const GoogleMapEngineerMarkerElement: React.FC<IGoogleMapEngineerMarkerElementProps> = ({ engineer }) => {
    const outerStyle: CSSProperties = {
        backgroundColor: engineer.LastEventDescription === "Ignition-Off" ? "grey" : "black",
        borderRadius: "10%",
        width: 40,
        height: 40,
        padding: 3
    };

    const innerStyle: CSSProperties = {
        backgroundColor: "white",
        borderRadius: "10%",
        width: "100%",
        height: "100%",
    };

    return (
        <div style={outerStyle}>
            <div style={innerStyle}>
                <GoogleMapEngineerIcon engineer={engineer} />
            </div>
        </div>
    )
}

interface IGoogleMapEngineerMarkerElementProps {
    engineer: IEngineerLocation;
}

const GoogleMapEngineerMarker: React.FC<IMarkerProps> = ({ map, engineer, marker }) => {
    const assetInfo = useGoogleMapAssetInformationContext();
    const [markerContainer, setMarkerContainer] = useState<IMarkerContainer>();

    if (!marker) {
        marker = new google.maps.marker.AdvancedMarkerElement();
    }

    const markerOptions: google.maps.marker.AdvancedMarkerElementOptions = useMemo(() => { // Marker options
        return {
            position: { lat: engineer.Latitude as number, lng: engineer.Longitude as number },
            map: map,
            title: engineer.LocationText,
            content: markerContainer?.domElement,
        }
    }, [map, engineer, markerContainer]);

    const markerElement: ReactElement = useMemo(() => { // Engineer marker element
        return <GoogleMapEngineerMarkerElement key={`${engineer.VehicleId}-element`} engineer={engineer} />;
    }, [engineer]);

    useEffect(() => { // Initialisation
        const domElement = document.createElement("span");
        const reactRoot = createRoot(domElement);
        setMarkerContainer({ reactRoot, domElement });

        return () => { // Teardown
            setTimeout(() => { // Unmount the react root in a timeout
                reactRoot.unmount();
            });

            if (marker) {
                marker.map = null; // Remove the old marker from the map
            }
        }
    }, [marker]);

    useEffect(() => { // Update marker data
        if (marker) {
            const markerData: IMarkerData = { type: MarkerType.Engineer, engineer };
            marker.id = JSON.stringify(markerData);
        }
    }, [marker, engineer]);

    useEffect(() => { // Update marker options
        if (marker) {
            Object.assign(marker, markerOptions);
        }
    }, [marker, markerOptions]);

    useEffect(() => { // Update engineer marker element
        markerContainer?.reactRoot.render(markerElement);
    }, [markerContainer, markerElement]);

    return <></>
};

interface IMarkerProps {
    map: google.maps.Map;
    engineer: IEngineerLocation;
    marker?: google.maps.marker.AdvancedMarkerElement;
}

interface IMarkerContainer {
    domElement: HTMLSpanElement;
    reactRoot: Root;
}

export default GoogleMapEngineerMarker;