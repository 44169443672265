import { Box, TextField } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import StringAutoComplete from "../../../shared/form/StringAutoComplete";
import powerBiApi, { IAnalyticsBIReport, IValidatedBIReport } from "../../../../shared/api/PowerBiApi";
import { LoadingButton } from "@mui/lab";
import ErrorIcon from '@mui/icons-material/Error';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import { OpenInNew } from "@mui/icons-material";

enum ValidationState {
    Unvalidated,
    Validating,
    Success,
    Failure,
}

const navTitleHelperText = "Enter a unique title (max 20 chars)";
const pageTitleHelperText = "Optional page title (max 50 chars) or leave blank";
const submenuHelperText = "Select or enter submenu group (max 20 chars)"
const groupIdHelperText = "Select or enter Power BI Group ID"
const reportIdHelperText = "Enter Power BI Report ID"

const PowerBiReportConfigForm: React.FC<IPowerBiReportConfigFormProps> = ({ report, editMode = false, onFormUpdated, onValidityChanged, existingReports = [], groupIds = [], submenus = [] }) => {
    const [isValid, setIsValid] = useState<boolean>(editMode);
    const [lastValidatedDetails, setLastValidatedDetails] = useState<IValidatedBIReport>();
    const [reportValidated, setReportValidated] = useState<ValidationState>(ValidationState.Unvalidated);

    const [titleDirty, setTitleDirty] = useState<boolean>(editMode);
    const [title, setTitle] = useState<string>(report?.navTitle || "");
    const [titleError, setTitleError] = useState<string>("");

    const [pageTitle, setPageTitle] = useState<string | undefined>(report?.pageTitle || "");

    const [submenuDirty, setSubmenuDirty] = useState<boolean>(editMode);
    const [submenu, setSubmenu] = useState<string>(report?.navSubMenu || "");
    const [submenuError, setSubmenuError] = useState<string>("");

    const [groupIdDirty, setGroupIdDirty] = useState<boolean>(editMode);
    const [groupId, setGroupId] = useState<string>(report?.groupId || "");
    const [groupIdError, setGroupIdError] = useState<string>("");

    const [reportIdDirty, setReportIdDirty] = useState<boolean>(editMode);
    const [reportId, setReportId] = useState<string>(report?.reportId || "");
    const [reportIdError, setReportIdError] = useState<string>("");

    const validateReportDetails = useCallback(async (groupId: string, reportId: string) => {
        setReportValidated(ValidationState.Validating);
        powerBiApi.validateReport(groupId, reportId).then((response) => {
            setReportValidated(ValidationState.Success);
            setLastValidatedDetails(response);
        }).catch((error) => {
            setReportValidated(ValidationState.Failure);
        });
    }, []);

    const verifyValidation = useCallback((groupId: string, reportId: string, lastValidated?: IValidatedBIReport) => {
        if (!lastValidated || groupId !== lastValidated.datasetWorkspaceId || reportId !== lastValidated.id) {
            setReportValidated(ValidationState.Unvalidated);
        } else {
            setReportValidated(ValidationState.Success);
        }
    }, []);

    useEffect(() => {
        if (onFormUpdated) {
            const formData: IAnalyticsBIReport = {
                id: report?.id || 0,
                navIndex: report?.navIndex || 0,
                navTitle: title,
                pageTitle,
                navSubMenu: submenu,
                groupId,
                reportId,
                hidden: report?.hidden || false,
            }

            onFormUpdated(formData);
        }
    }, [title, pageTitle, submenu, groupId, reportId, report, onFormUpdated]);

    const validateTitle = useCallback(() => {
        if (!titleDirty) { return false; }

        if (!title) {
            setTitleError("Title field cannot be empty");
            return false;
        }

        const match = existingReports.find((r) => r.navTitle === title)
        if (match && match.id !== report?.id) {
            setTitleError("Report already exists with this title");
            return false;
        }

        setTitleError("");
        return true;
    }, [title, titleDirty, existingReports, report]);

    const validateSubmenu = useCallback(() => {
        if (!submenuDirty) { return false; }

        if (!submenu) {
            setSubmenuError("Submenu field cannot be empty");
            return false;
        }

        setSubmenuError("");
        return true;
    }, [submenu, submenuDirty]);

    const validateGroupId = useCallback(() => {
        if (!groupIdDirty) { return false; }

        if (!groupId) {
            setGroupIdError("Group ID field cannot be empty");
            return false;
        }

        setGroupIdError("");
        return true;
    }, [groupId, groupIdDirty]);

    const validateReportId = useCallback(() => {
        if (!reportIdDirty) { return false; }

        if (!reportId) {
            setReportIdError("Report ID field cannot be empty");
            return false;
        }

        setReportIdError("");
        return true;
    }, [reportId, reportIdDirty]);

    useEffect(() => {
        // Validation
        const titleValid = validateTitle();
        const submenuValid = validateSubmenu();
        const groupIdValid = validateGroupId();
        const reportIdValid = validateReportId();
        const validated = reportValidated === ValidationState.Success;

        const isValid = titleValid && submenuValid && groupIdValid && reportIdValid && validated;
        setIsValid(isValid);
    }, [validateTitle, validateSubmenu, validateGroupId, validateReportId, reportValidated]);

    useEffect(() => {
        if (onValidityChanged) {
            onValidityChanged(isValid);
        }
    }, [isValid, onValidityChanged]);

    const validationButtonStyles: {
        colour: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning',
        icon: React.ReactNode,
        text: string,
    } = useMemo(() => {
        switch (reportValidated) {
            case ValidationState.Success:
                return { colour: "success", icon: <OpenInNew />, text: lastValidatedDetails?.name || "" }
            case ValidationState.Failure:
                return { colour: "error", icon: <ErrorIcon />, text: "Validate" }
            case ValidationState.Unvalidated:
            case ValidationState.Validating:
                return { colour: "primary", icon: <AnalyticsIcon />, text: "Validate" }
        }
    }, [reportValidated, lastValidatedDetails]);

    const openInNewTab = (url: string) => {
        window.open(url, '_blank', 'noreferrer');
    };

    return (
        <Box component="form">
            <TextField
                autoFocus
                required
                autoComplete="off"
                error={!!titleError}
                helperText={titleError || navTitleHelperText}
                margin="dense"
                id="report-title"
                label="Title"
                fullWidth
                variant="standard"
                value={title}
                onChange={(e) => { setTitle(e.target.value); setTitleDirty(true); }}
                inputProps={{ maxLength: 20 }}
            />
            <TextField
                autoComplete="off"
                helperText={pageTitleHelperText}
                margin="dense"
                id="page-title"
                label="Page Title"
                fullWidth
                variant="standard"
                value={pageTitle}
                onChange={(e) => { setPageTitle(e.target.value); }}
                inputProps={{ maxLength: 50 }}
            />
            <StringAutoComplete
                required
                error={!!submenuError}
                helperText={submenuError || submenuHelperText}
                margin="dense"
                id="submenu"
                label="Submenu"
                fullWidth
                variant="standard"
                value={submenu}
                onValueChanged={(val) => { setSubmenu(val); setSubmenuDirty(true); }}
                options={submenus}
                maxLength={20}
            />
            <StringAutoComplete
                required
                error={!!groupIdError}
                helperText={groupIdError || groupIdHelperText}
                margin="dense"
                id="group-id"
                label="Group ID"
                fullWidth
                variant="standard"
                value={groupId}
                onValueChanged={(val) => { setGroupId(val); setGroupIdDirty(true); verifyValidation(val, reportId, lastValidatedDetails); }}
                options={groupIds}
            />
            <TextField
                required
                autoComplete="off"
                error={!!reportIdError}
                helperText={reportIdError || reportIdHelperText}
                margin="dense"
                id="report-id"
                label="Report ID"
                fullWidth
                variant="standard"
                value={reportId}
                onChange={(e) => { setReportId(e.target.value); setReportIdDirty(true); verifyValidation(groupId, e.target.value, lastValidatedDetails); }}
            />
            <LoadingButton
                onClick={
                    reportValidated !== ValidationState.Success || !lastValidatedDetails ?
                        () => validateReportDetails(groupId, reportId) :
                        () => openInNewTab(lastValidatedDetails?.webUrl)
                }
                fullWidth
                color={validationButtonStyles.colour}
                variant="contained"
                loading={reportValidated === ValidationState.Validating}
                startIcon={validationButtonStyles.icon}
                sx={{ mt: 1, color: "white" }}
            >
                {validationButtonStyles.text}
            </LoadingButton>
        </Box>
    );
}

interface IPowerBiReportConfigFormProps {
    report?: IAnalyticsBIReport;
    editMode?: boolean;
    onFormUpdated?: (report: IAnalyticsBIReport) => void;
    onValidityChanged?: (isValid: boolean) => void;
    existingReports?: IAnalyticsBIReport[];
    groupIds?: string[];
    submenus?: string[];
}

export default PowerBiReportConfigForm;
