import { Box } from "@mui/material";
import { PropsWithChildren } from "react";

const TabPanel: React.FC<PropsWithChildren<ITabPanelProps>> = ({ children, value, index, padding = 2, paddingTop = 3 }) => {
    return (
        <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`}>
            {value === index && (
                <Box sx={{ p: padding, pt: paddingTop }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

interface ITabPanelProps {
    index: number;
    value: number;
    padding?: number;
    paddingTop?: number;
}

export default TabPanel;
