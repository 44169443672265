import { Button, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import React, { useCallback } from 'react';
import { IHistoricalData } from '../../shared/types/IHistoricalValues';
import moment, { Moment } from 'moment';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { CalendarToday } from '@mui/icons-material';
import piDataApi from '../../shared/api/PiDataApi';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useToastAlertContext } from '../ToastAlert';
import { isMobile } from 'react-device-detect';

export interface IBatterySite {
    ID: string;
    Name: string;
}

const BatteryContractsTable: React.FC<IBatteryContractsTable> = () => {
    const [loaded, setLoaded] = React.useState(false);

    const efaBlocks = ["EFA1", "EFA2", "EFA3", "EFA4", "EFA5", "EFA6"];
    const tableHeaders = ["Market", ...efaBlocks];

    const [siteList, setSiteList] = React.useState<IBatterySite[]>([
        { ID: "BLAOL", Name: "Blackpool" },
        { ID: "FLICK", Name: "Flitwick" },
        { ID: "WINER", Name: "Winchester" },
        { ID: "TORAY", Name: "Torquay" },
    ])

    const [selectedAsset, setSelectedAsset] = React.useState("")

    const [attributeList, setAttributeList] = React.useState<string[]>(["DC Contract Amount HF", "DC Contract Amount LF", "DC Mode", "DM Contract Amount HF", "DM Contract Amount LF", "DM Mode", "DR Contract Amount HF", "DR Contract Amount LF", "DR Mode"])
    const [calculationList, setCalculationList] = React.useState<string[]>(["Raw"])

    const [startDate, setStartDate] = React.useState<Moment>(moment())

    const [data, setData] = React.useState<IHistoricalData[]>([]);

    const { popToast } = useToastAlertContext();

    const loadComplete = () => {
        setLoaded(true);
    }

    const fetchData = useCallback(async () => {
        const sites: string[] = [selectedAsset];
        let start = startDate.clone();
        start = start.subtract(1, 'day');

        return piDataApi.getHistoricalValues(start.format("YYYY-MM-DDT21:00:00.00"), startDate.format("YYYY-MM-DDT21:00:00.00"), 1800, sites, attributeList, calculationList)
            .then((data) => {

                setData(data)
            })
    }, []);

    const load = useCallback(() => {
        setSelectedAsset(siteList[0].ID);

        const promises = [fetchData()];

        Promise.all(promises).catch((error: Error) => popToast(error.message, "error")).finally(loadComplete);
    }, [popToast, fetchData]);

    const handleAssetChange = (event: SelectChangeEvent) => {
        setSelectedAsset(event.target.value as string);
    };

    const updateStartDate = (Value: Moment | null) => {
        if (!Value) { return; }

        setStartDate(Value);
    }

    const clickQueryRequestButton = () => {
        const sites: string[] = [selectedAsset];
        let start = startDate.clone();
        start = start.subtract(1, 'day');

        piDataApi.getHistoricalValues(start.format("YYYY-MM-DDT21:00:00.00"), startDate.format("YYYY-MM-DDT21:00:00.00"), 1800, sites, attributeList, calculationList)
            .then((data) => {

                setData(data)
            })
    }

    const renderDataRows = (historicalData: IHistoricalData[]) => {
        return historicalData.map((val, index) => {
            return (
                <TableRow key={index}>
                    <TableCell key={index} align="center">{val.Attribute ?? ""}</TableCell>
                    {renderDataRow(efaBlocks, val)}
                </TableRow>
            )
        });
    }

    const renderDataRow = (columns: string[], historicalData: IHistoricalData) => {
        const filteredValues = historicalData.Values.filter((a) => (moment(a.StartTime).minute() === 0));

        return columns.map((column, index) => {
            const value = filteredValues[index];

            if (!value) {
                return (
                    <TableCell key={index} align="center">
                    </TableCell>
                )
            }

            return (
                <TableCell key={index} align="center">
                    <Typography color="darkgray">{moment(value.StartTime).format("DD-MMM-yyyy HH:mm:ss")}</Typography>
                    <Typography color={value.Value > 0 ? "limegreen" : "lightblue"}>{value.Value.toString()}</Typography>
                </TableCell>
            );
        });
    }

    return (
        <Grid container spacing={2}>
            <Grid xs={isMobile ? 6 : 2}>
                <FormControl fullWidth>
                    <InputLabel id="battery-asset-select-label">Asset</InputLabel>
                    <Select
                        labelId="battery-asset-select-label"
                        id="battery-asset-select"
                        value={selectedAsset}
                        label="Asset"
                        onChange={handleAssetChange}
                    >

                        {siteList.map(site => (
                            <MenuItem key={site.ID} value={site.ID}>{site.Name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid xs={isMobile ? 6 : 2} >
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                        label="Contract Date"
                        inputFormat="DD/MM/YYYY"
                        value={startDate}
                        onChange={updateStartDate}
                        renderInput={(params) => <TextField {...params}
                            fullWidth
                        />}
                    />
                </LocalizationProvider>
            </Grid>
            <Grid xs={isMobile ? 12 : 2} alignItems="stretch" style={{ display: "flex" }}>
                <Button
                    variant="contained"
                    startIcon={<CalendarToday />}
                    onClick={clickQueryRequestButton}
                    size="large"
                    fullWidth
                >
                    Refresh
                </Button>
            </Grid>
            <Grid xs={12}>
                <TableContainer sx={{ maxHeights: 440 }}>
                    <Table stickyHeader size="small" aria-label="sticky table">
                        <TableHead>
                            <TableRow key="header">
                                {tableHeaders.map((header) => (
                                    <TableCell key={header} align="center">{header}</TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {renderDataRows(data)}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    );
}

export interface IBatteryContractsTable { }

export default BatteryContractsTable;