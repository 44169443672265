import { Grid, Paper } from "@mui/material";
import PageWrapper from "../../../components/PageWrapper";
import React, { useEffect } from "react";
import { IPortalRouteOptions } from "../../../shared/types/shared/routes/routeTypes";
import { Dashboard, Schedule } from "@mui/icons-material";

const SchedulerPage: React.FC<ISchedulerPageProps> = () => {
    const [loaded, setLoaded] = React.useState(false);

    const loadComplete = () => {
        setLoaded(true);
    }

    setTimeout(loadComplete, 250);

    return (
        <PageWrapper title="Scheduler" loaded={loaded}>
            <Grid container>
                <Grid item xs={12}>
                    <Paper sx={{ p: 2 }}>
                        Select Scheduler Item from the menu
                        <br /><br />
                        Status, Release Notes etc will go here
                    </Paper>
                </Grid>
            </Grid>
        </PageWrapper>
    );
}

export interface ISchedulerPageProps {

}

const SchedulerPageConfig: IPortalRouteOptions = {
    relativeUrl: "scheduler",
    page: <SchedulerPage />,
    navDisplay: {
        title: "Scheduler",
        icon: <Schedule />,
        nestedTitle: "Status",
        nestedIcon: <Dashboard />,
    }
}

export default SchedulerPageConfig;
