import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IBOAData } from "../../types/data/IBOAData";

interface BOAState {
    newBoaData: IBOAData[]
}

const initialState: BOAState = {
    newBoaData: []
};

export const boaSlice = createSlice({
    name: "newBoas",
    initialState,
    reducers: {
        updateBOAs: (state, action: PayloadAction<IBOAData[]>) => {
            state.newBoaData = action.payload;
        },
        addBOA: (state, action: PayloadAction<IBOAData>) => {
            state.newBoaData = [action.payload, ...state.newBoaData];
        }
    },
});

export const { updateBOAs, addBOA } = boaSlice.actions;

export default boaSlice.reducer;
