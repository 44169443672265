import { LinearProgress, Typography } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { useEffect, useState } from "react";
import { useAppSelector } from "../../shared/hooks/StateHook";

interface PortfolioPositionData {
    tradedValue: number;
    burnValue: number;
    tradedPerc: number;
    burnPerc: number;
}

const PortfolioPosition = () => {
    const { data } = useAppSelector((state) => state.dailyMetered);
    const [position, setPosition] = useState<PortfolioPositionData>();

    useEffect(() => {
        const traded = data.reduce((sum, current) => sum + current.traded, 0);
        const burn = data.reduce((sum, current) => sum + current.burn, 0);
        const maximum = Math.max(traded, burn);
        setPosition({
            tradedValue: traded,
            burnValue: burn,
            tradedPerc: Math.floor((100 / maximum) * traded),
            burnPerc: Math.floor((100 / maximum) * burn),
        });
    }, [data]);

    return (
        <Grid container padding={5} alignItems="center" spacing={2}>
            <Grid md={3} pr={2}>
                <Typography variant="body2" align="right">
                    Total Traded Position {position?.tradedValue} Therms
                </Typography>
            </Grid>
            <Grid md={9}>
                <LinearProgress
                    variant="determinate"
                    color="success"
                    value={position?.tradedPerc || 0}
                    sx={{ width: "auto", padding: 1, borderRadius: 5 }}
                />
            </Grid>
            <Grid md={3} pr={2}>
                <Typography variant="body2" align="right">
                    Total Gas Burn {position?.burnValue} Therms
                </Typography>
            </Grid>
            <Grid md={9}>
                <LinearProgress
                    variant="determinate"
                    color="warning"
                    value={position?.burnPerc || 0}
                    sx={{ width: "auto", padding: 1, borderRadius: 5 }}
                />
            </Grid>
        </Grid>
    );
};

export default PortfolioPosition;
