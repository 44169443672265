import React from 'react';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { ThemeProvider } from '@emotion/react';
import { Paper } from '@mui/material';
import { useAppSelector } from '../../shared/hooks/StateHook';

interface Props {
    id: string;
}

const columns: GridColDef[] = [
    { field: "site", headerName: "Site", minWidth: 150 },
    { field: "code", headerName: "Code" },
    { field: "traded", headerName: "Traded" },
    { field: "burn", headerName: "Burn" },
    { field: "override", headerName: "Override" },
    { field: "residual", headerName: "Residual" },
];

const SubmissionDetailsGrid = () => {
    const { selectedDetails } = useAppSelector((state) => state.historicalSubmissions);

    return (
            <Paper>
                <div style={{ height: 300, width: "100%" }}>
                    {selectedDetails && (
                        <DataGrid
                            getRowId={(row) => row.code}
                            density="compact"
                            rows={selectedDetails}
                            columns={columns}
                            showCellRightBorder={true}
                            showColumnRightBorder={true}
                        />
                    )}
                </div>
            </Paper>
    );
}

export default SubmissionDetailsGrid;