import React, { useState } from "react";
import PageWrapper from "../../components/PageWrapper";
import { Box, Paper, Tab, Tabs } from "@mui/material";
import { IPortalRouteOptions } from "../../shared/types/shared/routes/routeTypes";
import { Groups, Key, Person } from "@mui/icons-material";
import TabPanel from "../../components/TabPanel";
import UserPermissionsTab from "../../components/system-admin/permissions/UserPermissionsTab";
import { useGetAllPermissionsQuery } from "../../shared/api/PermissionsApi";
import GroupPermissionsTab from "../../components/system-admin/permissions/GroupPermissionsTab";

enum PermissionsTab {
    User = 0,
    Group = 1,
}

const SystemAdminPermissionsPage: React.FC = () => {
    const [selectedTab, setSelectedTab] = useState<PermissionsTab>(PermissionsTab.User);
    const { data, isLoading, isFetching, refetch } = useGetAllPermissionsQuery();

    const reload = () => {
        refetch();
    }

    const updateTab = (event: React.SyntheticEvent, newValue: number) => {
        setSelectedTab(newValue);
    };

    return (
        <PageWrapper title="Permissions" loaded={!isLoading} contentOverflow="hidden" refreshing={isFetching}>
            <Paper sx={{ height: "calc(100% - 1px)", width: "100%", p: 0, overflowY: "hidden" }}>
                <Tabs value={selectedTab} onChange={updateTab} variant="fullWidth">
                    <Tab icon={<Person />} label="User Permissions" />
                    <Tab icon={<Groups />} label="Group Permissions" />
                </Tabs>
                <Box overflow="auto" height="calc(100% - 72px)" width="100%">
                    <TabPanel value={selectedTab} index={PermissionsTab.User} padding={0} paddingTop={0}>
                        <UserPermissionsTab permissions={data?.users || []} reload={reload} inputDisabled={isFetching} />
                    </TabPanel>
                    <TabPanel value={selectedTab} index={PermissionsTab.Group} padding={0} paddingTop={0}>
                        <GroupPermissionsTab permissions={data?.groups || []} reload={reload} inputDisabled={isFetching} />
                    </TabPanel>
                </Box>
            </Paper>
        </PageWrapper>
    );
}

const SystemAdminPermissionsPageConfig: IPortalRouteOptions = {
    relativeUrl: "permissions",
    page: <SystemAdminPermissionsPage />,
    navDisplay: {
        title: "Permissions",
        icon: <Key />,
    },
}


export default SystemAdminPermissionsPageConfig;