import React, { useEffect, useMemo, useState } from "react";
import PageWrapper from "../../components/PageWrapper";
import { Box, Button, Card, FormControl, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, Tab, TextField, Typography } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { useGetLiveAssetsQuery } from "../../shared/api/AssetApi";
import { Dictionary } from "@reduxjs/toolkit";
import availabilityApi from "../../shared/api/AvailabilityApi";
import { IAssetAvailability } from "../../shared/types/operate/IAssetAvailability";
import AssetAvailabilityChart from "../../components/operate/AssetAvailabilityChart"
import ResultsTable from "../../components/ResultsTable";
import LoadingSymbol from "../../components/operate/LoadingSymbol";
import { Download, EventAvailable } from '@mui/icons-material';
import moment, { Moment } from "moment";
import { IEngineOutage } from "../../shared/types/operate/IEngineOutage";
import { IPortalRouteOptions } from "../../shared/types/shared/routes/routeTypes";
import { isMobile } from "react-device-detect";

const SiteAvailabilityPage: React.FC<ISiteAvailabilityPage> = () => {
    const [loaded, setLoaded] = useState(false);
    const [loadedAvailability, setLoadedAvailability] = React.useState(true);
    const [loadedOutages, setLoadedOutages] = React.useState(true);
    const { data: assets } = useGetLiveAssetsQuery();

    const [selectedAssets, setSelectedAssets] = useState<string[]>([])
    const [assetSelectionType, setAssetSelectionType] = useState<string>("Asset")
    const [selectedArea, setSelectedArea] = useState<string>("Area 1")
    const [selectedRegion, setSelectedRegion] = useState<string>("North")
    const defaultStartTime = useMemo(() => moment.utc().startOf("day"), []);
    const defaultEndTime = useMemo(() => moment.utc().startOf("day").add(1, "day"), []);
    const [startDate, setStartDate] = useState<Moment>(defaultStartTime);
    const [endDate, setEndDate] = useState<Moment>(defaultEndTime);

    const [siteToAvailability, setSiteToAvailability] = useState<Dictionary<Array<IAssetAvailability>>>({})
    const [formattedSiteToAvailability, setFormattedSiteToAvailability] = useState<Dictionary<Array<IAssetAvailability>>>({})
    const [summarisedAvailability, setSummarisedAvailability] = useState<IAssetAvailability[]>([])
    const [formattedSummarisedAvailability, setFormattedSummarisedAvailability] = useState<IAssetAvailability[]>([])
    const [assetOutages, setAssetOutages] = useState<IEngineOutage[]>([])
    const [areaToAvailability, setAreaToAvailability] = useState<Dictionary<Array<IAssetAvailability>>>({})
    const [formattedAreaToAvailability, setFormattedAreaToAvailability] = useState<Dictionary<Array<IAssetAvailability>>>({})
    const [displayType, setDisplayType] = useState<string>("Asset")
    const [siteToOutages, setSiteToOutages] = useState<Dictionary<Array<IEngineOutage>>>({})
    const [areaToOutages, setAreaToOutages] = useState<Dictionary<Array<IEngineOutage>>>({})
    const [siteIdToName, setSiteIdToName] = useState<Dictionary<string>>({})
    const [siteIdToCapacity, setSiteIdToCapacity] = useState<Dictionary<number>>({})
    const [areaToCapacity, setAreaToCapacity] = useState<Dictionary<number>>({})
    const [areaToSiteId, setAreaToSiteId] = useState<Dictionary<Array<string>>>({})
    const [siteIdToArea, setSiteIdToArea] = useState<Dictionary<string>>({})
    const [availabilityPanel, setAvailabilityPanel] = useState<string>("GraphSummary");
    const [capacity, setCapacity] = useState<number>(0.0)

    useEffect(() => {
        switch (assetSelectionType) {
            case 'Asset':
                setSelectedAssets([])
                break;
            case 'Area':
                const assetsInArea = areaToSiteId[selectedArea]
                setSelectedAssets(assetsInArea || [])
                break
            case 'Region':
                if (selectedRegion === 'North') {
                    setSelectedAssets((areaToSiteId['Area 4'] || []).concat(areaToSiteId['Area 5'] || []).concat(areaToSiteId['Area 6'] || []))
                }
                else {
                    setSelectedAssets((areaToSiteId['Area 1'] || []).concat(areaToSiteId['Area 2'] || []).concat(areaToSiteId['Area 3'] || []))
                }
                break
            default:
                setSelectedAssets(Object.keys(siteIdToName).sort())
        }
    }, [assetSelectionType, selectedArea, selectedRegion])


    const formatArray = (inputArray: Array<IAssetAvailability>) => {
        const copyArray = [...inputArray]
        copyArray.forEach(sp => {
            sp.startGMT = moment(sp.startGMT).format("YYYY-MM-DD HH:mm")
            sp.endGMT = moment(sp.endGMT).format("YYYY-MM-DD HH:mm")
        })
        return copyArray
    }

    const formatDictionary = (inputDictionary: Dictionary<Array<IAssetAvailability>>) => {
        const copyDict: Dictionary<Array<IAssetAvailability>> = structuredClone(inputDictionary)
        for (let key in copyDict) {
            copyDict[key] = formatArray(copyDict[key]!)
        }
        return copyDict
    }

    useEffect(() => {
        setFormattedSummarisedAvailability(formatArray(summarisedAvailability))
    }, [summarisedAvailability])

    useEffect(() => {
        setFormattedAreaToAvailability(formatDictionary(areaToAvailability))
    }, [areaToAvailability])

    useEffect(() => {
        setFormattedSiteToAvailability(formatDictionary(siteToAvailability))
    }, [siteToAvailability])

    const loadComplete = () => {
        setLoaded(true);
    }

    const fetchPageData = () => {
        if (assets !== undefined) {
            var dictName: Dictionary<string> = {}
            var dictCapacity: Dictionary<number> = {}
            var dictAreaToSite: Dictionary<Array<string>> = {}
            var dictAreaToCapacity: Dictionary<number> = {}
            var dictSiteToArea: Dictionary<string> = {}
            assets?.forEach((item) => {
                dictName[item.SiteID] = item.Name
                dictSiteToArea[item.SiteID] = item.ConradRegion
                dictCapacity[item.SiteID] = (item.CapabilityKW === null ? 0 : item.CapabilityKW / 1000)
                if (!(item.ConradRegion in dictAreaToSite)) {
                    dictAreaToSite[item.ConradRegion] = new Array<string>(item.SiteID)
                    dictAreaToCapacity[item.ConradRegion] = item.CapabilityKW / 1000
                }
                else {
                    const existingSites = dictAreaToSite[item.ConradRegion]
                    let existingCapacity = dictAreaToCapacity[item.ConradRegion] || 0.0
                    existingSites?.push(item.SiteID)
                    existingCapacity += item.CapabilityKW / 1000
                    dictAreaToSite[item.ConradRegion] = existingSites
                    dictAreaToCapacity[item.ConradRegion] = existingCapacity
                }
            });
            for (let site in dictCapacity) {
                dictCapacity[site] = Math.round((dictCapacity[site] || 0) * 100)/ 100
            }
            for (let area in dictAreaToCapacity) {
                dictAreaToCapacity[area] = Math.round((dictAreaToCapacity[area] || 0) * 100) / 100
            }
            setSiteIdToName(dictName);
            setSiteIdToCapacity(dictCapacity);
            setAreaToSiteId(dictAreaToSite)
            setSiteIdToArea(dictSiteToArea)
            setAreaToCapacity(dictAreaToCapacity)

            var searchParams = new URLSearchParams(window.location.search);
            if (searchParams.get("assetid") !== "") {
                const searchAsset = searchParams.get("assetid")
                if (searchAsset !== null) {
                    setSelectedAssets([searchAsset]);
                }
            }
            loadComplete();
        }
    }

    const handleGatherDataClick = () => {
        setLoadedAvailability(false);
        setLoadedOutages(false);
        availabilityApi.getAssetAvailability(moment(startDate).format(), moment(endDate).format(), selectedAssets)
            .then((availData) => {
                availData.forEach(site =>
                    site.forEach(result => {
                        result.id = result.siteID + "-" + result.settlementDate + "-SP" + result.settlementPeriod
                    }
                ))

                // Getting summarised capacity
                let newCapacity = 0.0
                availData.forEach(site => {
                    newCapacity += siteIdToCapacity[site[0].siteID] || 0.0
                })
                setCapacity(newCapacity)

                // Getting asset based availability
                const summarisedAvailabilityDictionary: Dictionary<Dictionary<IAssetAvailability>> = {}
                const newAvailabilityDictionary: Dictionary<Array<IAssetAvailability>> = {}
                availData.forEach(site => {
                    const siteAvailability = new Array<IAssetAvailability>()
                    site.forEach(result => {
                        result.export = parseFloat(result.export.toFixed(2))
                        result.import = parseFloat(result.import.toFixed(2))
                        siteAvailability.push(result)
                    })
                    newAvailabilityDictionary[site[0].siteID] = siteAvailability

                })

                // Getting summarised availability
                const dataCopy: IAssetAvailability[][] = structuredClone(availData)
                dataCopy.forEach(site => {
                    site.forEach(result => {
                        const availDate = result.settlementDate
                        const availSP = result.settlementPeriod.toString()
                        if (summarisedAvailabilityDictionary[availDate] === undefined) {
                            const initialDictionary: Dictionary<IAssetAvailability> = {}
                            initialDictionary[availSP] = result
                            summarisedAvailabilityDictionary[availDate] = initialDictionary
                        }
                        else {
                            const dateDictionary = summarisedAvailabilityDictionary[availDate] || {}
                            let oldValue = dateDictionary[availSP]
                            if (oldValue === undefined) {
                                dateDictionary[availSP] = result
                            }
                            else {
                                oldValue.export += result.export
                                oldValue.import += result.import
                                dateDictionary[availSP] = oldValue
                            }
                            summarisedAvailabilityDictionary[availDate] = dateDictionary
                        }
                    })
                })

                // Converting summarised availability into array
                const newSummarisedAvailability = new Array<IAssetAvailability>()
                for (let date in summarisedAvailabilityDictionary) {
                    const dateDictionary = summarisedAvailabilityDictionary[date]
                    for (let SP in dateDictionary) {
                        const availabilityItem = dateDictionary[SP]
                        if (availabilityItem !== undefined) {
                            availabilityItem.id = "summarised-" + date + "-SP" + SP.toString()
                            availabilityItem.siteID = "Multiple"
                            availabilityItem.export = parseFloat(availabilityItem.export.toFixed(2))
                            availabilityItem.import = parseFloat(availabilityItem.import.toFixed(2))
                            newSummarisedAvailability.push(availabilityItem)
                        }
                    }
                }

                // Getting area summarised availability
                const dictionaryCopy: Dictionary<Array<IAssetAvailability>> = structuredClone(newAvailabilityDictionary)
                const newAreaToAvailability: Dictionary<Array<IAssetAvailability>> = {}
                for (let siteID in dictionaryCopy) {
                    const siteAvailability = dictionaryCopy[siteID]
                    const area = siteIdToArea[siteID]
                    if (area !== undefined && areas.indexOf(area) > -1 && siteAvailability !== undefined) {
                        const existingAvailabilities = newAreaToAvailability[area]
                        if (existingAvailabilities === undefined) {
                            newAreaToAvailability[area] = siteAvailability
                        }
                        else {
                            const newAreaAvailability = new Array<IAssetAvailability>()
                            for (let i = 0; i < siteAvailability.length; i++) {
                                const incomingAvailability = siteAvailability[i]
                                const existingAvailability = existingAvailabilities[i]
                                existingAvailability.export += incomingAvailability.export
                                existingAvailability.import += incomingAvailability.import
                                newAreaAvailability.push(existingAvailability)
                            }
                            newAreaToAvailability[area] = newAreaAvailability
                        }
                    }
                }
                for (let area in newAreaToAvailability) {
                    newAreaToAvailability[area]?.forEach((settlementPeriod) => {
                        settlementPeriod.siteID = area
                        settlementPeriod.id = area + "-" + settlementPeriod.settlementDate + "-SP" + settlementPeriod.settlementPeriod
                        settlementPeriod.export = parseFloat(settlementPeriod.export.toFixed(2))
                        settlementPeriod.import = parseFloat(settlementPeriod.import.toFixed(2))
                    })
                }
                setAreaToAvailability(newAreaToAvailability)
                setSummarisedAvailability(newSummarisedAvailability)
                setSiteToAvailability(newAvailabilityDictionary)
                setDisplayType(assetSelectionType)
            })
            .catch((error: Error) => {
                console.error(error.message)
                setSummarisedAvailability([])
                setSiteToAvailability({})
            })
            .finally(() => {
                setLoadedAvailability(true)
            })
        availabilityApi.getOutageByRequest(moment(startDate).format(), moment(endDate).format(), selectedAssets, "0")
            .then((outageData) => {
                setAssetOutages(outageData)
                const newSiteToOutages: Dictionary<Array<IEngineOutage>> = {}
                const newAreaToOutages: Dictionary<Array<IEngineOutage>> = {}
                outageData.forEach((outage) => {
                    const siteOutages = newSiteToOutages[outage.siteID]
                    const area = siteIdToArea[outage.siteID] || "Area 1"
                    const areaOutages = newAreaToOutages[area]
                    if (siteOutages === undefined) {
                        newSiteToOutages[outage.siteID] = new Array<IEngineOutage>(outage)
                    }
                    else {
                        siteOutages.push(outage)
                        newSiteToOutages[outage.siteID] = siteOutages
                    }
                    if (areaOutages === undefined) {
                        newAreaToOutages[area] = new Array<IEngineOutage>(outage)
                    }
                    else {
                        areaOutages.push(outage)
                        newAreaToOutages[area] = areaOutages
                    }
                })
                setSiteToOutages(newSiteToOutages)
                setAreaToOutages(newAreaToOutages)
            })
            .catch((error: Error) => {
                console.error(error.message)
                setAssetOutages([])
            })
            .finally(() => {
                setLoadedOutages(true)
            })
    }

    const handleAssetChange = (event: SelectChangeEvent<typeof selectedAssets>) => {
        const {
            target: { value },
        } = event;
        const newAssets = typeof value === 'string' ? value.split(',') : value
        setSelectedAssets(newAssets)
    };
    const handleSelectionTypeChange = (event: SelectChangeEvent) => {
        setAssetSelectionType(event.target.value as string)
    }
    const handleAreaChange = (event: SelectChangeEvent) => {
        setSelectedArea(event.target.value as string)
    }
    const handleRegionChange = (event: SelectChangeEvent) => {
        setSelectedRegion(event.target.value as string)
    }

    const handleStartChange = (newValue: Moment | null) => {
        if (!newValue) { return; }
        setStartDate(newValue)
    }
    const handleEndChange = (newValue: Moment | null) => {
        if (!newValue) { return; }
        setEndDate(newValue)
    }


    useEffect(() => {
        fetchPageData()
    }, [assets])

    const tableColumns = [
        { field: "settlementDate", headerName: "Date", width: 100 },
        { field: "settlementPeriod", headerName: "SP", width: 100 },
        { field: "startGMT", headerName: "Start (Local Time)", width: 170 },
        { field: "endGMT", headerName: "End (Local Time)", width: 170 },
        { field: "import", headerName: "Import MW", width: 140 },
        { field: "export", headerName: "Export MW", width: 140 },
    ]

    const assetSelectionTypes = [
        "Asset",
        "Area",
        "Region",
        "All"
    ]

    const areas = [
        "Area 1",
        "Area 2",
        "Area 3",
        "Area 4",
        "Area 5",
        "Area 6",
    ]

    const regions = [
        "North",
        "South"
    ]

    const handleAvailabilityPanelChange = (event: any, newValue: string) => {
        setAvailabilityPanel(newValue);
    };

  return (
      <PageWrapper title="View Site Availability" loaded={loaded}>
          <Paper sx={{ p: 2 }}>
              <Grid container spacing={2}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                      <Grid xs={isMobile ? 6 : 2}>
                          <Box>
                              <DateTimePicker
                                  label="Start Date (UTC)"
                                  inputFormat="DD/MM/YYYY HH:mm"
                                  value={startDate}
                                  onChange={handleStartChange}
                                  renderInput={(params: any) => <TextField {...params} fullWidth />}
                              />
                        </Box>
                      </Grid>
                      <Grid xs={isMobile ? 6 : 2}>
                          <Box>
                              <DateTimePicker
                                  label="End Date (UTC)"
                                  inputFormat="DD/MM/YYYY HH:mm"
                                  value={endDate}
                                  onChange={handleEndChange}
                                  renderInput={(params: any) => <TextField {...params} fullWidth />}
                              />
                          </Box>
                      </Grid>
                      <Grid xs={isMobile ? 6 : 2}>
                          <Box >
                              <FormControl fullWidth>
                                  <InputLabel id="selection-type-select">Get Availability By</InputLabel>
                                  <Select
                                      labelId="selection-type-select"
                                      id="selection-type-select"
                                      label="Get Availability By"
                                      value={assetSelectionType}
                                      onChange={handleSelectionTypeChange}
                                  >
                                      {
                                          assetSelectionTypes.map((selectionType) =>
                                              <MenuItem key={selectionType} value={selectionType}>{selectionType}</MenuItem>
                                          )
                                      }
                                  </Select>
                              </FormControl>
                          </Box>
                      </Grid>
                      <Grid xs={isMobile ? 6 : 3} hidden={assetSelectionType !== "Asset" && assetSelectionType !== "All"}>
                          <Box >
                              <FormControl fullWidth>
                                  <InputLabel id="asset-label">Asset</InputLabel>
                                  <Select
                                      labelId="asset-label"
                                      id="multiple-asset"
                                      multiple
                                      label="Assets"
                                      value={selectedAssets}
                                      onChange={handleAssetChange}
                                      renderValue={(selected) => {
                                          const maxNumberOfAssets = 5
                                          const renderedAssets: string[] = []
                                          selected.sort().slice(0, maxNumberOfAssets).forEach((asset) => {
                                              renderedAssets.push(siteIdToName[asset] || asset)
                                          })
                                          if (selected.length > maxNumberOfAssets) {
                                              renderedAssets.push("...")
                                          }
                                          return renderedAssets.join(', ')
                                      }
                                      }
                                  >
                                      {
                                          Object.keys(siteIdToName).sort().map((item, index) =>
                                              <MenuItem key={item} value={item}>{siteIdToName[item]}</MenuItem>
                                          )
                                      }
                                  </Select>
                              </FormControl>
                          </Box>
                      </Grid>
                      <Grid xs={isMobile ? 6 : 3} hidden={assetSelectionType !== "Area"}>
                          <Box >
                              <FormControl fullWidth>
                                  <InputLabel id="area-label">Area</InputLabel>
                                  <Select
                                      labelId="area-label"
                                      id="select-area"
                                      label="Area"
                                      value={selectedArea}
                                      onChange={handleAreaChange}
                                  >
                                      {
                                          areas.map((area) =>
                                              <MenuItem key={area} value={area}>{area}</MenuItem>
                                          )
                                      }
                                  </Select>
                              </FormControl>
                          </Box>
                      </Grid>
                      <Grid xs={isMobile ? 6 : 3} hidden={assetSelectionType !== "Region"}>
                          <Box >
                              <FormControl fullWidth>
                                  <InputLabel id="region-label">Region</InputLabel>
                                  <Select
                                      labelId="region-label"
                                      id="select-region"
                                      label="Region"
                                      value={selectedRegion}
                                      onChange={handleRegionChange}
                                  >
                                      {
                                          regions.map((region) =>
                                              <MenuItem key={region} value={region}>{region}</MenuItem>
                                          )
                                      }
                                  </Select>
                              </FormControl>
                          </Box>
                      </Grid>
                      <Grid xs={isMobile ? 12 : 3}>
                          <Box
                              sx={{ height: "100%" }}>
                              <Button
                                  variant="contained"
                                  startIcon={<Download />}
                                  onClick={handleGatherDataClick}
                                  fullWidth
                                  sx={{ height: "100%" }}
                                  disabled={selectedAssets.length === 0 || !loadedAvailability || !loadedOutages}
                              >
                                  Get Availability
                              </Button>
                          </Box>
                      </Grid>
                  </LocalizationProvider>
                  <Grid xs={12}>
                      <Card sx={{ width: "100%", height: "fit-content", display: "inline-block" }}>
                              {loadedAvailability && loadedOutages ? (
                                  <TabContext value={availabilityPanel}>
                                      <TabList onChange={handleAvailabilityPanelChange} aria-label="lab API tabs example" variant="fullWidth">
                                          <Tab label="Graph - Summary" value="GraphSummary" />
                                          <Tab label="Graph - Breakdown" value="GraphBreakdown" />
                                          <Tab label="Table - Summary" value="TableSummary" />
                                          <Tab label="Table - Breakdown" value="TableBreakdown" />
                                      </TabList>
                                      <TabPanel value="GraphSummary" sx={{ pt: 2 }}>
                                          <Box style={{ height: "50vh" }}>
                                              <AssetAvailabilityChart items={summarisedAvailability} capacity={capacity} outages={assetOutages}></AssetAvailabilityChart>
                                          </Box>
                                      </TabPanel>
                                      <TabPanel value="GraphBreakdown" sx={{ pt: 2 }}>
                                          {
                                              (displayType === "Area" || displayType === "Asset" ? 
                                                  Object.keys(siteToAvailability).sort().map((siteID) =>
                                                      <Box key={siteID}>
                                                          <Typography variant='h5' color="primary">{siteIdToName[siteID]}</Typography>
                                                          <Box sx={{ height: "50vh" }}>
                                                              <AssetAvailabilityChart items={siteToAvailability[siteID] || []} capacity={siteIdToCapacity[siteID] || 0.0} outages={siteToOutages[siteID] || []}></AssetAvailabilityChart>
                                                          </Box>
                                                      </Box>
                                                  ) :
                                                  Object.keys(areaToAvailability).sort().map((area) =>
                                                      <Box key={area}>
                                                          <Typography variant='h5' color="primary">{area}</Typography>
                                                          <Box sx={{ height: "50vh" }}>
                                                              <AssetAvailabilityChart items={areaToAvailability[area] || []} capacity={areaToCapacity[area] || 0.0} outages={areaToOutages[area] || []}></AssetAvailabilityChart>
                                                          </Box>
                                                      </Box>
                                                  )
                                              )
                                          }
                                      </TabPanel>
                                      <TabPanel value="TableSummary" sx={{ p: 1 }}>
                                          <Box style={{ height: "50vh", backgroundColor: "yellow" }}>
                                              <ResultsTable data={formattedSummarisedAvailability} columns={tableColumns} sortBy="startGMT" hideBottomRow={true} />
                                          </Box>
                                      </TabPanel>
                                      <TabPanel value="TableBreakdown" sx={{ p: 1 }} >
                                          {
                                              (displayType === "Area" || displayType === "Asset" ?
                                                  Object.keys(formattedSiteToAvailability).sort().map((siteID) =>
                                                      <Box key={siteID}>
                                                          <Typography variant='h5' color="primary">{siteIdToName[siteID]}</Typography>
                                                          <Box sx={{ height: "50vh" }}>
                                                              <ResultsTable data={formattedSiteToAvailability[siteID] || []} columns={tableColumns} sortBy="startGMT" hideBottomRow={true}></ResultsTable>
                                                          </Box>
                                                      </Box>
                                                  ) :
                                                  Object.keys(formattedAreaToAvailability).sort().map((area) =>
                                                      <Box key={area}>
                                                          <Typography variant='h5' color="primary">{area}</Typography>
                                                          <Box sx={{ height: "50vh" }}>
                                                              <ResultsTable data={formattedAreaToAvailability[area] || []} columns={tableColumns} sortBy="startGMT" hideBottomRow={true}></ResultsTable>
                                                          </Box>
                                                      </Box>
                                                  )
                                              )
                                          }
                                      </TabPanel>
                                  </TabContext>
                              ) : <LoadingSymbol />}
                      </Card>
                  </Grid>
              </Grid>
          </Paper>
      </PageWrapper>
  );
}

export interface ISiteAvailabilityPage {

}

const SiteAvailabilityPageConfig: IPortalRouteOptions = {
    relativeUrl: "site-availability",
    page: <SiteAvailabilityPage />,
    navDisplay: {
        title: "View Site Availability",
        icon: <EventAvailable />
    }
}

export default SiteAvailabilityPageConfig;