import { IHistoricalData, IHistoricalDataValue } from "../types/IHistoricalValues"
import ApiRoot from "./ApiRoot"

const apiKey = process.env.REACT_APP_API_GATEWAY_KEY
const rootUrl = process.env.REACT_APP_API_GATEWAY_URL + "pi-data"

const getHistoricalValues = async (startDate: string, endDate: string, interval: number, siteList: string[], attributeList: string[], calculation: string[]) => {

    const requestBody = {
        "StartDate": startDate,
        "EndDate": endDate,
        "interval": interval,
        "SiteList": siteList,
        "AttributeList": attributeList,
        "Calculation": calculation
    }

    let data = await ApiRoot.post<IHistoricalData[]>(`${rootUrl}/GetHistoricalValues`, requestBody, { apiKey });

    data.sort(compareHistoricalData);

    for (let i = 0; i < data.length; i++) {
        data[i].Values.sort(compareHistoricalDataValuesByDate);
    }

    return data
}

function compareHistoricalData(a: IHistoricalData, b: IHistoricalData) {
    if (a.SiteID < b.SiteID) {
        return -1;
    }
    else if (a.SiteID > b.SiteID) {
        return 1;
    }
    else {
        if (a.Attribute < b.Attribute) {
            return -1;
        }
        else if (a.Attribute > b.Attribute) {
            return 1;
        }
        else {
            return 0;
        }
    }
}

function compareHistoricalDataValuesByDate(a: IHistoricalDataValue, b: IHistoricalDataValue) {
    if (a.StartTime < b.StartTime) {
        return -1;
    }
    else if (a.StartTime > b.StartTime) {
        return 1;
    }
    else {
        return 0;
    }
}

const piDataApi = {
    getHistoricalValues
}

export default piDataApi;