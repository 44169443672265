import * as React from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { IAllContracts, IContractInformation, ServiceType } from '../../shared/types/dynamic-contracts/IDynamicContract'
import moment from 'moment';
import { FileStatus } from '../../shared/types/dynamic-contracts/IPerfmonFile';
import ErrorIcon from '@mui/icons-material/Cancel';
import GoodIcon from '@mui/icons-material/CheckCircle';
import UncertainIcon from '@mui/icons-material/RemoveCircle';
import TablePagination from '@mui/material/TablePagination';
import { useTheme } from '@mui/material';

export interface IContractRowProps {
    details: IContractInformation
}

const ContractRow = (props: IContractRowProps) => {
    const contract = props.details;
    const [open, setOpen] = React.useState(false);

    return (
        <>
            <TableRow sx={{ '& > td': { borderBottom: 'unset', } }}>
                <TableCell align="center">
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell align="left">{contract.siteName}</TableCell>
                <TableCell align="center">{contract.bmuid}</TableCell>
                <TableCell align="center">{ServiceType[contract.service]}</TableCell>
                <TableCell align="center">{moment(contract.deliveryDate).format("ddd DD MMM YYYY")}</TableCell>
                <TableCell align="center">{contract.efaBlock}</TableCell>
                <TableCell align="center">{String.fromCharCode(163) + contract.value.toFixed(2)}</TableCell>
                <TableCell align="center">{(contract.availability * 100).toFixed(3)}%</TableCell>
                <TableCell align="center" style={{ color: contract.performance === 0 && moment(contract.deliveryEnd).isBefore(moment()) ? "Red" : "" }} >{(contract.performance * 100).toFixed(2)}%</TableCell>
                <TableCell align="center">{String.fromCharCode(163) + (contract.value * contract.performance).toFixed(2)}</TableCell>
                <TableCell align="center">{contract.files.filter(x => x.fileProcessed === true).length}/4</TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography variant="h6" gutterBottom component="div">
                                Perfmon Files
                            </Typography>
                            <Table size="small" width="100%">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left">Filename</TableCell>
                                        <TableCell align="center" width="200px">Processed Time</TableCell>
                                        <TableCell align="center" width="80px">File OK</TableCell>
                                        <TableCell align="center" width="95px">Max Error</TableCell>
                                        <TableCell align="center" width="95px">K Factor</TableCell>
                                        <TableCell align="center" width="95px">Low Availability</TableCell>
                                        <TableCell align="center" width="95px">High Availability</TableCell>
                                        <TableCell align="center" width="130px">Missing Values</TableCell>
                                        <TableCell align="center" width="100px">Status</TableCell>
                                        <TableCell align="center" width="100px">Late</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {contract.files.map((file) => (
                                        <TableRow key={file.expectedFileName}>
                                            <TableCell align="left">{file.expectedFileName}</TableCell>
                                            <TableCell align="center">{!file.processedTime ? moment(file.processedTime).format("DD-MM-YYYY HH:mm:ss") : ""}</TableCell>
                                            <TableCell align="center">{!file.fileProcessed ? <UncertainIcon /> : file.fileOK ? <GoodIcon /> : <ErrorIcon />}</TableCell>
                                            <TableCell align="center">{(file.maxError * 100).toFixed(2)}%</TableCell>
                                            <TableCell align="center">{(file.kfactor * 100).toFixed(2)}%</TableCell>
                                            <TableCell align="center">{(file.lowAvailability * 100).toFixed(3)}%</TableCell>
                                            <TableCell align="center">{(file.highAvailability * 100).toFixed(3)}%</TableCell>
                                            <TableCell align="center" style={{ color: file.missingValues !== 0 ? "orangered" : "" }} >{file.missingValues}</TableCell>
                                            <TableCell align="center">{FileStatus[file.status]}</TableCell>
                                            <TableCell align="center">{file.late ? "Late" : "On Time"}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}

const DynamicContractTable: React.FC<IDynamicContractTableProps> = ({ contracts }) => {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const sortContracts = (a: IContractInformation, b: IContractInformation): number => {
        if (a.deliveryStart > b.deliveryStart) { return 1; }
        if (a.deliveryStart < b.deliveryStart) { return -1; }
        if (a.siteName > b.siteName) { return 1; }
        if (a.siteName < b.siteName) { return -1; }
        return a.service < b.service ? 1 : -1;
    }

    const getContractRow = (contract: IContractInformation): JSX.Element => {
        const key = `${contract.bmuid}-${contract.service}-${moment(contract.deliveryDate).toString()}-${contract.efaBlock}`;

        return (<ContractRow details={contract} key={key} />)
    }

    const sortedContracts = contracts?.allContracts?.sort(sortContracts) || [];
    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;

    return (
        <>
            <TableContainer component={Box}>
                <Table aria-label="collapsible table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" width="66px"></TableCell>
                            <TableCell align="left">Site Name</TableCell>
                            <TableCell align="center">BMU ID</TableCell>
                            <TableCell align="center" width="80px">Service</TableCell>
                            <TableCell align="center" width="160px">Delivery Date</TableCell>
                            <TableCell align="center" width="65px">EFA</TableCell>
                            <TableCell align="center">Value</TableCell>
                            <TableCell align="center">Asset Availability</TableCell>
                            <TableCell align="center" width="125px">Performance</TableCell>
                            <TableCell align="center" width="180px">Performance Value</TableCell>
                            <TableCell align="center" width="145px">Files Processed</TableCell >
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedContracts.slice(startIndex, endIndex).map(getContractRow)}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={sortedContracts.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    );
}

export interface IDynamicContractTableProps {
    contracts?: IAllContracts
}

export default DynamicContractTable;
