import { PortalArea } from "../types/shared/routes/routeHelperClasses";
import ConradDataLogo from '../../images/Conrad_Data_Icon.png';
import { IPortalAreaOptions } from "../types/shared/routes/routeTypes";
import { DataPermissions } from "../types/shared/auth/permissionsTypes";
import DataMeterValuesPageConfig from "../../pages/data/DataMeterValuesPage";
import DataPriceValuesPageConfig from "../../pages/data/DataPriceValuesPage";
import DataEngineOutagesPageConfig from "../../pages/data/DataEngineOutagesPage";
import DataHomePageConfig from "../../pages/data/DataHomePage";
import BOADataPageConfig from "../../pages/data/BOADataPage";

const areaOptions: IPortalAreaOptions = {
    title: "Data",
    description: "Access and download data from iON",
    iconImage: ConradDataLogo,
    allValidPermissions: { Data: DataPermissions.AllPermissions }
}

const DataPortalArea = new PortalArea(areaOptions, DataHomePageConfig);
DataPortalArea.addChildRoute(DataMeterValuesPageConfig);
DataPortalArea.addChildRoute(DataPriceValuesPageConfig);
DataPortalArea.addChildRoute(DataEngineOutagesPageConfig);
DataPortalArea.addChildRoute(BOADataPageConfig);

export default DataPortalArea;
