import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { graphConfig } from "../auth/config";
import { getLoginRequestToken } from "../auth/helpers";
import { IUserData } from "../types/shared/auth/userDataTypes";

export const userApi = createApi({
    reducerPath: 'userApi',
    baseQuery: fetchBaseQuery({   
        baseUrl: `${process.env.REACT_APP_PORTAL_API_URL}`,
        prepareHeaders: async (headers: Headers) => {
            const accessToken = await getLoginRequestToken();
            if (accessToken) {
                headers.set("Authorization", `Bearer ${accessToken}`);
                //headers.set("Api-Key", `${process.env.REACT_APP_BASE_API_KEY}`);
            }
        }        
    }),
    //tagTypes: ['UserSettings'],
    endpoints: (builder) => ({
        getUserData: builder.query<IUserData, void>({            
            query: () => graphConfig.graphMeEndpoint,       
            //providesTags: ["UserSettings"],
            onQueryStarted: (data) => {
                console.log("GET USER DATA");
            }
        }),
        //updateSettings: builder.mutation<string, { username: string, data: IUserSettings }>({
        //    query: (payload) => ({
        //        url: `/user/settings/${payload.username}`,
        //        method: 'POST',
        //        body: payload.data             
        //    }),
        //    invalidatesTags: ["UserSettings"],            
        //    onQueryStarted: (data) => {
                
        //    }
        //}),
    }),
})
export const { useGetUserDataQuery } = userApi;

