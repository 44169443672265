import React from "react";
import { FormControl, FormControlLabel, Paper, Radio, RadioGroup } from "@mui/material";

const UpdateOutageOption: React.FC<IUpdateOutageOption> = ({ modifyOrCancel, setModifyOrCancel }) => {

    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setModifyOrCancel((event.target as HTMLInputElement).value);
    };
    return (
        <Paper sx={{ px: 2, py: 1 }}>
            <FormControl>
                <RadioGroup
                    aria-labelledby="modify-cancel-radio-button"
                    name="modify-cancel-radio-button"
                    value={modifyOrCancel}
                    onChange={handleRadioChange}
                >
                    <FormControlLabel value="Modify" control={<Radio />} label="Modify this outage" />
                    <FormControlLabel value="Cancel" control={<Radio />} label="Cancel this outage" />
                </RadioGroup>
            </FormControl>
        </Paper>
    );
}
export interface IUpdateOutageOption {
    modifyOrCancel: string
    setModifyOrCancel: (modifyOrCancel: string) => void
}

export default UpdateOutageOption;