import React from "react";
import moment from "moment"
import { Box, Card, Divider, Grid, IconButton, Link, Stack, Typography, useTheme } from "@mui/material"
import { ArrowForwardIos, Clear, ExpandLess, ExpandMore } from "@mui/icons-material";
import { IPushNotification } from "../../shared/types/notification/IPushNotification";
import { useColourModeContext } from "../ColourMode";
import useNotificationMenu from "../../shared/hooks/NotificationMenuHook";
export interface ICustomNotificationBody {
    notification: IPushNotification,
    handleDismiss?: () => void,
}

const CustomNotificationBody: React.FC<ICustomNotificationBody> = ({ notification, handleDismiss }) => {
    const { markNotificationAsRead, deleteNotification } = useNotificationMenu()
    const [expanded, setExpanded] = React.useState<boolean>(false);
    const { isDarkMode } = useColourModeContext();
    const theme = useTheme();

    const flipExpanded = () => {
        setExpanded(!expanded)
    }
    let backgroundColor;
    let textColor;
    const borderColor = isDarkMode ? "white" : "black";

    if (notification.priority === "High") {
        backgroundColor = theme.palette.error.main;
        textColor = theme.palette.error.contrastText;
    }
    else if (notification.priority === "Warn") {
        backgroundColor = theme.palette.conradEnergyYellow.main;
        textColor = theme.palette.conradEnergyYellow.contrastText;
    }
    else if (notification.priority === "Low") {
        backgroundColor = theme.palette.info.main;
        textColor = theme.palette.info.contrastText;
    }
    else {
        backgroundColor = theme.palette.common.white;
        textColor = theme.palette.common.black;
    }
    const cardSx = {
        "cursor": "pointer",
        "width": "100%",
        "borderRadius": 2,
        "backgroundColor": backgroundColor,
        "color": textColor,
        padding: 2,
        border: (notification.seen ? "" : "3px solid " + borderColor),
        "&:hover": {
            backgroundColor: theme.palette.conradEnergyFreshGreen.dark
        }
    }

    return (
        <Card sx={cardSx} onClick={() => markNotificationAsRead(notification.notificationID)}>
            <Box>
                <Grid container>
                    <Grid item xs={10.5}>
                        <Stack>
                            <Typography color={textColor} variant="subtitle2">
                                {moment(notification.submissionTime).format("DD/MM/YY HH:mm")}
                            </Typography>
                            <Typography color={textColor} variant="subtitle1">
                                {notification.type + " - " + notification.asset}
                            </Typography>
                            <Typography color={textColor} variant="h6">
                                {notification.subject}
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={1.5}>
                        <div style={{ "alignContent": "center" }}>
                            {(handleDismiss === undefined ?
                                <IconButton
                                    size="small"
                                    sx={{ "transition": "all .2s", "color": textColor, "&:hover": { "backgroundColor": "rgba(160, 160, 160, 0.5)" } }}
                                    onClick={() => deleteNotification(notification.notificationID)}
                                >
                                    <Clear fontSize="medium" />
                                </IconButton>
                                :
                                <IconButton
                                    size="small"
                                    sx={{ "transition": "all .2s", "color": textColor, "&:hover": { "backgroundColor": "rgba(160, 160, 160, 0.5)" } }}
                                    onClick={handleDismiss}
                                >
                                    <ArrowForwardIos fontSize="medium" />
                                </IconButton>
                            )}
                            {(notification.details || notification.iONLink || notification.trackLink) ?
                                <IconButton
                                    size="small"
                                    sx={{ "transition": "all .2s", "color": textColor, "&:hover": { "backgroundColor": "rgba(160, 160, 160, 0.5)" } }}
                                    onClick={flipExpanded}
                                >
                                    {(expanded ? <ExpandLess fontSize="medium" /> : <ExpandMore fontSize="medium" />)}
                                </IconButton>
                                : <></>
                            }
                        </div>
                    </Grid>
                </Grid>
            </Box>
            {(expanded && (notification.details || notification.iONLink || notification.trackLink) ?
                <>
                    <Divider variant="middle" sx={{ "borderColor": textColor }} />
                    <Box sx={{
                        display: "flex",
                        justifyContent: "center",
                        "textAlign": "center"
                    }}>
                        <Box>
                            <Typography color={textColor} variant="body1" sx={{
                            }}>
                                {notification.details}
                            </Typography>
                            {(notification.iONLink ? <Typography color={textColor} variant="body1" sx={{ "fontWeight": "bold" }}><Link href={notification.iONLink} color={"inherit"}>iON Link</Link></Typography> : <></>)}
                            {(notification.trackLink ? <Typography color={textColor} variant="body1" sx={{ "fontWeight": "bold" }}><Link href={notification.trackLink} color={"inherit"}>Track Link</Link></Typography> : <></>)}
                        </Box>
                    </Box>
                </>
                : <></>
            )}
        </Card>
    )
}
export default CustomNotificationBody