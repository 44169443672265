import { ArrowBack, Download } from "@mui/icons-material";
import { Box, Button, Card, CardActions, CardContent, Grid, Stack, TextField, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useGetOpportunitySubmissionQuery } from "../../shared/api/CommercialOpportunityApi";
import { IOpportunity } from "../../shared/types/commercial/opportunity/IOpportunity";
import { IOpportunitySubmission } from "../../shared/types/commercial/opportunity/IOpportunitySubmission";

const OpportunityDetails: React.FC<IOpportunityDetailsProps> = ({ opportunity, onBack }) => {
    const [opportunityId, setOpportunityId] = useState<string>("");
    const { data, isFetching } = useGetOpportunitySubmissionQuery(opportunityId, { skip: opportunityId === "" ? true : false });
    const theme = useTheme();

    interface Props {
        title: string;
        value: any;
    }

    useEffect(() => {
        setOpportunityId(opportunity !== null ? opportunity.Id : "");
    }, [opportunity]);

    const OpportunityItem = ({ title, value }: Props): JSX.Element => {
        const result = (<Box>
            <TextField
                label={title}
                variant="outlined"
                value={value ?? ""}
                margin="dense"
                size="small"
                InputProps={{
                    readOnly: true,
                }}
                focused
                fullWidth

            />
        </Box>);

        return result;
    }

    const handleDownloadFile = (opportunitySubmission: IOpportunitySubmission) => {
        const filename = `TenderId-${opportunitySubmission.OpportunityId}-${opportunitySubmission.Type}.json`;
        const blob = new Blob([opportunitySubmission.Json], { type: "application/json" });
        const href = URL.createObjectURL(blob);

        // create "a" HTLM element with href to file
        const link = document.createElement("a");
        link.href = href;
        link.download = filename;
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    }

    const DownloadJsonButtons = (): JSX.Element => {
        if (data && !isFetching) {
            const buttons = data.map(d => {
                if (d.Type.startsWith("Existing Business")) {
                    return <Button key={d.Type} startIcon={<Download />} title="Download Renewal json" onClick={() => handleDownloadFile(d)}>Renewal ({d.Type.replace("Existing Business-", "")})</Button>;
                }
                else if (d.Type.startsWith("Site Addition")) {
                    return <Button key={d.Type} startIcon={<Download />} title="Download Site Addition json" onClick={() => handleDownloadFile(d)}>Site Addition ({d.Type.replace("Site Addition-", "")})</Button>;
                }
                else {
                    return <Button key={d.Type} startIcon={<Download />} title="Download New Business json" onClick={() => handleDownloadFile(d)}>New Business ({d.Type.replace("New Business-", "")})</Button>;
                }                
            });              

            if (buttons.length == 0) {
                return <><Typography variant="body1">No json submissions exist</Typography></>;
            }

            return <>{buttons}</>
        }
        return <></>;
    }

    return (
        <Box paddingTop={2}>
            {opportunity &&
                <Card sx={{ minWidth: 300, backgroundColor: theme.palette.background.default }}>
                    <CardContent>
                        <Grid container columnSpacing={2}>
                            <Grid item xs={8}>
                                <Typography variant="subtitle1" paddingBottom={2}>Opportunity Details</Typography>
                                <Stack>
                                    <OpportunityItem title="Tender Id" value={opportunity.Id} />

                                    <OpportunityItem title="Created Date" value={opportunity.CreatedDate} />

                                    <OpportunityItem title="Name" value={opportunity.Name} />

                                    <OpportunityItem title="Account Name" value={opportunity.Account.Name} />

                                    <OpportunityItem title="Type" value={opportunity.Type} />

                                    <OpportunityItem title="Number of Sites" value={opportunity.Number_of_Sites__c} />

                                    <OpportunityItem title="Total MW" value={opportunity.Total_EAC__c} />

                                    <OpportunityItem title="RTM" value={opportunity.RTM__c} />

                                    <OpportunityItem title="Deal Structure" value={opportunity.Deal_Structure__c} />

                                    <OpportunityItem title="Shape Type" value={opportunity.Shape_Type__c} />

                                    <OpportunityItem title="Revenue" value={opportunity.Revenue__c} />

                                    <OpportunityItem title="Margin" value={opportunity.Margin_Quotes__c} />

                                    <OpportunityItem title="Gross Margin" value={opportunity.Gross_Margin__c} />

                                    <OpportunityItem title="Date Priced" value={opportunity.Date_Priced__c} />

                                    <OpportunityItem title="Probability" value={opportunity.Probability} />

                                    <OpportunityItem title="Stage" value={opportunity.StageName} />

                                    <OpportunityItem title="Close Date" value={opportunity.CloseDate} />
                                </Stack>
                            </Grid>
                            <Grid item>
                                <Typography variant="subtitle1" paddingBottom={2}>Seaglass Submission Json</Typography>
                                <Stack spacing={2}>
                                    <DownloadJsonButtons />
                                </Stack>
                            </Grid>
                        </Grid>
                    </CardContent>
                    <CardActions>
                        <Button size="small" startIcon={<ArrowBack />} onClick={onBack}>Back</Button>
                    </CardActions>
                </Card>
            }
        </Box>
    );
};

export default OpportunityDetails;

export interface IOpportunityDetailsProps {
    opportunity: IOpportunity | null;
    onBack: () => void;
}
