import { Box, TextField } from "@mui/material";
import { useEffect, useState } from "react";

const PermissionsNewUserForm: React.FC<PermissionsNewUserFormProps> = ({ existingUsers, formUpdated, validityChanged }) => {
    const [username, setUsername] = useState<string>("");
    const [error, setError] = useState<string>("");
    const [dirty, setDirty] = useState(false);

    useEffect(() => {
        validityChanged(!error && dirty);
    }, [error, dirty, validityChanged]);

    const updateUserName = (value: string) => {
        setDirty(true);
        setUsername(value);
        formUpdated(value);

        if (!value) {
            setError("You must enter a user ID");
            return;
        }

        if (existingUsers.includes(value)) {
            setError("User has already been added");
            return;
        }

        if (!value.endsWith("@conradcloud.net")) {
            setError("Invalid user ID");
            return;
        }

        setError("");
    }

    return (
        <Box paddingTop={1}>
            <TextField
                label="User ID (user@conradcloud.net)"
                value={username}
                onChange={(e) => updateUserName(e.target.value)}
                error={!!error}
                helperText={error}
                variant="outlined"
                fullWidth
            />
        </ Box>
    );
}

export default PermissionsNewUserForm;

interface PermissionsNewUserFormProps {
    existingUsers: string[];
    formUpdated: (userId: string) => void;
    validityChanged: (valid: boolean) => void;
}
