import { IImage } from "../files";
import { AllPermissions, PermissionsArea, PermissionsType } from "./permissionsTypes";

export interface IUserData {
    businessPhones: string[];
    displayName: string;
    givenName: string;
    id: string;
    mail: string;
    mobilePhone: string;
    jobTitle: string;
    officeLocation: string;
    preferredLanguage: string;
    surname: string;
    userPrincipalName: string;
}

export interface IUserDataWithAvatar extends IUserData {
    avatar: IImage;
}

export interface IUserRole {
    appRoleId: string;
    createdDateTime: string;
    deletedDateTime: string;
    id: string;
    principalDisplayName: string;
    principalId: string;
    principalType: string;
    resourceDisplayName: string;
    resourceId: string;
}

export interface IUserList {
    id: string;
    name: string;
}

export interface IUserGroup {
    id: string;
    description: string;
    displayName: string;
    isAssignableToRole: boolean;
}

export type UserPermissionCollection = Record<PermissionsArea, PermissionsType>;

export const generateDummyPermissions = (id: string) => {
    return Object.keys(AllPermissions).map((key) => {
        return {
            id: id,
            portalArea: key,
            permissionsFlag: 0,
        }
    });
}
