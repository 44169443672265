import React from "react";
import PageWrapper from "../../components/PageWrapper";
import { Grid } from "@mui/material";
import { IPortalRouteOptions } from "../../shared/types/shared/routes/routeTypes";
import { Dashboard } from "@mui/icons-material";

const DispatchHomePage: React.FC<IDispatchHomePage> = () => {
  const [loaded, setLoaded] = React.useState(false);

  const loadComplete = () => {
    setLoaded(true);
  }

  setTimeout(loadComplete, 100);

  return (
    <PageWrapper title="Dashboard" loaded={loaded}>
      <Grid container spacing={2}>

      </Grid>
    </PageWrapper>
  );
}

export interface IDispatchHomePage {

}

const DispatchHomePageConfig: IPortalRouteOptions = {
  relativeUrl: "dispatch",
  page: <DispatchHomePage />,
  navDisplay: {
    title: "Dashboard",
    icon: <Dashboard />
  }
}

export default DispatchHomePageConfig;