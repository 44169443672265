import React, { useEffect } from "react";
import mainImage from "../../../images/iON_Analytics.png";
import PageWrapper from "../../../components/PageWrapper";
import { Analytics } from "@mui/icons-material";
import { IPortalRouteOptions } from "../../../shared/types/shared/routes/routeTypes";

const AnalyticsPowerBiLandingPage: React.FC<IAnalyticsPowerBiLandingPageProps> = () => {
    const [loaded, setLoaded] = React.useState(false);
    const loadComplete = () => {
        setLoaded(true);
    }

    useEffect(() => {
        loadComplete()
    }, [])

    return (
        <PageWrapper title="Power BI Reports" loaded={loaded}>
            <img alt="Analytics" src={mainImage} width="100%" />
        </PageWrapper>
    );
}

export interface IAnalyticsPowerBiLandingPageProps {

}

const AnalyticsPowerBiLandingPageConfig: IPortalRouteOptions = {
    relativeUrl: "power-bi",
    navDisplay: {
        title: "Power BI Reports",
        icon: <Analytics />,
        hideFromNested: true,
    },
    page: <AnalyticsPowerBiLandingPage />,
}

export default AnalyticsPowerBiLandingPageConfig;
