import React, { useEffect, useState } from "react";
import PageWrapper from "../../components/PageWrapper";
import { Box, Paper } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import ResultsTable from "../../components/data/ResultsTable";
import PriceDataRequestBar from "../../components/data/PriceDataRequestBar";
import { CurrencyPoundOutlined } from "@mui/icons-material";
import { IPortalRouteOptions } from "../../shared/types/shared/routes/routeTypes";

const DataPriceValuesPage: React.FC<IDataPriceValuesPageProps> = () => {
    const [loaded, setLoaded] = useState(false);
    const [querying, setQuerying] = useState<boolean>(false);
    const [readings, setReadings] = useState<any[]>([]);
    const [requestType, setRequestType] = useState<string>("Power");

    const loadComplete = () => {
        setLoaded(true);
    }

    useEffect(() => {
        setReadings([])
    }, [requestType])

    const columnsPower = [
        { field: 'dateTimeUTC', headerName: 'Date Time (UTC)', width: 170 },
        { field: 'settlementDate', headerName: 'Settlement Date', width: 170 },
        { field: 'settlementPeriod', headerName: 'SP', width: 30 },
        { field: 'price', headerName: 'Price', width: 70 },
        { field: 'priceUnit', headerName: 'Price Unit', width: 110 },
        { field: 'volume', headerName: 'Volume', width: 110 },
        { field: 'volumeUnit', headerName: 'Volume Unit', width: 110 },
        { field: 'market', headerName: 'Market', width: 170 },
        { field: 'horizon', headerName: 'Horizon', width: 110 },
        { field: 'currency', headerName: 'Currency', width: 110 },
        { field: 'country', headerName: 'Country', width: 110 },
        { field: 'exchange', headerName: 'Exchange', width: 110 },
    ]

    const columnsGas = [
        { field: 'date', headerName: 'Date', width: 120 },
        { field: 'product', headerName: 'Product', width: 200 },
        { field: 'strip', headerName: 'Strip', width: 120 },
        { field: 'unit', headerName: 'Unit', width: 100 },
        { field: 'year', headerName: 'Year', width: 70 },
        { field: 'timing', headerName: 'Timing', width: 70 },
        { field: 'contFwd', headerName: 'Cont Fwd', width: 90 },
        { field: 'fwdPeriod', headerName: 'Fwd Period', width: 100 },
        { field: 'commodity', headerName: 'Commodity', width: 100 },
        { field: 'market', headerName: 'Market', width: 70 },
        { field: 'value', headerName: 'Value', width: 70 },
    ]

    setTimeout(loadComplete, 100);

    return (
        <PageWrapper title="Price Values" loaded={loaded}>
            <Paper sx={{ height: "calc(100% - 1px)" }}>
                <Box sx={{ m: 1, height: "100%" }}>
                    <PriceDataRequestBar readings={readings} setReadings={setReadings} requestType={requestType} setRequestType={setRequestType} querying={querying} setQuerying={setQuerying} />
                    <Box sx={{ mt: 1 }}>
                        <ResultsTable data={readings} columns={(requestType === "Power" ? columnsPower : columnsGas)} sortBy={(requestType === "Power" ? "dateTimeUTC" : "date")} />
                    </Box>
                </Box>
            </Paper>
        </PageWrapper>
    );
}

export interface IDataPriceValuesPageProps {

}

const DataPriceValuesPageConfig: IPortalRouteOptions = {
    relativeUrl: "price-values",
    page: <DataPriceValuesPage />,
    navDisplay: {
        title: "Price Values",
        icon: <CurrencyPoundOutlined />
    },
}

export default DataPriceValuesPageConfig;