import React, { useState, useEffect } from "react";
import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { reasonsDictionary } from "../helpers/ReasonsDictionary";
import { isMobile } from "react-device-detect";

const ReasonSelector: React.FC<IReasonSelector> = ({ reason, setReason, disabled, selectedAssetType }) => {
    const defaultCategory = "General Servicing"
    const [category, setCategory] = useState<string>(defaultCategory);
    const [reasonList, setReasonList] = useState<string[]>(reasonsDictionary[category] || []);
    const [uniqueAssetType, setUniqueAssetType] = useState<boolean>(false)

    const handleCategoryChange = (event: SelectChangeEvent) => {
        setCategory(event.target.value as string)
    }
    const handleReasonChange = (event: SelectChangeEvent) => {
        setReason(event.target.value as string)
    }
    const categories = [
        "Ambient Temperature",
        "Controls and Sensors",
        "Cooling System",
        "Electrical",
        "Fuel Supply",
        "High Voltage",
        "Ignition",
        "Mechanical",
        "Safety Chain",
        "General Servicing",
        "Other Trading Desk"
    ]
    
    useEffect(() => {
        var reasons = reasonsDictionary[category];
        if (reasons !== undefined) {
            if (reasons.indexOf(reason) == -1) { // Reason needs to change
                setReasonList(reasons)
                setReason(reasons[0])
            }
        }
    }, [category])

    useEffect(() => {
        var reasons = reasonsDictionary[category];
        if (reasons !== undefined) {
            if (reasons.indexOf(reason) == -1) { // Category needs to change
                for (let key in reasonsDictionary) {
                    const value = reasonsDictionary[key]
                    if (value.indexOf(reason) > -1) {
                        setCategory(key)
                        setReasonList(value)
                    }
                }
            }
        }
    }, [reason])

    useEffect(() => {
        if (selectedAssetType !== "" && selectedAssetType !== "Gas" && selectedAssetType !== "Diesel") {
            setCategory(selectedAssetType)
            setUniqueAssetType(true)
        }
        else {
            if (categories.indexOf(category) < 0) {
                setCategory(defaultCategory)
            }
            setUniqueAssetType(false)
        }
    }, [selectedAssetType])

    return (
        <>
            <Grid xs={isMobile ? 6 : 4}>
                <Box>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Category</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={category}
                            label="Category"
                            disabled={disabled || uniqueAssetType}
                            onChange={handleCategoryChange}
                        >
                            {
                                uniqueAssetType ?
                                    <MenuItem key={selectedAssetType} value={selectedAssetType}>{selectedAssetType}</MenuItem>
                                    :
                                    categories.map((category) =>
                                        <MenuItem key={category} value={category}>{category}</MenuItem>
                                    )
                            }
                        </Select>
                    </FormControl>
                </Box>
            </Grid>
            <Grid xs={isMobile ? 6 : 4}>
                <Box>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Reason</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={reasonList.indexOf(reason) < 0 ? '' : reason}
                            label="Reason"
                            disabled={disabled}
                            onChange={handleReasonChange}
                        >
                            {
                                reasonList.map((reason) =>
                                    <MenuItem key={reason} value={reason}>{reason}</MenuItem>
                                )
                            }
                        </Select>
                    </FormControl>
                </Box>
            </Grid></>
    );

}

export interface IReasonSelector {
    reason: string;
    setReason: (reason: string) => void;
    disabled: boolean
    selectedAssetType: string
}

export default ReasonSelector;