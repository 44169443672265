import { CurrencyPound, Save } from "@mui/icons-material";
import { Button, Grid, Paper, Stack, TextField, Typography } from "@mui/material";
import { TimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment, { Moment } from "moment";
import React, { useCallback, useEffect, useState } from "react";
import LoadingSpinner from "../../components/LoadingSpinner";
import PageWrapper from "../../components/PageWrapper";
import { useToastAlertContext } from "../../components/ToastAlert";
import { useGetPricingScheduleQuery, useSavePricingScheduleMutation } from "../../shared/api/CommercialOpportunityApi";
import { IPortalRouteOptions } from "../../shared/types/shared/routes/routeTypes";

const CommercialPricingPage: React.FC = () => {
    const [loaded, setLoaded] = useState(false);
    const [nextScheduledRun, setNextScheduledRun] = useState<Moment>(moment());
    const { data, isFetching } = useGetPricingScheduleQuery();
    const [savePricingSchedule] = useSavePricingScheduleMutation();    
    const { popToast } = useToastAlertContext();
    const [isSaving, setIsSaving] = useState<boolean>(false);

    const loadComplete = useCallback(async () => {
        setLoaded(true);
    }, [])

    useEffect(() => {
        loadComplete()
    }, [loadComplete])


    const handleDateChange = (value: Moment | null) => {
        if (value != null) {
            setNextScheduledRun(value);
        }
    };

    const handleSave = async () => {
        setIsSaving(true);
        await savePricingSchedule(nextScheduledRun.toISOString())
            .unwrap()
            .then(() => popToast("Next Schedule Run Saved", "success"))
            .catch((error) => popToast(error.data, "warning"));
        setIsSaving(false);
    }

    useEffect(() => {
        if (!isFetching && data) {               
            const date = moment(data.value, "DD/MM/YYYY HH:mm:ss ZZ");
            setNextScheduledRun(moment(date));        
        }
    }, [data]);

    return (
        <PageWrapper title="Pricing" loaded={loaded}>
            <Paper sx={{ p: 2, width: "100%" }}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item sm={6}>
                            <Typography variant="h6">Pricing Schedule</Typography>  
                            {data &&
                                <>
                                <Stack direction="row" paddingTop={2} spacing={2}>
                                    <TimePicker
                                        label="Next Scheduled Run"
                                        inputFormat="HH:mm"
                                        value={nextScheduledRun}
                                        onChange={handleDateChange}
                                        renderInput={(params) => <TextField {...params} />} />

                                    {isSaving && <LoadingSpinner /> }

                                    {!isSaving &&
                                        <Button
                                            variant="contained"
                                            startIcon={<Save />} 
                                            onClick={handleSave}
                                        >
                                            Save
                                        </Button>
                                    }
                                </Stack>     
                                
                            </>
                            }
                        </Grid>
                    </Grid>
                </LocalizationProvider>
            </Paper>
        </PageWrapper>
    );
}

const CommercialPricingPageConfig: IPortalRouteOptions = {
    relativeUrl: "pricing",
    page: <CommercialPricingPage />,
    navDisplay: {
        title: "Pricing",
        icon: <CurrencyPound />
    }
}

export default CommercialPricingPageConfig;