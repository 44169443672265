import { Hexagon } from 'react-hexgrid';
import { CubicCoords } from '../../../shared/types/shared/hex-grid';
import { HexagonMouseEventHandler } from 'react-hexgrid/lib/Hexagon/Hexagon';
import { EngineHiveStatusData } from '../../../shared/types/operate/engine-hive';
import { Text } from 'react-hexgrid';

export const EngineStatusHiveTile: React.FC<IEngineStatusHiveTileProps> = ({ coords, status, onClick }) => {
    const contentColour = status.colour;

    return (
        <Hexagon q={coords.q} r={coords.r} s={coords.s} fill="hex-background" stroke={contentColour} strokeWidth={"0.01em"} className="data-hex" onClick={onClick}>
            <Text strokeWidth={"0"} fontSize={"0.11em"} fill={contentColour} y="-0.25em" fontWeight="bold">
                {status.siteName}
            </Text>
            <Text strokeWidth={"0"} fontSize={"0.11em"} fill={contentColour} y="1.25em" fontWeight="bold">
                {status.name}
            </Text>
        </Hexagon>
    );
}

export interface IEngineStatusHiveTileProps {
    coords: CubicCoords;
    status: EngineHiveStatusData;
    onClick: HexagonMouseEventHandler<SVGElement>;
}