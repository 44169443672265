import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Paper, Stack, Tab, Typography } from "@mui/material";
import { Dictionary } from "@reduxjs/toolkit";
import React, { useEffect } from "react";
import NotificationTypeTable from "../../components/notifications/tables/NotificationTypeTable";
import SelfServeNotificationTableAsset from "../../components/notifications/tables/SelfServeNotificationTableAsset";
import SelfServeNotificationTableMaster from "../../components/notifications/tables/SelfServeNotificationTableMaster";
import PageWrapper from "../../components/PageWrapper";
import { useGetAssetsQuery } from "../../shared/api/AssetApi";
import { useGetNotificationTypesQuery, useGetSelfServeSubscriptionsQuery } from "../../shared/api/NotificationsApi";
import { SystemAdministrationPermissions } from "../../shared/types/shared/auth/permissionsTypes";
import { IPortalRouteOptions } from "../../shared/types/shared/routes/routeTypes";
import { Notifications } from "@mui/icons-material";
import AdminNotificationSearch from "../../components/notifications/AdminNotificationSearch"

const NotificationAdminPage: React.FC<INotificationAdminPage> = () => {
    const [siteIdToName, setSiteIdToName] = React.useState<Dictionary<string>>({})
    const [adminPanel, setAdminPanel] = React.useState<string>("NotificationTypes");
    const { data: assets } = useGetAssetsQuery();
    const { data: notificationTypes, isFetching: fetchingNotificationTypes } = useGetNotificationTypesQuery();
    const { data: selfServeSubscriptions, isFetching: fetchingSelfServeSubscriptions } = useGetSelfServeSubscriptionsQuery();

    const fetchPageData = () => {
        if (assets !== undefined) {
            var dictName: Dictionary<string> = {}
            assets?.forEach((item) => {
                dictName[item.SiteID] = item.Name
            });
            setSiteIdToName(dictName)
        }
    }

    useEffect(() => {
        fetchPageData()
    }, [assets])

    const handleAdminPanelChange = (event: any, newValue: string) => {
        setAdminPanel(newValue);
    };

    return (
        <PageWrapper title="Notification Admin" loaded={!fetchingNotificationTypes && !fetchingSelfServeSubscriptions} displayBreadcrumbs={false}>
            <Paper sx={{ p: 2 }}>
                <TabContext value={adminPanel}>
                    <TabList onChange={handleAdminPanelChange} variant="fullWidth">
                        <Tab label="Notification Types" value="NotificationTypes" />
                        <Tab label="Self-Serve Notifications" value="NotificationSelfServe" />
                        <Tab label="Sent Notifications" value="Notifications" />
                    </TabList>
                    <TabPanel value="NotificationTypes" sx={{ pl: 0, pr: 0, pt: 2 }}>
                        <Box>
                            {notificationTypes && <NotificationTypeTable items={notificationTypes} searchBar={true} />}
                        </Box>
                    </TabPanel>
                    <TabPanel value="NotificationSelfServe" sx={{ pl: 0, pr: 0, pt: 2 }}>
                        <Stack spacing={2}>
                            <Box>
                                <Typography>Master Subscriptions</Typography>
                                {selfServeSubscriptions && <SelfServeNotificationTableMaster items={selfServeSubscriptions.masterSubscriptions || []} searchBar={true} />}
                            </Box>
                            <Box>
                                <Typography>Asset Subscriptions</Typography>
                                {selfServeSubscriptions && <SelfServeNotificationTableAsset items={selfServeSubscriptions.assetSubscriptions || []} siteIdToName={siteIdToName} searchBar={true} />}
                            </Box>
                        </Stack>
                    </TabPanel>
                    <TabPanel value="Notifications" sx={{ pl: 0, pr: 0, pt: 2 }}>
                        <Box>
                            <AdminNotificationSearch />
                        </Box>
                    </TabPanel>
                </TabContext>
            </Paper>
        </PageWrapper>
    );
}

export interface INotificationAdminPage {

}

const NotificationAdminPageConfig: IPortalRouteOptions = {
    relativeUrl: "notifications",
    page: <NotificationAdminPage />,
    navDisplay: {
        title: "Notifications",
        icon: <Notifications />,
    },
    requiredPermissions: { SystemAdmin: SystemAdministrationPermissions.Admin },
}

export default NotificationAdminPageConfig;