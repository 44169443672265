import { LinearProgress, Paper, ThemeProvider, useTheme } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../shared/hooks/StateHook";
import { IGasPosition } from "../../shared/types/gas-reconciliation/gasposition";
import { updateOverride } from "../../shared/state/slices/NonDailyMeteredSlice";
import OverrideDialog from "./OverrideDialog";

const columns: GridColDef[] = [
    { field: "site", headerName: "Site", minWidth: 200 },
    { field: "code", headerName: "Code" },
    { field: "volume", headerName: "Volume" },
    { field: "override", headerName: "Override" },
    { field: "residual", headerName: "Residual" },
];

const NonDailyMeteredGrid = () => {
    const { data, isDataBeingRefreshed } = useAppSelector((state) => state.nonDailyMetered);
    const [selectedSite, setSelectedSite] = useState<IGasPosition | null>(
        null
    );
    const dispatch = useAppDispatch();

    const handleRowClick = (dto: IGasPosition) => {
        setSelectedSite(dto);
    };

    const handleDialogClose = () => {
        setSelectedSite(null);
    };

    const handleDialogApplyOverride = (id: number, value: number) => {
        dispatch(updateOverride({ id, value }));
    };

    return (
        <>
            <Paper sx={{ height: 500 }}>
                {isDataBeingRefreshed && <LinearProgress />}
                {data && (
                    <DataGrid
                        density="compact"
                        rows={data}
                        columns={columns}
                        showCellRightBorder={true}
                        showColumnRightBorder={true}
                        onRowClick={(e) => handleRowClick(e.row as IGasPosition)}
                    />
                )}
                {/* </div> */}
            </Paper>
            {selectedSite && (
                <OverrideDialog
                    site={selectedSite}
                    handleClose={handleDialogClose}
                    handleApply={handleDialogApplyOverride}
                />
            )}
        </>
    );
};

export default NonDailyMeteredGrid;
