import { IPerfmonFile } from "./IPerfmonFile";

export interface IContractInformation {
    siteName: string;
    bmuid: string;
    service: ServiceType;
    deliveryDate: Date;
    volume: number;
    efaBlock: EfaBlock;
    deliveryStart: Date
    deliveryEnd: Date;
    value: number;
    performance: number;
    availability: number;
    files: IPerfmonFile[];
}

export interface IAllContracts {
    allContracts?: IContractInformation[]
}

export enum ServiceType {
    DCL = 0,
    DCH = 1,
    DRL = 2,
    DRH = 3,
    DML = 4,
    DMH = 5,
}

export type EfaBlock = 1 | 2 | 3 | 4 | 5 | 6;
