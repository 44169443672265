import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import AdminNotificationOptions from './AdminNotificationOptions';
import { IPushNotification } from '../../shared/types/notification/IPushNotification';
import { Dictionary } from '@reduxjs/toolkit';
import { useGetAssetsQuery } from '../../shared/api/AssetApi';
import NotificationsTable from './tables/NotificationsTable';

const AdminNotificationSearch: React.FC<IAdminNotificationSearch> = ({ }) => {
    const [queriedNotifications, setQueriedNotifications] = useState<IPushNotification[]>([])
    const [siteIdToName, setSiteIdToName] = useState<Dictionary<string>>({})
    const { data: assets } = useGetAssetsQuery();

    const fetchPageData = () => {
        if (assets !== undefined) {
            var dictName: Dictionary<string> = {}
            assets?.forEach((item) => {
                dictName[item.SiteID] = item.Name
            });
            setSiteIdToName(dictName)
        }
    }

    useEffect(() => {
        fetchPageData()
    }, [assets])
    return (
        <Box width="100%" height="100%">
            <AdminNotificationOptions setNotifications={setQueriedNotifications} siteIdToName={siteIdToName} />
            <NotificationsTable items={queriedNotifications} siteIdToName={siteIdToName} searchBar={true} tablePagination={true} />
        </Box>
    );

}
export interface IAdminNotificationSearch {
}

export default AdminNotificationSearch;