import { INotificationSubscription } from "../types/notification/INotificationSubscription";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getApiAccessToken } from "../auth/helpers";

export const notificationSettingsApi = createApi({
    reducerPath: 'notificationSettingsApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_PORTAL_API_URL}NotificationSettings`,
        prepareHeaders: async (headers: Headers, { getState }) => {
            const accessToken = await getApiAccessToken();
            if (accessToken) {
                headers.set("Authorization", `Bearer ${accessToken}`);
            }
        }
    }),
    tagTypes: ['NotificationSubscriptions'],
    endpoints: (builder) => ({
        getNotificationSubscriptions: builder.query<INotificationSubscription[], void>({
            providesTags: ["NotificationSubscriptions"],
            query: () => 'GetMyNotificationSubscriptions'
        }),
        setNotificationSubscriptions: builder.mutation<string[], INotificationSubscription[]>({
            query: (payload) => ({
                url: '/SetNotificationSubscriptions',
                method: 'POST',
                body: {
                    notificationSubscriptions: payload
                }
            }),
            invalidatesTags: ["NotificationSubscriptions"]            
        }),
    })
});

export const {
    useGetNotificationSubscriptionsQuery,
    useSetNotificationSubscriptionsMutation
} = notificationSettingsApi;
