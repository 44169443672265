import { Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import DetailedGasAssetTable from "../../components/asset/DetailedGasAssetTable";
import PageWrapper from "../../components/PageWrapper";
import { useGetDetailedGasAssetsQuery, useAddDetailedGasAssetMutation, useUpdateDetailedGasAssetMutation, useDeleteDetailedGasAssetMutation } from "../../shared/api/AssetApi";
import { IPortalRouteOptions } from "../../shared/types/shared/routes/routeTypes";
import { Business, DomainAdd } from "@mui/icons-material";
import { IAdvancedModalOptions, useModalWindowContext } from "../../components/ModalWindow";
import DetailedAssetModalForm from "../../components/asset/DetailedAssetModalForm";
import { IDetailedGasAsset } from "../../shared/types/asset/IDetailedGasAsset";
import { usePortalContext } from "../../components/PortalWrapper";
import { AssetPermissions } from "../../shared/types/shared/auth/permissionsTypes";
import { UserPermissionCollection } from "../../shared/types/shared/auth/userDataTypes";
import TableToolbar, { ITableToolbarButton } from "../../components/shared/table/TableToolbar";

const DetailedGasAssetsPage: React.FC<IDetailedGasAssetsPage> = () => {
    const { currentUser } = usePortalContext();
    const [selectedAsset, setSelectedAsset] = useState<IDetailedGasAsset | null>(null)
    const { data: assets, isSuccess } = useGetDetailedGasAssetsQuery();
    const [addSite] = useAddDetailedGasAssetMutation();
    const [updateSite] = useUpdateDetailedGasAssetMutation();
    const [deleteSite] = useDeleteDetailedGasAssetMutation();
    const [authenticated, setAuthenticated] = useState<boolean>(false)
    const modal = useModalWindowContext();

    useEffect(() => {
        const perm: Partial<UserPermissionCollection> = {
            Asset: AssetPermissions.Admin
        }
        setAuthenticated(currentUser?.hasPermissions(perm) || false)
    }, [currentUser])


    const addAsset = () => {
        setSelectedAsset({} as IDetailedGasAsset);
    }

    const updateAsset = (model: IDetailedGasAsset) => {
        setSelectedAsset(model);
    }

    const handleDialogSave = (model: IDetailedGasAsset, isNew: boolean) => {
        if (isNew) {
            addSite(model);
        }
        else {
            updateSite(model);
        }
    };

    const handleDialogClose = () => {
        setSelectedAsset(null);
    };

    const deleteAsset = async (model: IDetailedGasAsset) => {
        const options = {
            title: "Delete Asset",
            content: `Delete [${model.siteID}].  Are you sure?`,
            yesText: "Yes",
            noText: "No"
        } as IAdvancedModalOptions;
        const modalResult = await modal.openModal(options);
        if (!modalResult) {
            return;
        }

        deleteSite(model);
    }

    const actionButtons: ITableToolbarButton[] = [
        { tooltipText: "Add New Gas Site", icon: <DomainAdd />, action: addAsset, disabled: !authenticated }
    ];

    return (
        <PageWrapper title="Detailed Gas Asset Register" loaded={isSuccess} contentOverflow={"hidden"}>
            <Paper sx={{ height: "calc(100% - 1px)" }}>
                <TableToolbar title="Gas Sites" buttons={actionButtons} />
                <DetailedGasAssetTable items={assets || []} searchBar={true} updateAsset={updateAsset} deleteAsset={deleteAsset} authenticated={authenticated} />
                {selectedAsset && (
                    <DetailedAssetModalForm
                        attribute={selectedAsset}
                        handleClose={handleDialogClose}
                        handleSave={handleDialogSave}
                    />)
                }
            </Paper>
        </PageWrapper>
    );
}

export interface IDetailedGasAssetsPage {

}

const DetailedGasAssetRegisterPageConfig: IPortalRouteOptions = {
    relativeUrl: "detailed-gas",
    page: <DetailedGasAssetsPage />,
    navDisplay: {
        title: "Detailed Gas Assets",
        icon: <Business />
    }
}

export default DetailedGasAssetRegisterPageConfig;