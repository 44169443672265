import React, { useEffect, useState } from 'react';
import { Box, FormControl, InputLabel, ListItemIcon, ListItemText, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { BatteryFull, Propane, SolarPower, WindPower } from "@mui/icons-material";
import { Dictionary } from "@reduxjs/toolkit";
import { isMobile } from "react-device-detect";
import { IEngine } from "../../../shared/types/IEngine";

const AssetEngineSelector: React.FC<IAssetEngineSelector> = ({ siteIdToName, asset, setAsset, generator, setGenerator, siteIDToGenerators, siteIDToType, anyAsset, anyGenerator, disabled }) => {
    const [generatorList, setGeneratorList] = useState<Array<string>>(new Array<string>())

    useEffect(() => {
        var generators = getGeneratorsFromAssetID(asset)
        setGeneratorList(generators)
        if (generators.indexOf(generator) < 0) {
            setGenerator(generators[0])
        }
    }, [asset])

    function getGeneratorsFromAssetID(assetID: string) {
        var generators = new Array<string>();
        if (anyGenerator) {
            generators.push("Any");
        }
        generators.push("Whole Site");
        var siteGenerators = siteIDToGenerators.get(assetID)
        if (siteGenerators !== undefined) {
            siteGenerators.forEach((generator) => {
                generators.push(generator.engine);
            })
        }
        return generators;
    }

    const handleAssetChange = (event: SelectChangeEvent) => {
        const newAsset = event.target.value as string
        setAsset(newAsset)
    }

    const handleGeneratorChange = (event: SelectChangeEvent) => {
        const newGenerator = event.target.value as string
        setGenerator(newGenerator)
    }

    return (
        <Grid container spacing={2}>
            <Grid xs={isMobile ? 12 : 6}>
                <Box>
                    <FormControl fullWidth>
                        <InputLabel id="asset-select-label">Asset</InputLabel>
                        <Select
                            labelId="asset-select-label"
                            id="asset-select-label"
                            value={asset}
                            label="Asset"
                            onChange={handleAssetChange}
                            renderValue={item => siteIdToName[item] || item}
                            disabled={disabled}
                        >
                            {anyAsset && <MenuItem key={"Any"} value={"Any"}>Any</MenuItem>}
                            {
                                Object.keys(siteIdToName).sort().map((item, index) =>
                                    <MenuItem key={item} value={item}>
                                        <ListItemIcon>
                                            {(siteIDToType[item] === "Gas" || siteIDToType[item] === "" || siteIDToType[item] === "Diesel") && <Propane />}
                                            {siteIDToType[item] === "Battery" && <BatteryFull />}
                                            {siteIDToType[item] === "Solar" && <SolarPower />}
                                            {siteIDToType[item] === "Wind" && <WindPower />}
                                        </ListItemIcon>
                                        <ListItemText>{siteIdToName[item]}</ListItemText>
                                    </MenuItem>
                                )
                            }
                        </Select>
                    </FormControl>
                </Box>
            </Grid>
            <Grid xs={isMobile ? 12 : 6}>
                <Box>
                    <FormControl fullWidth>
                        <InputLabel id="generator-select-label">Generator</InputLabel>
                        <Select
                            labelId="generator-select-label"
                            id="generator-select-label"
                            value={generatorList.indexOf(generator) < 0 ? '' : generator}
                            label="Generator"
                            onChange={handleGeneratorChange}
                            disabled={asset === "" || asset === "Any" || disabled}
                        >
                            {
                                generatorList.map((generator) =>
                                    <MenuItem key={generator} value={generator}>{generator}</MenuItem>
                                )
                            }
                        </Select>
                    </FormControl>
                </Box>
            </Grid>
        </Grid>
    );

}
export interface IAssetEngineSelector {
    asset: string
    setAsset: (asset: string) => void
    generator: string
    setGenerator: (generator: string) => void
    siteIdToName: Dictionary<string>
    siteIDToGenerators: Map<string, Array<IEngine>>
    siteIDToType: Dictionary<string>
    anyAsset?: boolean
    anyGenerator?: boolean
    disabled?: boolean
}

export default AssetEngineSelector;