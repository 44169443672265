import PageWrapper from "../../components/PageWrapper";
import React, { useEffect } from "react";
import WithinDayMarketChart from "../../components/analytics/WithinDayMarketChart";
import { Box } from "@mui/material";
import { IPortalRouteOptions } from "../../shared/types/shared/routes/routeTypes";
import { Analytics } from "@mui/icons-material";

const AnalyticsWithinDayMarketPage: React.FC<IAnalyticsWithinDayMarketPage> = () => {
    const [loaded, setLoaded] = React.useState(false);

    const loadComplete = () => {
        setLoaded(true);
    }

    useEffect(() => {
        loadComplete()
    }, []);

    return (
        <PageWrapper title="Within Day Market Chart" loaded={loaded}>
            <Box sx={{ height: "75vh", maxHeight: "700px", width: "74vw" }} >
                <WithinDayMarketChart />
            </Box>
        </PageWrapper>
    );
}


export interface IAnalyticsWithinDayMarketPage {

}

const AnalyticsWithinDayMarketPageConfig: IPortalRouteOptions = {
    relativeUrl: "within-day-market",
    page: <AnalyticsWithinDayMarketPage />,
    navDisplay: {
        title: "WD Market",
        icon: <Analytics />
    },
}

export default AnalyticsWithinDayMarketPageConfig;