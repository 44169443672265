import { Notifications, Save } from "@mui/icons-material";
import { Box, Button, Checkbox, FormControl, ListItemText, MenuItem, Paper, Select, SelectChangeEvent, Stack, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { Dictionary } from "@reduxjs/toolkit";
import React, { useEffect } from "react";
import PageWrapper from "../../components/PageWrapper";
import { useGetAssetsQuery } from "../../shared/api/AssetApi";
import { useGetNotificationSubscriptionsQuery, useSetNotificationSubscriptionsMutation } from "../../shared/api/NotificationSettingsApi";
import { IFilter, INotificationSubscription } from "../../shared/types/notification/INotificationSubscription";
import { IPortalRouteOptions } from "../../shared/types/shared/routes/routeTypes";

const NotificationSettingsPage: React.FC<INotificationSettingsPage> = () => {
    const [mySubscriptions, setMySubscriptions] = React.useState<INotificationSubscription[]>([])
    const [siteIdToName, setSiteIdToName] = React.useState<Dictionary<string>>({})
    const { data: assets } = useGetAssetsQuery();
    const { data: subscriptions, isSuccess } = useGetNotificationSubscriptionsQuery();
    const [saveSubscriptions] = useSetNotificationSubscriptionsMutation();

    const fetchPageData = () => {
        if (assets !== undefined) {
            var dictName: Dictionary<string> = {}
            assets?.forEach((item) => {
                dictName[item.SiteID] = item.Name
            });
            setSiteIdToName(dictName)
        }
    }

    useEffect(() => {
        fetchPageData()
    }, [assets])

    const handleSwitchFlick = (subscriptionName: string, subscribed: boolean) => {
        const theSubscriptions = mySubscriptions.map(subscription => {
            if (subscription.name === subscriptionName) {
                return { ...subscription, subscribed: subscribed };
            }
            return subscription;
        });

        setMySubscriptions(theSubscriptions)
    }

    const handleFilterChange = (subscriptionName: string, filters: string[]) => {
        const theSubscriptions = [...mySubscriptions];
        theSubscriptions.forEach((subscription) => {
            if (subscription.name == subscriptionName) {
                const newFilters: IFilter[] = []
                subscription.filters.forEach((filter) => {
                    const newFilter: IFilter = {
                        asset: filter.asset,
                        subscribed: filters.indexOf(filter.asset) > -1
                    }
                    newFilters.push(newFilter)
                })
                subscription.filters = newFilters
            }
        })
        setMySubscriptions(theSubscriptions)
    }

    const sendNotifications = () => {
        saveSubscriptions(mySubscriptions);        
    }

    useEffect(() => {
        if (subscriptions) {
            setMySubscriptions(subscriptions);
        }
    }, [isSuccess]);

    return (
        <PageWrapper title="Notification Settings" loaded={isSuccess} displayBreadcrumbs={false}>
            <Paper>
                <Stack>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell><Typography variant="h6">Notification</Typography></TableCell>
                                    <TableCell><Typography variant="h6">Subscribed</Typography></TableCell>
                                    <TableCell><Typography variant="h6">Assets</Typography></TableCell>
                                </TableRow>
                            </TableHead>
                            {mySubscriptions &&
                                <TableBody>
                                    {mySubscriptions.map((subscription) => <Subscription key={subscription.name} subscription={subscription} handleFlick={handleSwitchFlick} handleFilterChange={handleFilterChange} siteIdToName={siteIdToName} />)}
                                </TableBody>
                            }
                        </Table>
                    </TableContainer>
                    <Box p={2} textAlign="center">
                        <Button variant="contained" startIcon={<Save />} onClick={sendNotifications}>Save</Button>
                    </Box>
                </Stack>
            </Paper>
        </PageWrapper>
    );
}

const Subscription: React.FC<ISubscription> = ({ subscription, handleFlick, handleFilterChange, siteIdToName }) => {
    const [checked, setChecked] = React.useState<boolean>(subscription.subscribed)
    const [assetFilters, setAssetFilters] = React.useState<string[]>([])
    const [assets, setAssets] = React.useState<string[]>([])

    const handleSwitchFlick = () => {
        handleFlick(subscription.name, !checked)
        setChecked(!checked)
    }
    const handleChange = (event: SelectChangeEvent<typeof assetFilters>) => {
        const {
            target: { value },
        } = event;
        const newFilters = typeof value === 'string' ? value.split(',') : value
        setAssetFilters(newFilters);
        handleFilterChange(subscription.name, newFilters)
    };

    useEffect(() => {
        const initialFilters: string[] = []
        const initialAssets: string[] = []
        subscription.filters.forEach((filter) => {
            if (filter.subscribed) {
                initialFilters.push(filter.asset)
            }
            initialAssets.push(filter.asset)
        })
        setAssetFilters(initialFilters)
        setAssets(initialAssets)
    }, [])

    return (
        <TableRow key={subscription.name}>
            <TableCell>
                <Typography>{subscription.name}</Typography>
            </TableCell>
            <TableCell>
                <Switch checked={checked} onChange={handleSwitchFlick}></Switch>
            </TableCell>
            <TableCell>
                <FormControl sx={{ m: 1, width: 300 }}>
                    <Select
                        id="asset-filter-checkbox"
                        multiple
                        disabled={!checked}
                        value={assetFilters}
                        onChange={handleChange}
                        renderValue={(selected) => {
                            const selectedAssets: string[] = []
                            assets.forEach((asset) => {
                                if (selected.indexOf(asset) > -1 && siteIdToName[asset] !== "") {
                                    selectedAssets.push(asset)
                                }
                            })
                            return selectedAssets.join(', ')
                        }
                        }
                        MenuProps={{
                            PaperProps: {
                                style: {
                                    maxHeight: 48 * 4.5 + 8,
                                    width: 250,
                                },
                            },
                        }}>
                        {assets.filter((asset) => siteIdToName[asset] !== "").map((asset) => (
                            <MenuItem key={asset} value={asset}>
                                <Checkbox checked={assetFilters.indexOf(asset) > -1} />
                                <ListItemText primary={siteIdToName[asset]} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </TableCell>
        </TableRow>
    )
}

interface ISubscription {
    subscription: INotificationSubscription,
    handleFlick: (name: string, subscribed: boolean) => void
    handleFilterChange: (name: string, filters: string[]) => void
    siteIdToName: Dictionary<string>
}
export interface INotificationSettingsPage {

}

const NotificationSettingsPageConfig: IPortalRouteOptions = {
    relativeUrl: "notifications",
    page: <NotificationSettingsPage />,
    navDisplay: {
        title: "Notifications",
        icon: <Notifications/>,
    }
}

export default NotificationSettingsPageConfig;