import PageWrapper from "../../components/PageWrapper";
import React, { useEffect } from "react";
import GasFlowChart from "../../components/analytics/GasFlowChart";
import { Box } from "@mui/material";
import { IPortalRouteOptions } from "../../shared/types/shared/routes/routeTypes";
import { Analytics } from "@mui/icons-material";

const AnalyticsGasFlowPage: React.FC<IAnalyticsGasFlowPage> = () => {
    const [loaded, setLoaded] = React.useState(false);

    const loadComplete = () => {
        setLoaded(true);

    }


    useEffect(() => {
        loadComplete()
    }, [])
    return (
        <PageWrapper title="Gas Flow Analytics" loaded={loaded}>
            <Box sx={{ height: "75vh", maxHeight: "700px", width: "74vw" }} >
                <GasFlowChart />
            </Box>
        </PageWrapper>
    );
}


export interface IAnalyticsGasFlowPage {

}

const AnalyticsGasFlowPageConfig: IPortalRouteOptions = {
    relativeUrl: "gas-flow",
    page: <AnalyticsGasFlowPage />,
    navDisplay: {
        title: "Gas Flow",
        icon: <Analytics />
    }
}

export default AnalyticsGasFlowPageConfig;