import ApiRoot from "./ApiRoot";
import { IEngineOutage } from "../types/operate/IEngineOutage"
import { IAssetAvailability } from "../types/operate/IAssetAvailability"
import { getApiAccessToken } from "../auth/helpers";
import { IEngine } from "../types/operate/IEngine";
import { IEngineTrip } from "../types/operate/IEngineTrip";
import { ILinkedOutage } from "../types/operate/ILinkedOutage";
import { IOutageOperationResponse } from "../types/operate/IOutageOperationResponse";
import { OperateType } from "../types/operate/OperateType";

const rootUrl = process.env.REACT_APP_PORTAL_API_URL + "Availability"
const apiKey = process.env.REACT_APP_API_GATEWAY_KEY;

const getEngines = async () => {
    const accessToken = await getApiAccessToken();
    return await ApiRoot.get<IEngine[]>(`${rootUrl}/GetEngines`, { apiKey, accessToken });
};

const getEngineTrips = async (startDate: string, endDate: string, siteIds: string[]) => {
    const requestBody = {
        "startDate": startDate,
        "endDate": endDate,
        "siteIDs": siteIds
    }
    const accessToken = await getApiAccessToken();
    return await ApiRoot.post<IEngineTrip[]>(`${rootUrl}/GetEngineTrips`, requestBody, { apiKey, accessToken });
}

const getUnfinishedOutages = async () => {
    const accessToken = await getApiAccessToken();
    return await ApiRoot.get<IEngineOutage[]>(`${rootUrl}/GetUnfinishedOutages`, { apiKey, accessToken });
}

const getFinishedOutages = async (startDate: string, endDate: string) => {
    const accessToken = await getApiAccessToken();
    return await ApiRoot.get<IEngineOutage[]>(`${rootUrl}/GetFinishedOutages?startDate=${startDate}&endDate=${endDate}`, { apiKey, accessToken });
}
const getOutage = async (remitID: string) => {
    const accessToken = await getApiAccessToken();
    return await ApiRoot.get<ILinkedOutage>(`${rootUrl}/GetOutage?remitID=${remitID}`, { apiKey, accessToken });
}

const getOutageByRequest = async (outageStart: string, outageEnd: string, siteIds: string[], remitID: string, engineIds: string[] = ['All'], reasons: string[] = ['All'], outageTypes: string[] = ['Restriction', 'Outage']) => {
    const requestBody = {
        "outageStart": outageStart,
        "outageEnd": outageEnd,
        "siteIDs": siteIds,
        "remitIDToAvoid": remitID.toString(),
        "engineIDS": engineIds,
        "reasons": reasons,
        "outageTypes": outageTypes
    }
    const accessToken = await getApiAccessToken();
    return await ApiRoot.post<IEngineOutage[]>(`${rootUrl}/GetOutagesByRequest`, requestBody, { apiKey, accessToken });
}

const getAssetAvailability = async (startDate: string, endDate: string, siteIds: string[],) => {
    const requestBody = {
        "startDate": startDate,
        "endDate": endDate,
        "siteIDs": siteIds
    }

    const accessToken = await getApiAccessToken();
    return await ApiRoot.post<IAssetAvailability[][]>(`${rootUrl}/GetAvailability`, requestBody, { apiKey, accessToken });
}

const performOutageOp = async (operationType: OperateType, remitID: string, siteID: string, engineID: string, outageStart: string, outageEnd: string, mwOutage: number, reason: string, insertedTime: string, detail: string, outageType: string, outageVariety: string, workOrder: string, bucket: string, repeating: boolean, repeatingInterval: string, repeatUntil: string, modifyAll: boolean, writeToDB: boolean) => {
    let detailValue = ""
    if (detail) {
        detailValue = detail
    }
    let opEnum = 0
    if (operationType === "Update") { opEnum = 1 }
    else if (operationType === "Delete") { opEnum = 2 }
    const requestBody = {
        "operationType": opEnum,
        "oldRemit": remitID.toString(),
        "siteID": siteID,
        "engineID": engineID,
        "outageStart": outageStart,
        "outageEnd": outageEnd,
        "mwOutage": mwOutage,
        "reason": reason,
        "insertedTime": insertedTime,
        "detail": detailValue,
        "outageType": outageType,
        "restriction": outageVariety === "Restriction",
        "workOrder": workOrder || null,
        "repeating": repeating,
        "repeatingInterval": repeatingInterval,
        "repeatUntil": repeatUntil,
        "modifyAll": modifyAll,
        "writeToDB": writeToDB,
        "bucket": bucket
    }
    const accessToken = await getApiAccessToken();
    return await ApiRoot.post<IOutageOperationResponse>(rootUrl + "/OutageOperation", requestBody, { apiKey, accessToken });
}

const availabilityApi = {
    getEngineTrips,
    getUnfinishedOutages,
    getFinishedOutages,
    getOutage,
    getOutageByRequest,
    getAssetAvailability,
    getEngines,
    performOutageOp
}

export default availabilityApi;