import { Hexagon } from 'react-hexgrid';
import { CubicCoords } from '../../shared/types/shared/hex-grid';
import { AssetType } from '../../shared/types/ISiteStatus';
import { CellTower } from '@mui/icons-material';
import { HexagonMouseEventHandler } from 'react-hexgrid/lib/Hexagon/Hexagon';
import { HexIndicatorBar } from './HexIndicatorBar';
import { HexMultiLineText } from './HexMultiLineText';
import { HexIcon } from './HexIcon';
import { HexStatusText } from './HexStatusText';
import { HexBatteryStatus } from './HexBatteryStatus';
import { HiveColours } from '../../shared/utils/StatusHiveUtils';
import { StatusHiveSiteData, StatusHiveTileStatus } from '../../shared/types/asset/status-hive';

export const StatusHiveTile: React.FC<IStatusHiveTileProps> = ({ coords, status, onClick }) => {
    const contentColour = status.colour;
    const opacity = status.tileStatus === StatusHiveTileStatus.Idle ? 0.5 : 1;
    const displayFrequencyServices = status.assetType === AssetType.Battery;

    return (
        <Hexagon q={coords.q} r={coords.r} s={coords.s} fill="hex-background" opacity={opacity} stroke={contentColour} strokeWidth={"0.01em"} className="data-hex" onClick={onClick}>
            <HexIcon Icon={status.assetIcon} fontSize={0.125} colour={contentColour} y={-0.44} tooltip={AssetType[status.assetType]} />

            <HexMultiLineText text={status.siteName || status.siteId} colour={contentColour} y={-1.1} fontSize={0.09} fontWeight={800} />


            {displayFrequencyServices ?
                <HexBatteryStatus status={status} activeColour={contentColour} inactiveColour={HiveColours.Idle} y={2.25} />
                :
                <HexStatusText status={status} colour={contentColour} y={2.45} />
            }

            <HexIcon Icon={CellTower} fontSize={0.125} colour={status.commsIconData.colour} y={0.32} tooltip={status.commsIconData.tooltip} />

            <HexIndicatorBar
                x={-0.38}
                y={0}
                width={0.04}
                height={0.45}
                fill={contentColour}
                value={status.availableExport}
                maxValue={status.siteCapacity}
                markerValue={status.activePowerExport}
                valueTooltipLabel="Available Export:"
                markerTooltipLabel="Active Export:"
                unit={"MW"}
            />
        </Hexagon>
    );
}

export interface IStatusHiveTileProps {
    coords: CubicCoords;
    status: StatusHiveSiteData;
    onClick: HexagonMouseEventHandler<SVGElement>;
}