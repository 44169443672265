import React, { useCallback, useEffect } from "react";
import PageWrapper from "../../components/PageWrapper";
import { Grid } from "@mui/material";
import { IPortalRouteOptions } from "../../shared/types/shared/routes/routeTypes";
import { Dashboard } from "@mui/icons-material";

const DataHomePage: React.FC<IDataHomePage> = () => {
  const [loaded, setLoaded] = React.useState(false);

  const loadComplete = useCallback(async () => {
    setLoaded(true);
  }, [])

  useEffect(() => {
    loadComplete()
  }, [loadComplete])

    return (
        <PageWrapper title="Data Access" loaded={loaded}>
            <Grid container spacing={2}>

            </Grid>
        </PageWrapper>
    );
}

export interface IDataHomePage {

}

const DataHomePageConfig: IPortalRouteOptions = {
  relativeUrl: "data",
  page: <DataHomePage />,
  navDisplay: {
    title: "Dashboard",
    icon: <Dashboard />
  }
}

export default DataHomePageConfig;