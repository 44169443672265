import React, { useState } from "react";
import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { isMobile } from "react-device-detect";

const GasPriceOptions: React.FC<IGasPriceOptionsProps> = ({ strip, setStrip, stripCategory, setStripCategory, forwardHorizon, setForwardHorizon, countryGas, setCountryGas, timing, setTiming, year, setYear, commodity, setCommodity, hidden, disabled, stripCategories, stripDict, gasCountries, timings }) => {
    const [strips, setStrips] = useState<string[]>(stripDict.get(stripCategory) || []);
    const handleStripChange = (event: SelectChangeEvent) => {
        setStrip(event.target.value as string);
    };
    const handleStripCategoryChange = (event: SelectChangeEvent) => {
        var newStripCategory = event.target.value as string
        setStripCategory(newStripCategory)
        var newStrips = stripDict.get(newStripCategory)
        setStrips(newStrips || [])
        setStrip(newStrips !== undefined ? newStrips[0] : "")
    }
    const handleForwardHorizonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setForwardHorizon(event.target.value as string);
    };
    const handleCountryGasChange = (event: SelectChangeEvent) => {
        setCountryGas(event.target.value as string);
    };
    const handleTimingChange = (event: SelectChangeEvent) => {
        setTiming(event.target.value as string);
    };
    const handleYearChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setYear(event.target.value as string);
    };
    const handleCommodityChange = (event: SelectChangeEvent) => {
        setCommodity(event.target.value as string);
    };
    const commodities = [
        "PEAK",
        "BASE",
        "OP",
        "GAS"
    ]
    return (
        <>
            <Grid xs={isMobile ? 6 : 2} hidden={hidden}>
                <Box>
                    <FormControl fullWidth>
                        <InputLabel id="strip-category-label">Strip Category</InputLabel>
                        <Select
                            labelId="strip-category-label"
                            id="strip-category-select"
                            value={stripCategory}
                            label="Strip Category"
                            onChange={handleStripCategoryChange}
                            disabled={disabled}
                        >
                            <MenuItem value={"Any"}>{"Any"}</MenuItem>
                            {
                                stripCategories.map((item) =>
                                    <MenuItem key={item} value={item}>{item}</MenuItem>
                                )
                            }
                        </Select>
                    </FormControl>
                </Box>
            </Grid>
            <Grid xs={isMobile ? 6 : 2} hidden={hidden}>
                <Box>
                    <FormControl fullWidth>
                        <InputLabel id="strip-label">Strip</InputLabel>
                        <Select
                            labelId="strip-label"
                            id="strip-select"
                            value={strip}
                            label="Strip"
                            onChange={handleStripChange}
                            disabled={stripCategory === "Any" || disabled}
                        >
                            {
                                strips.map((item) =>
                                    <MenuItem key={item} value={item}>{item}</MenuItem>
                                )
                            }
                        </Select>
                    </FormControl>
                </Box>
            </Grid>
            <Grid xs={isMobile ? 12 : 2} hidden={hidden}>
                <Box>
                    <TextField
                        id="forward-horizon"
                        label="Forward Horizon"
                        variant="outlined"
                        type="number"
                        value={forwardHorizon}
                        InputProps={{ inputProps: { min: 0, max: 10 } }}
                        onChange={handleForwardHorizonChange}
                        disabled={disabled}
                        fullWidth
                    />
                </Box>
            </Grid>
            <Grid xs={isMobile ? 6 : 1.5} hidden={hidden}>
                <Box>
                    <FormControl fullWidth>
                        <InputLabel id="country-label">Country</InputLabel>
                        <Select
                            labelId="country-label"
                            id="country-select"
                            value={countryGas}
                            label="Country"
                            onChange={handleCountryGasChange}
                            disabled={disabled}
                        >
                            <MenuItem value={"Any"}>{"Any"}</MenuItem>
                            {
                                gasCountries.map((item) =>
                                    <MenuItem key={item} value={item}>{item}</MenuItem>
                                )
                            }
                        </Select>
                    </FormControl>
                </Box>
            </Grid>
            <Grid xs={isMobile ? 6 : 1.5} hidden={hidden}>
                <Box>
                    <FormControl fullWidth>
                        <InputLabel id="timing-label">Timing</InputLabel>
                        <Select
                            labelId="timing-label"
                            id="timing-select"
                            value={timing}
                            label="Timing"
                            onChange={handleTimingChange}
                            disabled={disabled}
                        >
                            <MenuItem value={"Any"}>{"Any"}</MenuItem>
                            {
                                timings.map((item) =>
                                    <MenuItem key={item} value={item}>{item}</MenuItem>
                                )
                            }
                        </Select>
                    </FormControl>
                </Box>
            </Grid>
            <Grid xs={isMobile ? 6 : 1.5} hidden={hidden}>
                <Box>
                    <TextField
                        id="year"
                        label="Year"
                        variant="outlined"
                        type="number"
                        value={year}
                        onChange={handleYearChange}
                        disabled={disabled}
                        fullWidth
                    />
                </Box>
            </Grid>
            <Grid xs={isMobile ? 6 : 1.5} hidden={hidden}>
                <Box>
                    <FormControl fullWidth>
                        <InputLabel id="commodity-label">Commodity</InputLabel>
                        <Select
                            labelId="commodity-label"
                            id="commodity-select"
                            value={commodity}
                            label="Commodity"
                            onChange={handleCommodityChange}
                            disabled={disabled}
                        >
                            <MenuItem value={"Any"}>{"Any"}</MenuItem>
                            {
                                commodities.map((item) =>
                                    <MenuItem key={item} value={item}>{item}</MenuItem>
                                )
                            }
                        </Select>
                    </FormControl>
                </Box>
            </Grid>
        </>
    );
}

export interface IGasPriceOptionsProps {
    strip: string
    setStrip: (strip: string) => void
    stripCategory: string
    setStripCategory: (stripCategory: string) => void
    forwardHorizon: string
    setForwardHorizon: (forwardHorizon: string) => void
    countryGas: string
    setCountryGas: (countryGas: string) => void
    timing: string
    setTiming: (timing: string) => void
    year: string
    setYear: (year: string) => void
    commodity: string
    setCommodity: (commodity: string) => void
    hidden: boolean
    disabled: boolean
    stripCategories: string[]
    stripDict: Map<string, string[]>
    gasCountries: string[]
    timings: string[]
}

export default GasPriceOptions;