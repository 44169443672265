import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IHistoricalSubmission } from "../../types/gas-reconciliation/historicalsubmission";
import { ISubmissionDetails } from "../../types/gas-reconciliation/submissiondetails";
import gasReconciliationApi, { HistoricalSubmissionAPIResponse, SubmissionDetailsAPIResponse } from "../../api/GasReconciliationApi";

interface HistoricalSubmissionsState {
    data: IHistoricalSubmission[];
    isDataBeingRefreshed: boolean;
    selectedDetails?: ISubmissionDetails[] | null;   
}

const initialState: HistoricalSubmissionsState = {
    data: [],
    selectedDetails: null,
    isDataBeingRefreshed: false,
};

interface inputData {
    startDate: Date;
    endDate: Date;
}

interface detailsInputData {
    batchNumber?: number;
    siteType?: number;
}

export const getData = createAsyncThunk(
    "get-historicalsubmissions",
    async (dates: inputData, thunkApi) => {
        const response = await gasReconciliationApi.getHistoricalSubmissions(dates.startDate, dates.endDate);
        return response;
    }
);

export const getDetails = createAsyncThunk(
    "get-submissiondetails",
    async (input: detailsInputData, thunkApi) => {
        if (input.batchNumber === null || input.siteType === null) {
            return null;
        }

        const response = await gasReconciliationApi.getSubmissionDetails(input.batchNumber as number, input.siteType as number);
        return response;
    }
);

export const historicalSubmissionsSlice = createSlice({
    name: "historicalSubmissions",
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(getData.pending, (state) => {
                state.selectedDetails = null;
                state.isDataBeingRefreshed = true;
            })
            .addCase(getData.rejected, (state) => {
                state.isDataBeingRefreshed = false;
            })
            .addCase(
                getData.fulfilled,
                (state, action: PayloadAction<HistoricalSubmissionAPIResponse>) => {
                    state.data = action.payload.results;
                    state.isDataBeingRefreshed = false;
                }
            )
            .addCase(getDetails.pending, (state) => {
                state.isDataBeingRefreshed = true;
            })
            .addCase(getDetails.rejected, (state) => {
                state.isDataBeingRefreshed = false;
            })
            .addCase(
                getDetails.fulfilled,
                (state, action: PayloadAction<SubmissionDetailsAPIResponse | null>) => {
                    state.selectedDetails = action.payload?.results;
                    state.isDataBeingRefreshed = false;
                }
            )
    },
});

export default historicalSubmissionsSlice.reducer;
