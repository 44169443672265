import * as React from 'react';
import { GanttTimestampedData, buildGanttViewDataFromContracts } from '../../shared/types/dynamic-contracts/gantt-chart-data';
import { IContractInformation, ServiceType } from '../../shared/types/dynamic-contracts/IDynamicContract';
import { SxProps, Table, TableBody, TableCell, TableHead, TableRow, Theme, Tooltip } from '@mui/material';
import { getGanttCellBackgroundColour, getGanttCellPercentageText, getGanttCellTextColour, getGanttCellValueText, getGanttFileDisplayData, getGanttFileTooltip } from '../../shared/utils/GanttChartUtils';
import DynamicContractsGanttEmpty from './DynamicContractsGanttEmpty';

const blocksPerDay = 6;
const filesPerBlock = 4;
const rowsPerService = 2;
const chartCellSxProps: SxProps<Theme> = { p: 1 }

const DummyColumns = (): JSX.Element => {
    return (
        <>
            <TableCell id="site-name-column" sx={{ ...chartCellSxProps }}></TableCell>
            <TableCell id="service-column" sx={{ ...chartCellSxProps }}></TableCell>
        </>
    );
}

const DateHeaderRow = ({ dates }: IGanttChartHeaderRowProps): JSX.Element => {
    return (
        <TableRow>
            <DummyColumns />
            {dates.map((date) =>
                <TableCell
                    sx={{ ...chartCellSxProps }} align="center"
                    key={date} colSpan={filesPerBlock * blocksPerDay}>
                    {date}
                </TableCell>)}
        </TableRow>
    );
}

const EfaBlockHeaderRow = ({ dates }: IGanttChartHeaderRowProps): JSX.Element => {
    const getBlockColumnHeaders = (date: string) => {
        const elements: JSX.Element[] = [];

        for (var block = 1; block <= blocksPerDay; block++) {
            elements.push(<TableCell sx={{ ...chartCellSxProps }} colSpan={filesPerBlock} align="center" key={`${date}-${block}`}>EFA {block}</TableCell>)
        }

        return elements;
    }

    return (
        <TableRow>
            <DummyColumns />
            {dates.map((date) =>
                <React.Fragment key={`${date}-blocks`}>
                    {getBlockColumnHeaders(date)}
                </React.Fragment>
            )}
        </TableRow>
    );
}

const SummaryRow = ({ displaySiteName, siteName, totalServices, service, timeData }: IGanttChartSummaryRowProps): JSX.Element => {
    return (
        <TableRow>
            {displaySiteName && <TableCell sx={{ ...chartCellSxProps }} rowSpan={totalServices * rowsPerService} align="center">{siteName}</TableCell>}
            <TableCell sx={{ ...chartCellSxProps }} rowSpan={rowsPerService} align="center">{ServiceType[service]}</TableCell>
            {timeData.map((dataByDate) => {
                return Object.values(dataByDate.blockData).map((block) => {
                    const backgroundColour = getGanttCellBackgroundColour(block);
                    const textColour = getGanttCellTextColour(block);
                    const key = `${siteName}-${service}-${dataByDate.deliveryDate.toISOString()}-${block.efaBlock}-summary`
                    const sx = { ...chartCellSxProps, border: `solid 1px ${backgroundColour}`, color: textColour, background: backgroundColour, fontWeight: "bold" };

                    return (
                        <TableCell key={key} align="center" colSpan={filesPerBlock} sx={sx}>
                            {getGanttCellPercentageText(block)}
                            <br />
                            {getGanttCellValueText(block)}
                        </TableCell>
                    );
                })
            })}
        </TableRow>
    );
}

const FileRow = ({ siteName, service, timeData }: IGanttChartFileRowProps): JSX.Element => {
    const dummyFiles = [1, 2, 3, 4];

    return (
        <TableRow>
            {timeData.map((dataByDate) => {
                return Object.values(dataByDate.blockData).map((block) => {
                    return dummyFiles.map((dummyFile, index) => {
                        const file = block.files[index] || null;
                        const displayData = getGanttFileDisplayData(file);
                        const fileTooltop = getGanttFileTooltip(file);
                        const key = `${siteName}-${service}-${dataByDate.deliveryDate.toISOString()}-${block.efaBlock}-file${index}`

                        return (
                            <TableCell sx={{ ...chartCellSxProps, color: displayData.colour }} key={key} align="center">
                                <Tooltip title={<div style={{ whiteSpace: 'pre-line' }}>{fileTooltop}</div>}>{displayData.icon}</Tooltip>
                            </TableCell>
                        );
                    })
                })
            })}
        </TableRow>
    );
}

const DynamicContractsGanttDayView: React.FC<IDynamicContractsGanttDayViewProps> = ({ contracts }) => {
    const viewData = buildGanttViewDataFromContracts(contracts);
    const allSitesData = Object.values(viewData.data);

    if (allSitesData.length == 0) {
        return (
            <DynamicContractsGanttEmpty />
        );
    }

    return (
        <Table>
            <TableHead>
                <DateHeaderRow dates={viewData.datesCovered} />
                <EfaBlockHeaderRow dates={viewData.datesCovered} />
            </TableHead>
            <TableBody>
                {
                    allSitesData.map((siteData) => {
                        const services = Object.values(siteData.serviceData);
                        return (
                            <React.Fragment key={siteData.siteName}>
                                {
                                    services.map((service, serviceIndex) => {
                                        const timeData = Object.values(service.timestampedData)
                                            .sort((a, b) => a.deliveryDate > b.deliveryDate ? 1 : -1)

                                        return (
                                            <React.Fragment key={`${siteData.siteName}-${service.service}`}>
                                                <SummaryRow siteName={siteData.siteName} displaySiteName={serviceIndex === 0} service={service.service} timeData={timeData} totalServices={services.length} />
                                                <FileRow siteName={siteData.siteName} service={service.service} timeData={timeData} />
                                            </React.Fragment>
                                        );
                                    })
                                }
                            </ React.Fragment>
                        );
                    })
                }
            </TableBody>
        </Table>
    );
}

interface IGanttChartHeaderRowProps {
    dates: string[];
}

interface IGanttChartSummaryRowProps {
    displaySiteName: boolean;
    siteName: string;
    totalServices: number;
    service: ServiceType;
    timeData: GanttTimestampedData[];
}

interface IGanttChartFileRowProps {
    siteName: string;
    service: ServiceType;
    timeData: GanttTimestampedData[];
}

export interface IDynamicContractsGanttDayViewProps {
    contracts: IContractInformation[];
}

export default DynamicContractsGanttDayView;
