import { Text } from 'react-hexgrid';

export const HexMultiLineText: React.FC<IHexMultiLineTextProps> = ({ text: siteName, colour, y = 0, fontSize = 0.09, fontWeight, maxChars = 12, breakSingleWord = false }) => {
    const bottomLineLength = maxChars;
    let topLine = "";
    let bottomLine = "";
    if (!breakSingleWord && !siteName.includes(' ')) { // Don't break the text at all if there are no spaces and breakSingleWord is disabled
        bottomLine = siteName;
    } else if (siteName.length <= bottomLineLength) { // Don't do anything if the full string is less than the character limit
        bottomLine = siteName;
    } else { // Split the text into two lines
        const spaceIndex = siteName.indexOf(" ", siteName.length - (bottomLineLength + 1));
        bottomLine = siteName.slice(spaceIndex, siteName.length).trimStart();
        topLine = siteName.slice(0, spaceIndex).trimEnd();
    }

    const wordSpacing = 1.25;

    const bottomY = topLine ? y + (wordSpacing / 2) : y;
    const topY = y - (wordSpacing / 2);

    return (
        <>
            <Text fontWeight={fontWeight} y={`${topY}em`} fill={colour} fontSize={`${fontSize}em`} strokeWidth={"0"}>
                {topLine}
            </Text>
            <Text fontWeight={fontWeight} y={`${bottomY}em`} fill={colour} fontSize={`${fontSize}em`} strokeWidth={"0"}>
                {bottomLine}
            </Text>
        </>
    );
}

export interface IHexMultiLineTextProps {
    text: string;
    colour?: string;
    y?: number;
    fontSize?: number;
    fontWeight?: string | number;
    maxChars?: number;
    breakSingleWord?: boolean;
}
