import { Box } from "@mui/material";
import PageWrapper from "../../components/PageWrapper";
import React, { useEffect } from "react";
import GasSystemLengthChart from "../../components/analytics/GasSystemLengthChart";
import { IPortalRouteOptions } from "../../shared/types/shared/routes/routeTypes";
import { Analytics } from "@mui/icons-material";

const AnalyticsGasSystemLengthPage: React.FC<IAnalyticsGasSystemLengthPage> = () => {
    const [loaded, setLoaded] = React.useState(false);

    const loadComplete = () => {
        setLoaded(true);
    }

    useEffect(() => {
        loadComplete()
    }, [])
    return (
        <PageWrapper title="Gas System Length Analytics" loaded={loaded}>
            <Box sx={{ height: "75vh", maxHeight: "700px", width: "74vw" }} >
                <GasSystemLengthChart />
            </Box>
        </PageWrapper>
    );
}

export interface IAnalyticsGasSystemLengthPage {

}

const AnalyticsGasSystemLengthPageConfig: IPortalRouteOptions = {
    relativeUrl: "gas-system-length",
    page: <AnalyticsGasSystemLengthPage />,
    navDisplay: {
        title: "Gas System Length",
        icon: <Analytics />,
    }
}

export default AnalyticsGasSystemLengthPageConfig;