import { Box, CircularProgress } from '@mui/material';
import { Cloud } from '@mui/icons-material';

const LoadingSpinner: React.FC = () => {
    return (
        <Box sx={{ position: 'relative', height: 60, width: 60 }}>
            <Cloud fontSize="large" sx={{position: 'absolute', left: 12, top: 12}}/>

            <CircularProgress
                size={60}
                sx={{
                    position: 'absolute',
                    left: 0,
                    zIndex: 1,
                }}
            />
        </Box>
    )
}

export default LoadingSpinner;
