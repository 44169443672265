import React, { useEffect, useState } from "react";
import PageWrapper from "../../components/PageWrapper";
import { Box, Paper } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { Dictionary } from "@reduxjs/toolkit";
import EngineOutageTable from "../../components/operate/EngineOutageTable";
import OutageDataRequestBar from "../../components/data/OutageDataRequestBar";
import { useGetAssetsQuery } from "../../shared/api/AssetApi";
import { IEngineOutage } from "../../shared/types/operate/IEngineOutage";
import { IEngine } from "../../shared/types/operate/IEngine";
import availabilityApi from "../../shared/api/AvailabilityApi";
import { IPortalRouteOptions } from "../../shared/types/shared/routes/routeTypes";
import { EventBusy } from "@mui/icons-material";

const DataEngineOutagesPage: React.FC<IDataEngineOutagesPage> = () => {
    const [siteIdToName, setSiteIdToName] = React.useState<Dictionary<string>>({})
    const [loadedGenerators, setLoadedGenerators] = useState<boolean>(false);
    const [siteIDToGenerators, setSiteIDToGenerators] = React.useState<Map<string, Array<IEngine>>>(new Map<string, Array<IEngine>>())
    const [outages, setOutages] = useState<IEngineOutage[]>([]);
    const { data: assets, isSuccess: loaded } = useGetAssetsQuery();

    useEffect(() => {
        if (assets !== undefined) {
            var dict: Dictionary<string> = {}
            assets?.forEach((item) => dict[item.SiteID] = item.Name);
            setSiteIdToName(dict);
            availabilityApi.getEngines()
                .then((engines) => {
                    let dict = new Map<string, Array<IEngine>>();
                    engines.forEach((engine) => {
                        var generators = dict.get(engine.siteID);
                        if (generators !== undefined) {
                            generators.push(engine);
                            dict.set(engine.siteID, generators);
                        }
                        else {
                            dict.set(engine.siteID, new Array<IEngine>(engine));
                        }
                    })
                    setSiteIDToGenerators(dict);
                    setLoadedGenerators(true);
                })
        }
    }, [assets])


    return (
        <PageWrapper title="Engine Outages" loaded={loaded && loadedGenerators}>
            <Grid container>
                <Grid xs={12}>
                    <Paper sx={{ p: 1 }}>
                        <OutageDataRequestBar readings={outages} setReadings={setOutages} siteIdToName={siteIdToName} siteIDToGenerators={siteIDToGenerators} />
                        <Box sx={{ mt: 1 }}>
                            <EngineOutageTable items={outages} siteIdToName={siteIdToName} searchBar={true} />
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </PageWrapper>
    );
}

export interface IDataEngineOutagesPage {

}

const DataEngineOutagesPageConfig: IPortalRouteOptions = {
    relativeUrl: "outage-data",
    page: <DataEngineOutagesPage />,
    navDisplay: {
        title: "Outage Data",
        icon: <EventBusy />
    },
}

export default DataEngineOutagesPageConfig;