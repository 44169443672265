import React from "react";
import { Box, TextField } from "@mui/material";

const OutageFormString: React.FC<IOutageFormString> = ({ label = "", value, setValue, disabled = false }) => {

    const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            setValue(event.target.value as string)
    }

    return (
        <Box>
            <TextField
                id={label}
                label={label}
                variant="outlined"
                value={value}
                onChange={handleValueChange}
                disabled={disabled}
                fullWidth
            />
        </Box>
    );
}
export interface IOutageFormString {
    label?: string
    value: string
    setValue: (value: string) => void
    min?: number
    max?: number
    units?: string
    disabled?: boolean
}

export default OutageFormString;