import { forwardRef, useCallback } from "react";
import { useSnackbar, SnackbarContent, CustomContentProps } from "notistack";
import CustomNotificationBody from "./CustomNotificationBody"
import { IPushNotification } from "../../shared/types/notification/IPushNotification";
export interface ICustomNotification extends CustomContentProps {
    notification: IPushNotification
}

const CustomNotification = forwardRef<HTMLDivElement, ICustomNotification>(
    ({ id, ...props }, ref) => {
        const { closeSnackbar } = useSnackbar();

        const handleDismiss = useCallback(() => {
            closeSnackbar(id);
        }, [id, closeSnackbar]);

        return (
            <SnackbarContent ref={ref} style={{ "minWidth": 300 }}>
                <CustomNotificationBody notification={props.notification} handleDismiss={handleDismiss} />
            </SnackbarContent>
        )
    }
)
CustomNotification.displayName = "CustomNotification";
export default CustomNotification;