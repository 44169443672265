import { Close } from '@mui/icons-material';
import { Alert, AlertColor, Button, IconButton, Snackbar } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { PropsWithChildren, createContext, useContext } from 'react';

interface IContextProps {
  popToast: (message: string, severity?: AlertColor, autoHideDuration?: number) => void;
  popActionToast: (message: string, toastAction: IToastAction, severity?: AlertColor, autoHideDuration?: number) => void;
}

const ToastAlertContext = createContext<IContextProps>({ popToast: () => {}, popActionToast: () => {} });

const ToastAlertContextProvider: React.FC<PropsWithChildren<any>> = ({ children }: PropsWithChildren<{}>) => {
  const defaultAutoHideDuration = 5000;
  const [open, setOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState<IToastMessage | undefined>();
  const [toastRack, setToastRack] = useState<readonly IToastMessage[]>([]);

  const { message, autoHideDuration, severity, toastAction } = toastMessage || {};

  useEffect(() => {
    if (toastRack.length && !toastMessage) {
      // Set a new toast when we don't have an active one
      setToastMessage({ ...toastRack[0] });
      setToastRack((prev) => prev.slice(1));
      setOpen(true);
    } else if (toastRack.length && toastMessage && open) {
      // Close an active snack when a new one is added
      setOpen(false);
    }
  }, [toastRack, toastMessage, open]);

  const openToast = useCallback((message: string, severity?: AlertColor, autoHideDuration: number = defaultAutoHideDuration): void => {
    setToastRack((prev) => [...prev, { key: new Date().getTime(), message, severity, autoHideDuration, toastAction: undefined }]);
  }, []);

  const openActionToast = useCallback((message: string, toastAction: IToastAction, severity?: AlertColor, autoHideDuration: number = defaultAutoHideDuration): void => {
    setToastRack((prev) => [...prev, { key: new Date().getTime(), message, severity, autoHideDuration, toastAction }]);
  },[]);

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handleToastAction = (event: React.SyntheticEvent | Event, reason?: string) => {
    toastAction?.action();
    handleClose(event, reason);
  }

  const handleExited = () => {
    setToastMessage(undefined);
  };

  const action: JSX.Element = (
    <>
      {toastAction &&
      <Button color="secondary" size="small" onClick={handleToastAction}>
        {toastAction?.text}
      </Button>}
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <Close fontSize="small" />
      </IconButton>
    </>
  );

  return (
    <ToastAlertContext.Provider value={{ popToast: openToast, popActionToast: openActionToast }}>
      {children}
      {severity ?
        <Snackbar
          open={open}
          autoHideDuration={autoHideDuration}
          onClose={handleClose}
          action={action}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          TransitionProps={{ onExited: handleExited }}
        >
          <Alert severity={severity} onClose={handleClose} action={action}>
            {message}
          </Alert>
        </Snackbar>
        :
        <Snackbar
          open={open}
          autoHideDuration={autoHideDuration}
          onClose={handleClose}
          message={message}
          action={action}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          TransitionProps={{ onExited: handleExited }}
        />
      }
    </ToastAlertContext.Provider>
  )
}

export interface IToastMessage {
  key: number
  message: string;
  autoHideDuration: number;
  severity?: AlertColor;
  toastAction?: IToastAction;
}

export interface IToastAction {
  text: string;
  action: () => void;
}

export const useToastAlertContext = () => useContext(ToastAlertContext);
export default ToastAlertContextProvider;
