import React from "react";
import { Paper } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { DataGrid } from "@mui/x-data-grid";
const ResultsTables: React.FC<IResultsTableProps> = ({ data, columns, sortBy, height = "70vh" }) => {

    return (
        <Grid
            container
            alignItems="center"
            alignContent="start"
            spacing={2}
            justifyContent="flex-start"
            sx={{ height }}>
            <Grid xs={12} sx={{ height: "100%" }}>
                <Paper sx={{ height: "100%" }}>
                    <DataGrid sx={{ height: "100%", width: "100%" }}
                        getRowId={(row) => row.id}
                        rows={data}
                        columns={columns}
                        initialState={{
                            pagination: {
                                pageSize: 100,
                            },
                            sorting: {
                                sortModel: (sortBy !== undefined ? [{ field: sortBy, sort: 'asc' }] : []),
                            },
                        }}
                        rowsPerPageOptions={[20, 50, 100]}
                    />
                </Paper>
            </Grid>
        </Grid>
    );
}

export interface IResultsTableProps {
    data: any;
    columns: { field: string, headerName: string, width: number }[];
    sortBy?: string;
    height?: string;
}

export default ResultsTables;