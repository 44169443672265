import React, { useCallback } from 'react';
import PageWrapper from '../../../components/PageWrapper';
import { Paper, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import BatteryContractsTable from '../../../components/battery-contracts/BatteryContractsTable';
import LogDataGrid from '../../../components/LogDataGrid';
import { useTheme } from '@mui/material/styles';
import { ILogEntries } from '../../../shared/types/ILogEntries';
import loggingApi from '../../../shared/api/LoggingApi';
import { useToastAlertContext } from '../../../components/ToastAlert';
import { IPortalRouteOptions } from '../../../shared/types/shared/routes/routeTypes';
import Today from '@mui/icons-material/Today';

const BatteryContractsPage: React.FC<IBatteryContractsPage> = () => {
    const [loaded, setLoaded] = React.useState(false);
    const [logEntriesBcp, setLogEntriesBcp] = React.useState<ILogEntries>();

    const appIdBcp = "iON Battery Contracts Processor (PROD)";

    const { popToast } = useToastAlertContext();

    const theme = useTheme();

    const loadComplete = () => {
        setLoaded(true);
    }

    setTimeout(loadComplete, 500);

    const load = useCallback(() => {
        const logdataBcp = loggingApi.getLogEntriesForAppId(appIdBcp).then((data) => {
            setLogEntriesBcp(data);
        })

        const promises = [logdataBcp];
        Promise.all(promises).catch((error: Error) => popToast(error.message, "error")).finally(loadComplete);
    }, [popToast]);

    React.useEffect(() => {
        load();
    }, [load])

    return (
        <PageWrapper title="Battery Contracts Processor" loaded={loaded}>
            <Grid container spacing={4}>
                <Grid xs={12}>
                    <Paper sx={{ p: 2 }}>
                        <BatteryContractsTable />
                    </Paper>
                </Grid>

                <Grid xs={12}>
                    <Paper sx={{ p: 2 }}>
                        <Typography variant="h6" color={theme.palette.primary.contrastText} flexGrow={1} textAlign="left" sx={{ width: "100%" }}>Battery Contract Processor Logs</Typography>
                        <LogDataGrid items={logEntriesBcp}></LogDataGrid>
                    </Paper>
                </Grid>
            </Grid>
        </PageWrapper>
    );
}

const BatteryContractsPageConfig: IPortalRouteOptions = {
    relativeUrl: "battery-contracts",
    page: <BatteryContractsPage />,
    navDisplay: {
        title: "Battery Contracts",
        icon: <Today />
    }
}

export interface IBatteryContractsPage { }

export default BatteryContractsPageConfig;