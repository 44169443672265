import { Box, Button, CircularProgress, Paper, TextField, Tooltip } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment, { Moment } from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import PageWrapper from "../../../../components/PageWrapper"
import { IAllContracts } from "../../../../shared/types/dynamic-contracts/IDynamicContract";
import contractListApi from "../../../../shared/api/PerfmonStatus";
import DynamicContractTable from "../../../../components/dynamic-contracts/DynamicContractTable";
import { CalendarToday, Description } from "@mui/icons-material";
import { useToastAlertContext } from "../../../../components/ToastAlert";
import { IPortalRouteOptions } from "../../../../shared/types/shared/routes/routeTypes";
import { isMobile } from "react-device-detect";

const DynamicContractsPage: React.FC = () => {
    const { popToast } = useToastAlertContext();

    const defaultStartTime = useMemo(() => moment.utc().subtract(1, "day").startOf("day"), []);
    const defaultEndTime = useMemo(() => moment.utc().endOf("day"), []);

    const [startDate, setStartDate] = useState<Moment>(defaultStartTime);
    const [endDate, setEndDate] = useState<Moment>(defaultEndTime);
    const [querying, setQuerying] = useState<boolean>(true);
    const [contracts, setContracts] = useState<IAllContracts>();
    const [loaded, setLoaded] = useState(false);
    const [queryError, setQueryError] = useState<string>("");

    const loadComplete = () => {
        setLoaded(true);
        setQuerying(false);
    }

    const validateDateRange = (startDate: Moment, endDate: Moment) => {
        if (startDate.isAfter(endDate)) {
            setQueryError("Start Date must be before End Date");
            return;
        }

        const millisecondDiff = endDate.diff(startDate);
        const daysDiff = moment.duration(millisecondDiff, 'milliseconds').asDays();

        if (daysDiff > 8) {
            setQueryError("Date range exceeds maximum of 7 days");
            return;
        }

        setQueryError("");
    }

    const updateStartDate = (Value: Moment | null) => {
        if (!Value) { return; }

        setStartDate(Value);
        validateDateRange(Value, endDate);
    }

    const updateEndDate = (Value: Moment | null) => {
        if (!Value) { return; }

        setEndDate(Value);
        validateDateRange(startDate, Value);
    }

    const queryRequest = useCallback((startDate: Moment, endDate: Moment) => {
        contractListApi.getContractList(startDate.toISOString(), endDate.toISOString())
            .then((response) => {
                setContracts({ allContracts: response });
            })
            .catch((error) => { popToast(`Failed to load file list: ${error}`, "error"); })
            .finally(loadComplete);
    }, [popToast]);

    const clickQueryRequestButton = () => {
        setQuerying(true);
        queryRequest(startDate, endDate);
    }

    useEffect(() => {
        queryRequest(defaultStartTime, defaultEndTime);
    }, [queryRequest, defaultStartTime, defaultEndTime]);

    return (
        <PageWrapper title="Dynamic Contracts" loaded={loaded}>
            <Paper>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <Grid
                        container
                        alignItems="center"
                        alignContent="start"
                        spacing={isMobile ? 1 : 2}
                        justifyContent="flex-start"
                        sx={{ p: 1 }}
                    >
                        <Grid xs={isMobile ? 6 : 2}>
                            <Box>
                                <DatePicker
                                    label="Start Date"
                                    inputFormat="DD/MM/YYYY"
                                    value={startDate}
                                    onChange={updateStartDate}
                                    disabled={querying}
                                    renderInput={(params) => <TextField {...params} fullWidth/>}
                                />
                            </Box>
                        </Grid>
                        <Grid xs={isMobile ? 6 : 2 }>
                            <Box>
                                <DatePicker
                                    label="End Date"
                                    inputFormat="DD/MM/YYYY"
                                    value={endDate}
                                    onChange={updateEndDate}
                                    disabled={querying}
                                    renderInput={(params) => <TextField {...params} fullWidth/>}
                                />
                            </Box>
                        </Grid>
                        <Grid xs={isMobile ? 12 : 2 }>
                            <Tooltip title={queryError}>
                                <Box sx={{ position: 'relative' }}>
                                    <Button
                                        variant="contained"
                                        startIcon={<CalendarToday />}
                                        disabled={querying || !!queryError}
                                        onClick={clickQueryRequestButton}
                                        sx={{ height: "55px"} }
                                        fullWidth
                                    >
                                        Gather Data
                                    </Button>
                                    {querying && (
                                        <CircularProgress
                                            size={24}
                                            sx={{
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                marginTop: '-12px',
                                                marginLeft: '-12px',
                                            }}
                                        />
                                    )}
                                </Box>
                            </Tooltip>
                        </Grid>
                    </Grid>
                    <DynamicContractTable contracts={contracts} />
                </LocalizationProvider>
            </Paper>
        </PageWrapper>
    )
}

const DynamicContractsPageConfig: IPortalRouteOptions = {
    relativeUrl: "dynamic-contracts",
    page: <DynamicContractsPage />,
    navDisplay: {
        title: "Dynamic Contracts",
        icon: <Description />
    }
}

export default DynamicContractsPageConfig;
