import { Search } from "@mui/icons-material";
import { Button, Stack, TextField } from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import * as yup from "yup";
import LoadingSpinner from "../LoadingSpinner";

const OpportunitySearchForm: React.FC<IOpportunitiesSearchFormProps> = ({ onSearchData, searchCriteria, isSearching }) => {
    const validatioSchema = yup.object({
        searchCriteria: yup
            .string()
            .min(3, "Search criteria must be at least 3 characters")
            .required("Search criteria is required")
    });

    const formik = useFormik({
        initialValues: {
            searchCriteria: searchCriteria
        },
        validationSchema: validatioSchema,
        validateOnChange: true,
        validateOnMount: true,
        onSubmit: (values) => {
            onSearchData(values.searchCriteria);
        }
    });

    return (
        <form onKeyDown={formik.handleBlur} onSubmit={formik.handleSubmit}>
            <Stack direction="row" spacing={3} paddingTop={2}>

                <TextField
                    id="searchCriteria" value={formik.values.searchCriteria}
                    onChange={formik.handleChange}
                    label="Search criteria"
                    variant="outlined"
                    type="text"
                    error={formik.touched.searchCriteria && Boolean(formik.errors.searchCriteria)} helperText={formik.touched.searchCriteria && formik.errors.searchCriteria}
                    sx={{ minWidth: 400 }}
                />

                {isSearching && 
                    <LoadingSpinner />    
                }

                {!isSearching &&
                    <Button
                        disabled={!formik.isValid}
                        variant="contained"
                        startIcon={<Search />}
                        type="submit"
                    >
                        SEARCH
                    </Button>
                }
            </Stack>
        </form>
    );
};

export default OpportunitySearchForm;

export interface IOpportunitiesSearchFormProps {
    searchCriteria: string;
    onSearchData: (searchCriteria: string) => void;
    isSearching: boolean;
}