export const NewColorPalette = [
    "#31fe64",
    "#00f199",
    "#00e0d1",
    "#00ccff",
    "#00b6ff",
    "#009bff",
    "#0077ff",
    "#3942ff",
    "#c700de",
    "#ff00b1",
    "#ff0082",
    "#ff4855",
    "#ff8728",
    "#ffb900",
    "#fce300",
]