import React, { useEffect, useMemo, useState } from "react";
import PageWrapper from "../../components/PageWrapper";
import { Box, Button, CircularProgress, Paper, TextField, Tooltip, Typography } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { Dictionary } from "@reduxjs/toolkit";
import EngineOutageTable from "../../components/operate/EngineOutageTable";
import { useGetLiveAssetsQuery } from "../../shared/api/AssetApi";
import { IEngineOutage } from "../../shared/types/operate/IEngineOutage";
import availabilityApi from "../../shared/api/AvailabilityApi";
import { IPortalRouteOptions } from "../../shared/types/shared/routes/routeTypes";
import { EventAvailable, Search } from "@mui/icons-material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment, { Moment } from "moment";
import { isBrowser } from "react-device-detect";

const HistoricEngineOutagesPage: React.FC<IHistoricEngineOutagesPage> = () => {
    const defaultStartTime = useMemo(() => moment.utc().startOf("hour").add(-6, "months"), []);
    const defaultEndTime = useMemo(() => moment.utc().startOf("hour"), []);
    const [startTime, setStartTime] = useState<Moment>(defaultStartTime);
    const [endTime, setEndTime] = useState<Moment>(defaultEndTime);
    const [searchContents, setSearchContents] = React.useState<string>("");
    const [searching, setSearching] = useState<boolean>(false)
    const [tooltipError, setTooltipError] = useState<string>("")
    const [outages, setOutages] = useState<IEngineOutage[]>([])
    const [filteredOutages, setFilteredOutages] = useState<IEngineOutage[]>([])
    const [siteIdToName, setSiteIdToName] = useState<Dictionary<string>>({})
    const { data: assets } = useGetLiveAssetsQuery();
    const buttonFontSize = 30

    const fetchPageData = () => {
        if (assets !== undefined) {
            var dictName: Dictionary<string> = {}
            assets?.forEach((item) => {
                dictName[item.SiteID] = item.Name
            });
            setSiteIdToName(dictName);
        }
    }

    useEffect(() => {
        fetchPageData()
    }, [assets])

    useEffect(() => {
        if (startTime > endTime) {
            setTooltipError("Start time must be before end time")
        }
        else if (endTime > defaultEndTime) {
            setTooltipError("End time cannot be later than current time ")
        }
        else {
            setTooltipError("")
        }
    }, [startTime, endTime])

    const filterOutage = (outage: IEngineOutage) => {
        const searchTerms = searchContents.toLowerCase().split(" ");
        if (searchContents === "" || searchTerms.length === 0) { return true }
        const combinedOutageTerms = outage.siteID + " " + outage.engineID + " " + outage.remitID + " " + siteIdToName[outage.siteID] + " " + outage.comments
        const outageTerms = combinedOutageTerms.toLowerCase().split(" ")
        for(const outageTerm of outageTerms) {
            for(const searchTerm of searchTerms) {
                if (outageTerm.startsWith(searchTerm)) {
                    return true
                }
            }
        }
        return false
    }

    useEffect(() => {
        setFilteredOutages(outages.filter(outage => filterOutage(outage)))
        setSearching(false)
    }, [outages])

    const handleStartTimeChange = (newValue: Moment | null) => {
        if (!newValue) { return; }
        setStartTime(newValue)
    }

    const handleEndTimeChange = (newValue: Moment | null) => {
        if (!newValue) { return; }
        setEndTime(newValue)
    }

    const handleSearchContentsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchContents(event.target.value as string)
    }

    const clickSearch = () => {
        setSearching(true)
        var getOutageData = availabilityApi.getOutageByRequest(moment(startTime).utc().format(), moment(endTime).utc().format(), ['All'], "0")
            .then(response => {
                setOutages(response);
            });

        const promises = [
            getOutageData
        ]

        return Promise.all(promises)
    }

    return (
        <PageWrapper title="View Historic Outages" loaded={true}>
            <Typography>'Historic Outages' includes all outages whose 'Outage End' timestamp is in the past.</Typography>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <Grid container
                    spacing={2}>
                    <Grid xs={12}>

                        <Paper sx={{ p: 1 }}>
                            <Grid container
                                direction="row"
                                justifyContent="center"
                                alignItems="stretch"
                                spacing={2}>
                                <Grid xs={9.5}>
                                    <Grid
                                        container
                                        spacing={2}
                                        columns={12}
                                    >
                                        <Grid xs={12}>
                                            <TextField
                                                id="search-label"
                                                label="Enter search terms"
                                                value={searchContents}
                                                onChange={handleSearchContentsChange}
                                                name="Search"
                                                placeholder="Enter search terms"
                                                sx={{ marginBottom: 1 }}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid xs={6}>
                                            <Box>
                                                <DateTimePicker
                                                    label="Outage Start (UTC)"
                                                    inputFormat="DD-MMM-YY HH:mm"
                                                    disableMaskedInput
                                                    value={startTime}
                                                    onChange={handleStartTimeChange}
                                                    disabled={searching}
                                                    renderInput={(params: any) => <TextField {...params} fullWidth />}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid xs={6}>
                                            <Box>
                                                <DateTimePicker
                                                    label="Outage End (UTC)"
                                                    inputFormat="DD-MMM-YY HH:mm"
                                                    disableMaskedInput
                                                    value={endTime}
                                                    onChange={handleEndTimeChange}
                                                    disabled={searching}
                                                    renderInput={(params: any) => <TextField {...params} fullWidth />}
                                                />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid xs={2.5} sx={{ minHeight: "100%" }}>
                                    <Tooltip title={tooltipError}>
                                        <Box
                                            sx={{ height: "100%" }}>
                                            <Button
                                                variant="contained"
                                                disabled={searching || !!tooltipError}
                                                onClick={clickSearch}
                                                fullWidth
                                                sx={{ height: "100%", fontSize: buttonFontSize }}
                                            >
                                                {searching ? <CircularProgress
                                                    size={buttonFontSize}
                                                /> : isBrowser ?
                                                    <><Search fontSize="inherit" /> Search</>
                                                    : <Search fontSize="inherit" />
                                                }
                                            </Button>
                                        </Box>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        </Paper> 
                    </Grid>
                    <Grid xs={12}>
                        <Paper sx={{ p: 1 }}>
                            <EngineOutageTable items={filteredOutages} siteIdToName={siteIdToName}></EngineOutageTable>
                        </Paper>
                    </Grid>
                </Grid>
            </LocalizationProvider>
        </PageWrapper>
  );
}

export interface IHistoricEngineOutagesPage {

}

const HistoricEngineOutagesPageConfig: IPortalRouteOptions = {
    relativeUrl: "historic-outages",
    page: <HistoricEngineOutagesPage />,
    navDisplay: {
        title: "View Historic Outages",
        icon: <EventAvailable />
    },
}

export default HistoricEngineOutagesPageConfig;