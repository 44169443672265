import { Autocomplete, Box, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { IUserGroup } from "../../../shared/types/shared/auth/userDataTypes";

const PermissionsNewGroupForm: React.FC<IPermissionsNewUserFormProps> = ({ existingGroups, allGroups, formUpdated, validityChanged }) => {
    const [group, setGroup] = useState<string>("");
    const options = allGroups.filter((g) => !existingGroups.includes(g.displayName)).map((g) => g.displayName);

    useEffect(() => {
        validityChanged(!!group);
    }, [group, validityChanged]);

    const updateGroup = (value?: string | null) => {
        setGroup(value || "");
        formUpdated(value || "");
    }

    return (
        <Box paddingTop={1}>
            <Autocomplete
                options={options}
                renderInput={(params) => <TextField {...params} label="Group" variant="outlined" />}
                fullWidth
                onChange={(e, newValue) => updateGroup(newValue)}               
            />
        </ Box>
    );
}

export default PermissionsNewGroupForm;

interface IPermissionsNewUserFormProps {
    existingGroups: string[];
    allGroups: IUserGroup[];
    formUpdated: (userId: string) => void;
    validityChanged: (valid: boolean) => void;
}
