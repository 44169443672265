import { ViewModule } from "@mui/icons-material";
import { Box, CircularProgress, Paper, Typography } from "@mui/material";
import moment, { Moment } from "moment";
import React, { useEffect, useState } from "react";
import PageWrapper from "../../../components/PageWrapper";
import contractListApi from "../../../shared/api/PerfmonStatus";
import { IAuctionProduct, IPeriodPerformanceTooltipData, IStackedService } from "../../../shared/types/dynamic-contracts/IStackedService";
import { IPortalRouteOptions } from "../../../shared/types/shared/routes/routeTypes";
import ServicesRollup from "../../../components/optimise/dynamic-services/ServicesRollup";
import { Dictionary } from "@reduxjs/toolkit";
import SiteEFABlocks from "../../../components/optimise/dynamic-services/SiteEFABlocks";
import MouseTracker from "../../../components/shared/MouseTracker";
import AuctionProductTooltip from "../../../components/optimise/dynamic-services/AuctionProductTooltip";
import SPDataTooltip from "../../../components/optimise/dynamic-services/SPDataTooltip";
import DynamicControlBar from "../../../components/optimise/dynamic-services/DynamicControlBar";
import { BrowserView, isMobile } from "react-device-detect";


const DynamicServicesPage: React.FC<IDynamicServicesPage> = () => {
    const [allDayData, setAllDayData] = useState<Map<number, Map<string, IStackedService>>>(new Map<number, Map<string, IStackedService>>())
    const [idToName, setIdToName] = useState<Dictionary<string>>({})
    const [selectedAuctionProduct, setSelectedAuctionProduct] = useState<IAuctionProduct | null>(null)
    const [selectedSPTooltipData, setSelectedSPTooltipData] = useState<IPeriodPerformanceTooltipData | null>(null)
    const [selectedDate, setSelectedDate] = useState<Moment>(moment.utc().startOf("day"))
    const [querying, setQuerying] = useState<boolean>(false)
    const siteWidth = isMobile ? 75 : 100
    const blockWidth = isMobile ? 350 : 600
    const blockPadding = 1

    useEffect(() => {
        setQuerying(true)
        contractListApi.getStackedServices(selectedDate.clone().subtract(2, "day").format("YYYY-MM-DD"), selectedDate.clone().add(2, "day").format("YYYY-MM-DD"))
            .then((response) => {

                const currentDate = selectedDate.format("YYYY-MM-DD")
                const allDayDict = new Map<number, Map<string, IStackedService>>()
                const idToNameDict: Dictionary<string> = {}
                response.forEach((stackedServiceResponse) => {
                    const stackDate = moment(stackedServiceResponse.contractPeriod.efaDateOnly).format("YYYY-MM-DD")
                    const stackBlock = stackedServiceResponse.contractPeriod.efa
                    const stackID = stackedServiceResponse.contractPeriod.unitID
                    const stackUnitName = stackedServiceResponse.contractPeriod.unitName
                    if (stackDate === currentDate) {
                        let existingBlock = allDayDict.get(stackBlock) || new Map<string, IStackedService>()
                        existingBlock.set(stackID, stackedServiceResponse)
                        allDayDict.set(stackBlock, existingBlock)
                    }
                    idToNameDict[stackID] = stackUnitName
                })
                setAllDayData(allDayDict)
                setIdToName(idToNameDict)
            })
            .finally(() => {
                setQuerying(false)
            })
    }, [selectedDate])

    return (
        <PageWrapper title="Dynamic Services" loaded={true} contentOverflow="hidden">
            <MouseTracker offset={{ x: -220, y: -220 }}>
                <AuctionProductTooltip auctionProduct={selectedAuctionProduct} />
            </MouseTracker>
            <MouseTracker offset={{ x: -220, y: -220 }}>
                <SPDataTooltip data={selectedSPTooltipData} />
            </MouseTracker>
            <Paper
                sx={{ padding: "10px", height: "calc(100% - 1px)" }}
                onTouchStart={() => {
                    setSelectedAuctionProduct(null)
                    setSelectedSPTooltipData(null)
                }}
            >
                <DynamicControlBar querying={querying} selectedDate={selectedDate} setSelectedDate={setSelectedDate} />
                <Box sx={{ overflowX: "auto", overflowY: "auto", height: "calc(100% - 55px)" }}>
                        { querying ? 
                            <CircularProgress
                                size={24}
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: '-12px',
                                    marginLeft: '-12px',
                                }}
                            /> :
                            <>
                                <Box sx={{ width: 6 * blockWidth + siteWidth }}>
                                    <Box sx={{ display: "inline-block", width: siteWidth }} />
                                    {Array.from(allDayData.keys()).sort().map((blockNumber) =>
                                        <Box sx={{ width: blockWidth, display: "inline-block", p: blockPadding }}>
                                            <Typography variant="h5" align="center">EFA Block {blockNumber}</Typography>
                                        </Box>
                                    )}
                                </Box>
                                <BrowserView>
                                    <Box sx={{ width: 6 * blockWidth + siteWidth }}>
                                        <Box sx={{ display: "inline-block", width: siteWidth }} />
                                        {Array.from(allDayData.keys()).sort().map((blockNumber) =>
                                            <Box sx={{ width: blockWidth, display: "inline-block", p: blockPadding }}>
                                                <ServicesRollup currentBlocks={allDayData.get(blockNumber)!} />
                                            </Box>
                                        )}
                                    </Box>
                                </BrowserView>
                                {Object.keys(idToName).sort().map((id) => {
                                    const siteName = idToName[id] || ""
                                    return (
                                        <Box sx={{ width: 6 * blockWidth + siteWidth }}>
                                            <Box sx={{ display: "inline-block", width: siteWidth }}>
                                                <Typography>{isMobile ? id : siteName}</Typography>
                                            </Box>
                                            {Array.from(allDayData.keys()).sort().map((blockNumber) =>
                                                <Box sx={{ width: blockWidth, display: "inline-block", p: blockPadding }}>
                                                    <SiteEFABlocks
                                                        key={id}
                                                        id={id}
                                                        currentBlock={allDayData.get(blockNumber)!.get(id) || null}
                                                        siteName={siteName}
                                                        setSelectedAuctionProduct={setSelectedAuctionProduct}
                                                        setSelectedSPRollupData={setSelectedSPTooltipData}
                                                    />
                                                </Box>
                                            )}
                                        </Box>
                                    )}
                                )}
                            </>
                        }
                </Box>
            </Paper>
        </PageWrapper>
    );
}

export interface IDynamicServicesPage {

}

const DynamicServicesPageConfig: IPortalRouteOptions = {
    relativeUrl: "dynamic-services",
    page: <DynamicServicesPage />,
    navDisplay: {
        title: "Dynamic Services",
        icon: <ViewModule />
    }
}

export default DynamicServicesPageConfig;
