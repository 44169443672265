import { Box, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import NotificationsTable from "../../components/notifications/tables/NotificationsTable";
import PageWrapper from "../../components/PageWrapper";
import { useGetAssetsQuery } from "../../shared/api/AssetApi";
import { IPushNotification } from "../../shared/types/notification/IPushNotification";
import { IPortalRouteOptions } from "../../shared/types/shared/routes/routeTypes";
import { Dictionary } from "@reduxjs/toolkit";
import NotificationManagementOptions from "../../components/notifications/NotificationManagementOptions";
import useNotificationMenu from "../../shared/hooks/NotificationMenuHook";
import { EditNotifications } from "@mui/icons-material";

const NotificationManagementPage: React.FC<INotificationManagementPage> = () => {
    const [siteIdToName, setSiteIdToName] = useState<Dictionary<string>>({})
    const { data: assets, isSuccess } = useGetAssetsQuery();
    const { notifications, massMarkNotificationAsRead, massDelete } = useNotificationMenu()
    const [filteredNotifications, setFilteredNotifications] = useState<IPushNotification[]>([])
    const [reapplyFilter, setReapplyFilter] = useState<boolean>(false)

    const fetchPageData = () => {
        if (assets !== undefined) {
            var dictName: Dictionary<string> = {}
            assets?.forEach((item) => {
                dictName[item.SiteID] = item.Name
            });
            setSiteIdToName(dictName)
        }
    }

    useEffect(() => {
        fetchPageData()
    }, [assets])

    
    useEffect(() => {
        setFilteredNotifications(notifications)
        setReapplyFilter(!reapplyFilter)
    }, [notifications])
    
    const clickMarkAsRead = () => {
        const selectedIDs = new Array<string>()
        filteredNotifications.forEach((notif) => {
            selectedIDs.push(notif.notificationID)
        })
        massMarkNotificationAsRead(selectedIDs)
    }

    const clickDelete = () => {
        const selectedIDs = new Array<string>()
        filteredNotifications.forEach((notif) => {
            selectedIDs.push(notif.notificationID)
        })
        massDelete(selectedIDs)
    }


    return (
        <PageWrapper title="Notification Management" loaded={isSuccess} displayBreadcrumbs={false}>
            <Paper sx={{ height: "calc(100% - 1px)" }}>
                <Box sx={{ m: 1, height: "100%" }}>
                    <NotificationManagementOptions allNotifications={notifications} setFilteredNotifications={setFilteredNotifications} siteIdToName={siteIdToName} markAsReadFunction={clickMarkAsRead} massDeleteFunction={clickDelete} reapplyFilter={reapplyFilter} />
                    <NotificationsTable items={filteredNotifications} siteIdToName={siteIdToName} searchBar={false} showDeletedNotifs={false} height="650px" />
                </Box>
            </Paper>
        </PageWrapper>
    );
}

export interface INotificationManagementPage {

}

const NotificationManagementPageConfig: IPortalRouteOptions = {
    relativeUrl: "notification-manager",
    page: <NotificationManagementPage />,
    navDisplay: {
        title: "Notification Manager",
        icon: <EditNotifications />,
    }
}

export default NotificationManagementPageConfig;