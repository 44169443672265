export const reasonsDictionary: IReasonsDictionary  = {
    "Ambient Temperature": ["Power De-rate",
        "Container Ambient",
        "Mixture Temperature",
        "Oil Temperature High",
        "Water Temperature High"],
    "Controls and Sensors": ["Engine Sensors",
        "Communication",
        "Engine Screens",
        "PLC controls",
        "MMD",
        "Control Panel",
        "Software"],
    "Cooling System": ["HT Pressure",
        "LT Pressure",
        "Radiators",
        "Heat Exchanger",
        "HT Temperature",
        "LT Tmperature",
        "Water Bellows",
        "Coolant Leaks"],
    "Electrical": ["Motors",
        "Breakers",
        "Variable Speed Drive",
        "Pumps",
        "Fans",
        "Contactors",
        "Wiring",
        "Starter Motors",
        "Battery Charger",
        "Relays",
        "Engine Loom",
        "Engine Batteries",
        "Control Panel Batteries",
        "Heaters"],
    "Fuel Supply": ["PCC Gas Compressor",
        "Gas Train",
        "Primary Gas Skid",
        "Zero Pressure Regulator",
        "Gas Supply Pressure"],
    "High Voltage": ["Switch Gear",
        "Cabling",
        "DNO",
        "G99 Protection",
        "Transformer",
        "Alternator"],
    "Ignition": ["Spark plugs ",
        "Ignition module",
        "Transformers",
        "Candle / HT Lead"],
    "Mechanical": ["Air Fuel Control",
        "Oil Cooler",
        "Valve Lash (Maintenance) ",
        "Turbo",
        "Turbo Bypass",
        "Aftercooler",
        "Power Pack",
        "Oil Filters",
        "Air Filters",
        "Compensators / Bellows",
        "Oil Leaks",
        "Seal / Gasket Failures",
        "Throttle Actuator",
        "Exhaust",
        "Mechanical Failure"],
    "Safety Chain": ["Fire alarm",
        "Slam Shut",
        "Gas Alarm",
        "E Stop"],
    "General Servicing": ["Servicing"],
    "Other Trading Desk": ["Out of Hours Trip",
        "Engineer not able to reach site",
        "Commercial Restriction"],
    "Battery": [
        "Battery failure",
        "BMS failure",
        "Comms failure",
        "DNO/DERMS outage",
        "Electrical anomaly detected",
        "Estop triggered",
        "Fire",
        "Flooding",
        "G99 protection",
        "Inverter failure",
        "Planned Maintenance",
        "RMU failure",
        "Switchgear failure",
        "Temperature anomaly detected",
        "Transformer failure",
        "Other"
    ],
    "Solar": [
        "Comms Failure",
        "DNO/DERMS outage",
        "Inverter Failure",
        "Panel Failure",
        "Planned Maintenance",
        "Temperature Anomaly",
        "Other"
    ],
    "Wind": [
        "Comms Failure",
        "Dangerous weather conditions",
        "DNO/DERMS outage",
        "Planned Maintenance",
        "Turbine Failure",
        "Other"
    ]
}

interface IReasonsDictionary {
    [key: string]: string[]
}