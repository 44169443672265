import React from 'react';
import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import { IAssetSubscription } from "../../../shared/types/notification/IAdminNotificationSubscription";
import { Dictionary } from "@reduxjs/toolkit";

const headCells: HeadCell[] = [
    {
        id: "recipient",
        label: "User"
    },
    {
        id: "name",
        label: "Notification"
    },
    {
        id: "subscribed",
        label: "Subscribed"
    },

    {
        id: "asset",
        label: "Asset"
    },
]

const SelfServeNotificationTableAsset: React.FC<ISelfServeNotificationTableAsset> = ({ items, siteIdToName, searchBar }) => {
    const [orderDescending, setOrderDescending] = React.useState<boolean>(false)
    const [orderCategory, setOrderCategory] = React.useState<keyof IAssetSubscription>("recipient")
    const [searchContents, setSearchContents] = React.useState<string>("")

    const sortItems = (inputArray: IAssetSubscription[], category: keyof IAssetSubscription, descending: boolean) => {
        const sortedArray = [...inputArray];
        sortedArray.sort((a, b) => {
            if (a[category] < b[category]) { return -1 }
            return 1
        })
        if (descending) {
            sortedArray.reverse()
        }
        return sortedArray
    }

    if (items === undefined) {
        items = []
    }

    const [internalItems, setInternalItems] = React.useState<IAssetSubscription[]>(sortItems(items, orderCategory, orderDescending))

    const handleSearchContentsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchContents(event.target.value as string)
    }

    const handleSort = (categoryID: keyof IAssetSubscription) => {
        let descending = false
        if (orderCategory === categoryID && !orderDescending) {
            descending = true
        }
        setOrderDescending(descending)
        setOrderCategory(categoryID)
        setInternalItems(sortItems(internalItems, categoryID, descending))
    }

    const filterOutage = (subscription: IAssetSubscription) => {
        return  subscription.recipient.toLowerCase().startsWith(searchContents.toLowerCase()) ||
                subscription.name.toLowerCase().startsWith(searchContents.toLowerCase()) ||
                subscription.asset.toLowerCase().startsWith(searchContents.toLowerCase()) ||
                siteIdToName[subscription.asset]?.toLowerCase().startsWith(searchContents.toLowerCase())
    }

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            {(searchBar ? <TextField
                id="search-label"
                label="Search"
                value={searchContents}
                onChange={handleSearchContentsChange}
                name="Search"
                placeholder="Search"
                fullWidth
            /> : <></>)}
            <TableContainer sx={{ maxHeight: 440, mt: 2 }}>
                <Table stickyHeader size="small" aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {headCells.map((headCell) => (
                                <TableCell
                                    align="center"
                                    key={headCell.id}
                                >
                                    <IconButton
                                        size="small"
                                        sx={{ "transition": "all .2s", "color": "inherit", "&:hover": { "backgroundColor": "rgba(160, 160, 160, 0.5)" } }}
                                        onClick={() => handleSort(headCell.id)}
                                    >
                                        <Typography variant="subtitle1" fontSize="small">{headCell.label}</Typography>
                                        {headCell.id === orderCategory ? (orderDescending ? < ArrowDownward fontSize="small" /> : <ArrowUpward fontSize="small" />) : <></>}
                                    </IconButton>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            internalItems
                                .filter((item) => filterOutage(item))
                                .map((item) => {
                                    return (
                                        <TableRow>
                                            <TableCell align="center">{item.recipient}</TableCell>
                                            <TableCell align="center">{item.name}</TableCell>
                                            <TableCell align="center" sx={{ backgroundColor: (item.subscribed ? "#00FF00" : "#FF0000") }}>{(item.subscribed ? "Opt In" : "Opt Out")}</TableCell>
                                            <TableCell align="center">{item.asset}</TableCell>
                                        </TableRow>
                                    )
                                })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );

}
interface HeadCell {
    id: keyof IAssetSubscription,
    label: string
}
export interface ISelfServeNotificationTableAsset {
    items: IAssetSubscription[],
    siteIdToName: Dictionary<string>,
    searchBar?: boolean
}

export default SelfServeNotificationTableAsset;