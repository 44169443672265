import React, { useEffect } from 'react';
import { Button, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Typography } from "@mui/material";
import { ArrowDownward, ArrowUpward, Search } from "@mui/icons-material";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import moment from "moment";
import { useTheme } from "@mui/material";
import { IBOAData } from "../../shared/types/data/IBOAData";

const headCells: HeadCell[] = [
    {
        id: "settlementDate",
        label: "Settlement Date",
        date: true
    },

    {
        id: "settlementPeriodFrom",
        label: "SP From"
    },
    {
        id: "settlementPeriodTo",
        label: "SP To"
    },
    {
        id: "timeFrom",
        label: "Time From",
        dateTime: true
    },
    {
        id: "timeTo",
        label: "Time To",
        dateTime: true
    },
    {
        id: "levelFrom",
        label: "Level From"
    },
    {
        id: "levelTo",
        label: "Level To"
    },
    {
        id: "acceptanceNumber",
        label: "Acceptance Number"
    },
    {
        id: "acceptanceTime",
        label: "Acceptance Time",
        dateTime: true
    },
    {
        id: "deemedBoFlag",
        label: "Deemed BO Flag",
        bool: true
    },
    {
        id: "soFlag",
        label: "SO Flag",
        bool: true
    },
    {
        id: "amendmentFlag",
        label: "Amendment Flag"
    },
    {
        id: "storFlag",
        label: "STOR Flag",
        bool: true
    },
    {
        id: "rrFlag",
        label: "RR Flag",
        bool: true
    },
    {
        id: "nationalGridBmUnit",
        label: "National Grid BM Unit ID"
    },
    {
        id: "bmUnit",
        label: "BM Unit ID"
    }
]

const BOADataTable: React.FC<IBOADataTable> = ({ items, searchBar, tablePagination = false, conradBMUIDs }) => {
    const [orderDescending, setOrderDescending] = React.useState<boolean>(true);
    const [orderCategory, setOrderCategory] = React.useState<keyof IBOAData>("acceptanceTime");
    const [searchContents, setSearchContents] = React.useState<string>("");
    const theme = useTheme()

    const [pg, setpg] = React.useState<number>(0);
    const [rpg, setrpg] = React.useState<number>(50);

    function handleChangePage(event: any, newpage: number) {
        setpg(newpage);
    }

    function handleChangeRowsPerPage(event: any) {
        setrpg(parseInt(event.target.value, 10));
        setpg(0);
    } 

    const sortItems = (inputArray: IBOAData[], category: keyof IBOAData, descending: boolean) => {
        const outputArray = [...inputArray]
        outputArray.sort((a, b) => {
            const aValue = a[category]
            const bValue = b[category]
            if (aValue === null) { return 1 }
            else if (bValue === null) { return -1 }
            else if (aValue < bValue) { return -1 }
            return 1
        })
        if (descending) {
            outputArray.reverse()
        }
        return outputArray
    }

    useEffect(() => {
        setInternalItems(sortItems(items, orderCategory, orderDescending))
    }, [items])

    if (items === undefined) {
        items = []
    }

    const [internalItems, setInternalItems] = React.useState<IBOAData[]>(sortItems(items, orderCategory, orderDescending))

    const handleSearchContentsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchContents(event.target.value as string)
    }

    const handleSort = (categoryID: keyof IBOAData) => {
        let descending = false
        if (orderCategory === categoryID && !orderDescending) {
            descending = true
        }
        setOrderDescending(descending)
        setOrderCategory(categoryID)
        setInternalItems(sortItems(internalItems, categoryID, descending))
    }

    const filterOutage = (boa: IBOAData) => {
        const lowerSearchContents = searchContents.toLowerCase()
        return (
            boa.bmUnit.toLowerCase().startsWith(lowerSearchContents) ||
            boa.nationalGridBmUnit.toLowerCase().startsWith(lowerSearchContents) ||
            boa.amendmentFlag.toLowerCase().startsWith(lowerSearchContents) ||
            boa.acceptanceNumber.toString().startsWith(lowerSearchContents)
        )
    }

    const search = () => {
        setInternalItems(sortItems(items.filter((item) => filterOutage(item)), orderCategory, orderDescending))
    }

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            {(searchBar ?
                <Grid container
                    alignItems="center"
                    alignContent="start"
                    spacing={1}
                    justifyContent="flex-start"
                    sx={{marginBottom: 1}}
                >
                        <Grid xs={10}>
                            <TextField
                                id="search-label"
                                label="Search"
                                value={searchContents}
                                onChange={handleSearchContentsChange}
                                name="Search"
                                placeholder="Search"
                                fullWidth
                            />
                    </Grid>
                    <Grid xs={2}>
                        <Button
                            variant="contained"
                            startIcon={<Search />}
                            disabled={false}
                            onClick={search}
                            size="large"
                            sx={{ height: "55px" }}
                            fullWidth
                        >
                            Search
                        </Button>
                    </Grid>
                    </Grid>
                 : <></>)}
            <TableContainer sx={{ maxHeight: 570 }}>
                <Table stickyHeader size="small" aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {headCells.map((headCell) => (
                                <TableCell
                                    align="center"
                                    key={headCell.id}
                                >
                                    <IconButton
                                        size="small"
                                        sx={{ "transition": "all .2s", "color": "inherit", "&:hover": { "backgroundColor": "rgba(160, 160, 160, 0.5)" } }}
                                        onClick={() => handleSort(headCell.id)}
                                    >
                                        <Typography variant="subtitle1" fontSize="small">{headCell.label}</Typography>
                                        {headCell.id === orderCategory ? (orderDescending ?  < ArrowDownward fontSize="small" /> : <ArrowUpward fontSize="small" />) : <></>}
                                    </IconButton>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {internalItems
                                .slice(pg * rpg, tablePagination ? pg * rpg + rpg : internalItems.length)
                                .map((item) => {
                                    const textColor = item.inputTime && moment(item.inputTime).isSameOrAfter(moment().subtract(30, "seconds")) ? "black" : "white"
                                    const rowKey = "BOA-table-key-" + item.nationalGridBmUnit + "-" + item.acceptanceTime + "-" + item.acceptanceNumber + "-" + item.timeFrom + "-" + item.timeTo + "-" + item.bmUnit
                                    return (
                                        <TableRow
                                            sx={{
                                                backgroundColor: conradBMUIDs.has(item.nationalGridBmUnit) ?
                                                    theme.palette.conradEnergyFreshGreen.dark
                                                    :
                                                    item.inputTime && moment(item.inputTime).isSameOrAfter(moment().subtract(30, "seconds")) ?
                                                        theme.palette.conradEnergyYellow.main
                                                        :
                                                        "default"
                                            }}
                                            key={rowKey}>
                                        {
                                                headCells.map((headCell) => 
                                                (headCell.bool ?
                                                    <TableCell key={rowKey+"-"+headCell.id} align="center" sx={{
                                                        backgroundColor: item[headCell.id] ? theme.palette.success.dark : theme.palette.error.dark,
                                                        color: textColor
                                                    }}>{item[headCell.id] ? "True" : "False"}</TableCell> :
                                                    <TableCell key={rowKey + "-" + headCell.id} align="center" sx={{
                                                        color: textColor
                                                    } }>{headCell.dateTime ?
                                                        moment(item[headCell.id] as string).format("DD-MMM-YY HH:mm:ss")
                                                        :
                                                        headCell.date ? 
                                                            moment(item[headCell.id] as string).format("DD-MMM-YY")
                                                            :
                                                            item[headCell.id]
                                                    }</TableCell>
                                                )
                                            )
                                        }
                                    </TableRow>
                                )})
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            {tablePagination &&
                <TablePagination
                    rowsPerPageOptions={[10, 20, 50, 100]}
                    component="div"
                    count={internalItems.length}
                    rowsPerPage={rpg}
                    page={pg}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            }
        </Paper>
    );

}
interface HeadCell {
    id: keyof IBOAData,
    label: string,
    date?: boolean,
    dateTime?: boolean,
    bool?: boolean
}
export interface IBOADataTable {
    items: IBOAData[]
    searchBar?: boolean
    tablePagination?: boolean
    conradBMUIDs: Set<string>
}

export default BOADataTable;