import { HexagonMouseEventHandler } from "react-hexgrid/lib/Hexagon/Hexagon";
import { CubicCoords } from "../shared/hex-grid";
import { EngineStatusHiveTile } from "../../../components/operate/engine-status-hive/EngineStatusHiveTile";
import { IEngineStatus } from "../ISiteEngineResponse";

export class EngineHiveDataTile {
    constructor(position: CubicCoords, status: EngineHiveStatusData) {
        this._position = position;
        this._status = status;
    }

    private _position: CubicCoords;
    private _status: EngineHiveStatusData;

    public readonly updateTileData = (newData: EngineHiveDataTile) => {
        this._status = newData._status;
        this._position = newData._position;
    }

    private readonly onClick: HexagonMouseEventHandler<SVGElement> = (event, source) => {
        
    }

    public readonly graphic = (): JSX.Element => {
        return (
            <EngineStatusHiveTile
                key={this._status.key}
                coords={this._position}
                status={this._status}
                onClick={(e, h) => this.onClick(e, h)}
            />
        );
    }
}

export type EngineHiveStatusData = IEngineStatus & {
    key: string;
    siteName: string;
    priority: number;
    colour: string;
}

export enum DispatchPlan {
    Current,
    Upcoming,
    None,
}

export interface IEngineHivePlacementResult {
    placed?: EngineHiveDataTile[];
    failed?: EngineHiveStatusData[];
}

export type EngineStatusCollection = { [key in DispatchPlan]: EngineHiveStatusData[] };

export type EngineStatusPlacementCollection = { [key in DispatchPlan]: IEngineHivePlacementResult };
