import { Table, TableBody, TableCell, TableHead, Typography } from "@mui/material";
import SearchOffIcon from '@mui/icons-material/SearchOff';

const DynamicContractsGanttEmpty: React.FC<IDynamicContractsGanttEmptyProps> = () => {
    return (
        <Table>
            <TableHead>
                <TableCell />
            </TableHead>
            <TableBody>
                <TableCell align="center">
                    <SearchOffIcon fontSize="large" />
                    <br />
                    <Typography variant="h6">No Data Available</Typography>
                </TableCell>
            </TableBody>
        </Table>
    );
}

export interface IDynamicContractsGanttEmptyProps {
    
}

export default DynamicContractsGanttEmpty;
