import { useTheme } from '@mui/material/styles';
import React, { useEffect } from 'react';
import { Area, AreaChart, Label, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { ISchedulerPosition } from '../../shared/types/ISchedulerPosition';
import { GetChartColours } from '../../shared/utils/RechartsGraphUtils';
const SchedulerDataChart: React.FC<ISchedulerDataChart> = ({ data }) => {
    const theme = useTheme();
    const [chartColors, setChartColors] = React.useState<string[]>([]);


    useEffect(() => {
        setChartColors(GetChartColours)
    }, [])

    return (
        <ResponsiveContainer width="100%" height="100%">
            <AreaChart data={data} margin={{ left: 5, bottom: 5, top: 15, right: 20 }}>
                <defs>
                    <linearGradient id="colorTrade" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor={chartColors[0]} stopOpacity={0.2} />
                        <stop offset="95%" stopColor={chartColors[0]} stopOpacity={0} />
                    </linearGradient>
                    <linearGradient id="colorAvailability" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor={chartColors[1]} stopOpacity={0.2} />
                        <stop offset="95%" stopColor={chartColors[1]} stopOpacity={0} />
                    </linearGradient>
                    <linearGradient id="colorIon" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor={chartColors[2]} stopOpacity={0.2} />
                        <stop offset="95%" stopColor={chartColors[2]} stopOpacity={0} />
                    </linearGradient>
                </defs>
                <Area type="step" name="Trade" dataKey="pnPlanTrade" stroke={chartColors[0]} xAxisId="time" dot={false} fillOpacity={1} fill="url(#colorTrade)" />
                <Area type="step" name="Availability" dataKey="pnPlanAvailability" stroke={chartColors[1]} xAxisId="time" dot={false} fillOpacity={1} fill="url(#colorAvailability)" />
                <Area type="step" name="iON" dataKey="pnPlanIon" stroke={chartColors[2]} xAxisId="time" dot={false} fillOpacity={1} fill="url(#colorIon)" />
                {/*<CartesianGrid stroke="#ccc" strokeDasharray="3 3" />*/}
                <XAxis dataKey="dispatchTime" xAxisId="time" tick={{ fontSize: "0.75rem", dy: 3 }}>
                    <Label value="Time" position="bottom" offset={15} />
                </XAxis>
                <XAxis dataKey="dispatchDate" axisLine={false} tickLine={false} xAxisId="date" tick={{ fontSize: "0.75rem", dy: -12 }} />
                <YAxis tick={{ fontSize: "0.75rem" }}>
                    <Label value="MW" angle={-90} position="insideLeft" offset={10} style={{ textAnchor: "middle" }} />
                </YAxis>
                <Tooltip contentStyle={{ backgroundColor: theme.palette.background.paper }} />
                <Legend verticalAlign="top" height={36}>

                </Legend>
            </AreaChart>
        </ResponsiveContainer>
    );
}

export interface ISchedulerDataChart {
    data: ISchedulerPosition[]
}

export default SchedulerDataChart;