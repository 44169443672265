import { apiRequest, loginRequest, powerBiRequest } from "./config";
import { msalInstance } from "../..";
import { InteractionRequiredAuthError } from "@azure/msal-browser";

export const getApiAccessToken = (): Promise<string> => {
    return acquireToken(apiRequest);
}

export const getLoginRequestToken = (): Promise<string> => {
    return acquireToken(loginRequest);
}

export const getPowerBiAccessToken = (): Promise<string> => {
    return acquireToken(powerBiRequest);
}

const acquireToken = (request: any): Promise<string> => {
    return new Promise((resolve, reject) => {
        const requestObj = {
            ...request,
            account: msalInstance.getActiveAccount() ?? undefined
        }

        msalInstance.acquireTokenSilent(requestObj)
            .then((response) => {
                resolve(response.accessToken);
            }).catch((error) => {
                if (error instanceof InteractionRequiredAuthError) {
                    msalInstance.acquireTokenRedirect(request);
                }
            });
    });
}
