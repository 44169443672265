import ApiRoot from "./ApiRoot";
import { getApiAccessToken } from "../auth/helpers";
import { IGasSystemLength } from "../types/analytics/IGasSystemLength";
import { IGasTerminalSupply } from "../types/analytics/IGasTerminalSupply";
import { IWindResponse } from "../types/analytics/IWindResponse";
import { IFuelResponse } from "../types/analytics/IFuelResponse";
import { IBalancePriceResponse } from "../types/analytics/IBalancePriceResponse";
import { IBalanceVolumeResponse } from "../types/analytics/IBalanceVolumeResponse";
import { IWDMarketData } from "../types/analytics/IWDMarketData";

const rootUrl = process.env.REACT_APP_API_GATEWAY_URL + "Analytics"
const apiKey = process.env.REACT_APP_API_GATEWAY_KEY;

const getGasSystemLength = async () => {
    const accessToken = await getApiAccessToken();
    return await ApiRoot.get<IGasSystemLength[]>(`${rootUrl}/GetGasSystemLength`, { apiKey, accessToken });
};

const getTerminalSupplyFlow = async () => {
    const accessToken = await getApiAccessToken();
    return await ApiRoot.get<IGasTerminalSupply[]>(`${rootUrl}/GetGasTerminalFlowData`, { apiKey, accessToken });
};
const getWindData = async () => {
    const accessToken = await getApiAccessToken();
    return await ApiRoot.get<IWindResponse[]>(`${rootUrl}/GetWindData`, { apiKey, accessToken });
};
const getFuelData = async () => {
    const accessToken = await getApiAccessToken();
    return await ApiRoot.get<IFuelResponse[]>(`${rootUrl}/GetFuelData`, { apiKey, accessToken });
};
const getBalancePriceData = async () => {
    const accessToken = await getApiAccessToken();
    return await ApiRoot.get<IBalancePriceResponse[]>(`${rootUrl}/GetBalancePriceData`, { apiKey, accessToken });
};
const getBalanceVolumeData = async () => {
    const accessToken = await getApiAccessToken();
    return await ApiRoot.get<IBalanceVolumeResponse[]>(`${rootUrl}/GetBalanceVolumeData`, { apiKey, accessToken });
};
const getWDMarketData = async () => {
    const accessToken = await getApiAccessToken();
    return await ApiRoot.get<IWDMarketData[]>(`${rootUrl}/GetWDMarketData`, { apiKey, accessToken });
};

const analyticsApi = {
    getGasSystemLength,
    getTerminalSupplyFlow,
    getWindData,
    getFuelData,
    getBalancePriceData,
    getBalanceVolumeData,
    getWDMarketData
}

export default analyticsApi;