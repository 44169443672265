import React, { useEffect } from "react";
import PageWrapper from "../../../components/PageWrapper";
import { Grid } from "@mui/material";
import { IPortalRouteOptions } from "../../../shared/types/shared/routes/routeTypes";
import { Dashboard, QueryStats } from "@mui/icons-material";

const GasReconciliationHomePage: React.FC<IGasReconciliationHomePage> = () => {
    const [loaded, setLoaded] = React.useState(false);
    // const [status, setStatus] = React.useState({});

    const loadComplete = () => {
        setLoaded(true);
    }

    setTimeout(loadComplete, 500);

    //useEffect(() => {
    //    ApiRoot.get<any>(`${process.env.REACT_APP_BASE_API_URL}gas-rec/status`)
    //        .then(response => {
    //            setStatus(response);
    //        })
    //}, []);

    return (
        <PageWrapper title="Gas Reconciliation Dashboard" loaded={loaded}>
            <Grid container spacing={2}>
                {/*<p>{ JSON.stringify(status) }</p>*/}
            </Grid>
        </PageWrapper>
    );
}

export interface IGasReconciliationHomePage {

}

const GasReconciliationHomePageConfig: IPortalRouteOptions = {
    relativeUrl: "gas-reconciliation",
    page: <GasReconciliationHomePage />,
    navDisplay: {
        title: "Gas Reconciliation",
        icon: <QueryStats />,
        nestedTitle: "Dashboard",
        nestedIcon: <Dashboard />,
    }
}

export default GasReconciliationHomePageConfig;
