import { Timeline } from "@mui/icons-material";
import { List, ListItemButton, ListItemText, Paper, Typography } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { Dictionary } from '@reduxjs/toolkit';
import moment from "moment";
import React, { useCallback, useEffect } from "react";
import { Bar, BarChart, Label, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import PageWrapper from "../../../components/PageWrapper";
import { useToastAlertContext } from '../../../components/ToastAlert';
import TradesTable from "../../../components/trade/TradesTable";
import tradeProcessorApi from "../../../shared/api/TradeProcessorApi";
import { IIglooAssetTrade } from "../../../shared/types/IIglooAssetTrade";
import { IPortalRouteOptions } from "../../../shared/types/shared/routes/routeTypes";
import TableToolbar from "../../../components/shared/table/TableToolbar";
import { isMobile } from "react-device-detect";

const TradedVolumePage: React.FC<ISchedulerPageProps> = () => {
    const theme = useTheme();
    const { popToast } = useToastAlertContext();
    const [loaded, setLoaded] = React.useState(false);

    const [backgroundRefreshing, setBackgroundRefreshing] = React.useState<boolean>(false);
    const [dataRefreshed, setDataRefreshed] = React.useState<string>("")
    const [iglooTrades, setIglooTrades] = React.useState<IIglooAssetTrade[]>([])
    const [assetImbalanceVolume, setAssetImbalanceVolume] = React.useState<IImbalanceAssetsType[]>([])

    const loadComplete = () => {
        setLoaded(true);
    }

    const fetchTrades = useCallback(async () => {
        const requestMade = moment().format("HH:mm:ss.SSS");

        const getRawTrades =
            tradeProcessorApi.getRawTrades()
                .then(setIglooTrades)
                .catch((error) => { throw new Error("Unable to get Igloo Trades data: " + error); });

        const getProfilesFromAvailability =
            tradeProcessorApi.getProfilesFromAvailability()
                .catch((error) => { throw new Error("Unable to get Profile From Availability data: " + error); });

        const getProfilesFromTrades =
            tradeProcessorApi.getProfilesFromTrades()
                .catch((error) => { throw new Error("Unable to get Profile From Trades data: " + error); });

        const getImbalanceVolume = Promise.all([getProfilesFromAvailability, getProfilesFromTrades]).then((response) => {
            const profilesData = response[0];
            const tradesData = response[1];

            var imbalances: Dictionary<number> = {};

            profilesData && Object.keys(profilesData).forEach((key, keyIndex) => {
                var assetVolume: number = 0.0;
                var assetAvailability: number = 0.0;

                var assetTrades = tradesData[key];
                assetTrades && Object.keys(assetTrades).forEach((item) => {
                    assetVolume += (assetTrades ? assetTrades[item] ?? 0.0 : 0.0);
                })
                var assetsAvail = profilesData[key];
                assetsAvail && Object.keys(assetsAvail).forEach((key2, index) => {
                    assetAvailability += (assetsAvail ? assetsAvail[key2] ?? 0.0 : 0.0);
                });

                if (assetVolume !== assetAvailability) {
                    if (assetVolume !== 0.0) {
                        imbalances[key] = assetAvailability - assetVolume;
                    }
                }
            });

            const entries = Object.entries(imbalances);
            entries.sort((x, y) => (y[1] ?? 0.0) - (x[1] ?? 0.0));

            var imbalanceItems: IImbalanceAssetsType[] = [];
            for (const [, value] of Object.entries(entries)) {
                imbalanceItems.push({
                    asset: String(value[0]),
                    imbalance: Number(value[1])
                });
            }

            setAssetImbalanceVolume(imbalanceItems);
        }).catch(error => { throw error; })

        const promises = [getRawTrades, getImbalanceVolume];
        return Promise.all(promises)
            .then(() => { setDataRefreshed(requestMade); })
            .catch((error: Error) => popToast(error.message, "error"))
            .finally(loadComplete);
    }, [popToast])

    useEffect(() => {
        fetchTrades();

        const intervalId = setInterval(async () => {
            setBackgroundRefreshing(true);
            await fetchTrades(); // Fetch data every 2 minutes
            setBackgroundRefreshing(false);
        }, 120000);

        return () => clearInterval(intervalId);
    }, [fetchTrades])

    return (
        <PageWrapper title="Traded Volume" loaded={loaded} refreshing={backgroundRefreshing}>
            <Typography fontSize={10} textAlign="left" sx={{ width: "100%" }}>Last Updated: {dataRefreshed}</Typography>
            <Grid container spacing={1}>
                <Grid xs={12}>
                    <Paper>
                        <Typography flexGrow={1} textAlign="left" sx={{ width: "100%", p: 1 }}>Trades</Typography>
                        <TradesTable items={iglooTrades}></TradesTable>
                    </Paper>
                </Grid>
                <Grid xs={isMobile ? 12 : 3}>
                    <Paper sx={{ p: 1, height: "100%" }}>
                        <Typography flexGrow={1} textAlign="left" sx={{ width: "100%", pb: 1 }}>Assets with Differences</Typography>
                        <List sx={{
                            width: '100%',
                            maxHeight: 400,
                            position: 'relative',
                            overflow: 'auto',
                            height: "auto",
                            '& ul': { padding: 0 }
                        }} dense={true} disablePadding={true}>
                            {assetImbalanceVolume.map((item, index) =>
                                <ListItemButton dense={true} key={item.asset}>
                                    <ListItemText
                                        primary={item.asset}
                                        secondary={"(" + item.imbalance + " MW)"}
                                    />
                                </ListItemButton>,
                            )}
                        </List>
                    </Paper>
                </Grid>
                <Grid xs={isMobile ? 12 : 9}>
                    <Paper sx={{ p: 1, maxWidth: "100%", overflow: "auto" }}>
                        <Typography flexGrow={1} textAlign="left" sx={{ width: "100%", mb: 1 }}>Traded vs Available</Typography>
                        <ResponsiveContainer width="100%" height={400}>
                            <BarChart data={assetImbalanceVolume}>
                                <XAxis dataKey="asset" angle={-90} offset={0} height={75} orientation="top" axisLine={false} tickLine={false} />
                                <YAxis tickCount={15}>
                                    <Label value="MW" angle={-90} position="insideLeft" offset={10} style={{ textAnchor: "middle" }} />
                                </YAxis>
                                <Tooltip contentStyle={{ backgroundColor: theme.palette.background.paper }} cursor={{ fill: 'transparent' }} />
                                <Bar dataKey="imbalance" fill="#8dd1e1" />
                            </BarChart>
                        </ResponsiveContainer>
                    </Paper>
                </Grid>
            </Grid>
        </PageWrapper>
    );
}

export interface IImbalanceAssetsType {
    asset: string,
    imbalance: number
}

export interface ISchedulerPageProps {

}

const TradedVolumePageConfig: IPortalRouteOptions = {
    relativeUrl: "trades",
    page: <TradedVolumePage />,
    navDisplay: {
        title: "Traded Volume",
        icon: <Timeline />,
    }
}

export default TradedVolumePageConfig;
