import { IPortalRouteOptions } from "../../shared/types/shared/routes/routeTypes";
import { Assessment } from "@mui/icons-material";
import { AnalyticsPowerBiPage } from "../analytics/power-bi/AnalyticsPowerBiReportsPage";

const ForecastReportPageConfig: IPortalRouteOptions = {
    relativeUrl: "forecast-report",
    page: <AnalyticsPowerBiPage title={"Operational Commercial Forecast"} groupId={"cc00690d-cfb1-4910-9df1-7caafd36ec41"} reportId={"51f6a1c5-1b00-4ba3-a9c1-37e28801ccae"} />,
    navDisplay: {
        title: "Forecast Report",
        icon: <Assessment />
    }
}

export default ForecastReportPageConfig;