import { PropsWithChildren, createContext, useCallback, useContext, useRef, useState, ReactNode, useEffect } from 'react';
import { Breakpoint, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';

export interface IBasicModalOptions {
    title: string;
    content?: string;
    fullWidth?: boolean;
    maxWidth?: Breakpoint;
}

export interface IAdvancedModalOptions extends IBasicModalOptions {
    contentComponent?: ReactNode;
    yesText?: string;
    noText?: string;
    canSubmit?: boolean;
    error?: boolean;
}

export interface IModalUpdateOptions {
    canSubmit?: boolean;
}

interface IContextProps {
    openModal: (options: IAdvancedModalOptions) => Promise<boolean>;
    openErrorModal: (options: IBasicModalOptions) => Promise<boolean>;
    updateModal: (options: IModalUpdateOptions) => void;
}

const ModalWindowContext = createContext<IContextProps>({ openModal: async (options: IAdvancedModalOptions) => true, openErrorModal: async (options: IBasicModalOptions) => true, updateModal: (options: IModalUpdateOptions) => { } });

const ModalWindowContextProvider: React.FC<PropsWithChildren<any>> = ({ children }: PropsWithChildren<{}>) => {
    const resolver = useRef<any>();
    const [open, setOpen] = useState<boolean>(false);
    const [title, setTitle] = useState<string>("");
    const [content, setContent] = useState<string>();
    const [yesText, setYesText] = useState<string>();
    const [noText, setNoText] = useState<string>();
    const [canSubmit, setCanSubmit] = useState<boolean>(true);
    const [isError, setIsError] = useState(false);
    const [fullWidth, setFullWidth] = useState<boolean>();
    const [maxWidth, setMaxWidth] = useState<Breakpoint>();
    const [component, setComponent] = useState<ReactNode | undefined>();

    const handleOpen = useCallback((options: IAdvancedModalOptions): Promise<boolean> => {
        const { title, content, contentComponent, yesText, noText, canSubmit, fullWidth, error, maxWidth } = options;

        setTitle(title);
        setContent(content);
        setOpen(true);
        setYesText(yesText);
        setNoText(noText);
        setCanSubmit(canSubmit === undefined ? true : canSubmit);
        setFullWidth(fullWidth);
        setMaxWidth(maxWidth);
        setIsError(error || false);
        setComponent(contentComponent);

        return new Promise((resolve: any) => {
            resolver.current = resolve;
        });
    }, []);

    const handleErrorOpen = useCallback((options: IAdvancedModalOptions): Promise<boolean> => {
        const { title, content, fullWidth, maxWidth } = options;

        setTitle(title);
        setContent(content);
        setOpen(true);
        setYesText(undefined);
        setNoText(undefined);
        setCanSubmit(true);
        setFullWidth(fullWidth);
        setMaxWidth(maxWidth);
        setIsError(true);

        return new Promise((resolve: any) => {
            resolver.current = resolve;
        });
    },[]);

    const handleUpdate = useCallback((options: IModalUpdateOptions) => {
        const { canSubmit } = options;

        setCanSubmit(canSubmit === undefined ? true : canSubmit);
    }, [open]);


    const handleOk = () => {
        resolver.current && resolver.current(true);
        setOpen(false);
    }

    const handleClose = () => {
        resolver.current && resolver.current(false);
        setOpen(false);
    }

    return (
        <ModalWindowContext.Provider value={{ openModal: handleOpen, openErrorModal: handleErrorOpen, updateModal: handleUpdate }}>
            {children}
            <Dialog
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-window-title"
                aria-describedby="modal-window-description"
            >
                <DialogTitle id="modal-window-title">
                    {title}
                </DialogTitle>
                <DialogContent>
                    {content &&
                        <DialogContentText id="modal-window-description" component="div">
                            <Stack direction="row">
                                {isError && <ErrorIcon color="error" sx={{ margin: '0 10px 0 0' }} />}
                                {content}
                            </Stack>
                        </DialogContentText>}
                    {component &&
                        <>{component}</>
                    }
                </DialogContent>
                <DialogActions>
                    {yesText && <Button variant="contained" onClick={handleOk} disabled={!canSubmit}>{yesText}</Button>}
                    {noText && <Button variant="contained" onClick={handleClose}>{noText}</Button>}
                    {!yesText && !noText && <Button variant="contained" onClick={handleClose}>Close</Button>}
                </DialogActions>
            </Dialog>
        </ModalWindowContext.Provider>
    )
}

export const useModalWindowContext = () => useContext(ModalWindowContext);
export default ModalWindowContextProvider;