import React, { useEffect, useMemo, useState } from "react";
import PageWrapper from "../../components/PageWrapper";
import { Box, Button, Paper, TextField } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { useGetAssetsQuery } from "../../shared/api/AssetApi";
import { IPortalRouteOptions } from "../../shared/types/shared/routes/routeTypes";
import { CalendarToday, ViewList } from "@mui/icons-material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment, { Moment } from "moment";
import BOADataTable from "../../components/data/BOADataTable";
import { IBOAData } from "../../shared/types/data/IBOAData";
import dataRequestApi from "../../shared/api/DataRequestApi";
import useBOAData from "../../shared/hooks/BOADataHook";

const BOADataPage: React.FC<IBOADataPage> = () => {
    const defaultTime = useMemo(() => moment.utc().startOf("day"), []);
    const [selectedDate, setSelectedDate] = useState<Moment>(defaultTime)
    const [conradBMUIDs, setConradBMUIDs] = useState<Set<string>>(new Set<string>())
    const [historicalBOAs, setHistoricalBOAs] = useState<IBOAData[]>([])
    const [combinedBOAData, setCombinedBOAData] = useState<IBOAData[]>([])
    const { newBoaData, setNewBOAs } = useBOAData();
    const { data: assets, isSuccess: loaded } = useGetAssetsQuery();
    const [loadedAtLeastOnce, setLoadedAtLeastOnce] = useState<boolean>(false)

    useEffect(() => {
        if (assets) {
            const newSet = new Set<string>();
            assets.forEach((asset) => {
                if (asset.BMID !== null && asset.BMID !== "NONBM" && asset.BMID !== "") {
                    newSet.add(asset.BMID)
                }
            })
            setConradBMUIDs(newSet)
        }
    }, [assets]);

    const gatherData = () => {
        dataRequestApi.getHistoricBOAData(selectedDate.toISOString())
            .then(incomingData => {
                setNewBOAs([])
                setHistoricalBOAs(incomingData)
            });
    }

    useEffect(() => {
        if (selectedDate.utc().format() === defaultTime.utc().format() && loadedAtLeastOnce) {
            setCombinedBOAData([...newBoaData, ...historicalBOAs])
        }
        else {
            setCombinedBOAData(historicalBOAs)
        }
    }, [historicalBOAs, newBoaData])

    useEffect(() => {
        setLoadedAtLeastOnce(true)
    }, [combinedBOAData])

    const updateSelectedDate = (Value: Moment | null) => {
        if (!Value) { return; }
        setSelectedDate(Value);
    }

    return (
        <PageWrapper title="BOA" loaded={loaded}>
            <Paper sx={{ height: "calc(100% - 1px)" }}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <Grid container
                        sx={{ width: "100%", maxWidth: "600px", p: 1 }}
                        alignItems="center"
                        alignContent="start"
                        spacing={1}
                        justifyContent="flex-start"
                    >
                        <Grid xs={6}>
                            <Box>
                                <DatePicker
                                    label="Date"
                                    inputFormat="DD/MM/YYYY"
                                    value={selectedDate}
                                    onChange={updateSelectedDate}
                                    renderInput={(params) => <TextField {...params}
                                        fullWidth
                                    />}
                                />
                            </Box>
                        </Grid>
                        <Grid xs={6}>
                            <Button
                                variant="contained"
                                startIcon={<CalendarToday />}
                                disabled={false}
                                onClick={gatherData}
                                size="large"
                                sx={{ height: "55px" }}
                                fullWidth
                            >
                                Gather Data
                            </Button>
                        </Grid>
                    </Grid>
                </LocalizationProvider>
                <Box sx={{ mt: 1 }}>
                    <BOADataTable items={combinedBOAData} searchBar={true} tablePagination={true} conradBMUIDs={conradBMUIDs} />
                </Box>
            </Paper>
        </PageWrapper>
    );
}

export interface IBOADataPage {

}

const BOADataPageConfig: IPortalRouteOptions = {
    relativeUrl: "BOA-Data",
    page: <BOADataPage />,
    navDisplay: {
        title: "BOA",
        icon: <ViewList />
    },
}

export default BOADataPageConfig;