import PageWrapper from "../../components/PageWrapper";
import React, { useEffect } from "react";
import mainImage from "../../images/iON_Analytics.png";
import { IPortalRouteOptions } from "../../shared/types/shared/routes/routeTypes";
import { Dashboard } from "@mui/icons-material";

const AnalyticsHomePage: React.FC<IAnalyticsHomePageProps> = () => {
    const [loaded, setLoaded] = React.useState(false);
    const loadComplete = () => {
        setLoaded(true);
    }

    useEffect(() => {
        loadComplete()
    }, [])

    return (
        <PageWrapper title="Demand, Generation, Market Analytics" loaded={loaded}>
            <img alt="Analytics" src={mainImage} width="100%" />
        </PageWrapper>
    );
}

export interface IAnalyticsHomePageProps {

}

const AnalyticsHomePageConfig: IPortalRouteOptions = {
    relativeUrl: "analytics",
    page: <AnalyticsHomePage />,
    navDisplay: {
        title: "Dashboard",
        icon: <Dashboard/>
    }
}

export default AnalyticsHomePageConfig;
