import ApiRoot from "./ApiRoot";
import { IMeterReading } from "../types/IMeterReading"
import { IAnalyticsSrmc } from "../types/IAnalyticsSrmc"
import { IAnalyticsFrenchStacker } from "../types/IAnalyticsFrenchStacker"
import { IAnalyticsNivAnalysis } from "../types/IAnalyticsNivAnalysis"
import { IAnalyticsImbalanceTripSummary } from "../types/IAnalyticsImbalanceTripSummary";
import { IAnalyticsSystemPriceForecastDetail } from "../types/IAnalyticsSystemPriceForecastDetail"
import moment from "moment";

const rootUrl = process.env.REACT_APP_TRADE_PROCESSOR_URL + "Analytics";

const getFrenchStacker = async () => {
    return await ApiRoot.get<IAnalyticsFrenchStacker[]>(`${rootUrl}/FrenchStacker`);
}

const getImbalanceTripSummary = async () => {
    return await ApiRoot.get<IAnalyticsImbalanceTripSummary[]>(`${rootUrl}/ImbalanceTripSummary`);
}

const getSrmc = async (theDate: Date) => {
    const params = { theDate: moment(theDate).format("YYYY-MM-DD"), }
    return await ApiRoot.get<IAnalyticsSrmc[]>(`${rootUrl}/SRMC`, {}, params);
}

const getSystemPriceForecastDetail = async () => {
    return await ApiRoot.get<IAnalyticsSystemPriceForecastDetail[]>(rootUrl + "/SystemPriceForecastDetail");
}

const getNivAnalysis = async () => {
    return await ApiRoot.get<IAnalyticsNivAnalysis[]>(rootUrl + "/NivAnalysis");
}

const getMeterReadings = async (startDate: string, endDate: string, siteId: string) => {
    return await ApiRoot.get<IMeterReading[]>(rootUrl + "/MeterData?startDate=" + startDate + "&endDate=" + endDate + "&siteId=" + siteId);
}


const marketAnalyticsApi = {
    getFrenchStacker,
    getImbalanceTripSummary,
    getSrmc,
    getSystemPriceForecastDetail,
    getNivAnalysis,
    getMeterReadings
}

export default marketAnalyticsApi;