import { CalendarToday, PlusOne, Refresh, Today } from "@mui/icons-material";
import { Box, Button, Paper, Stack, TextField, Typography } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment, { Moment } from "moment";
import React, { useEffect, useState } from "react";
import BoxWithTitle from "../../../components/BoxWithTitle";
import DailyMeteredGrid from "../../../components/gas-reconciliation/DailyMeteredGrid";
import PortfolioPosition from "../../../components/gas-reconciliation/DailyMeteredPortfolioPosition";
import GasTrades from "../../../components/gas-reconciliation/GasTrades";
import { useModalWindowContext } from "../../../components/ModalWindow";
import PageWrapper from "../../../components/PageWrapper";
import { Counterparty } from "../../../shared/enums/CounterpartyEnum";
import useDailyMetered from "../../../shared/hooks/gas-reconciliation/DailyMeteredHook";
import { useAppDispatch } from "../../../shared/hooks/StateHook";
import { updateAllocateCompleted, updateAllocateSPARKCompleted } from "../../../shared/state/slices/DailyMeteredSlice";
import { IPortalRouteOptions } from "../../../shared/types/shared/routes/routeTypes";

const GasReconciliationDailyMeteredPage: React.FC = () => {
    const [gasDay, setGasDay] = useState<Moment>(moment());
    const { lastRefresh, totalResidual, isDataBeingRefreshed, refresh, allocateTrades,
        allocateTradesCompletedPerc, allocateSPARKTradesCompletedPerc, loadData, hasCounterpartyCashExposure, totalSPARKVolume } = useDailyMetered(gasDay);
    const alertDialog = useModalWindowContext();
    const dispatch = useAppDispatch();
    const [loaded, setLoaded] = useState(false);

    const loadComplete = () => {
        setLoaded(true);
    }

    setTimeout(loadComplete, 500);

    const handleGasDayChange = (value: Moment | null) => {
        if (!isDataBeingRefreshed) {
            if (value != null) {
                setGasDay(value);
            }
        }
    };

    const getLastRefresh = (): string => {
        return `Data Last Refreshed: ${lastRefresh == null
            ? "N/A"
            : moment(lastRefresh).format("DD/MM/YYYY HH:mm")
            }`;
    };

    const refreshButtonContent = (): React.ReactNode => {
        return !isDataBeingRefreshed ? "REFRESH" : "Refreshing...";
    };

    const handleAllocateTrades = async (ppt: number, residualValue: number, counterparty: Counterparty) => {
        const alertTitle = `${counterparty} - Trade Submission`;
        await alertDialog?.openModal({ title: alertTitle, content: "Submitting trades to Igloo is currently disabled.  This process will only simulate a submission." });

        if (ppt <= 0) {
            await alertDialog?.openModal({ title: alertTitle, content: `Stop right there, can't allocate ${residualValue} therms at ${ppt} ppt` });
            return;
        }
        if (ppt > 100) {
            const pptResult = await alertDialog?.openModal({ title: alertTitle, content: "Your Gas Price doesn't look correct, is this correct?", yesText: "Yes it's correct", noText: "Cancel" });
            if (pptResult === false) {
                return;
            }
        }
        if (residualValue === 0) {
            const zeroAllocationResult = await alertDialog?.openModal({ title: alertTitle, content: "Stop right there, You have a Zero allocation volume. Is this correct?", yesText: "Yes", noText: "No" });
            if (zeroAllocationResult != true) {
                return;
            }
        }

        const allocateTradesResult = await alertDialog?.openModal({ title: alertTitle, content: `Send trades of ${residualValue} residual and ${ppt} PPT to Igloo?`, yesText: "Yes", noText: "No" });
        if (allocateTradesResult == true) {
            const response = await allocateTrades(gasDay, ppt, counterparty);

            if (response == null) {
                await alertDialog?.openModal({ title: alertTitle, content: "Trade Allocations have been completed." });
                dispatch(updateAllocateCompleted(0));
                dispatch(updateAllocateSPARKCompleted(0));
                loadData();
            }
            else {
                await alertDialog?.openErrorModal({ title: alertTitle, content: `There was an error: ${response}` });
            }
        }
    }


    return (
        <PageWrapper title="Daily Metered" loaded={loaded}>
            <Paper sx={{ p: 2 }}>
                <Stack direction="column" spacing={2}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <Grid
                            container
                            alignItems="center"
                            alignContent="start"
                            spacing={2}
                            justifyContent="flex-start">
                            <Grid>
                                <Box sx={{ maxWidth: 200 }}>
                                    <DatePicker
                                        label="Trading Day"
                                        inputFormat="DD/MM/YYYY"
                                        value={gasDay}
                                        onChange={handleGasDayChange}
                                        disabled={isDataBeingRefreshed}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </Box>
                            </Grid>
                            <Grid>
                                <Button
                                    variant="contained"
                                    startIcon={<CalendarToday />}
                                    onClick={() => handleGasDayChange(moment())}>
                                    WITHIN DAY
                                </Button>
                            </Grid>
                            <Grid>
                                <Button
                                    variant="contained"
                                    startIcon={<PlusOne />}
                                    onClick={() => handleGasDayChange(moment().add(1, "day"))}>
                                    DAY AHEAD
                                </Button>
                            </Grid>
                            <Grid>
                                <Button
                                    variant="contained"
                                    startIcon={<Refresh />} onClick={refresh}
                                >
                                    {refreshButtonContent()}
                                </Button>
                            </Grid>
                            <Grid>
                                <Typography
                                    variant="caption"
                                    component="p"
                                    position="relative"
                                >
                                    {getLastRefresh()}
                                </Typography>
                            </Grid>
                        </Grid>
                    </LocalizationProvider>

                    <BoxWithTitle title="Daily Metered Sites">
                        <DailyMeteredGrid />
                    </BoxWithTitle>

                    <BoxWithTitle title="Portfolio Position">
                        <PortfolioPosition />
                    </BoxWithTitle>

                    <BoxWithTitle title="Gas Trades (Corona Energy)">
                        <GasTrades counterparty={Counterparty.Corona} totalResidual={totalResidual} allocateTrades={handleAllocateTrades} allocationCompleted={allocateTradesCompletedPerc} />
                    </BoxWithTitle>

                    <BoxWithTitle title="Gas Trades (Macquarie)">
                        <GasTrades disabled={!hasCounterpartyCashExposure} counterparty={Counterparty.Macquarie} totalResidual={totalSPARKVolume} allocateTrades={handleAllocateTrades} allocationCompleted={allocateSPARKTradesCompletedPerc} />
                    </BoxWithTitle>
                </Stack>
            </Paper>
        </PageWrapper>
    );
}

const GasReconciliationDailyMeteredPageConfig: IPortalRouteOptions = {
    relativeUrl: "dailymetered",
    page: <GasReconciliationDailyMeteredPage />,
    navDisplay: {
        title: "Daily Metered",
        icon: <Today />
    }
}

export default GasReconciliationDailyMeteredPageConfig;
