import { Card, Grid, Paper, Typography } from "@mui/material";
import React, { useCallback, useEffect } from "react";
import PageWrapper from "../../components/PageWrapper";

import { useTheme } from '@mui/material/styles';
import { Dictionary } from "@reduxjs/toolkit";
import BatteryExample from '../../images/Blackpool-battery-storage.jpg';
import FlexGenExample from '../../images/ConradEnergy-Chatterley.jpg';
import { useGetAssetsQuery } from "../../shared/api/AssetApi";
import { IAssetInformation } from "../../shared/types/IAssetInformation";
import { IPortalRouteOptions } from "../../shared/types/shared/routes/routeTypes";
import { useNavigate } from "react-router-dom";

const AssetDetailsPage: React.FC<IAssetDetailsPage> = () => {
    const [loaded, setLoaded] = React.useState(false);
    const theme = useTheme();
    const navigate = useNavigate();
    const { data: assets } = useGetAssetsQuery();

    const [selectedAsset, setSelectedAsset] = React.useState<IAssetInformation>()

    const loadComplete = () => {
        setLoaded(true);
    }

    setTimeout(loadComplete, 500);

    const fetchAssets = useCallback(() => {
        if (window.location.search === "") {
            navigate("/asset/register");
        }
        else {

            var dict: Dictionary<string> = {}
            assets?.forEach((item) => dict[item.SiteID] = item.Name);
            var searchParams = new URLSearchParams(window.location.search);
            if (searchParams.get("assetid") !== "") {
                setSelectedAsset(assets?.filter(item => item.SiteID === searchParams.get("assetid"))[0]);
            }
        }
    }, [assets]);


    useEffect(() => {
        fetchAssets()
    }, [fetchAssets])

    return (
        <PageWrapper title="Asset Details" loaded={loaded}>
            <Grid container spacing={2}>
                <Grid item md={3} xs={6}>
                    <Card sx={{ width: "100%", height: 400, display: "inline-block" }}>
                        <Paper sx={{ p: 2 }}>
                            <Typography>Name: <Typography color={theme.palette.primary.main}>{selectedAsset?.Name}</Typography></Typography>
                        </Paper>
                        <Paper sx={{ p: 2 }}>
                            <Typography>Type: <Typography color={theme.palette.primary.main}>{selectedAsset?.GenerationType}</Typography></Typography>
                        </Paper>
                        <Paper sx={{ p: 2 }}>
                            <Typography>Live Output: <Typography color={theme.palette.primary.main}>{selectedAsset?.LiveOutputs ? ("True") : ("False")}</Typography></Typography>
                        </Paper>

                    </Card>
                </Grid>
                <Grid item md={3} xs={6}>
                    <Card sx={{ width: "100%", height: 400, display: "inline-block" }}>
                        <Paper sx={{ p: 2 }}>
                            <Typography>ID: <Typography color={theme.palette.primary.main}>{selectedAsset?.SiteID}</Typography></Typography>
                        </Paper>
                        <Paper sx={{ p: 2 }}>
                            <Typography>BMU: <Typography color={theme.palette.primary.main}>{selectedAsset?.BMID}</Typography></Typography>
                        </Paper>
                    </Card>
                </Grid>
                <Grid item md={3} xs={6}>
                    <Card sx={{ width: "100%", height: 400, display: "inline-block" }}>
                        <Paper sx={{ p: 2 }}>
                            <Typography>Region: <Typography color={theme.palette.primary.main}>{selectedAsset?.ConradRegion}</Typography></Typography>
                        </Paper>
                        <Paper sx={{ p: 2 }}>
                            <Typography>Latitude: <Typography color={theme.palette.primary.main}>{selectedAsset?.Latitude}</Typography></Typography>
                        </Paper>
                        <Paper sx={{ p: 2 }}>
                            <Typography>Longitude: <Typography color={theme.palette.primary.main}>{selectedAsset?.Longitude}</Typography></Typography>
                        </Paper>
                        <Paper sx={{ p: 2 }}>
                            <Typography>Post Code: <Typography color={theme.palette.primary.main}>{selectedAsset?.Postcode}</Typography></Typography>
                        </Paper>
                    </Card>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item md={6} xs={6}>
                    <Card sx={{ width: "100%", height: 400, display: "inline-block" }}>
                        <img src={selectedAsset?.GenerationType === "Battery" ? BatteryExample : FlexGenExample} alt={selectedAsset?.GenerationType + " Site"} width="100%"></img>
                    </Card>
                </Grid>
            </Grid>
        </PageWrapper>
    );
}

export interface IAssetDetailsPage {

}

const AssetDetailsPageConfig: IPortalRouteOptions = {
    relativeUrl: "details",
    page: <AssetDetailsPage />,
}

export default AssetDetailsPageConfig;