import React, { useEffect, useMemo, useState } from "react";
import { Box, Button, CircularProgress, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Tooltip } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import DateRangeSelector from "../../components/data/DateRangeSelector";
import CSVButton from "../../components/data/CSVButton";
import { Moment } from "moment";
import moment from "moment"
import dataRequestApi from "../../shared/api/DataRequestApi";
import { CalendarToday } from "@mui/icons-material";
import { IAssetInformation } from "../../shared/types/IAssetInformation";
import { isMobile } from "react-device-detect";

interface IAssetOption {
    name: string;
    value: string;
}

const MeterDataRequestBar: React.FC<IMeterDataRequestProps> = ({ readings, setReadings, assets }) => {
    const defaultStartTime = useMemo(() => moment.utc().subtract(1, "day").startOf("day"), []);
    const defaultEndTime = useMemo(() => moment.utc().endOf("day"), []);
    const [queryError, setQueryError] = useState<string>("");
    const [asset, setAsset] = useState<string>("");
    const [startDate, setStartDate] = useState<Moment>(defaultStartTime);
    const [endDate, setEndDate] = useState<Moment>(defaultEndTime);
    const [querying, setQuerying] = useState<boolean>(false);
    const [options, setOptions] = useState<IAssetOption[]>([]);

    useEffect(() => {
        const options: IAssetOption[] = [];

        assets.forEach((asset) => {
            if (!asset.Name || !asset.SiteID) { return; }

            options.push({ name: asset.Name, value: asset.SiteID });
        });

        setOptions(options);
        if (options.length > 0) {
            setAsset(options[0].value);
        }
    }, [assets]);

    const clickQueryRequestButton = () => {
        setQuerying(true);

        var getMeterData = dataRequestApi.getMeterData(moment(startDate).format(), moment(endDate).format(), asset)
            .then(readings => {
                readings.forEach(reading => reading.id = reading.siteID + '-' + reading.dateTimeUTC)
                setReadings(readings);
            });

        const promises = [
            getMeterData
        ]

        return Promise.all(promises).then().finally(() => setQuerying(false));
    }

    const handleAssetChange = (event: SelectChangeEvent) => {
        setAsset(event.target.value as string);
    };

    const columnsCsv = [
        { key: 'siteID', label: 'Site Id' },
        { key: 'siteName', label: 'Site' },
        { key: 'dateTimeUTC', label: 'Date Time (UTC)' },
        { key: 'settlementDate', label: 'Settlement Date' },
        { key: 'settlementPeriod', label: 'SP' },
        { key: 'readingkwh', label: 'Reading (KWH)' },
    ];

    return (
        <Grid
            container
            alignItems="center"
            alignContent="start"
            spacing={isMobile ? 1 : 2}
            justifyContent="flex-start">
            <Grid xs={isMobile ? 12 : 2}>
                <Box>
                    <FormControl fullWidth>
                        <InputLabel id="asset-label">Asset</InputLabel>
                        <Select
                            labelId="asset-label"
                            id="asset-label"
                            value={asset}
                            label="Asset"
                            onChange={handleAssetChange}
                        >
                            {options.map((option) => <MenuItem key={option.value} value={option.value}>{option.name}</MenuItem>)}
                        </Select>
                    </FormControl>
                </Box>
            </Grid>
            <DateRangeSelector startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate} maxRange={3} invalidFunction={setQueryError} disabled={querying} xs={isMobile ? 6 : 2} />
            <Grid xs={isMobile ? 6 : 3}>
                <Tooltip title={queryError}>
                    <Box sx={{ position: 'relative' }}>
                        <Button
                            variant="contained"
                            startIcon={<CalendarToday />}
                            disabled={querying || !!queryError}
                            onClick={clickQueryRequestButton}
                            size="large"
                            sx={{ height: "55px" }}
                            fullWidth
                        >
                            Gather Data
                        </Button>
                        {querying && (
                            <CircularProgress
                                size={24}
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: '-12px',
                                    marginLeft: '-12px',
                                }}
                            />
                        )}
                    </Box>
                </Tooltip>
            </Grid>
            <CSVButton data={readings} disabled={querying || !!queryError} columns={columnsCsv} filename={asset + '_MeterValues_' + moment(startDate).format("YYYYMMDD") + '_to_' + moment(endDate).format("YYYYMMDD") + '.csv'} xs={isMobile ? 6 : 3} />
        </Grid>
    );
}

export interface IMeterDataRequestProps {
    readings: any
    setReadings: (readings: any) => void;
    assets: IAssetInformation[];
}

export default MeterDataRequestBar;