import React, { useEffect } from 'react';
import moment from "moment"
import { Box, IconButton, Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Typography } from "@mui/material";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import { IPushNotification } from "../../../shared/types/notification/IPushNotification";
import { Dictionary } from "@reduxjs/toolkit";
import { fullColumns, shortenedColumns, HeadCell } from "./NotificationsTableHeaders";


const NotificationsTable: React.FC<INotificationsTable> = ({ items, siteIdToName, searchBar, tablePagination= false, showDeletedNotifs = true, height = "440px", truncatedColumns = false }) => {
    const [orderDescending, setOrderDescending] = React.useState<boolean>(true)
    const [orderCategory, setOrderCategory] = React.useState<keyof IPushNotification>("submissionTime")
    const [searchContents, setSearchContents] = React.useState<string>("")
    const [headCells, setHeadCells] = React.useState<HeadCell[]>([])

    const [pg, setpg] = React.useState<number>(0);
    const [rpg, setrpg] = React.useState<number>(50);

    function handleChangePage(event: any, newpage: number) {
        setpg(newpage);
    }

    function handleChangeRowsPerPage(event: any) {
        setrpg(parseInt(event.target.value, 10));
        setpg(0);
    } 

    const sortItems = (inputArray: IPushNotification[], category: keyof IPushNotification, descending: boolean) => {
        const sortedArray = [...inputArray];
        sortedArray.sort((a, b) => {
            if (a[category] === undefined) { return 1 }
            else if (b[category] === undefined) { return -1 }
            else if ((a[category] || "") < (b[category] || "")) { return -1 }
            return 1
        })
        if (descending) {
            sortedArray.reverse()
        }
        return sortedArray;
    }

    if (items === undefined) {
        items = []
    }

    const [internalItems, setInternalItems] = React.useState<IPushNotification[]>(sortItems(items, orderCategory, orderDescending))

    const handleSearchContentsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchContents(event.target.value as string)
    }

    const handleSort = (categoryID: keyof IPushNotification) => {
        let descending = false
        if (orderCategory === categoryID && !orderDescending) {
            descending = true
        }
        setOrderDescending(descending)
        setOrderCategory(categoryID)
        setInternalItems(sortItems(internalItems, categoryID, descending))
    }

    const filterOutage = (notification: IPushNotification) => {
        return  notification.notificationID.toLowerCase().startsWith(searchContents.toLowerCase()) ||
                notification.priority.toLowerCase().startsWith(searchContents.toLowerCase()) ||
                notification.type?.toLowerCase().startsWith(searchContents.toLowerCase()) ||
                notification.asset.toLowerCase().startsWith(searchContents.toLowerCase()) ||
                siteIdToName[notification.asset]?.toLowerCase().startsWith(searchContents.toLowerCase()) ||
                notification.subject.toLowerCase().startsWith(searchContents.toLowerCase()) ||
                notification.recipient.toLowerCase().startsWith(searchContents.toLowerCase())
    }

    useEffect(() => {
        setInternalItems(sortItems(items, orderCategory, orderDescending))
    }, [items, orderCategory, orderDescending])

    useEffect(() => {
        setpg(0)
    }, [searchContents, items])

    useEffect(() => {
        if (truncatedColumns) {
            setHeadCells(shortenedColumns)
        }
        else {
            setHeadCells(fullColumns)
        }
    }, [truncatedColumns])

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden', pt: 2 }}>
            {(searchBar ? <TextField
                id="search-label"
                label="Search"
                value={searchContents}
                onChange={handleSearchContentsChange}
                name="Search"
                placeholder="Search"
                fullWidth
            /> : <></>)}
            <TableContainer sx={{ mt: 2, maxHeight: height }}>
                <Table stickyHeader size="small" aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {headCells.map((headCell) => (
                                <TableCell
                                    align="center"
                                    key={headCell.id}
                                    width={(headCell.id === "details" ? "500px" : "auto")}
                                >
                                    <IconButton
                                        size="small"
                                        sx={{ "transition": "all .2s", "color": "inherit", "&:hover": { "backgroundColor": "rgba(160, 160, 160, 0.5)" } }}
                                        onClick={() => handleSort(headCell.id)}
                                    >
                                        <Typography variant="subtitle1" fontSize="small">{headCell.label}</Typography>
                                        {headCell.id === orderCategory ? (orderDescending ? < ArrowDownward fontSize="small" /> : <ArrowUpward fontSize="small" />) : <></>}
                                    </IconButton>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            internalItems
                                .filter((item) => filterOutage(item))
                                .filter((item) => showDeletedNotifs || !item.deleted)
                                .slice(pg * rpg, tablePagination ? pg * rpg + rpg : internalItems.length)
                                .map((item) => {
                                    let backgroundColor;
                                    let textColor = "black";
                                    if (item.priority === "High") {
                                        backgroundColor = "#C33702"
                                        textColor = "white"
                                    }
                                    else if (item.priority === "Warn") {
                                        backgroundColor = "#FFC000"
                                    }
                                    else if (item.priority === "Low") {
                                        backgroundColor = "#CCEDFF"
                                    }
                                    else {
                                        backgroundColor = "#CBCBCB"
                                    }
                                    return (
                                        <TableRow key={item.notificationID} sx={{ backgroundColor: backgroundColor }}>
                                            {
                                                headCells.map((headCell) => {
                                                    const cellContents = item[headCell.id]
                                                    if (headCell.formatType === "Boolean") {
                                                        return (
                                                            <TableCell align="center" sx={{ color: textColor, backgroundColor: cellContents ? "green" : "red" }}>{(cellContents ? "Read" : "Not Read")}</TableCell>
                                                        )
                                                    }
                                                    else if (headCell.formatType === "DateTime") {
                                                        return (
                                                            <TableCell align="center" sx={{ color: textColor }}>{moment(cellContents?.toString()).format("DD-MMM-YYYY HH:mm:ss")}</TableCell>
                                                        )
                                                    }
                                                    else if (headCell.formatType === "Link") {
                                                        return (
                                                            <TableCell align="center" sx={{ color: textColor }}>{(cellContents ? <Link href={cellContents.toString()}>{cellContents}</Link> : '-')}</TableCell>
                                                        )
                                                    }
                                                    else if (headCell.formatType === "RichText") {
                                                        return (
                                                            <TableCell align="center" sx={{ color: textColor }}>
                                                                <Box sx={{ maxHeight: "200px", width: "300px" }}>{(cellContents ? cellContents : '-')}</Box>
                                                            </TableCell>
                                                        )
                                                    }
                                                    else {
                                                        return (
                                                            <TableCell align="center" sx={{ color: textColor }}>{(cellContents ? cellContents : '-')}</TableCell>
                                                        )
                                                    }
                                                })
                                            }
                                        </TableRow>
                                    )
                                })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            {tablePagination && 
                <TablePagination
                    rowsPerPageOptions={[10, 20, 50, 100]}
                    component="div"
                    count={items.length}
                    rowsPerPage={rpg}
                    page={pg}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            }

        </Paper>
    );

}
export interface INotificationsTable {
    items: IPushNotification[],
    siteIdToName: Dictionary<string>
    searchBar?: boolean
    showDeletedNotifs?: boolean
    height?: string
    tablePagination?: boolean
    truncatedColumns?: boolean
}

export default NotificationsTable;