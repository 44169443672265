import { CSSProperties, useCallback, useEffect, useState } from "react";
import GoogleMapAssetIcon from "./GoogleMapAssetIcon";
import { Cluster, ClusterStats, MarkerClusterer, MarkerClustererOptions, SuperClusterOptions } from "@googlemaps/markerclusterer";
import { Root, createRoot } from "react-dom/client";
import GoogleMapEngineerIcon from "./GoogleMapEngineerIcon";
import { IMarkerData, MarkerType } from "./GoogleMap";
import { PieChart } from '@mui/x-charts/PieChart';
import { PieValueType } from "@mui/x-charts/models/seriesType";
import { Marker } from "@googlemaps/markerclusterer/dist/marker-utils";

const GoogleMapClusterElement: React.FC<IGoogleMapClusterElementProps> = ({ markers }) => {
    const size = 40;

    const outerRadius = (size / 2);
    const innerRadius = outerRadius * 0.6;
    const chartPos = outerRadius - 5;

    const iconSize = size * 0.5;
    const iconCentre = (size - iconSize) / 2;

    const outerCircle: CSSProperties = {
        backgroundColor: "white",
        WebkitClipPath: "circle(50%)",
        clipPath: "circle(50%)",
        width: size,
        height: size,
    };

    const assetData = new Map<string, number>();
    const engineerData = new Map<string, number>();
    let assetCount = 0;
    let engineerCount = 0;

    markers?.forEach(m => {
        if (m instanceof google.maps.marker.AdvancedMarkerElement) {
            let markerData: IMarkerData;
            try {
                markerData = JSON.parse(m.id);
            } catch {
                return;
            }

            if (markerData.type === MarkerType.Asset && markerData.status !== undefined) {
                assetCount++;

                const key = markerData.status !== null ? markerData.status.textHexColour : "white";
                let value = assetData.get(key) || 0;
                value++;
                assetData.set(key, value);
            }

            if (markerData.type === MarkerType.Engineer && markerData.engineer) {
                engineerCount++;

                const key = markerData.engineer.LastEventDescription === "Ignition-Off" ? "grey" : markerData.engineer.Speed > 0 ? "green" : "black";
                let value = engineerData.get(key) || 0;
                value++;
                engineerData.set(key, value);
            }
        }
    });

    const assetSize = 100 / assetCount;
    const engineerSize = 100 / engineerCount;

    const assetGraphData: PieValueType[] = [];
    assetData.forEach((value, key) => {
        assetGraphData.push({ id: key, color: key, value: value * assetSize });
    });

    const engineerGraphData: PieValueType[] = [];
    engineerData.forEach((value, key) => {
        engineerGraphData.push({ id: key, color: key, value: value * engineerSize });
    });

    return (
        <div>
            {assetCount > 0 &&
                <div style={outerCircle} id="asset-cluster">
                    {<PieChart
                        tooltip={{ trigger: "none" }}
                        height={size}
                        width={size}
                        series={[
                            {
                                data: assetGraphData,
                                innerRadius,
                                outerRadius,
                                paddingAngle: 2,
                                cornerRadius: 1,
                                startAngle: 0,
                                endAngle: 360,
                                cx: chartPos,
                                cy: chartPos,
                            }
                        ]}
                    >
                        <svg x={iconCentre} y={iconCentre}>
                            <GoogleMapAssetIcon status={null} height={iconSize} width={iconSize} />
                        </svg>
                    </PieChart>}
                </div>
            }
            {engineerCount > 0 &&
                <div style={outerCircle} id="engineer-cluster">
                    {<PieChart
                        tooltip={{ trigger: "none" }}
                        height={size}
                        width={size}
                        series={[
                            {
                                data: engineerGraphData,
                                innerRadius,
                                outerRadius,
                                paddingAngle: 2,
                                cornerRadius: 1,
                                startAngle: 0,
                                endAngle: 360,
                                cx: chartPos,
                                cy: chartPos,
                            }
                        ]}
                    >
                        <svg x={iconCentre} y={iconCentre}>
                            <GoogleMapEngineerIcon height={iconSize} width={iconSize} />
                        </svg>
                    </PieChart>}
                </div>}
        </div>

    )
};

interface IGoogleMapClusterElementProps {
    markers?: Marker[];
}

const GoogleMapCluster: React.FC<IGoogleMapClusterProps> = ({ map, markers }) => {
    const [markerClusterer, setMarkerClusterer] = useState<MarkerClusterer>();
    const [markerContainers] = useState<Map<string, ITrackedContainer>>(new Map());

    const renderCluster = useCallback((cluster: Cluster, stats: ClusterStats, map: google.maps.Map) => {
        const key = cluster.position.toString();

        let container: ITrackedContainer | undefined;
        if (markerContainers.has(key)) {
            container = markerContainers.get(key);
        }

        if (!container) {
            const domElement = document.createElement("span");
            const reactRoot = createRoot(domElement);
            container = { domElement, reactRoot, lastUpdated: new Date() }
            markerContainers.set(key, container);
        }

        container.lastUpdated = new Date();
        container.reactRoot.render(<GoogleMapClusterElement markers={cluster.markers} />);

        return new google.maps.marker.AdvancedMarkerElement({
            zIndex: 1000,
            position: cluster.position,
            content: container.domElement,
        });
    }, [markerContainers]);

    useEffect(() => { // Create clusterer only once
        const options: MarkerClustererOptions = {
            renderer: { render: renderCluster },
            algorithmOptions: { radius: 160 } as SuperClusterOptions,
        }

        const clusterer = new MarkerClusterer(options);
        setMarkerClusterer(clusterer);

        return () => {
            clusterer.clearMarkers();
            clusterer.unbindAll();
            clusterer.setMap(null);

            Array.from(markerContainers.values()).forEach((mc) => {
                setTimeout(() => {
                    mc.reactRoot.unmount();
                });
            });
            markerContainers.clear();
        }
    }, [renderCluster]);

    useEffect(() => { // Update clusterer map
        if (map) {
            markerClusterer?.setMap(map);
        }

        return () => {
            markerClusterer?.setMap(null);
        }
    }, [map, markerClusterer]);

    useEffect(() => { // Update clusterer markers
        markerClusterer?.addMarkers(markers);

        return () => {
            markerClusterer?.clearMarkers();
        };
    }, [markers, markerClusterer]);

    useEffect(() => { // Configure brute force refresh to free up memory
        const timer = setTimeout(() => {
            console.log ("Reload page");
            window.location.reload();
        }, 30 * 60 * 1000);
        return () => clearTimeout(timer);
    }, []);

    return <></>;
}

export interface IGoogleMapClusterProps {
    map?: google.maps.Map;
    markers: google.maps.marker.AdvancedMarkerElement[],
}

export interface ITrackedContainer {
    domElement: HTMLSpanElement;
    reactRoot: Root;
    lastUpdated: Date;
}

export default GoogleMapCluster;
