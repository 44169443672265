import { IEngineOutage } from "../../shared/types/operate/IEngineOutage";
import React, { useEffect } from 'react';
import { GasCommercialOverviewUrl, GasEngineOverviewUrl } from "../../shared/utils/DispathWebUtils"
import { Button, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import { Dictionary } from "@reduxjs/toolkit";
import EditIcon from '@mui/icons-material/Edit';
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material";

const headCells: HeadCell[] = [
    {
        id: "remitID",
        label: "Remit ID"
    },

    {
        id: "siteID",
        label: "Site ID"
    },
    {
        id: "engineID",
        label: "Engine ID"
    },
    {
        id: "outageStart",
        label: "Outage Start (Local Time)"
    },
    {
        id: "outageEnd",
        label: "Outage End (Local Time)"
    },
    {
        id: "mwOutage",
        label: "Outage MW"
    },
    {
        id: "comments",
        label: "Reason"
    },
    {
        id: "live",
        label: "Live"
    },
    {
        id: "restriction",
        label: "Outage/Restriction"
    },
    {
        id: "created",
        label: "Created (Local Time)"
    },
    {
        id: "workOrder",
        label: "EAM Work Order"
    }
]

const EngineOutageTable: React.FC<IEngineOutageTableProps> = ({ items, siteIdToName, searchBar, maxHeight = 440 }) => {
    const [orderDescending, setOrderDescending] = React.useState<boolean>(false);
    const [orderCategory, setOrderCategory] = React.useState<keyof IEngineOutage>("remitID");
    const [searchContents, setSearchContents] = React.useState<string>("");
    const navigate = useNavigate();
    const theme = useTheme()

    const sortItems = (inputArray: IEngineOutage[], category: keyof IEngineOutage, descending: boolean) => {
        inputArray.sort((a, b) => {
            const aValue = a[category]
            const bValue = b[category]
            if (aValue === null) { return 1 }
            else if (bValue === null) { return -1 }
            else if (aValue < bValue) { return -1 }
            return 1
        })
        if (descending) {
            inputArray.reverse()
        }
        return inputArray
    }

    useEffect(() => {
        setInternalItems(sortItems(items, orderCategory, orderDescending))
    }, [items])

    if (items === undefined) {
        items = []
    }

    const [internalItems, setInternalItems] = React.useState<IEngineOutage[]>(sortItems(items, orderCategory, orderDescending))

    const handleSearchContentsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchContents(event.target.value as string)
    }

    const handleSort = (categoryID: keyof IEngineOutage) => {
        let descending = false
        if (orderCategory === categoryID && !orderDescending) {
            descending = true
        }
        setOrderDescending(descending)
        setOrderCategory(categoryID)
        setInternalItems(sortItems(internalItems, categoryID, descending))
    }

    const filterOutage = (outage: IEngineOutage) => {
        return(
            outage.remitID.toString().startsWith(searchContents) ||
            outage.siteID.toLowerCase().startsWith(searchContents.toLowerCase()) ||
            siteIdToName[outage.siteID]?.toLowerCase().startsWith(searchContents.toLowerCase()) ||
            outage.engineID.toLowerCase().startsWith(searchContents.toLowerCase()) ||
            outage.comments.toLowerCase().startsWith(searchContents.toLowerCase())
        )
    }

    const navigateToUpdateOutage = (remitId: string) => {
        navigate(`/operate/availability/update-engine-outage?RemitID=${remitId}`);
    }

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            {(searchBar ? <TextField
                id="search-label"
                label="Search"
                value={searchContents}
                onChange={handleSearchContentsChange}
                name="Search"
                placeholder="Search"
                sx={{marginBottom: 1} }
                fullWidth
            /> : <></>)}
            <TableContainer sx={{ maxHeight: maxHeight }}>
                <Table stickyHeader size="small" aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {headCells.map((headCell) => (
                                <TableCell
                                    align="center"
                                    key={headCell.id}
                                >
                                    <IconButton
                                        size="small"
                                        sx={{ "transition": "all .2s", "color": "inherit", "&:hover": { "backgroundColor": "rgba(160, 160, 160, 0.5)" } }}
                                        onClick={() => handleSort(headCell.id)}
                                    >
                                        <Typography variant="subtitle1" fontSize="small">{headCell.label}</Typography>
                                        {headCell.id === orderCategory ? (orderDescending ?  < ArrowDownward fontSize="small" /> : <ArrowUpward fontSize="small" />) : <></>}
                                    </IconButton>
                                </TableCell>
                            ))}
                            <TableCell align="center"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            internalItems
                                .filter((item) => filterOutage(item))
                                .map((item) => {
                                return (
                                    <TableRow key={"outage-table-key-" + item.remitID}>
                                        <TableCell align="center">{item.remitID}</TableCell>
                                        <TableCell align="center"><Button sx={{ width:"100%", color: "white" }} onClick={() => window.open(GasCommercialOverviewUrl(siteIdToName[item.siteID]), '_blank')}>{item.siteID}</Button></TableCell>
                                        <TableCell align="center">{item.engineID.startsWith('G') ? <Button sx={{ width: "100%", color: "white" }} onClick={() => window.open(GasEngineOverviewUrl(siteIdToName[item.siteID], item.engineID), '_blank')}>{item.engineID}</Button> : item.engineID}</TableCell>
                                        <TableCell align="center">{moment(item.outageStart).format("DD-MMM-YY HH:mm:ss")}</TableCell>
                                        <TableCell align="center">{moment(item.outageEnd).format("DD-MMM-YY HH:mm:ss")}</TableCell>
                                        <TableCell align="center">{item.mwOutage.toFixed(1)}</TableCell>
                                        <TableCell align="center">{item.comments}</TableCell>
                                        <TableCell align="center" sx={{ backgroundColor: item.live ? theme.palette.success.dark : theme.palette.error.dark }}>{(item.live ? "True" : "False")}</TableCell>
                                        <TableCell align="center">{(item.restriction ? "Restriction" : "Outage")}</TableCell>
                                        <TableCell align="center">{moment(item.created).format("DD-MMM-YY HH:mm:ss")}</TableCell>
                                        <TableCell align="center">{(item.workOrder === null ? "-" : item.workOrder.toString())}</TableCell>
                                        <TableCell align="center" sx={{ pr: 0 }}><Button sx={{ width: "100%" }} onClick={() => navigateToUpdateOutage(item.remitID)}><EditIcon /></Button></TableCell>
                                    </TableRow>
                                )})
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );

}
interface HeadCell {
    id: keyof IEngineOutage,
    label: string
}
export interface IEngineOutageTableProps {
    items: IEngineOutage[],
    siteIdToName: Dictionary<string>
    searchBar?: boolean,
    maxHeight?: number|string
}

export default EngineOutageTable;