import React, { useEffect, useState } from "react";
import PageWrapper from "../../components/PageWrapper";
import { Box, Paper } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { IMeterReading } from "../../shared/types/IMeterReading";
import ResultsTable from "../../components/data/ResultsTable";
import MeterDataRequestBar from "../../components/data/MeterDataRequestBar";
import { useGetAssetsQuery } from "../../shared/api/AssetApi";
import { IPortalRouteOptions } from "../../shared/types/shared/routes/routeTypes";
import { ElectricMeter } from "@mui/icons-material";
import { IAssetInformation } from "../../shared/types/IAssetInformation";
import { stringPropertySort } from "../../shared/utils/SortingUtils";

const DataMeterValuesPage: React.FC<IDataMeterValuesPage> = () => {
    const [readings, setReadings] = useState<IMeterReading[]>([]);
    const { data: assets, isSuccess: loaded } = useGetAssetsQuery();
    const [sortedAssets, setSortedAssets] = useState<IAssetInformation[]>([]);

    useEffect(() => {
        if (assets) {
            const sorted = [...assets].sort(stringPropertySort("Name"));
            setSortedAssets(sorted);
        }
    }, [assets]);

    const columns = [
        { field: 'siteID', headerName: 'Site Id', width: 150 },
        { field: 'siteName', headerName: 'Site', width: 170 },
        { field: 'dateTimeUTC', headerName: 'Date Time (UTC)', width: 270 },
        { field: 'settlementDate', headerName: 'Settlement Date', width: 200 },
        { field: 'settlementPeriod', headerName: 'SP', width: 200 },
        { field: 'readingkwh', headerName: 'Reading (KWH)', width: 200 },
    ];


    return (
        <PageWrapper title="Meter Values" loaded={loaded}>
            <Paper sx={{ height: "calc(100% - 1px)" }}>
                <Box sx={{ m: 1, height: "100%" }}>
                    <MeterDataRequestBar readings={readings} setReadings={setReadings} assets={sortedAssets} />
                    <Box sx={{ mt: 1 }}>
                        <ResultsTable data={readings} columns={columns} sortBy={"dateTime"} />
                    </Box>
                </Box>
            </Paper>
        </PageWrapper>
    );
}

export interface IDataMeterValuesPage {

}

const DataMeterValuesPageConfig: IPortalRouteOptions = {
    relativeUrl: "meter-values",
    page: <DataMeterValuesPage />,
    navDisplay: {
        title: "Meter Values",
        icon: <ElectricMeter />
    },
}

export default DataMeterValuesPageConfig;