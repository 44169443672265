import PageWrapper from "../../components/PageWrapper";
import React, { useEffect } from "react";
import BalancingPricingChart from "../../components/analytics/BalancingPricingChart";
import { Box } from "@mui/material";
import { Analytics } from "@mui/icons-material";
import { IPortalRouteOptions } from "../../shared/types/shared/routes/routeTypes";

const AnalyticsBalancingPricingPage: React.FC<IAnalyticsBalancingPricingPage> = () => {
    const [loaded, setLoaded] = React.useState(false);

    const loadComplete = () => {
        setLoaded(true);

    }

    useEffect(() => {
        loadComplete()
    }, []);

    return (
        <PageWrapper title="Balancing Pricing Chart" loaded={loaded}>
            <Box sx={{ height: "75vh", maxHeight: "700px", width: "74vw" }} >
                <BalancingPricingChart />
            </Box>
        </PageWrapper>
    );
}


export interface IAnalyticsBalancingPricingPage {

}

const AnalyticsBalancingPricingPageConfig: IPortalRouteOptions = {
    relativeUrl: "balance-pricing",
    page: <AnalyticsBalancingPricingPage />,
    navDisplay: { 
        title: "Balancing Pricing", 
        icon: <Analytics /> 
    },
}

export default AnalyticsBalancingPricingPageConfig;