import * as React from 'react';
import { GanttServiceData, buildGanttViewDataFromContracts } from '../../shared/types/dynamic-contracts/gantt-chart-data';
import { IContractInformation, ServiceType } from '../../shared/types/dynamic-contracts/IDynamicContract';
import { Button, SxProps, Table, TableBody, TableCell, TableHead, TableRow, Theme, Tooltip } from '@mui/material';
import { ganttDateDisplayFormat, ganttDateKeyFormat, getAllDatesInWeeks, getGanttCellBackgroundColour, getGanttCellPercentageText, getGanttCellTextColour, getGanttCellValueText } from '../../shared/utils/GanttChartUtils';
import { Moment } from 'moment';
import DynamicContractsGanttEmpty from './DynamicContractsGanttEmpty';

const daysPerWeek = 7;
const barsPerDay = 1;
const rowsPerService = 1;
const chartCellSxProps: SxProps<Theme> = { p: 1 }

const DummyColumns = (): JSX.Element => {
    return (
        <>
            <TableCell id="site-name-column" sx={{ ...chartCellSxProps }}></TableCell>
            <TableCell id="service-column" sx={{ ...chartCellSxProps }}></TableCell>
        </>
    );
}

const WeekHeaderRow = ({ weeks }: IGanttChartWeekRowProps): JSX.Element => {
    return (
        <TableRow>
            <DummyColumns />
            {weeks.map((week) =>
                <TableCell
                    sx={{ ...chartCellSxProps }} align="center"
                    key={week} colSpan={barsPerDay * daysPerWeek}>
                    Week {week}
                </TableCell>)}
        </TableRow>
    );
}

const DayHeaderRow = ({ dates, onClickWeekDay }: IGanttChartDayRowProps): JSX.Element => {
    return (
        <TableRow>
            <DummyColumns />
            {dates.map((dateObj) => {
                const day = dateObj.format("dddd");
                const date = dateObj.format(ganttDateDisplayFormat);

                return (
                    <React.Fragment key={`${date}-days`}>
                        <TableCell
                            sx={{ ...chartCellSxProps }}
                            colSpan={barsPerDay}
                            align="center"
                            key={`${date}`}>
                            <Button variant="text" onClick={() => onClickWeekDay(dateObj.clone())}>{day}<br />{date}</Button>
                        </TableCell>
                    </React.Fragment>
                );
            })}
        </TableRow>
    );
}

const SummaryRow = ({ displaySiteName, siteName, totalServices, service, dates }: IGanttChartSummaryRowProps): JSX.Element => {
    return (
        <TableRow>
            {displaySiteName && <TableCell sx={{ ...chartCellSxProps }} rowSpan={totalServices * rowsPerService} align="center">{siteName}</TableCell>}
            <TableCell sx={{ ...chartCellSxProps }} rowSpan={rowsPerService} align="center">{ServiceType[service.service]}</TableCell>
            {dates.map((date) => {
                const key = `${siteName}-${service}-${date.toISOString()}-summary`
                const dateData = service.timestampedData[date.format(ganttDateKeyFormat)];

                const data = dateData?.getAllBlockSummary() || {};
                const backgroundColour = getGanttCellBackgroundColour(data);
                const textColour = getGanttCellTextColour(data);

                const sx = { ...chartCellSxProps, border: `solid 1px ${backgroundColour}`, color: textColour, background: backgroundColour, fontWeight: "bold" };

                return (
                    <TableCell key={key} align="center" colSpan={barsPerDay} sx={sx}>
                        {getGanttCellPercentageText(data)}
                        <br />
                        {getGanttCellValueText(data)}
                    </TableCell>);
            })}
        </TableRow>
    );
}

const DynamicContractsGanttWeekView: React.FC<IDynamicContractsGanttWeekViewProps> = ({ contracts, onClickWeekDay }) => {
    const viewData = buildGanttViewDataFromContracts(contracts);
    const allDatesInWeeks = getAllDatesInWeeks(viewData.weeksCovered);
    const allSitesData = Object.values(viewData.data);

    if (allSitesData.length == 0) {
        return (
            <DynamicContractsGanttEmpty />
        );
    }

    return (
        <Table>
            <TableHead>
                <WeekHeaderRow weeks={viewData.weeksCovered} />
                <DayHeaderRow dates={allDatesInWeeks} onClickWeekDay={onClickWeekDay} />
            </TableHead>
            <TableBody>
                {
                    allSitesData.map((siteData) => {
                        const services = Object.values(siteData.serviceData);
                        return (
                            <React.Fragment key={siteData.siteName}>
                                {
                                    services.map((service, serviceIndex) => {
                                        return (
                                            <React.Fragment key={`${siteData.siteName}-${service.service}`}>
                                                <SummaryRow siteName={siteData.siteName} displaySiteName={serviceIndex === 0} service={service} dates={allDatesInWeeks} totalServices={services.length} />
                                            </React.Fragment>
                                        );
                                    })
                                }
                            </ React.Fragment>
                        );
                    })
                }
            </TableBody>
        </Table>
    );
}

interface IGanttChartWeekRowProps {
    weeks: number[];
}

interface IGanttChartDayRowProps {
    dates: Moment[];
    onClickWeekDay: (dateToShow: Moment) => void;
}

interface IGanttChartSummaryRowProps {
    displaySiteName: boolean;
    siteName: string;
    totalServices: number;
    service: GanttServiceData;
    dates: Moment[];
}

export interface IDynamicContractsGanttWeekViewProps {
    contracts: IContractInformation[];
    onClickWeekDay: (dateToShow: Moment) => void;
}

export default DynamicContractsGanttWeekView;
