export interface IHiveDimensions {
    window: IWindowDimensions;
    grid: IHexGridDimensions;
}

export interface IWindowDimensions {
    width: number;
    height: number;
    viewBoxWidth: number;
    viewBoxHeight: number;
}

export interface IHexGridDimensions {
    gridHeight: number;
    gridWidth: number;
    tileRadius: number;
    tileSize: number;
    spacing: number;
    gridOriginX: number;
    gridOriginY: number;
}

export interface IHexGridConfig {
    panelWidth?: number;
    panelHeight?: number;
    heightOffset?: number;
    numberOfRowsWidescreen?: number;
    numberOfRowsUltraWide?: number;
}

const maximalDiameterMultiplier = 1.1547005;
const minimalDiameterMultipler = 0.8660254;
export const getCurrentDimension = (config: IHexGridConfig): IHiveDimensions => {
    const {
        panelWidth = window.innerWidth,
        panelHeight = window.innerHeight,
        heightOffset = 0,
        numberOfRowsWidescreen = 7,
        numberOfRowsUltraWide = 5 
    } = config;

    const screenWidth = panelWidth;
    const screenHeight = panelHeight + heightOffset;

    const diff = screenWidth - screenHeight;
    const increaseAmount = (diff / screenHeight) * 100;

    const viewBoxHeight = 100; // Viewbox height is 100 to allow us to treat sizes as percentages
    const viewBoxWidth = 100 + increaseAmount; // Viewbox width is a percentage relative to viewbox height

    const paddingY = 0;
    const paddingX = 0;

    const isUltraWide = increaseAmount > 175;
    const gridHeight = isUltraWide ? numberOfRowsUltraWide : numberOfRowsWidescreen; // How many hexagon rows we want to fit in

    const tileSpacing = 0.1; // This is the tile spacing value
    const spacingAsScreenPercentage = tileSpacing * viewBoxHeight; // This is the goal spacing expressed as a percentage of the screen height
    const screenSpacingPerTile = spacingAsScreenPercentage / (gridHeight + 1); // This is the amount of screen space each tiles spacing should occupy

    // Sizes are absolute extremities, so 10, 10 means it actually takes up 20%
    const availableHeight = viewBoxHeight - (screenSpacingPerTile * 2); // How much of the viewbox can have hexagons
    const availableWidth = viewBoxWidth - (screenSpacingPerTile * 2); // How much of the viewbox can have hexagons

    const rowHeight = availableHeight / gridHeight; // The space available for each row, assuming no overlap

    const tileOverlapPerHexagon = rowHeight / 4; // Each hexagon overlaps by 25% of its total height at the top and bottom
    const tileOverlapPerRow = tileOverlapPerHexagon - (tileOverlapPerHexagon / gridHeight);
    const spacingOffset = screenSpacingPerTile / 2;

    const rowHeightWithOverlap = rowHeight + tileOverlapPerRow; // This is how tall each row can be with overlap

    const maximumHeightPerTile = rowHeightWithOverlap + (spacingOffset / gridHeight);

    // These values are the actual sizes of the tiles without spacing
    const heightPercentage = maximumHeightPerTile - spacingOffset; // The height of the hexagons as a percentage of screen height
    const maximalRadius = heightPercentage / 2; // Maximal radius is the radius from point to point
    const widthPercentage = heightPercentage * minimalDiameterMultipler; // The width of the tiles (from parallel side to parallel side) as a percentage of screen height
    const minimalRadius = widthPercentage / 2; // Minimal radius is the radius from parallel side to parallel side

    const maximumWidthPerTile = widthPercentage + screenSpacingPerTile;

    // Calculate remaining grid values
    const gridWidth = Math.floor(availableWidth / maximumWidthPerTile); // Calculate how many we can fit in horizontally using the maximum allowable value

    // const horizontalRemainder = (availableWidth % maximumWidthPerTile) -  (((gridWidth/2)-1) * screenSpacingPerTile)
    const horizontalRemainder = (availableWidth % maximumWidthPerTile / 2) + (screenSpacingPerTile / 2);

    const gridOriginX = minimalRadius + horizontalRemainder + paddingX;
    const gridOriginY = maximalRadius + screenSpacingPerTile + paddingY;

    const windowDimensions: IWindowDimensions = {
        width: screenWidth,
        height: screenHeight,
        viewBoxHeight,
        viewBoxWidth: viewBoxWidth,
    }

    const gridDimensions: IHexGridDimensions = {
        gridHeight,
        gridWidth,
        spacing: tileSpacing,
        tileRadius: maximalRadius,
        tileSize: heightPercentage,
        gridOriginX,
        gridOriginY,
    }

    return {
        window: windowDimensions,
        grid: gridDimensions,
    }
}