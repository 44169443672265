import React, { useEffect, useMemo, useState } from "react";
import { Box, Button, CircularProgress, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Tooltip } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import DateRangeSelector from "../../components/data/DateRangeSelector";
import CSVButton from "../../components/data/CSVButton";
import { Moment } from "moment";
import moment from "moment"
import dataRequestApi from "../../shared/api/DataRequestApi";
import { CalendarToday } from "@mui/icons-material";
import PowerPriceOptions from "./PowerPriceOptions";
import GasPriceOptions from "./GasPricesOptions";
import { isMobile } from "react-device-detect";

const PriceDataRequestBar: React.FC<IPriceDataRequestProps> = ({ readings, setReadings, requestType, setRequestType, querying, setQuerying }) => {
    const defaultStartTime = useMemo(() => moment.utc().subtract(1, "day").startOf("day"), []);
    const defaultEndTime = useMemo(() => moment.utc().endOf("day"), []);
    const [queryError, setQueryError] = useState<string>("");
    const [startDate, setStartDate] = useState<Moment>(defaultStartTime);
    const [endDate, setEndDate] = useState<Moment>(defaultEndTime);

    const [marketCategory, setMarketCategory] = useState<string>("Any");
    const [market, setMarket] = useState<string>("");
    const [country, setCountry] = useState<string>("Any");
    const [horizon, setHorizon] = useState<string>("Any");
    const [exchange, setExchange] = useState<string>("Any");


    const [strip, setStrip] = useState<string>("");
    const [stripCategory, setStripCategory] = useState<string>("Any");
    const [forwardHorizon, setForwardHorizon] = useState<string>("Any");
    const [countryGas, setCountryGas] = useState<string>("Any");
    const [timing, setTiming] = useState<string>("Any");
    const [year, setYear] = useState<string>("Any");
    const [commodity, setCommodity] = useState<string>("Any");

    const [marketCategories, setMarketCategories] = useState<string[]>([])
    const [markets, setMarkets] = useState<Map<string, string[]>>(new Map<string, string[]>())
    const [countries, setCountries] = useState<string[]>([])
    const [horizons, setHorizons] = useState<string[]>([])
    const [stripCategories, setStripCategories] = useState<string[]>([])
    const [strips, setStrips] = useState<Map<string, string[]>>(new Map<string, string[]>())
    const [gasCountries, setGasCountries] = useState<string[]>([])
    const [timings, setTimings] = useState<string[]>([])
    const [loadedFilters, setLoadedFilters] = useState<boolean>(false)

    const handleRequestTypeChange = (event: SelectChangeEvent) => {
        setRequestType(event.target.value as string);
    };

    const clickQueryRequestButton = () => {

        setQuerying(true);

        if (requestType === "Power") {
            dataRequestApi.getPowerPriceData(moment(startDate).format(), moment(endDate).format(), (marketCategory === "Any" ? "Any" : market), country, horizon, exchange)
                .then(readings => {
                    readings.forEach(reading => {
                        reading.id = reading.dateTimeUTC + '-' + reading.market + "-" + reading.exchange
                        reading.settlementPeriod = parseInt(reading.settlementPeriod).toString()
                    })
                    setReadings(readings)
                    setQuerying(false)
                });
        }
        else {
            dataRequestApi.getGasPriceData(moment(startDate).format(), moment(endDate).format(), (stripCategory === "Any" ? "Any" : strip), forwardHorizon, countryGas, timing, year, commodity)
                .then(readings => {
                    readings.forEach(reading => reading.id = Math.random().toString())
                    setReadings(readings);
                    setQuerying(false)
                });
        }
        return;
    }

    const loadFilterData = () => {
        dataRequestApi.getFilterData()
            .then(filterData => {
                let marketDict = new Map<string, string[]>()
                let marketList = new Array<string>();
                for (let key in filterData.markets) {
                    marketList.push(key)
                    var markets = filterData.markets[key]
                    markets.sort()
                    marketDict.set(key, markets);
                }
                let stripDict = new Map<string, string[]>()
                let stripList = new Array<string>();
                for (let key in filterData.strips) {
                    stripList.push(key)
                    var strips = filterData.strips[key]
                    strips.sort((a, b) => stripSorter(a, b))
                    stripDict.set(key, strips)
                }
                setMarketCategories(marketList)
                setMarkets(marketDict)
                setCountries(filterData.countries)
                setHorizons(filterData.horizons)
                setStripCategories(stripList)
                setStrips(stripDict)
                setGasCountries(filterData.gasCountries)
                setTimings(filterData.timings)
                setLoadedFilters(true)
            });
    }
    function stripSorter(a: string, b: string) {
        // B.O.M < Q1/2/3/4 < Summer < Winter < Day Ahead < Week Ahead < Weekend < Jan-Dec
        if (a === "B.O.M") {
            return -1
        }
        if (b === "B.O.M") {
            return 1
        }
        var aQIndex = a.indexOf("Q");
        var bQIndex = b.indexOf("Q");
        if (aQIndex > -1 || bQIndex > -1) {
            if (aQIndex > -1 && bQIndex > -1) {
                return (a < b ? -1 : 1)
            }
            else if (aQIndex > -1) {
                return -1
            }
            else {
                return 1
            }
        }
        if (a.includes("Summer")) { return -1 }
        if (b.includes("Summer")) { return 1 }
        if (a.includes("Winter")) { return -1 }
        if (b.includes("Winter")) { return 1 }
        if (a.includes("Day")) { return -1 }
        if (b.includes("Day")) { return 1 }
        if (a.includes("Week Ahead")) { return -1 }
        if (b.includes("Week Ahead")) { return 1 }
        if (a.includes("Weekend")) { return -1 }
        if (b.includes("Weekend")) { return 1 }
        var aMonth = a.substring(0, 3)
        var bMonth = b.substring(0, 3)
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
        var aMonIndex = months.indexOf(aMonth)
        var bMonIndex = months.indexOf(bMonth)
        return (aMonIndex < bMonIndex ? -1 : 1)
    }

    useEffect(() => {
        loadFilterData()
    }, [])

    const requestTypes = [
        "Power",
        "Gas"
    ]

    const powerColumnsCsv = [
        { key: 'dateTimeUTC', label: 'Date Time (UTC)' },
        { key: 'settlementDate', label: 'Settlement Date' },
        { key: 'settlementPeriod', label: 'SP' },
        { key: 'price', label: 'Price' },
        { key: 'priceUnit', label: 'Price Unit' },
        { key: 'volume', label: 'Volume' },
        { key: 'volumeUnit', label: 'Volume Unit' },
        { key: 'market', label: 'Market' },
        { key: 'horizon', label: 'Horizon' },
        { key: 'currency', label: 'Currency' },
        { key: 'country', label: 'Country' },
        { key: 'exchange', label: 'Exchange' },
    ]

    const gasColumnsCsv = [
        { key: 'date', label: 'Date' },
        { key: 'product', label: 'Product' },
        { key: 'strip', label: 'Strip' },
        { key: 'unit', label: 'Unit' },
        { key: 'year', label: 'Year' },
        { key: 'timing', label: 'Timing' },
        { key: 'contFwd', label: 'Cont Fwd' },
        { key: 'fwdPeriod', label: 'Fwd Period' },
        { key: 'commodity', label: 'Commodity' },
        { key: 'market', label: 'Market' },
        { key: 'value', label: 'Value' },
    ]

    const Buttons: React.FC = () => {
        return (
            <>
                <Grid xs={isMobile ? 6 : 3}>
                    <Tooltip title={queryError}>
                        <Box sx={{ position: 'relative' }}>
                            <Button
                                variant="contained"
                                startIcon={<CalendarToday />}
                                disabled={querying || !!queryError}
                                onClick={clickQueryRequestButton}
                                size="large"
                                sx={{ height: "55px" }}
                                fullWidth
                            >
                                Gather Data
                            </Button>
                            {querying && (
                                <CircularProgress
                                    size={24}
                                    sx={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        marginTop: '-12px',
                                        marginLeft: '-12px',
                                    }}
                                />
                            )}
                        </Box>
                    </Tooltip>
                </Grid>
                <CSVButton data={readings} disabled={querying || !!queryError} columns={(requestType === "Power" ? powerColumnsCsv : gasColumnsCsv)} filename={requestType + '_Price_' + moment(startDate).format("YYYYMMDD") + '_to_' + moment(endDate).format("YYYYMMDD") + '.csv'} xs={isMobile ? 6 : 3} />
            </>
        )
    }

    return (
        <Grid
            container
            alignItems="center"
            alignContent="start"
            spacing={isMobile ? 1 : 2}
            justifyContent="flex-start">
            <Grid xs={isMobile ? 12 : 2}>
                <Box>
                    <FormControl fullWidth>
                        <InputLabel id="request-label">Request</InputLabel>
                        <Select
                            labelId="request-label"
                            id="request-label"
                            value={requestType}
                            label="Request"
                            onChange={handleRequestTypeChange}
                        >
                            {
                                requestTypes.map((item) =>
                                    <MenuItem key={item} value={item}>{item}</MenuItem>
                                )
                            }
                        </Select>
                    </FormControl>
                </Box>
            </Grid>
            <DateRangeSelector startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate} maxRange={3} invalidFunction={setQueryError} disabled={querying} xs={isMobile ? 6 : 2} />
            {!isMobile && <Buttons />}
            <PowerPriceOptions market={market} setMarket={setMarket} marketCategory={marketCategory} setMarketCategory={setMarketCategory} country={country} setCountry={setCountry} horizon={horizon} setHorizon={setHorizon} exchange={exchange} setExchange={setExchange} hidden={requestType !== "Power"} disabled={!loadedFilters} marketCategories={marketCategories} marketDict={markets} countries={countries} horizons={horizons} />
            <GasPriceOptions strip={strip} setStrip={setStrip} stripCategory={stripCategory} setStripCategory={setStripCategory} forwardHorizon={forwardHorizon} setForwardHorizon={setForwardHorizon} countryGas={countryGas} setCountryGas={setCountryGas} timing={timing} setTiming={setTiming} year={year} setYear={setYear} commodity={commodity} setCommodity={setCommodity} hidden={requestType !== "Gas"} disabled={!loadedFilters} stripCategories={stripCategories} stripDict={strips} gasCountries={gasCountries} timings={timings} />
            {isMobile && <Buttons /> }
        </Grid>
    );
}

export interface IPriceDataRequestProps {
    readings: any
    setReadings: (readings: any) => void;
    requestType: string;
    setRequestType: (requestType: any) => void;
    querying: boolean;
    setQuerying: (querying: boolean) => void;
}

export default PriceDataRequestBar;