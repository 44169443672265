import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface MapSettingsState {
    sites: boolean;
    engineers: boolean;
    opsAreas: boolean;
    powerInterconnectors: boolean;
    presentationMode: boolean;
}

const initialState: MapSettingsState = {
    sites: true,
    engineers: true,
    opsAreas: true,
    powerInterconnectors: true,
    presentationMode: false
};

export const mapSettingsSlice = createSlice({
    name: "mapSettings",
    initialState,
    reducers: {
        updateSiteLayer: (state, action: PayloadAction<boolean>) => {
            state.sites = action.payload;
        },
        updateEngineersLayer: (state, action: PayloadAction<boolean>) => {
            state.engineers = action.payload;
        },
        updateOpsAreasLayer: (state, action: PayloadAction<boolean>) => {
            state.opsAreas = action.payload;
        },
        updatePowerInterconnectorsLayer: (state, action: PayloadAction<boolean>) => {
            state.powerInterconnectors = action.payload;
        },
        updatePresentationMode: (state, action: PayloadAction<boolean>) => {
            state.presentationMode = action.payload;
        }   
    },
});

export const { updateSiteLayer, updateEngineersLayer, updateOpsAreasLayer, updatePresentationMode, updatePowerInterconnectorsLayer } = mapSettingsSlice.actions;

export default mapSettingsSlice.reducer;
