import { Addchart } from "@mui/icons-material";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import PowerBiReportConfigForm from "./PowerBiReportConfigForm";
import { IAdvancedModalOptions, useModalWindowContext } from "../../../ModalWindow";
import TableToolbar, { ITableToolbarButton } from "../../../shared/table/TableToolbar";
import PowerBiReportConfigRow from "./PowerBiReportConfigRow";
import powerBiApi, { IAnalyticsBIReport } from "../../../../shared/api/PowerBiApi";

import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';

const buildConfigMap = (data: IAnalyticsBIReport[]) => {
    const values = [...data];

    const map = new Map<string, IAnalyticsBIReport[]>();

    values.forEach(element => {
        const values = map.get(element.navSubMenu) || [];
        values.push(element);
        map.set(element.navSubMenu, values);
    });

    return map;
}

const PowerBiReportConfigTable: React.FC<IPowerBiReportConfigTableProps> = ({ data, isFetching }) => {
    const { openModal, updateModal } = useModalWindowContext();
    const [configList, setConfigList] = useState<IAnalyticsBIReport[]>([]);
    const [configMap, setConfigMap] = useState<Map<string, IAnalyticsBIReport[]>>();
    const [formData, setFormData] = useState<IAnalyticsBIReport | undefined>();
    const [createOrUpdate, setCreateOrUpdate] = useState(false);
    const [performingAction, setPerformingAction] = useState(false);

    const [uniqueGroupIds, setUniqueGroupIds] = useState<string[]>([]);
    const [uniqueSubMenus, setUniqueSubMenus] = useState<string[]>([]);

    const [addOrUpdate] = powerBiApi.useAddOrUpdateConfigMutation();
    const [deleteReport] = powerBiApi.useDeleteConfigMutation();
    const [updateNavIndex] = powerBiApi.useUpdateConfigNavIndexMutation();

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const disableActions = useMemo(() => {
        return createOrUpdate || performingAction || isFetching;
    }, [createOrUpdate, performingAction, isFetching]);

    // Initialise map
    useEffect(() => {
        const values = data.map((report) => Object.assign({}, report));

        setConfigList(values);
        setConfigMap(buildConfigMap(values));
    }, [data]);

    // Determine unique values for form
    useEffect(() => {
        if (!performingAction) {
            const titles = new Set<string>();
            const groupIds = new Set<string>();
            const subMenus = new Set<string>();

            configMap?.forEach((values, key) => {
                subMenus.add(key);

                values.forEach((report) => {
                    titles.add(report.navTitle);
                    groupIds.add(report.groupId);
                });
            });

            setUniqueGroupIds(Array.from(groupIds));
            setUniqueSubMenus(Array.from(subMenus));
        }
    }, [configMap, performingAction]);

    // Callbacks
    const updateModalValidity = useCallback((isValid: boolean) => {
        updateModal({ canSubmit: isValid });
    }, [updateModal]);

    // Open configure modal
    const openConfigureReportModal = useCallback(async (report?: IAnalyticsBIReport, error?: string) => {
        const editMode = !!report;
        const formComponent = (
            <PowerBiReportConfigForm
                report={report}
                editMode={editMode}
                onFormUpdated={setFormData}
                onValidityChanged={updateModalValidity}
                existingReports={configList}
                groupIds={uniqueGroupIds}
                submenus={uniqueSubMenus}
            />
        );

        const options: IAdvancedModalOptions = {
            title: editMode ? "Edit Report Configuration" : "Configure New Report",
            content: error,
            contentComponent: formComponent,
            yesText: editMode ? "Update" : "Create",
            noText: "Cancel",
            fullWidth: true,
            canSubmit: editMode,
            error: !!error,
        }

        const confirm = await openModal(options);

        if (!confirm) { return; }

        setCreateOrUpdate(true);
    }, [configList, openModal, uniqueGroupIds, uniqueSubMenus, updateModalValidity]);

    // Open delete modal
    const openDeleteReportModal = async (report: IAnalyticsBIReport) => {
        const options: IAdvancedModalOptions = {
            title: "Delete configuration",
            content: `Are you sure you want to delete "${report.navTitle}"? This cannot be undone.`,
            yesText: "Delete",
            noText: "Cancel",
        }

        const shouldDelete = await openModal(options);

        if (!shouldDelete) { return; }

        setPerformingAction(true);

        deleteReport(report.id)
            .catch(() => {
                // Catch error
            }).finally(() => {
                setPerformingAction(false);
            });
    }

    // Toggle hidden value
    const hiddenToggled = async (report: IAnalyticsBIReport, newValue: boolean) => {
        report.hidden = newValue;

        setPerformingAction(true);

        addOrUpdate(report)
            .catch(() => {
                report.hidden = !newValue;
            })
            .finally(() => {
                setPerformingAction(false);
            });
    };

    // Re-order reports in nav
    const handleDragEnd = (event: any) => {
        const { active, over } = event;

        if (!active || !over) { return; }

        const toMoveId = active.id; // The unique id of the object we're moving
        const toReplaceId = over.id; // This unique id of the object it's replacing

        if (toMoveId === toReplaceId) { return; }

        // Reflect change visually
        const items = [...configList];
        const toMoveIndex = items.findIndex(item => item.id === toMoveId); // The index of the object we're moving
        const toReplaceIndex = items.findIndex(item => item.id === toReplaceId); // The index of the object it's replacing

        const newNavIndex = items[toReplaceIndex].navIndex; // The new nav index for the object we're moving

        const reorderedItems = arrayMove(items, toMoveIndex, toReplaceIndex);
        setConfigList(reorderedItems);
        setConfigMap(buildConfigMap(reorderedItems));

        setPerformingAction(true);
        updateNavIndex({ id: toMoveId, navIndex: newNavIndex })
            .catch(() => {
                setConfigList(items);
                setConfigMap(buildConfigMap(items));
            })
            .finally(() => {
                setPerformingAction(false);
            });
    }

    // Perform create/update
    useEffect(() => {
        if (createOrUpdate && formData && !performingAction) {
            setPerformingAction(true);
            addOrUpdate(formData)
                .catch((e) => {
                    openConfigureReportModal(formData, "Something went wrong when saving your changes.");
                })
                .finally(() => {
                    setCreateOrUpdate(false);
                    setPerformingAction(false);
                });
        }
    }, [formData, createOrUpdate, performingAction, openConfigureReportModal, addOrUpdate]);

    const actionButtons: ITableToolbarButton[] = [
        { tooltipText: "Add New Report", icon: <Addchart />, action: openConfigureReportModal, disabled: disableActions }
    ];

    return (
        <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
            <TableToolbar title="Power BI Reports" buttons={actionButtons} />
            <TableContainer sx={{ maxHeight: "calc(100% - 64px)" }}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ textAlign: "center", width: "34px", pr: 0 }} />
                            <TableCell sx={{ textAlign: "center", width: "34px", pr: 0 }} />
                            <TableCell sx={{ textAlign: "center", width: "60px" }}>Hidden</TableCell>
                            {/* <TableCell sx={{ textAlign: "left", width: "200px" }}>Submenu</TableCell> */}
                            <TableCell>Title</TableCell>
                            <TableCell sx={{ textAlign: "center", width: "34px", pr: 0 }} />
                            <TableCell sx={{ textAlign: "center", width: "115px" }}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {configMap && Array.from(configMap.keys()).map((key) => {
                            const items = (configMap.get(key) || []);
                            return (
                                <Fragment key={key}>
                                    <TableRow>
                                        <TableCell colSpan={6}>
                                            <Typography variant="h6">{key}</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <SortableContext
                                        items={items}
                                        strategy={verticalListSortingStrategy}
                                    >
                                        {items.map((report) =>
                                            <PowerBiReportConfigRow
                                                id={report.id}
                                                key={report.navTitle}
                                                report={report}
                                                disableActions={disableActions}
                                                onEditClicked={openConfigureReportModal}
                                                onDeleteClicked={openDeleteReportModal}
                                                onHiddenToggled={hiddenToggled}
                                            />
                                        )}
                                    </SortableContext>
                                </Fragment>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </DndContext>
    );
}

interface IPowerBiReportConfigTableProps {
    data: IAnalyticsBIReport[];
    isFetching: boolean;
}

export default PowerBiReportConfigTable;
