import React, { useEffect, useState } from 'react';
import moment from "moment";
import { Box, Paper, Typography, useTheme } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { IAuctionProduct, IPerformanceFiles, IPeriodPerformances, IPeriodPerformanceTooltipData, IStackedService, ServiceTypeEnum } from '../../../shared/types/dynamic-contracts/IStackedService';
import { utcToSP } from '../../../shared/utils/SPCalcUtils';
import { Dictionary } from '@reduxjs/toolkit';
import SiteSPElement from './SiteSPElement';
import SiteAuctionProducts from './SiteAuctionProducts';
import { isMobile } from 'react-device-detect';

const SiteEFABlocks: React.FC<ISiteEFABlocks> = ({ currentBlock, id, siteName, setSelectedAuctionProduct, setSelectedSPRollupData }) => {
    const theme = useTheme();
    const [currentHighDataMap, setCurrentHighDataMap] = useState<Dictionary<IPeriodPerformances | null>>({})
    const [currentLowDataMap, setCurrentLowDataMap] = useState<Dictionary<IPeriodPerformances | null>>({})
    const [currentPerformanceFileDict, setCurrentPerformanceFileDict] = useState<Dictionary<IPerformanceFiles | null>>({})
    const [numberOfSPCurrent, setNumberOfSPCurrent] = useState<number>(8)

    useEffect(() => {
        // Setting up default data
        const defaultCurrentData: Dictionary<IPeriodPerformances | null> = {}
        const defaultCurrentFiles: Dictionary<IPerformanceFiles | null> = {}
        if (currentBlock !== null) {
            const startTime = moment(currentBlock.contractPeriod.startTimeUTC + "Z")
            const endTime = moment(currentBlock.contractPeriod.endTimeUTC + "Z")
            while (startTime.isBefore(endTime)) {
                const key = startTime.utc().format("YYYY-MM-DDTHH:mm:SSZ")
                defaultCurrentData[key] = null
                defaultCurrentFiles[key] = null
                startTime.add(30, "minutes")
            }
        }

        // Setting up SP data
        const newCurrentHighDataMap: Dictionary<IPeriodPerformances | null> = structuredClone(defaultCurrentData)
        const newCurrentLowDataMap: Dictionary<IPeriodPerformances | null> = structuredClone(defaultCurrentData)
        currentBlock?.periodPerformances.forEach((periodPerformance) => {
            const key = moment(periodPerformance.periodStart + "Z").utc().format("YYYY-MM-DDTHH:mm:SSZ")
            if (periodPerformance.serviceType === ServiceTypeEnum.High) {
                newCurrentHighDataMap[key] = periodPerformance
            }
            else {
                newCurrentLowDataMap[key] = periodPerformance
            }
        })

        setCurrentHighDataMap(newCurrentHighDataMap)
        setCurrentLowDataMap(newCurrentLowDataMap)
        setNumberOfSPCurrent(Object.keys(defaultCurrentData).length)

        // Setting up file data
        const newCurrentPerformanceFileDict: Dictionary<IPerformanceFiles | null> = structuredClone(defaultCurrentFiles)
        currentBlock?.performanceFiles.forEach((performanceFile) => {
            const key = moment(performanceFile.fileStartDateTime + "Z").utc().format("YYYY-MM-DDTHH:mm:SSZ")
            newCurrentPerformanceFileDict[key] = performanceFile
        })

        setCurrentPerformanceFileDict(newCurrentPerformanceFileDict)
    }, [currentBlock])

    return (
                <Paper
                    sx={{
                        backgroundColor: theme.palette.conradEnergyDeepBlue.dark + "90",
                        borderRadius: "5px",
                        padding: "10px 10px 15px 10px",
                        minHeight: isMobile ? "100px" : "160px" 
                    }}
                >
                    {currentBlock === null ?
                        <Grid container
                            spacing={0}
                            direction="column"
                            alignItems="center"
                            justifyContent="center"
                            sx={{ minHeight: isMobile ? "100px" : "110px" }}>
                            <Typography variant="h5" align="center">No Block Data Found</Typography>
                        </Grid>
                        :
                        <>
                            <Box sx={{ minHeight: "30px" }}>
                                {currentBlock !== null && <SiteAuctionProducts auctionProducts={currentBlock.auctionProducts} setSelectedAuctionProduct={setSelectedAuctionProduct} />}
                            </Box>
                            <Grid
                                container
                                alignItems="center"
                                alignContent="start"
                                spacing={0.4}
                                justifyContent="flex-start"
                                marginTop="5px"
                            >
                        {Object.keys(currentHighDataMap).sort().map((key, index) => {
                                    const sp = utcToSP(key).toString()
                                    const highPeriodPerformance = currentHighDataMap[key] || null
                                    const lowPeriodPerformance = currentLowDataMap[key] || null
                                    const startOfHour = moment(key).utc().startOf("hour").format("YYYY-MM-DDTHH:mm:SSZ")
                                    const performanceFile = currentPerformanceFileDict[startOfHour] || null
                                    return (
                                        <SiteSPElement
                                            key={key}
                                            highPeriodPerformance={highPeriodPerformance}
                                            lowPeriodPerformance={lowPeriodPerformance}
                                            performanceFile={performanceFile}
                                            utcDatetime={key}
                                            sp={sp}
                                            xs={12 / numberOfSPCurrent}
                                            setSelectedSPRollupData={setSelectedSPRollupData}
                                        />
                                    )
                                }
                                )}
                            </Grid>
                        </>
                    }
                </Paper>
    );
}

export interface ISiteEFABlocks {
    currentBlock: IStackedService | null
    id: string
    siteName: string
    setSelectedAuctionProduct: (auctionProduct: IAuctionProduct | null) => void
    setSelectedSPRollupData: (rollupData: IPeriodPerformanceTooltipData | null) => void
}

export default SiteEFABlocks;