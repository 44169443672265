import { InteractionStatus } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import React, { createContext, PropsWithChildren, ReactElement, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import powerBiApi from '../shared/api/PowerBiApi';
import userDataApi from '../shared/api/UserDataApi';
import { loginRequest } from '../shared/auth/config';
import AnalyticsPortalArea from '../shared/routes/AnalyticsRoutes';
import AssetPortalArea from '../shared/routes/AssetRoutes';
import CommercialPortalArea from '../shared/routes/CommercialRoutes';
import DataPortalArea from '../shared/routes/DataRoutes';
import DispatchPortalArea from '../shared/routes/DispatchRoutes';
import OperatePortalArea from '../shared/routes/OperateRoutes';
import OptimisePortalArea from '../shared/routes/OptimiseRoutes';
import SystemAdminPortalArea from '../shared/routes/SystemAdminRoutes';
import UserPortalArea from '../shared/routes/UserRoutes';
import { PortalUser } from '../shared/types/shared/auth/authHelperClasses';
import { IUserDataWithAvatar, IUserGroup, IUserRole } from '../shared/types/shared/auth/userDataTypes';
import { PortalAreaCollection } from '../shared/types/shared/routes/routeHelperClasses';
import { IFlatNavItem } from '../shared/types/shared/routes/routeTypes';
import { addBiReportsToArea } from '../shared/utils/UserDefinedRouteUtils';
import { appInsights } from './ApplicationInsightsService';
import ColourModeContextProvider from './ColourMode';
import ModalWindowContextProvider from './ModalWindow';
import ToastAlertContextProvider from './ToastAlert';
import { useGetAllPermissionsQuery } from '../shared/api/PermissionsApi';

interface IPortalContextProps {
    isAuthenticated: boolean;
    signIn: () => Promise<void>;
    signOut: () => Promise<void>;
    currentUser?: PortalUser;
    portalAreas?: PortalAreaCollection;
    routes?: ReactElement[];
    navItems?: Map<string, IFlatNavItem[]>;
    allGroups?: IUserGroup[];
}

const defaultProps: IPortalContextProps = {
    isAuthenticated: false,
    signIn: async () => { },
    signOut: async () => { },
}

const PortalContext = createContext<IPortalContextProps>(defaultProps);

const PortalContextProvider: React.FC<PropsWithChildren<any>> = ({ children }: PropsWithChildren<{}>) => {
    const { data: configuredReports } = powerBiApi.useGetConfigsQuery(undefined, { pollingInterval: 10 * 60 * 1000 });
    const { data: userPermissions } = useGetAllPermissionsQuery(undefined, { pollingInterval: 10 * 60 * 1000 });
    const isAuthenticated = useIsAuthenticated();
    const { instance, inProgress } = useMsal();
    const [currentUser, setCurrentUser] = useState<PortalUser>();
    const [portalAreas, setPortalAreas] = useState<PortalAreaCollection>();
    const [routes, setRoutes] = useState<ReactElement[]>();
    const [navItems, setNavItems] = useState<Map<string, IFlatNavItem[]>>();
    const [allGroups, setAllGroups] = useState<IUserGroup[]>();

    const signIn = useCallback(async () => {
        return await instance.loginRedirect(loginRequest);
    }, [instance]);

    const signOut = useCallback(async () => {
        return await instance.logoutRedirect();
    }, [instance]);

    const reloadRoutes = useCallback(() => {        
        if (configuredReports) {
            addBiReportsToArea(AnalyticsPortalArea, configuredReports);
        }

        if (portalAreas && currentUser) {
            setRoutes(portalAreas.getAllowedRoutes(currentUser));
            setNavItems(portalAreas.getAllNavs(currentUser));            
        }
    }, [currentUser, portalAreas, configuredReports]);

    const isAuthenticationComplete = useMemo(() => {
        return isAuthenticated && !!currentUser;
    }, [isAuthenticated, currentUser]);

    // Load current user data
    useEffect(() => {        
        const authProcess = async () => {            

            if (isAuthenticated && !currentUser && inProgress === InteractionStatus.None && userPermissions) {
                const promises = [];
                promises.push(userDataApi.getUserData());
                promises.push(userDataApi.getUserRoles());
                promises.push(userDataApi.getAllGroups());

                Promise.all(promises).then(async ([userData, userRoles, allGroups]) => {
                    const user = userData as IUserDataWithAvatar;
                    const roles = userRoles as IUserRole[];
                    const groups = allGroups as IUserGroup[];

                    setCurrentUser(new PortalUser(user, roles, userPermissions));
                    setAllGroups(groups);

                    appInsights.setAuthenticatedUserContext(user.userPrincipalName, user.id, true);
                }).catch((error) => {                    
                    throw error;
                });
            };            
        }

        authProcess();

    }, [isAuthenticated, currentUser, inProgress, userPermissions]);

    // Load portal areas
    useEffect(() => {
        const allAreas = [
            OptimisePortalArea,
            OperatePortalArea,
            AnalyticsPortalArea,
            DispatchPortalArea,
            AssetPortalArea,
            DataPortalArea,
            UserPortalArea,
            SystemAdminPortalArea,
            CommercialPortalArea
        ];

        setPortalAreas(new PortalAreaCollection(allAreas));        
    }, []);

    // Load routes
    useEffect(() => {
        reloadRoutes();
    }, [reloadRoutes]);

    return (
        <PortalContext.Provider value={{ isAuthenticated: isAuthenticationComplete, signIn, signOut, currentUser, portalAreas, routes, navItems, allGroups }}>
            <ColourModeContextProvider>
                <ToastAlertContextProvider>
                    <ModalWindowContextProvider>
                        {children}
                    </ModalWindowContextProvider>
                </ToastAlertContextProvider>
            </ColourModeContextProvider>
        </PortalContext.Provider>
    )
}

export const usePortalContext = () => useContext(PortalContext);
export default PortalContextProvider;
