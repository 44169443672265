import { SnackbarProvider } from 'notistack';
import React, { PropsWithChildren, useEffect } from "react";
import { useGetNotificationsQuery } from "../../shared/api/NotificationsApi";
import useNotificationMenu from "../../shared/hooks/NotificationMenuHook";
import { IPushNotification } from "../../shared/types/notification/IPushNotification";
import { GetMillisecondsFromMinutes } from "../../shared/utils/PortalUtils";
import CustomNotification from './CustomNotification';
import LiveNotifications from './LiveNotifications';
import NotificationHistory from './NotificationHistory';

declare module "notistack" {
    interface VariantOverrides {
        customNotification: {
            notification: IPushNotification
        }
    }
}

const NotificationSubscriber: React.FC<PropsWithChildren<INotificationSubscriber>> = ({ children, authenticated }) => {
    const { setNotifications } = useNotificationMenu();
    const { data: notifications, isFetching } = useGetNotificationsQuery(undefined, { pollingInterval: GetMillisecondsFromMinutes(2) });

    useEffect(() => {
        if (notifications) {
            setNotifications(notifications);
        }
    }, [isFetching]);

    return (
            <SnackbarProvider
                autoHideDuration={4000}
                anchorOrigin={{ "horizontal": "right", "vertical": "bottom" }}
                Components={{
                    customNotification: CustomNotification
                }}
            >
                {(authenticated ? <LiveNotifications/> : <></>)}
                {(authenticated ? <NotificationHistory/> : <></>)}
            {children}
            </SnackbarProvider>
        
  );
}
export interface INotificationSubscriber {
    authenticated: boolean
}
export default NotificationSubscriber;