import React, { useEffect } from "react";
import { Box, Grid, Paper, Tab, Typography } from "@mui/material";
import moment, { Moment } from "moment";
import { Dictionary } from "@reduxjs/toolkit";
import EngineOutageTable from "../EngineOutageTable";
import { IEngineOutage } from "../../../shared/types/operate/IEngineOutage";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { OperateType } from "../../../shared/types/operate/OperateType";
import { IAssetAvailability } from "../../../shared/types/operate/IAssetAvailability";
import AssetAvailabilityChart from "../AssetAvailabilityChart";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { IOutageOperationResponse } from "../../../shared/types/operate/IOutageOperationResponse";

const utcDateTimeStamp = (dateObject: Date, format?: boolean): string => {
    if (format) {
        return moment(dateObject).utc().format('YYYY-MM-DD HH:mm:ss')
    }
    else {
        return moment(dateObject).utc().format()
    }
}

const localDateTimeStamp = (dateObject: Date, format?: boolean): string => {
    if (format) {
        return moment(dateObject).format('YYYY-MM-DD HH:mm:ss')
    }
    else {
        return moment(dateObject).format()
    }
}

const PropertyUpdateElement: React.FC<IPropertyUpdateElement> = ({ propertyKey, propertyName, initialOutage, newOutage, type, localTime }) => {
    const [initialValue, setInitialValue] = React.useState<string>("")
    const [newValue, setNewValue] = React.useState<string>("")

    useEffect(() => {
        if (initialOutage) {
            if (propertyKey === "outageStart" || propertyKey === "outageEnd") {
                setInitialValue(localTime ? localDateTimeStamp(initialOutage[propertyKey], true) : utcDateTimeStamp(initialOutage[propertyKey], true))
            }
            else if (propertyKey === "restriction") {
                setInitialValue(initialOutage[propertyKey] ? "Restriction" : "Outage")
            }
            else if (propertyKey === "mwOutage") {
                setInitialValue(initialOutage[propertyKey] + " MW")
            }
            else {
                if (initialOutage[propertyKey]) {
                    setInitialValue((initialOutage[propertyKey] || "").toString())
                }
                else {
                    setInitialValue("")
                }
            }
        }
    }, [initialOutage])

    useEffect(() => {
        if (newOutage) {
            if (propertyKey === "outageStart" || propertyKey === "outageEnd") {
                setNewValue(localTime ? localDateTimeStamp(newOutage[propertyKey], true) : utcDateTimeStamp(newOutage[propertyKey], true))
            }
            else if (propertyKey === "restriction") {
                setNewValue(newOutage[propertyKey] ? "Restriction" : "Outage")
            }
            else if (propertyKey === "mwOutage") {
                setNewValue(newOutage[propertyKey] + " MW")
            }
            else {
                if (newOutage[propertyKey]) {
                    setNewValue((newOutage[propertyKey] || "").toString())
                }
                else {
                    setNewValue("")
                }
            }
        }
    }, [newOutage])

    return (
        (initialValue !== newValue || type === "Submit" || type === "Delete" ?
            <>
                <Grid item xs={12}><Typography sx={{ textDecoration: "underline" }} variant="h6">{propertyName}:</Typography></Grid>
                <Grid item xs={12}>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        }}>
                            {(type === "Submit" || type === "Delete" ?
                                <span>{(type === "Delete" ? initialValue : newValue)}</span>
                                :
                                <>
                                    <span>{(initialValue === "" ? "NULL" : initialValue)}</span>
                                    <ArrowRightAltIcon />
                                    <span>{(newValue === "" ? "NULL" : newValue)}</span>
                                </>
                            )}
                    </div>
                </Grid>
            </>
        : <></>)
    )
}

const UpdateConfirm: React.FC<IUpdateConfirm> = ({ initialOutage, newOutage, type, siteIdToName, siteIdToCapacity, repeating, repeatingInterval, repeatUntil, modifyAll, outageOpData }) => {
    const [availabilityData, setAvailabilityData] = React.useState<IAssetAvailability[]>([])
    const [outageData, setOutageData] = React.useState<IEngineOutage[]>([])
    const [otherInfoPanel, setOtherInfoPanel] = React.useState<string>("Availability");

    const handleInfoPanelChange = (event: any, newValue: string) => {
        setOtherInfoPanel(newValue);
    };

    useEffect(() => {
        setAvailabilityData(outageOpData.newAvailability)
        setOutageData(outageOpData.updatedOutageList)
    }, [outageOpData])

    return (
        <Box>
            {(type === "Delete" ?
                <Paper sx={{ p: 2, mb: 2 }} elevation={2}>
                    <Typography variant="h5" color="error">Warning</Typography>
                    <Typography variant="body1" color="error" style={{ wordWrap: "break-word" }}>
                        Only delete outages if they have been submitted erroneously or the outage has been cancelled.
                        <br></br>
                        Outages that have finished should not be deleted but instead kept for record keeping purposes.
                    </Typography>
                </Paper> : <></>)}
            <Typography variant={"h5"}>
                {(type === "Submit" || type === "Delete" ? "Values:" : "Differences:")}
            </Typography>
            {modifyAll &&
                <Typography variant={"h6"}>
                    *{type === "Delete" ? "Deleting" : "Modifying"} all instances of
                </Typography>
            }
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
            >
                <PropertyUpdateElement initialOutage={initialOutage} newOutage={newOutage} propertyKey="siteID" propertyName="Asset" type={type} />
                <PropertyUpdateElement initialOutage={initialOutage} newOutage={newOutage} propertyKey="engineID" propertyName="Engine" type={type} />
                <PropertyUpdateElement initialOutage={initialOutage} newOutage={newOutage} propertyKey="outageStart" propertyName="Outage Start (UTC)" type={type} />
                <PropertyUpdateElement initialOutage={initialOutage} newOutage={newOutage} propertyKey="outageEnd" propertyName="Outage End (UTC)" type={type} />
                <PropertyUpdateElement initialOutage={initialOutage} newOutage={newOutage} propertyKey="outageStart" propertyName="Outage Start (Local Time)" type={type} localTime={true} />
                <PropertyUpdateElement initialOutage={initialOutage} newOutage={newOutage} propertyKey="outageEnd" propertyName="Outage End (Local Time)" type={type} localTime={true} />
                <PropertyUpdateElement initialOutage={initialOutage} newOutage={newOutage} propertyKey="outageType" propertyName="Outage Type" type={type} />
                <PropertyUpdateElement initialOutage={initialOutage} newOutage={newOutage} propertyKey="restriction" propertyName="Outage/Restriction" type={type} />
                <PropertyUpdateElement initialOutage={initialOutage} newOutage={newOutage} propertyKey="mwOutage" propertyName="Lost Availability" type={type} />
                <PropertyUpdateElement initialOutage={initialOutage} newOutage={newOutage} propertyKey="comments" propertyName="Reason" type={type} />
                <PropertyUpdateElement initialOutage={initialOutage} newOutage={newOutage} propertyKey="detail" propertyName="Comments" type={type} />
                <PropertyUpdateElement initialOutage={initialOutage} newOutage={newOutage} propertyKey="workOrder" propertyName="EAM Work Order" type={type} />
                <PropertyUpdateElement initialOutage={initialOutage} newOutage={newOutage} propertyKey="bucket" propertyName="Bucket" type={type} />
                {repeating && type === "Submit" &&
                    <Grid item xs={12}>
                        <Typography>*Repeating every {repeatingInterval} until {repeatUntil.utc().format("DD-MMM-YYYY")}</Typography>
                    </Grid>
                }
                <Grid item xs={12} sx={{ width: "100%" }}>
                    <TabContext value={otherInfoPanel}>
                        <TabList onChange={handleInfoPanelChange} aria-label="OtherInfoPanels" variant="fullWidth">
                            <Tab label="New Availability" value="Availability" />
                            <Tab label="Other Outages" value="Outages" />
                        </TabList>
                        <TabPanel value="Availability">
                            <div style={{ height: 350, width: "100%" }}>
                                {(availabilityData.length > 0 ? <AssetAvailabilityChart items={availabilityData} capacity={siteIdToCapacity[(type === "Delete" ? initialOutage?.siteID || "" : newOutage?.siteID || "")] || 0} outages={outageData}></AssetAvailabilityChart> : <>No availability data available</>)}
                            </div>
                        </TabPanel>
                        <TabPanel value="Outages">
                            <div style={{ height: 350, width: "100%" }}>
                            {(outageData.length > 0 ? <EngineOutageTable items={outageData.filter((outage) => outage.remitID && parseInt(outage.remitID) > 0)} siteIdToName={siteIdToName} searchBar={false}></EngineOutageTable> : <>No outages at this time</>)}
                            </div>
                        </TabPanel>
                    </TabContext>
                </Grid>
            </Grid>
        </Box>
    );
}

interface IPropertyUpdateElement {
    propertyKey: keyof IEngineOutage
    propertyName: string
    initialOutage: IEngineOutage | null
    newOutage: IEngineOutage | null
    type: OperateType
    localTime?: boolean
}
export interface IUpdateConfirm {
    initialOutage: IEngineOutage | null
    newOutage: IEngineOutage | null
    type: OperateType
    siteIdToName: Dictionary<string>
    siteIdToCapacity: Dictionary<number>
    repeating: boolean
    repeatingInterval: string
    repeatUntil: Moment
    modifyAll: boolean
    outageOpData: IOutageOperationResponse
}

export default UpdateConfirm;