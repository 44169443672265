import { Analytics } from "@mui/icons-material";
import AnalyticsPowerBiLandingPageConfig from "../../pages/analytics/power-bi/AnalyticsPowerBiLandingPage";
import AnalyticsPowerBiReportsPageConfig from "../../pages/analytics/power-bi/AnalyticsPowerBiReportsPage";
import { IAnalyticsBIReport } from "../api/PowerBiApi";
import { PortalArea, PortalRoute } from "../types/shared/routes/routeHelperClasses";

export const addBiReportsToArea = (area: PortalArea, reports: IAnalyticsBIReport[]) => {
    const filteredReports = reports.filter((r) => !r.hidden);
    if (filteredReports.length <= 0) { return; }

    const powerBiSubMenu = area.addTransientChildRoute("reportConfigs", AnalyticsPowerBiLandingPageConfig);
    const powerBiChildSubMenus = new Map<string, PortalRoute>();

    filteredReports.forEach((report) => {
        const submenu = report.navSubMenu.replaceAll(" ", "-").toLowerCase();
        let childSubmenu = powerBiChildSubMenus.get(submenu);

        if (!childSubmenu) {
            childSubmenu = powerBiSubMenu.addChildRoute({ relativeUrl: submenu, navDisplay: { title: report.navSubMenu, icon: <Analytics /> } });
            powerBiChildSubMenus.set(submenu, childSubmenu);
        }

        childSubmenu.addChildRoute(AnalyticsPowerBiReportsPageConfig(report));
    });
};
