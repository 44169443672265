import moment from "moment";

// Takes a UTC datetime string and returns the SP it belongs to
export const utcToSP = (dateTimeString: string) => {
	const dateTimeObject = moment(dateTimeString).utc();
	const date = dateTimeObject.clone().startOf("day")
	var dateClocksGoForward = lastSunday(date.year(), 3);
	var dateClocksGoBack = lastSunday(date.year(), 10);

	// Strictly between dates of clocks changing
	if (date.valueOf() < dateClocksGoBack.valueOf() && date.valueOf() > dateClocksGoForward.valueOf()) {
		dateTimeObject.add(1, "hour");
		return ((dateTimeObject.hours() * 2) + Math.floor(dateTimeObject.minutes() / 30) + 1);
	}
	// On last sunday of March
	else if (date.valueOf() === dateClocksGoForward.valueOf()) {
		return ((((dateTimeObject.hours() * 2) + Math.floor(dateTimeObject.minutes() / 30)) % 46) + 1);
	}
	// On last sunday of October
	else if (date.valueOf() === dateClocksGoBack.valueOf()) {
		return ((dateTimeObject.hours() * 2) + Math.floor(dateTimeObject.minutes() / 30) + 3);
	}
	// Strictly outside dates of clocks changing
	else {
		return ((dateTimeObject.hours() * 2) + Math.floor(dateTimeObject.minutes() / 30) + 1);
	}
}

// Takes a year and a month (1 = Jan, 2 = Feb, ...) and returns moment of the date the last sunday of that month
export const lastSunday = (year: number, month: number) => {
	var date = moment(year + "-" + (month + 1).toString().padStart(2, "0") + "-01T12:00:00")
	let weekday = date.day();
	let dayDiff = weekday === 0 ? 7 : weekday;
	let lastSunday = date.subtract(dayDiff, "days").startOf("day")
	return lastSunday;
}