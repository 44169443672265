import { IIglooAssetTrade } from "../../shared/types/IIglooAssetTrade"
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import React from 'react';
import moment from "moment"

const TradesTable: React.FC<ITradesTableProps> = ({ items, height = 422 }) => {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(100);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <>
            <TableContainer sx={{ height: height-52 }}>
                <Table stickyHeader size="small" aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">Asset</TableCell>
                            <TableCell align="center">Schedule Date</TableCell>
                            <TableCell align="center">Settlement Period</TableCell>
                            <TableCell align="center">Dispatch Time UTC</TableCell>
                            <TableCell align="center">Buy Sell</TableCell>
                            <TableCell align="center">Volume</TableCell>
                            <TableCell align="center">VWAP</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            items
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((item, index) => {
                                return (
                                    <TableRow key={index}>
                                        <TableCell align="center">{item.asset}</TableCell>
                                        <TableCell align="center">{moment(item.scheduleDate).format("DD-MMM-YYYY")}</TableCell>
                                        <TableCell align="center">{item.settlementPeriod}</TableCell>
                                        <TableCell align="center">{moment(item.dispatchTimeUTC).utc().format("DD-MMM-YYYY HH:mm")}</TableCell>
                                        <TableCell align="center" sx={{ color:item.buySell === "0" ? "red" : "limegreen" } }>{item.buySell}</TableCell>                                      
                                        <TableCell align="center">{item.volume}</TableCell>
                                        <TableCell align="center">{item.vwap}</TableCell>
                                    </TableRow>
                                )})
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={items.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    );

}

export interface ITradesTableProps {
    items: IIglooAssetTrade[];
    height?: number;
}

export default TradesTable;