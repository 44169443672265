import { Moment } from "moment";
import { useEffect } from "react";
import { getApiAccessToken } from "../../auth/helpers";
import { Counterparty } from "../../enums/CounterpartyEnum";
import { getData, refreshData, updateAllocateCompleted, updateAllocateSPARKCompleted } from "../../state/slices/DailyMeteredSlice";
import { useAppDispatch, useAppSelector } from "../StateHook";

const useDailyMetered = (date: Moment) => {
    const dispatch = useAppDispatch();
    const { data, lastRefresh, totalResidual, isDataBeingRefreshed, allocateTradesCompletedPerc, allocateSPARKTradesCompletedPerc, hasCounterpartyCashExposure, totalSPARKVolume } =
        useAppSelector((state) => state.dailyMetered);

    const refresh = () => {
        const run = async () => {
            const dateOnly = date.startOf("day");
            dispatch(refreshData(dateOnly.toDate()));
        };
        run();
    };

    const allocateTrades = async (gasDay: Moment, pricePerTherm: number, counterparty: Counterparty): Promise<string | null> => {
        let responseMessage = null;
        const run = async () => {
            try {
                await submitTrades(gasDay, pricePerTherm, counterparty);
            }
            catch (ex: any) {
                responseMessage = ex;
            }
        };
        await run();
        return responseMessage;
    };

    const submitTrades = async (gasDay: Moment, pricePerTherm: number, counterparty: Counterparty): Promise<void> => {
        const accessToken = await getApiAccessToken();

        const postData = {
            pricePerTherm: pricePerTherm,
            totalVolume: counterparty === Counterparty.Corona ? totalResidual : totalSPARKVolume,
            tradeItems: counterparty === Counterparty.Corona ? data : null,
            counterpartyName: counterparty,
            gasDay: gasDay.format()
        };

        return new Promise((resolve, reject) => {
            fetch(`${process.env.REACT_APP_BASE_API_URL}gas-rec/dailymetered/allocate`,
                {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${accessToken}`, "Api-Key": `${process.env.REACT_APP_BASE_API_KEY}` },
                    body: JSON.stringify(postData)
                })
                .then(async response => {
                    const reader = response.body?.getReader();
                    if (!reader) {
                        throw new Error('Failed to read response');
                    }
                    const decoder = new TextDecoder();

                    while (true) {
                        const { done, value } = await reader.read();
                        if (done) break;
                        if (!value) continue;
                        const rawValue = decoder.decode(value);
                        const completePerc = parseFloat(rawValue.replace(/[^\d.-]/g, ''));

                        if (counterparty === Counterparty.Corona) {
                            dispatch(updateAllocateCompleted(completePerc));
                        }
                        else {
                            dispatch(updateAllocateSPARKCompleted(completePerc));
                        }
                    }
                    reader.releaseLock();
                    resolve();
                })
                .catch((ex: any) => {
                    reject(ex);
                });
        });
    }

    const loadData = () => {
        const run = async () => {
            const dateOnly = date.startOf("day");
            dispatch(getData(dateOnly.toDate()));  
            dispatch(updateAllocateCompleted(0));            
            dispatch(updateAllocateSPARKCompleted(0));  
        };
        run();
    };

    useEffect(() => {
        loadData();
    }, [date, dispatch]);

    const response = {
        data,
        lastRefresh,
        totalResidual,
        isDataBeingRefreshed,
        refresh,
        allocateTrades,
        allocateTradesCompletedPerc,
        allocateSPARKTradesCompletedPerc,
        loadData,
        hasCounterpartyCashExposure,
        totalSPARKVolume
    };

    return response;
}

export default useDailyMetered;
