import React from "react";
import { Box, InputAdornment, TextField } from "@mui/material";

const OutageFormNumber: React.FC<IOutageFormNumber> = ({ label = "", value, setValue, units, disabled = false }) => {

    const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value as string)
    }

    return (
        <Box>
            <TextField
                id={label}
                label={label}
                variant="outlined"
                type="number"
                value={value}
                InputProps={{ endAdornment: units ? <InputAdornment position="end">{units}</InputAdornment> : <></> }}
                onChange={handleValueChange}
                disabled={disabled}
                fullWidth
            />
        </Box>
    );
}
export interface IOutageFormNumber {
    label?: string
    value: string
    setValue: (value: string) => void
    units?: string
    disabled?: boolean
}

export default OutageFormNumber;