import { Paper, Typography, useTheme } from "@mui/material"
import { useEffect, useState } from "react";
import { IAuctionProduct, ServiceTypeEnum } from "../../../shared/types/dynamic-contracts/IStackedService";
import { extractProductType } from "./SiteAuctionProducts"

const AuctionProductTooltip: React.FC<IAuctionProductTooltip> = ({ auctionProduct }) => {
    const theme = useTheme()
    const cColor = theme.palette.conradEnergyCyan.light
    const mColor = theme.palette.conradEnergyMagenta.dark
    const rColor = theme.palette.error.dark

    const [borderColor, setBorderColor] = useState<string>(cColor)

    useEffect(() => {
        if (auctionProduct !== null) {
            const productType = extractProductType(auctionProduct)
            if (productType === "DCL" || productType === "DCH") { setBorderColor(cColor) }
            else if (productType === "DML" || productType === "DMH") { setBorderColor(mColor) }
            else if (productType === "DRL" || productType === "DRH") { setBorderColor(rColor) }
        }
    }, [auctionProduct])

    return (
        <>
            {
                auctionProduct !== null &&
                <Paper
                        sx={{
                            padding: "10px",
                            border: "3px solid " + borderColor
                        }}
                    >
                    <Typography variant="h6">Auction Product:</Typography>
                    <Typography>Unit ID: {auctionProduct.auctionUnit}</Typography>
                    <Typography>Product Type: {extractProductType(auctionProduct)}</Typography>
                    <Typography>Clearing Price: {auctionProduct.clearingPrice}</Typography>
                    <Typography>Executed Quantity: {auctionProduct.executedQuantity}</Typography>
                    <Typography>Service Type: {auctionProduct.serviceType === ServiceTypeEnum.High ? "High" : "Low"}</Typography>
                    <Typography>Technology Type: {auctionProduct.technologyType}</Typography>
                </Paper>
            }
        </>

    )
}

export interface IAuctionProductTooltip {
    auctionProduct: IAuctionProduct | null
}

export default AuctionProductTooltip