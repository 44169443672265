import { Box, Paper, TextField, Typography, useTheme } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import React, { useCallback, useEffect } from "react";
import PageWrapper from "../../../components/PageWrapper";

import marketAnalyticsApi from "../../../shared/api/MarketAnalyticsApi";

import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { Dictionary } from "@reduxjs/toolkit";
import moment, { Moment } from "moment";
import SrmcTable from '../../../components/optimise/SrmcTable';
import { useToastAlertContext } from '../../../components/ToastAlert';
import { useGetAssetsQuery } from "../../../shared/api/AssetApi";
import { IAnalyticsSrmc } from "../../../shared/types/IAnalyticsSrmc";
import { IPortalRouteOptions } from "../../../shared/types/shared/routes/routeTypes";
import { CurrencyPound } from "@mui/icons-material";
import { isMobile } from "react-device-detect";

const StrikePricePage: React.FC<IStrikePricePageProps> = () => {
    const theme = useTheme();
    const { popToast } = useToastAlertContext();
    const [loaded, setLoaded] = React.useState(false);
    const { data: assets } = useGetAssetsQuery();

    const [srmcSites, setSrmcSites] = React.useState<string[]>([])
    const [selectedDate, setSelectedDate] = React.useState<string>("")
    const [selectedAsset, setSelectedAsset] = React.useState<string>("")

    const [allSrmc, setAllSrmc] = React.useState<IAnalyticsSrmc[]>([]);
    const [selectedSrmc, setSelectedSrmc] = React.useState<IAnalyticsSrmc[]>([]);

    const [siteIdToName, setSiteIdToName] = React.useState<Dictionary<string>>({})

    const loadComplete = () => {
        setLoaded(true);
    }

    const fetchSrmc = useCallback(async (value: Moment | null) => {
        if (!value) { return; }

        return marketAnalyticsApi.getSrmc(new Date(value.format("YYYY-MM-DD")))
            .then((data) => {
                var uniqueSites = Array.from(new Set(data.map((item: IAnalyticsSrmc) => item.siteId)))
                setSrmcSites(uniqueSites);
                setAllSrmc(data);
            })
            .catch(error => { throw new Error("Unable to get SRMC data: " + error); });
    }, []);

    const loadData = useCallback(() => {
        var dict: Dictionary<string> = {}
        assets?.forEach((item) => dict[item.SiteID] = item.Name);
        setSiteIdToName(dict);

        var currentDay = moment().format("YYYY-MM-DD");
        setSelectedDate(currentDay);

        const promises = [fetchSrmc(moment())];
        Promise.all(promises)
            .catch((error: Error) => popToast(error.message, "error"))
            .finally(loadComplete);
    }, [fetchSrmc, popToast, assets]);

    const handleSelectedDateChange = (value: Moment | null) => {
        if (value != null) {
            setSelectedDate(value.format("YYYY-MM-DD"));
            fetchSrmc(value);
            handleAssetClick("?");
        }
    }

    const handleAssetClick = (index: string) => {
        setSelectedAsset(index);
        setSelectedSrmc(allSrmc.filter((item) => item.siteId === index));
    }

    useEffect(() => {
        loadData();
    }, [loadData])

    return (
        <PageWrapper title="Strike Price" loaded={loaded} contentOverflow={"hidden"}>
            <Paper sx={{ height: "calc(100% - 1px)" }}>
                <Box m={1} height="100%">
                    <Grid container spacing={2} height="100%">
                        <Grid xs={isMobile ? 12 : 3} height={isMobile ? "50%" : "100%"}>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DatePicker
                                    label=""
                                    inputFormat="YYYY-MM-DD"
                                    value={selectedDate}
                                    onChange={handleSelectedDateChange}
                                    renderInput={(params) => <TextField {...params} fullWidth />}
                                />
                            </LocalizationProvider>
                            <List sx={{
                                mt: 1,
                                width: '100%',
                                bgcolor: 'background.paper',
                                position: 'relative',
                                overflow: 'auto',
                                height: "calc(100% - 64px)",
                                '& ul': { padding: 0 },
                            }} dense={true} disablePadding={true}>
                                {srmcSites.map((item) =>
                                    <ListItemButton dense={true} key={item} onClick={() => { handleAssetClick(item) }}>
                                        <ListItemText
                                            primary={item}
                                            secondary={"(" + siteIdToName[item] + ")"}
                                        />
                                    </ListItemButton>,
                                )}
                            </List>
                        </Grid>
                        <Grid xs={isMobile ? 12 : 9} height={isMobile ? "50%" : "100%"}>
                            <SrmcTable height="100%" items={selectedSrmc}></SrmcTable>
                        </Grid>
                    </Grid>
                </Box>
            </Paper>
        </PageWrapper>
    );
}

export interface IStrikePricePageProps {

}

const StrikePricePageConfig: IPortalRouteOptions = {
    relativeUrl: "strike-price",
    page: <StrikePricePage />,
    navDisplay: {
        title: "Strike Price",
        icon: <CurrencyPound />
    }
}

export default StrikePricePageConfig;
