import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getApiAccessToken } from "../auth/helpers";
import { IMetadataAttributeSetting } from "../types/system-admin/IMetadataAttributeSetting";

export const metadataApi = createApi({
    reducerPath: 'metadataApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_PORTAL_API_URL}`,
        prepareHeaders: async (headers: Headers, { getState }) => {
            const accessToken = await getApiAccessToken();
            if (accessToken) {
                headers.set("Authorization", `Bearer ${accessToken}`);
            }
        }
    }),
    tagTypes: ["MetadataAttributes"],
    endpoints: (builder) => ({        
        getAttributeSettings: builder.query<IMetadataAttributeSetting[], void>({
            query: () => '/metadata/attribute-setting', 
            providesTags: ["MetadataAttributes"],
        }),    
        updateAttributeSetting: builder.mutation<void, IMetadataAttributeSetting>({
            query: (payload) => ({
                url: 'metadata/attribute-setting',
                method: 'PATCH',
                body: payload
            }),
            invalidatesTags: ["MetadataAttributes"]
        }),
        addAttributeSetting: builder.mutation<void, IMetadataAttributeSetting>({
            query: (payload) => ({
                url: 'metadata/attribute-setting',
                method: 'POST',
                body: payload
            }),
            invalidatesTags: ["MetadataAttributes"]
        }),
        deleteAttributeSetting: builder.mutation<void, IMetadataAttributeSetting>({
            query: (payload) => ({
                url: 'metadata/attribute-setting',
                method: 'DELETE',
                body: payload
            }),
            invalidatesTags: ["MetadataAttributes"]
        }),
    }),
})
export const { useGetAttributeSettingsQuery, useUpdateAttributeSettingMutation, useAddAttributeSettingMutation, useDeleteAttributeSettingMutation } = metadataApi

